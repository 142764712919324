import axios from "axios";

const state = {
    mensaje: ""
};


const mutations = {
    setMensaje: (state, mensaje) => {
        state.mensaje = mensaje;
    },
};

const actions = {
    async exportar({commit}, data) {
        try {
            let resp = await axios.post(`/v1/exportar/${data.entidad}/filtro`, data.filtro, {responseType: "arraybuffer"});

            if (resp?.data) {
                commit("setMensaje", "Descarga exitosa");
                return resp?.data
            } else {
                commit("setMensaje", "Descarga fallida");
                return null;
            }
        } catch (error) {
            return error;
        }
    },

    async exportarV2(_, data) {
        try {
            let resp = await axios.post(`/v1/exportar/${data.entidad}/filtro`, data.filtro, {responseType: "arraybuffer"});
            return {
                success: true,
                data: resp.data,
            }
        } catch (error) {
            return {
                success: false,
                data: error.response.data.errors
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
