import axios from "axios";

const state = {
 filtros: [],
 filtrosDetalle: [],
 entidad:null
};

const getters = {
  filtros: (state) => state.filtros,
  filtrosDetalle: (state) => state.filtrosDetalle,
  entidad: (state) => state.entidad,
};

const mutations = {
  setFiltros: (state, filtros) => {
    state.filtros = filtros;
  },
  setFiltrosDetalle: (state, filtrosDetalle) => {
    state.filtrosDetalle = filtrosDetalle;
  },
  setEntidad: (state, entidad) => {
    state.entidad = entidad;
  },
};

const actions = {
  async crear(_, data) {
    try {
      await axios.post(`/v1/filtros`, data);
    } catch (error) {
      return error;
    }
  },
  async consultarSinDetalle({ commit }, data) {
    try {
      let res = await axios.get(`/v1/filtros/${data.entidad}`);
      commit("setFiltros", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultar({ commit }, data) {
    try {
      let res = await axios.get(`/v2/filtros/${data.entidad}`);
      commit("setFiltros", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarDetalles({ commit }, data) {
    try {
      let res = await axios.get(`/v1/filtros/${data.id}/detalles`);
      commit("setFiltrosDetalles", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async eliminar({ commit, state }, data) {
    try {
      let res = await axios.delete(`/v1/filtros/${data.id}`);
      let copia = state.filtros;
      const filtrados = copia.filter(
        (item) => item.id !== res?.data?.result?.id
      );
      commit("setFiltros", filtrados);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
