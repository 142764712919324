<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-12">
            <h3 class="font-weight-bold">Procesar requerimiento</h3>
          </div>
        </div>
        <div class="white-content-general mb-4">
          <form name="f1" id="formElement">

            <CabeceraRequerimiento/>

            <div class="mt-4 ml-4">
              <h5 class="card-title">Actividades</h5>
              <div>
                <a
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#modalAgregarActividad"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                  <i class="icon ti-plus"></i> Agregar actividad</a
                >
              </div>
              <br/>
              <div class="table-responsive mb-3" v-if="actividades.length > 0">
                <table class="table table-sm table-borderless">
                  <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Placa</th>
                    <th>Tipo</th>
                    <th>Correctivo</th>
                    <th>Observación</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in actividades" :key="item.id">
                    <td>{{ item?.fecha }}</td>
                    <td>{{ item?.equipo?.placa }}</td>
                    <td>{{ item?.tipoActividad }}</td>
                    <td>{{ item?.tipoCorrectivo }}</td>
                    <td>{{ item?.observacion }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <a
                    href="javascript:void(0)"
                    @click="seleccionarTipoProceso('REQUERIMIENTO')"
                >
                  <i class="icon ti-close"></i> Finalizar requerimiento</a
                >
              </div>
            </div>


            <div class="mt-4 ml-4" v-if="mostrarRequerimiento">
              <div>
                <h5 class="card-title">Finalizar requerimiento</h5>
                <div class="form-check" v-if="actividades?.length">
                  <input
                      class="form-check-input ml-1"
                      type="radio"
                      id="ejecutado"
                      value="EJECUTADO"
                      v-model="requerimiento"
                      @click="seleccionarRequerimiento"
                  />
                  <label class="form-check-label" for="ejecutado">
                    EJECUTADO
                  </label>
                </div>
                <div class="form-check">
                  <input
                      class="form-check-input ml-1"
                      type="radio"
                      id="fallido"
                      value="FALLIDO"
                      v-model="requerimiento"
                      @click="seleccionarRequerimiento"
                  />
                  <label class="form-check-label" for="fallido"> FALLIDO </label>
                </div>
                <div class="form-group" v-if="mostrarMotivo">
                  <label>Motivo</label>
                  <select class="form-control" v-model="motivo">
                    <option value="" disabled>Seleccione un motivo</option>
                    <option value="DIRECCIÓN NO ENCONTRADA">
                      DIRECCIÓN NO ENCONTRADA
                    </option>
                    <option value="EQUIPO NO ENCONTRADO">
                      EQUIPO NO ENCONTRADO
                    </option>
                    <option value="OTRO MOTIVO">OTRO MOTIVO</option>
                  </select>
                </div>
                <h5 v-if="variable">Novedad</h5>
                <CustomVariableMultiple
                    :variable="variable"
                    :selectVariable="selectEjecutadoFallido"
                />
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Observación</label>
                  <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      v-model="observacion"
                  ></textarea>
                </div>
                <button
                    type="button"
                    class="btn btn-primary btn-block mt-3"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    @click="confirmar"
                    :disabled="!requerimiento"
                >
                  <i class="ti-check-box"></i> Finalizar requerimiento
                </button>
              </div>
            </div>
          </form>
          <Modal :mensaje="mensaje" :method="procesar"/>
          <ModalMessages
              :mensaje="mensajeProceso"
              :titulo="titulo"
              :method="cerrar"
          />
          <AgregarActividad
              titulo="Agregar actividad"
              :agregar="agregarActividad"
              origen="BODEGA"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../components/ModalMessages.vue";
import Modal from "../../../components/Modal.vue";
import CabeceraRequerimiento from "./CabeceraRequerimiento.vue";
import AgregarActividad from "../../../components/AgregarActividad.vue";
import CustomVariableMultiple from "@/components/CustomVariableMultiple.vue";

export default {
  components: {
    ModalMessages,
    CabeceraRequerimiento,
    Modal,
    AgregarActividad,
    CustomVariableMultiple
  },
  data() {
    return {
      observacion: "",
      idEquipos: [],
      requerimiento: "",
      mostrarMotivo: false,
      motivo: "",
      mensaje: "",
      mensajeProceso: null,
      titulo: "",
      ejecutarCerrar: false,
      tipoTrabajo: "",
      tipoProceso: "",
      mostrarActividad: false,
      mostrarRequerimiento: false,
      infoAdicional: [],
      variable: null
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.$store.commit("equipos/setEquipos", null);
  },
  mounted() {
    this.consultarActividadesXSolicitud();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarActividadesXSolicitud() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch(
            "actividadesMantenimiento/consultarXSolicitud",
            {
              idSolicitud: this.solicitudProveedorSeleccionada?.solicitud?.id,
            }
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    seleccionarRequerimiento(e) {
      if (e.target.value === "FALLIDO") {
        this.mostrarMotivo = true;
        //this.variable = `FALLIDO_${this.solicitudProveedorSeleccionada.solicitud.tipoSolicitud}`;
        this.variable = `FALLIDO_MANTENIMIENTO`;
      } else {
        this.mostrarMotivo = false;
        //this.variable = `EJECUTADO_${this.solicitudProveedorSeleccionada.solicitud.tipoSolicitud}`;
        this.variable = `EJECUTADO_MANTENIMIENTO`;
      }
    },
    seleccionarTipoProceso(tipo) {
      if (tipo === "REQUERIMIENTO") {
        this.mostrarRequerimiento = !this.mostrarRequerimiento;
      }
    },
    confirmar() {
      this.mensaje = "¿Desea continuar con el proceso de mantenimiento?";
    },
    async procesar() {
      if (
          this.requerimiento === "" ||
          !this.solicitudProveedorSeleccionada.id ||
          (this.requerimiento === "FALLIDO" && this.motivo.length === 0) ||
          this.observacion.length === 0 ||
          this.infoAdicional.length === 0
      ) {
        this.mensajeProceso = [
          "Por favor ingrese toda la información requerida antes de continuar con el proceso de retiro",
        ];
        this.titulo = "Advertencia";
        this.ejecutarCerrar = false;
        document.getElementById("modalButton").click();
        return;
      }
      const data = {
        estadoSolicitud: this.requerimiento,
        motivo: this.requerimiento === "EJECUTADO" ? "" : this.motivo,
        idSolicitudProveedor: this.solicitudProveedorSeleccionada.id,
        observacion: this.observacion,
        infoAdicional: this.infoAdicional
      };

      try {
        this.loaderSpinner();
        const resp = await this.$store.dispatch(
            "solicitudesProveedor/procesarMantenimiento",
            {
              data,
            }
        );
        this.mensajeProceso = [resp];
        this.titulo = "Mantenimiento";
        this.ejecutarCerrar = true;
        document.getElementById("modalButton").click();
      } catch (error) {
        this.mensaje = error;
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      if (this.ejecutarCerrar) {
        this.$router.go(-1);
      }
    },
    seleccionarEquipo(datos) {
      if (datos.tipo === "ADD") {
        this.idEquipos.push(datos.valor);
      }
      if (datos.tipo === "DELETE") {
        const idEquiposFiltrados = this.idEquipos.filter(
            (item) => item !== datos.valor
        );
        this.idEquipos = idEquiposFiltrados;
      }
    },
    async agregarActividad(data) {
      try {
        this.loaderSpinner();
        const formData = new FormData();
        data?.archivosArray?.map((item) => formData.append("file", item));
        formData.append("equipo", data?.tipo === 'SOLICITUD' ? 0 : data?.idEquipos[0]);
        formData.append("observacion", data?.observacion);
        formData.append("tipoActividad", data?.tipo);
        formData.append("tipoCorrectivo", data?.tipoTrabajo);
        formData.append(
            "solicitud",
            this.solicitudProveedorSeleccionada?.solicitud?.id
        );
        const resp = await this.$store.dispatch(
            "actividadesMantenimiento/crearActividad",
            {
              data: formData,
              idSolicitud: this.solicitudProveedorSeleccionada?.solicitud?.id,
            }
        );
        if (resp[0] === "ERROR") {
          this.mensajeProceso = [resp[1]];
          this.titulo = "Actividades";
          this.ejecutarCerrar = false;
          document.getElementById("modalButton").click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    selectEjecutadoFallido(infoAdicional) {
      this.infoAdicional = infoAdicional;
    },
  },
  computed: {
    solicitudProveedorSeleccionada() {
      return this.$store.getters[
          "solicitudesProveedor/solicitudProveedorSeleccionada"
          ]
          ? this.$store.getters[
              "solicitudesProveedor/solicitudProveedorSeleccionada"
              ]
          : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    equipos() {
      return this.$store.getters["equipos/equipos"]
          ? this.$store.getters["equipos/equipos"]
          : {};
    },
    actividades() {
      return this.$store.getters["actividadesMantenimiento/actividades"]
          ? this.$store.getters["actividadesMantenimiento/actividades"]
          : [];
    },
  },
};
</script>
