<template>
  <GestionBaseObjetivo />
</template>
<script>
import GestionBaseObjetivo from "./components/GestionBaseObjetivo";
export default {
  components: { GestionBaseObjetivo },
  mounted() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>