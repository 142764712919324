<template>
  <div>
    <div class="white-content-table">

      <i @click="consultar" class="icon-reload" title="Refrescar" style="cursor: pointer"></i>

      <div class="table-responsive">

        <table class="table table-striped">
          <thead>
          <tr>
            <th></th>
            <th>Estado</th>
            <th>Archivos</th>
            <th>Fecha</th>
            <th>Usuario</th>
            <th>Correctos</th>
            <th>Fallidos</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(item) in traslados" :key="item.id">
            <tr>
              <td style="width: 2%">
                <a v-if="item.estadoProceso ==='SOLICITADO'" title="Anular Traslado"
                   href="javascript:void(0)"
                   @click.stop="anular(item)"
                > <i class="ti-close text-danger"></i></a>
              </td>
              <td>
                <div
                    class="badge"
                    :class="
                      item?.estadoProceso === 'SOLICITADO'
                        ? 'badge-secondary'
                        :  item?.estadoProceso === 'RECIBIDO' ? 'badge-success': 'badge-secondary'
                    "
                >
                  {{ item?.estadoProceso }}
                </div>
              </td>
              <td>
                <FileDownload
                    v-if="item.nombreArchivo && item.rutaArchivo"
                    title="Archivo Datos"
                    icon="ti-file"
                    :file-name="item.nombreArchivo"
                    :folder-name="item.rutaArchivo"/>
                <FileDownload v-if="item.nombreAdjunto && item.rutaAdjunto"
                              title="Adjunto"
                              icon="ti-clip"
                              :file-name="item.nombreAdjunto"
                              :folder-name="item.rutaAdjunto"/>
              </td>

              <td>{{ item.fecha }}</td>
              <td>{{ item.usuario }}</td>
              <td>
                <a title="Visualizar"
                   href="javascript:void(0)"
                   @click.stop="toggleDetail(item.id)"
                > {{ item.correctos }}</a>
              </td>
              <td>
                <a
                    title="Visualizar"
                    href="javascript:void(0)"
                    @click.stop="visualizarDetalleFallido(item.fallidosDetalle)"
                > {{ item.fallidos }}</a>
              </td>

            </tr>

            <tr v-if="selectedId === item.id" :key="`detail-${item.id}`">
              <td colspan="6">
                <TrasladosDetalle :id-archivo="item.id"/>
              </td>
            </tr>

          </template>

          </tbody>
        </table>

        <Paginacion :updatePage="updatePage"/>

      </div>
    </div>


    <ModalDetalleFallidos v-model:display="displayModalDetalle" :detalle="detalleFallidos"/>

    <Modal
        mensaje="Se eliminará el registro, ¿Está seguro?"
        :method="procesarAnular"
    />
    <button
        type="button"
        v-show="false"
        id="exampleModalButton"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#exampleModal"
        data-backdrop="static"
        data-keyboard="false"
    >
      Modal messages
    </button>

  </div>

</template>
<script>
import TrasladosDetalle from "@/views/procesos/traslados/components/TrasladosDetalle.vue";
import FileDownload from "@/components/FileDownload.vue";
import Paginacion from "@/components/Paginacion.vue";
import ModalDetalleFallidos from "@/components/ModalDetalleFallidos.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
    ModalDetalleFallidos,
    FileDownload,
    Paginacion,
    TrasladosDetalle
  },
  data() {
    return {
      pagina: 1,
      selectedItems: [],
      displayModal: false,
      displayModalDetalle: false,
      error: false,
      detalleFallidos: [],
      selectedId: null,
      itemSelected: null,
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      const data = {
        pagina: pagina || 1,
      };
      try {
        this.loaderSpinner();
        await this.$store.dispatch("traslados/consultarTrasladosPagina", data);
        await this.$store.dispatch(
            "traslados/consultarTrasladosTotalPagina"
        );
        let paginas = await this.$store.getters["traslados/totalPaginas"].totalPaginas;
        await this.$store.commit("pagination/setTotalPaginas", paginas);
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    visualizarDetalleFallido(fallido) {
      this.displayModalDetalle = true;
      this.error = false;
      this.detalleFallidos = JSON.parse(fallido);

    },

    updatePage(page) {
      this.pagina = page;
    },

    toggleDetail(id) {
      this.selectedId = this.selectedId === id ? null : id;
    },
    async anular(item) {
      this.itemSelected = item
      document.getElementById("exampleModalButton").click();
    },
    async procesarAnular() {
      try {
        const resp = await this.$store.dispatch("traslados/anular", {id: this.itemSelected.id});
        if (resp.success) {
          await this.consultar();
        } else {
          alert("Error al anular el registro")
        }
      } catch (error) {
        alert("Error al anular el registro")
      }
    }
  },
  computed: {
    traslados() {
      return this.$store.getters["traslados/traslados"]
          ? this.$store.getters["traslados/traslados"]
          : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
}
</script>
