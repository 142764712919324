<template>
  <div class="row">
    <div
        class="modal"
        tabindex="-1"
        :style="display ? 'display: block;' : 'display: none;'"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detalle de Filas Fallidas</h5>
          </div>
          <div class="modal-body">

            <p v-for="(index,detalleValor) in detalle" :key="detalleValor">
              Linea {{ detalleValor }} : {{ index }} </p>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-general-off btn-secondary"
                data-bs-dismiss="modal"
                @click="cerrarModalDetalle"
            >
              <i class="ti-close"></i> Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props : {
    detalle : {
      type: Array,
      required: true
    },
    display : {
      type: Boolean,
      required: true
    }
  },
  methods : {
    cerrarModalDetalle() {
      this.$emit('close-modal');
      this.$emit('update:display', false);
    },
  }
}
</script>
