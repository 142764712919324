import axios from "axios";

const state = {
    solicitudesProveedor: null,
    solicitudProveedorSeleccionada: null,
    coordenadas: [],
    puntosSeleccionados: [],
    mensaje: ""
};

const getters = {
    solicitudesProveedor: (state) => state.solicitudesProveedor,
    solicitudProveedorSeleccionada: (state) => state.solicitudProveedorSeleccionada,
    coordenadas: (state) => state.coordenadas,
    puntosSeleccionados: (state) => state.puntosSeleccionados,
};

const mutations = {
    setSolicitudes: (state, solicitudes) => {
        state.solicitudesProveedor = solicitudes;
    },
    setSolicitudProveedorSeleccionada: (state, solicitudProveedorSeleccionada) => {
        state.solicitudProveedorSeleccionada = solicitudProveedorSeleccionada;
    },
    setCoordenadas: (state, coordenadas) => {
        state.coordenadas = coordenadas;
    },
    setPuntosSeleccionados: (state, punto) => {
        state.puntosSeleccionados = [...state.puntosSeleccionados, punto];
    },
    updatePuntosSeleccionados: (state, puntos) => {
        state.puntosSeleccionados = [...puntos];
    },
    deletePuntoSeleccionado: (state, punto) => {
        const puntosActualizados = state.puntosSeleccionados.filter(
            (item) => item !== punto
        );
        state.puntosSeleccionados = [...puntosActualizados];
    },
    inicializar: (state) => {
        state.solicitudesProveedor = null
    },
    inicializarPuntosSeleccionados: (state) => {
        state.puntosSeleccionados = []
    },
    setMensaje: (state, mensaje) => {
        state.mensaje = mensaje;
    }
};

const actions = {
    async consultar({commit}) {
        try {
            let res = await axios.get(`/v1/solicitudes-asignadas`);
            commit("setSolicitudes", res?.data?.result);
        } catch (error) {
            return error;
        }
    },
    async procesarRetiro({commit}, data) {
        try {
            const headers = {'Content-Type': 'multipart/form-data'};
            await axios.post(`v1/solicitudes-asignadas/procesar-retiro`, data?.data, {headers});
            commit("setMensaje", "Retiro exitoso");
            return "Proceso de retiro exitoso";
        } catch (error) {
            return "No se pudo procesar el requerimiento";
        }
    },
    async procesarInstalacion({commit}, data) {
        try {
            const headers = {'Content-Type': 'multipart/form-data'};
            await axios.post(`v1/solicitudes-asignadas/procesar-instalacion`, data?.data, {headers});
            commit("setMensaje", "Instalación exitosa");
            return "Proceso de instalación exitoso";
        } catch (error) {
            return error?.response?.data?.errors?.[0] || "No se pudo procesar el requerimiento";
        }
    },
    async procesarMantenimiento({commit}, data) {
        try {

            await axios.post(`v1/solicitudes-asignadas/procesar-mantenimiento`, data?.data);
            commit("setMensaje", "Mantenimiento exitoso");
            return "Proceso de mantenimiento exitoso";
        } catch (error) {
            return "No se pudo procesar el requerimiento";
        }
    },


    async procesarMantenimientoZone({commit}, data) {
        try {
            const headers = { 'Content-Type': 'multipart/form-data' };
            await axios.post(`/v2/solicitudes-asignadas/procesar-mantenimiento`, data?.data, {headers});
            commit("setMensaje", "Mantenimiento exitoso");
            return "Proceso de mantenimiento exitoso";
        } catch (error) {
            return "No se pudo procesar el requerimiento";
        }
    },


    async procesarPrimeraPosicion({commit}, data) {
        try {
            const headers = { 'Content-Type': 'multipart/form-data' };
            await axios.post(`/v1/solicitudes-asignadas/procesar-primera-posicion`, data?.data, {headers});
            commit("setMensaje", "Primera Posición exitoso");
            return "Proceso de cambio primera posición exitoso";
        } catch (error) {
            return "No se pudo procesar el requerimiento";
        }
    },
    async reasignarRequerimiento({commit}, data) {
        try {
            await axios.post(`/v1/reasignaciones`, data?.data);
            commit("setMensaje", "Reasignamiento exitoso");
            return "Proceso de Reasignación exitoso";
        } catch (error) {
            return "ERROR";
        }
    },
    async reasignarRequerimientoZone({commit}, data) {
        try {
            await axios.post(`/v2/reasignaciones`, data?.data);
            commit("setMensaje", "Reasignamiento exitoso");
            return "Proceso de Reasignación exitoso";
        } catch (error) {
            return "ERROR";
        }
    },
    async procesarCambio({commit}, data) {
        try {
            const headers = {'Content-Type': 'multipart/form-data'};
            await axios.post(`/v1/solicitudes-asignadas/procesar-cambio`, data?.data, {headers});
            commit("setMensaje", "Cambio exitoso");
            return "Proceso de cambio exitoso";
        } catch (error) {
            return error?.response?.data?.errors?.[0] || "No se pudo procesar el requerimiento";
        }
    },
    async buscarSolicitudXId({commit},data){
        try {
            let res = await axios.get(`/v1/solicitudes/${data.solicitud}`);
            commit("setSolicitudProveedorSeleccionada", res?.data?.result);
        } catch (error) {
            return error;
        }
    },

    async buscarSolicitudProveedorBySolicitud(_,data){
        try {
            let res = await axios.get(`/v1/solicitudes-proveedor/${data.idSolicitud}/solicitud`);
            return res.data.result;
        } catch (error) {
            return error;
        }
    }

};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
