<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-3">
            <h3 class="font-weight-bold">Gestión de Eventos</h3>
          </div>
          <div class="col-md-6">
          
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">

            </div>
            <div class="col-md-6">
              <button @click="agregar" class="btn btn-primary btn-general float-right ml-2">
                <span class="icon-plus"></span> Agregar
              </button>
              <button
                @click="exportExcel"
                class="btn btn-primary btn-general float-right"
                :disabled="eventos.length === 0"
              >
                <span class="icon-download"></span> Exportar a excel
              </button>
            </div>
          </div>
        </div>
        
        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th>Cliente</th>
                  <th>Gerencia General</th>
                  <th>Gerencia Ventas</th>
                  <th>Provincia</th>
                  <th>Sector</th>
                  <th>Dirección</th>
                  <th>Teléfono cliente</th>
                  <th>Nombre evento</th>
                  <th>Fecha de entrega</th>
                  <th>Fecha de retiro</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in eventos" :key="item.id">
                  <td>
                    <!-- <a
                        href="javascript:void(0)"
                        @click="editar(item)"
                    ><i class="ti-pencil btn-icon-append"></i></a
                    > -->
                  </td>
                  <td>{{ item.codigoCliente }}</td>
                  <td>{{ item.gerenciaGeneral }}</td>
                  <td>{{ item.gerenciaVentas }}</td>
                  <td>{{ item.provincia }}</td>
                  <td>{{ item.sectorBarrio }}</td>
                  <td>{{ item.direccion }}</td>
                  <td>{{ item.telefono }}</td>
                  <td>{{ item.nombreEvento }}</td>
                  <td>
                    {{ item?.fechaEntrega }}
                  </td>
                  <td>{{ item.fechaRetiro }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { saveExcel } from "@progress/kendo-vue-excel-export";
export default {
  name: "GestionEventosUsuario",
  data() {
    return {};
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("eventos/consultarEventosXUsuario");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      await this.$store.commit("eventos/setEventoSeleccionado", null);
      this.$router.push({ name: "EventoForm" });
    },
    exportExcel() {
      saveExcel({
        data: this.eventos,
        fileName: "eventos",
        columns: [
          { field: "codigoCliente", title: "Cliente" },
          { field: "gerenciaGeneral", title: "Gerencia General" },
          { field: "gerenciaVentas", title: "Gerencia de Ventas" },
          { field: "provincia", title: "Provincia" },
          { field: "sectorBarrio", title: "Sector" },
          { field: "direccion", title: "Dirección" },
          { field: "telefono", title: "Teléfono" },
          {
            field: "nombreEvento",
            title: "Nombre evento",
          },
          { field: "fechaEntrega", title: "Fecha de entrega" },
          { field: "fechaRetiro", title: "Fecha de retiro" },
        ],
      });
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    eventos() {
      return this.$store.getters["eventos/eventos"]
        ? this.$store.getters["eventos/eventos"]
        : [];
    },
  },
};
</script>
<style></style>
