<template>
    <div class="mb-3">
        <button
            class="btn btn-block btn-secondary btn font-weight-medium auth-form-btn"
            @click.prevent="loginWithSSO">
            Iniciar Sesión con SSO
        </button>
    </div>        
</template>
<script>

  export default {
    name: "LoginSso",
    components: {},
    data() {
      return {

      };
    },
    methods: {
        async loginWithSSO(){
            const resp = await this.$store.dispatch("auth/sso"); 
            const { error, message } = resp;
            if (error) {
                this.error = true;
                this.$router.push({ name: "Login" });
                this.$emit('send-to-parent', message);
                return;
            }

            const ruta=await this.$store.dispatch("auth/buscarRuta");

            if(ruta.home){
              this.$router.replace({ name: ruta.home });
            }else{
              this.$router.replace({ name: "Welcome" });
            }
            
        },
    },
    computed: {

    },mounted() {

    }
  };
  </script>
  