<template>
  <div>
    <input
        type="password"
        id="clave"
        v-model="clave"
        @input="validatePassword"
        class="form-control"
        :class="{'is-invalid': passwordError}"
        :disabled="isDisabled"
    >
    <div v-if="passwordError" class="invalid-feedback">
      {{ passwordError }}
    </div>

    <ul class="list-unstyled mt-2 password" v-if="mostrar">
      <li :class="{'text-success': validations.hasUpperCase, 'text-danger': !validations.hasUpperCase} ">
        <i :class="{'ti-arrow-circle-down': validations.hasUpperCase, 'ti-na': !validations.hasUpperCase}"></i>&nbsp;
        Una mayúscula
      </li>
      <li :class="{'text-success': validations.hasNumber, 'text-danger': !validations.hasNumber}">
        <i :class="{'ti-arrow-circle-down': validations.hasNumber, 'ti-na': !validations.hasNumber}"></i>&nbsp;
        Un número
      </li>
      <li :class="{'text-success': validations.hasSpecialChar, 'text-danger': !validations.hasSpecialChar}">
        <i :class="{'ti-arrow-circle-down': validations.hasSpecialChar, 'ti-na': !validations.hasSpecialChar}"></i>&nbsp;
        Un carácter especial
      </li>
      <li :class="{'text-success': validations.hasMinLength, 'text-danger': !validations.hasMinLength}">
        <i :class="{'ti-arrow-circle-down': validations.hasMinLength, 'ti-na': !validations.hasMinLength}"></i>&nbsp;
        Mínimo 8 caracteres
      </li>
    </ul>


  </div>
</template>

<script>
export default {

  props: {
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      clave: "",
      passwordError: '',
      formMessage: '',
      formMessageClass: '',
      mostrar: false,
      validations: {
        hasUpperCase: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasMinLength: false,
      }
    };
  },
  methods: {
    validatePassword() {
      if (this.clave.length > 0) {
        this.mostrar = true;
      } else {
        this.mostrar = false;
      }
      const hasUpperCase = /[A-Z]/.test(this.clave);
      const hasNumber = /\d/.test(this.clave);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(this.clave);
      const hasMinLength = this.clave.length >= 8;

      this.validations = {
        hasUpperCase,
        hasNumber,
        hasSpecialChar,
        hasMinLength,
      };

      this.passwordError = '';

      if (!hasUpperCase) {
        this.passwordError = 'La clave debe incluir al menos una mayúscula.';
      }
      if (!hasNumber) {
        this.passwordError = 'La clave debe incluir al menos un número.';
      }
      if (!hasSpecialChar) {
        this.passwordError = 'La clave debe incluir al menos un carácter especial.';
      }
      if (!hasMinLength) {
        this.passwordError = 'La clave debe tener al menos 8 caracteres.';
      }

      // Emitir evento con el estado de validación
      const isValid = hasUpperCase && hasNumber && hasSpecialChar && hasMinLength;
      this.$emit('validation-changed', isValid);
      this.$emit('password-changed', this.clave);
    }
  }
}
</script>
