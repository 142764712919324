
const state = {
    isPreventNavigation: false
};

const mutations = {
    setIsPreventNavigation(state, data) {
        state.isPreventNavigation = data;
    }
};

const actions = {
    updateIsPreventNavigation({commit}, data) {
        commit('setIsPreventNavigation', data);
    }
};

const getters = {
    isPreventNavigation: (state) => state.isPreventNavigation
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
