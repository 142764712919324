<template>
  <div class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Permisos Usuarios</h3>
          </div>

        </div>

        <div class="mb-3" name="f1" id="formElement">
          <div class="white-content-general">
            <Form
                @submit="submit"
                :validation-schema="formValidation"
                v-slot="{ errors }"
            >
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="busqueda"> Usuario *</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="valorBusqueda"
                        aria-label="Text input with segmented dropdown button"
                        @input="buscar"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="nombre">Nombre *</label>
                    <fieldset>
                      <Field type="text" name="nombre" id="nombre" class="form-control" placeholder="Nombre de Usuario"
                             disabled="true"
                             v-model.trim="nombre"/>
                      <MensajeValidacion :mensaje="errors.nombre"/>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div class="row" v-if="usuarios">
                <div class="col-md-12" >
                  <p style="cursor: pointer" class=" text-muted" v-for="usuario in usuarios" :key="usuario.id"
                      @click="seleccionarUsuario(usuario)">
                    {{ usuario.usuario }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Proveedores *</label>
                    <fieldset>
                      <Field
                          as="select"
                          name="proveedor"
                          class="form-control"
                          v-model="proveedor"
                          placeholder="Proveedor"
                          @change="consultarBodega"
                      >
                        <option value="" disabled>
                          Seleccione un proveedor
                        </option>
                        <option
                            v-for="proveedor in proveedores"
                            :key="proveedor.id"
                            :value="proveedor.id"
                        >
                          {{ proveedor.nombreProveedor }}
                        </option>
                      </Field>
                    </fieldset>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="bodegas">Bodegas *</label>
                    <fieldset>
                      <Field
                          name="bodega"
                          as="select"
                          class="form-control"
                          v-model="formData.bodega"

                      >
                        <option value="" disabled>
                          Seleccione una bodega
                        </option>
                        <option
                            v-for="bodega in bodegas"
                            :key="bodega.id"
                            :value="bodega.id"
                        >
                          {{ bodega.nombre }}
                        </option>
                      </Field>
                      <MensajeValidacion :mensaje="errors.bodega"/>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <button
                      type="submit"
                      id="form-submit"
                      class="btn btn-primary btn-general mr-2"
                  >
                    <i class="icon-plus"></i> Asignar
                  </button>
                  <button
                      type="button"
                      class="btn btn-secondary btn-general-off"
                      @click="cerrar"
                  >
                    <i class="ti-close"></i> Cerrar
                  </button>
                </div>
              </div>
              <ErrorMessage :mensaje="mensaje" v-if="error"/>
            </Form>
          </div>

        </div>
        <div class="white-content-table" v-if="bodegaEstado">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th width="5%"></th>
                <th>Bodega Asignada</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <a
                      href="javascript:void(0)"
                      @click="eliminar(bodegasUsuario.id)"
                  ><i class="ti-trash btn-icon-append"></i></a
                  >&nbsp;
                </td>
                <td>{{ bodegasUsuario.bodega.nombre }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Form, Field} from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";

export default {
  name: "PermisoUsuarioForm",
  components: {
    Field,
    MensajeValidacion,
    Form,
    ErrorMessage
  },
  data() {
    const formValidation = {
      proveedor: "required",
      bodega: "required",
      nombre: "required",


    };
    return {
      archivo: null,
      valorBusqueda: "",
      mensaje: [],
      error: false,
      usuarioSeleccionado: null,
      usuarios: [],
      proveedor: null,
      nombre: null,
      bodegaEstado: false,
      bodegasUsuario: [],
      formData: {
        usuario: null,
        bodega: null,
      },
      formValidation,
      bodegas: [],
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  mounted() {
    this.consultarProveedores();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarProveedores() {
      try {
        await this.$store.dispatch("proveedores/consultar");
      } catch (error) {
        alert("No se pudo realizar la consulta de los proveedores");
      }
    },
    buscar() {
      try {
        this.loaderSpinner();
        this.nombre = "";
        if (this.valorBusqueda.length >= 4) {
          const resp = this.$store.dispatch(
              "usuarios/consultarByUserName",
              {
                username: this.valorBusqueda,
              }
          );
          if (!resp) {
            this.mensaje = ["No se recuperó información con el username ingresado"];
            document.getElementById("modalButton").click();
          }

          this.usuarios = this.$store.getters["usuarios/usuarios"]
              ? this.$store.getters["usuarios/usuarios"]
              : null;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async consultarBodega() {
      await this.$store.dispatch(
          "bodegas/consultarXProveedor",
          {
            proveedor: this.proveedor,
          }
      );
      this.bodegas = this.$store.getters["bodegas/bodegas"]
          ? this.$store.getters["bodegas/bodegas"]
          : [];
    },
    async submit() {
      this.error = false;
      this.loaderSpinner();
      try {

        if (this.usuarioSeleccionado) {
          this.nombre = this.usuarioSeleccionado.nombre;
          this.formData.usuario = this.usuarioSeleccionado.id;

        }
        let res = await this.$store.dispatch(
            "usuarios/crearUsuarioBodega",
            this.formData
        );

        if (res?.response?.data?.errors) {
          this.$swal("", res?.response?.data?.errors[0], "error");
        }
        await this.$store.dispatch(
            "usuariosBodega/consultarBodegaUsuario",
            {idUsuario: this.usuarioSeleccionado.id}
        );
        let bodegasUsuario = this.$store.getters["usuariosBodega/usuarioBodega"]
            ? this.$store.getters["usuariosBodega/usuarioBodega"]
            : [];
        if (bodegasUsuario.bodega) {
          this.bodegasUsuario = bodegasUsuario;
          this.bodegaEstado = true;
        } else {
          this.bodegaEstado = false;
          this.bodegasUsuario = [];
        }


      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo asignar permisos al usuario";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.push({name: "UsuariosVehiculosTransporte"});
    },
    async seleccionarUsuario(item) {

      this.valorBusqueda = item.usuario;
      this.usuarioSeleccionado = item;
      this.nombre = item.nombre;
      this.usuarios = [];
      await this.$store.dispatch(
          "usuariosBodega/consultarBodegaUsuario",
          {idUsuario: this.usuarioSeleccionado.id}
      );
      let bodegasUsuario = this.$store.getters["usuariosBodega/usuarioBodega"]
          ? this.$store.getters["usuariosBodega/usuarioBodega"]
          : [];
      if (bodegasUsuario.bodega) {
        this.bodegasUsuario = bodegasUsuario;
        this.bodegaEstado = true;
      } else {
        this.bodegaEstado = false;
        this.bodegasUsuario = [];
      }
    },
    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la asignación de bodega?",
        //icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("usuariosBodega/eliminar", {id: id});
            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
            this.bodegasUsuario = [];
            this.bodegaEstado = false;
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      });
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    proveedores() {
      return this.$store.getters["proveedores/proveedores"]
          ? this.$store.getters["proveedores/proveedores"]
          : [];
    }
  },
};
</script>
<style></style>
