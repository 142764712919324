<template>
  <div>
    <button
        @click="confirmar"
        class="btn btn-general-off btn-secondary float-right mr-2 mb-3"
    >
      <span class="icon-check"></span> Aprobar Seleccionados
    </button>
    <table class="table table-striped">
      <thead>
      <tr>
        <th></th>
        <th>ActivoCodBarras</th>
        <th>ActivoCategoria</th>
        <th>ActivoSubcategoria</th>
        <th>ActivoDescripcion</th>
        <th>ActivoCapacidad</th>
        <th>Bodega Origen</th>
        <th>Bodega Destino</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in detalles" :key="item.id">
        <td style="width: 2%">
          <input type="checkbox" :checked="isSelected(item.id)" @change="selectItem(item)"
          />
        </td>
        <td>{{ item.equiposFrios?.placa }}</td>
        <td>{{ item.equiposFrios?.tipoEquipo }}</td>
        <td>{{ item.equiposFrios?.subCategoria }}</td>
        <td>{{ item.equiposFrios?.descripcion }}</td>
        <td>{{ item.equiposFrios?.capacidadCajas }}</td>
        <td>{{ item.bodegaOrigen?.nombre }}</td>
        <td>{{ item.bodegaDestino?.nombre }}</td>
      </tr>
      </tbody>
    </table>

    <Modal
        mensaje="¿Desea aprobar los registros seleccionados?"
        :method="procesar"
    />
    <button
        type="button"
        v-show="false"
        id="exampleModalButton"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#exampleModal"
        data-backdrop="static"
        data-keyboard="false"
    >
      Modal messages
    </button>

  </div>
</template>
<script>

import Modal from "@/components/Modal.vue";

export default {
  components: {Modal},
  props: {
    idArchivo: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      detalles: [],
      selectedItems: []
    }
  },

  created() {
    this.consultarDetalles();
  },

  methods: {
    async consultarDetalles() {
      try {
        const resp = await this.$store.dispatch("traslados/consultarTraslados", this.idArchivo);
        this.detalles = resp?.data?.result
      } catch (error) {
        alert("No se pudo realizar la consulta");
      }
    },

    async consultar() {
      try {
        await this.$store.dispatch("traslados/consultarTrasladosPendientes");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      }
    },

    isSelected(id) {
      return this.selectedItems.includes(id);
    },
    selectItem(check) {
      const findedOption = this.selectedItems?.findIndex(
          (item) => item === check.id
      );
      let arraySelectedItems = [];
      if (findedOption === -1) {
        arraySelectedItems = [...this.selectedItems, check.id];
      } else {
        arraySelectedItems = this.selectedItems.filter(
            (item) => item !== check.id
        );
      }
      this.selectedItems = arraySelectedItems;
    },
    async procesar() {
      const resp = await this.$store.dispatch(
          "trasladosAprobacion/aprobarSeleccion",
          {
            idArchivo: this.idArchivo,
            idTraslados: this.selectedItems
          }
      );

      if (resp.success) {
        await this.consultar()
        await this.consultarDetalles()
      } else {
        alert("Error en el proceso de aprobación")
      }

    },
    confirmar() {
      if (this.selectedItems.length === 0) {
        alert("Debe seleccionar al menos 1 elemento")
        return
      }
      document.getElementById("exampleModalButton").click();
    },
  },
}
</script>
