<template>
  <a href="#" @click.stop="download"><i :class="icon" :title="title"></i></a>
</template>
<script>
export default {
  props: {
    folderName: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async download() {
      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch("commons/download", {

          fileName: this.fileName,
          folderName: this.folderName

        });
        if (resp) {
          const url = window.URL.createObjectURL(new Blob([resp]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.fileName);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    }
  }
}
</script>
