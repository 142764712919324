<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-4">
            <h3 class="font-weight-bold">Requerimientos Asignados</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <p class="lh-m mb-0">
                <span class="card-title">Total registros: </span>
                <span class="txt-celeste font-weight-bold fs-l">{{ totalRegistros }}</span>
              </p>
            </div>
            <div class="col-md-6" v-if="rutas.length">
              <button @click="abrirMapa" class="btn btn-primary btn-general float-right" v-if="!mostrarMapa">
                <span class="icon-map"></span> Mapa
              </button>
              <button @click="abrirTabla" class="btn btn-primary btn-general float-right" v-if="!mostrarTabla">
                <span class="icon-file"></span> Tabla
              </button>
              <button @click="exportExcel" class="btn btn-primary btn-general float-right mr-2">
                <span class="icon-download"></span> Exportar
              </button>
            </div>
          </div>
        </div>


        <div class="white-content-table">
          <div v-if="mostrarTabla">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th></th>
                  <th>Id</th>
                  <th>{{ this.$config.labelTicket }}</th>
                  <th>{{ this.$config.labelBodega }}</th>
                  <th>{{ this.$config.labelCodigoCliente }}</th>
                  <th>Tipo</th>
                  <th>Fecha solicitud</th>
                  <th>Días Transcurridos</th>
                  <th>{{ this.$config.labelGerenciaGeneral }}</th>
                  <th>{{ this.$config.labelGerenciaVentas }}</th>
                  <th>ECB</th>
                  <th>{{ this.$config.labelNombreCliente }}</th>
                  <th>{{ this.$config.labelEstablecimiento }}</th>
                  <th>{{ this.$config.labelDireccion }}</th>
                  <th>Tipo de caso</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in rutas" :key="item.id"
                    :class="item?.tipoCaso === 'COOL INVENTORY BOT' ? 'bgFila' : ''">
                  <td>
                    <a title="Procesar Solicitud" href="javascript:void(0)" @click="procesar(item)"
                    ><i class="ti-angle-right"></i
                    ></a>&nbsp;
                    <a title="Devolver Solicitud" href="javascript:void(0)" @click="devolver(item)"
                    ><i class="ti-close text-danger"></i
                    ></a>
                  </td>
                  <td>{{ item.solicitudProveedor.solicitud.id }}</td>
                  <td>{{ item.solicitudProveedor.solicitud.numeroTicket }}</td>
                  <td>{{ item.solicitudProveedor.bodega.nombre }}</td>
                  <td>{{ item.solicitudProveedor.solicitud.codigoCliente }}</td>
                  <td>
                    <strong>{{
                        item.solicitudProveedor.solicitud.tipoSolicitud
                      }}</strong>
                  </td>
                  <td>
                    {{ item.solicitudProveedor.solicitud.fechaSolicitud }}
                  </td>
                  <td>
                    <div
                        class="badge"
                        :class="
                          item.solicitudProveedor.solicitud
                            .diasTranscurridosLabolables < 3
                            ? 'badge-success'
                            : item.solicitudProveedor.solicitud
                                .diasTranscurridosLabolables > 5
                            ? 'badge-danger'
                            : 'badge-warning'
                        "
                    >
                      {{
                        item.solicitudProveedor.solicitud
                            .diasTranscurridosLabolables
                      }}
                      día(s)
                    </div>
                  </td>
                  <td>
                    {{ item.solicitudProveedor.solicitud.gerenciaGeneral }}
                  </td>
                  <td>
                    {{ item.solicitudProveedor.solicitud.gerenciaVentas }}
                  </td>
                  <td>{{ item.solicitudProveedor.solicitud.ecb }}</td>
                  <td>{{ item.solicitudProveedor.solicitud.nombre }}</td>
                  <td>
                    {{
                      item.solicitudProveedor.solicitud.nombreEstablecimiento
                    }}
                  </td>
                  <td>{{ item.solicitudProveedor.solicitud.direccion }}</td>
                  <td>{{ item.solicitudProveedor.solicitud.tipoCaso }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <MapaTecnico
            :coordenadas="coordenadas"
            :seleccionarPunto="procesarMapa"
            v-if="mostrarMapa"
        />
        <Modal
            mensaje="Se devolverá el requerimiento, ¿Está seguro?"
            :method="procesarDevolver"
        />
        <button
            id="modalConfirmacion"
            type="button"
            data-toggle="modal"
            data-target="#exampleModal"
            v-show="false"
        ></button>
      </div>
    </div>
  </div>
</template>
<script>
import MapaTecnico from "../../solicitudesTecnico/components/MapaTecnico.vue";
import {saveExcel} from "@progress/kendo-vue-excel-export";
import Modal from "../../../components/Modal.vue";

export default {
  name: "GestionSolicitudesTecnico",
  components: {MapaTecnico, Modal},
  data() {
    return {
      mostrar: false,
      mensaje: "",
      tipoFiltro: "",
      mostrarTabla: true,
      mostrarMapa: false,
      coordenadas: [],
      requerimientoSeleccionado: null,
      country: "EC"
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  mounted() {
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("usuariosBodega/consultarBodegaTecnico");

        this.country = this.$config.countryFlag
        if (this.country === 'EC') {
          await this.$store.dispatch("ruta/consultarRutaTecnicos", {
            idBodegaTecnico: this.usuarioBodega?.bodegaTecnico?.id,
          });
        } else {
          await this.$store.dispatch("ruta/consultarRutaTecnicosZone");
        }
        this.configurarCoordenadas(this.rutas);
      } catch (error) {
        this.mensaje = error;
      } finally {
        this.loader.hide();
      }
    },
    abrirMapa() {
      this.configurarCoordenadas();
      this.mostrarTabla = false;
      this.mostrarMapa = true;
    },
    abrirTabla() {
      this.mostrarTabla = true;
      this.mostrarMapa = false;
    },
    configurarCoordenadas() {
      let coordenadas = [];
      this.rutas.map((item) =>
          coordenadas.push({
            lat: item.solicitudProveedor.solicitud.latitud,
            lng: item.solicitudProveedor.solicitud.longitud,
            id: item.id,
            requerimiento: item,
          })
      );
      this.coordenadas = coordenadas;
    },
    async procesar(item) {
      await this.$store.commit("ruta/setRutaSeleccionada", item);
      await this.$store.commit(
          "solicitudesProveedor/setSolicitudProveedorSeleccionada",
          item?.solicitudProveedor
      );
      if (item.solicitudProveedor.solicitud.tipoSolicitud === "CAMBIO") {
        this.$router.push({
          name: "ProcesarCambioTecnicoForm",
        });
        return;
      }
      if (item.solicitudProveedor.solicitud.tipoSolicitud === "MANTENIMIENTO") {
        this.$router.push({
          name: "ProcesarMantenimientoTecnicoForm",
        });
        return;
      }
      if (item.solicitudProveedor.solicitud.tipoSolicitud === "RETIRO") {
        this.$router.push({
          name: "ProcesarRetiroTecnicoForm",
        });
        return;
      }
      if (item.solicitudProveedor.solicitud.tipoSolicitud === "SOLICITUD" || item.solicitudProveedor.solicitud.tipoSolicitud === "INSTALACION") {
        this.$router.push({
          name: "ProcesarInstalacionTecnicoForm",
        });
        return;
      }
      if (item.solicitudProveedor.solicitud.tipoSolicitud === "PRIMERA_POSICION") {
        this.$router.push({
          name: "ProcesarPrimeraPosicion",
        });
        return;
      }
    },
    async procesarMapa(item) {
      await this.$store.commit("ruta/setRutaSeleccionada", item.requerimiento);
      if (item.requerimiento.solicitudProveedor.solicitud.tipoSolicitud === "MANTENIMIENTO") {
        this.$router.push({
          name: "ProcesarMantenimientoTecnicoForm",
        });
        return;
      }
      if (item.requerimiento.solicitudProveedor.solicitud.tipoSolicitud === "RETIRO") {
        this.$router.push({
          name: "ProcesarRetiroTecnicoForm",
        });
        return;
      }
      if (item.requerimiento.solicitudProveedor.solicitud.tipoSolicitud === "SOLICITUD") {
        this.$router.push({
          name: "ProcesarInstalacionTecnicoForm",
        });
        return;
      }

      if (item.requerimiento.solicitudProveedor.solicitud.tipoSolicitud === "CAMBIO") {
        this.$router.push({
          name: "ProcesarCambioTecnicoForm",
        });
        return;
      }

    },
    devolver(item) {
      this.requerimientoSeleccionado = item;
      document.getElementById("modalConfirmacion").click();
    },
    async procesarDevolver() {
      try {
        await this.$store.dispatch("solicitudesTecnico/devolucion", {idSolicitudProveedor: this.requerimientoSeleccionado?.solicitudProveedor?.id});
        this.consultar();
      } catch (error) {
        this.mensaje = error;
      }
    },
    exportExcel() {
      saveExcel({
        data: this.rutas,
        fileName: "ruta-tecnico",
        columns: [
          {field: "solicitudProveedor.solicitud.id", title: "Id"},
          {field: "solicitudProveedor.solicitud.numeroTicket", title: "Ticket"},
          {field: "solicitudProveedor.bodega.nombre", title: "Bodega"},
          {field: "solicitudProveedor.solicitud.codigoCliente", title: "Cliente"},
          {field: "solicitudProveedor.solicitud.tipoSolicitud", title: "Tipo"},
          {field: "solicitudProveedor.solicitud.fechaSolicitud", title: "Fecha solicitud"},
          {field: "solicitudProveedor.solicitud.diasTranscurridosLabolables", title: "Días Transcurridos"},
          {field: "solicitudProveedor.solicitud.gerenciaGeneral", title: "Gerencia general"},
          {field: "solicitudProveedor.solicitud.gerenciaVentas", title: "Gerencia ventas"},
          {field: "solicitudProveedor.solicitud.ecb", title: "ECB"},
          {field: "solicitudProveedor.solicitud.nombre", title: "Nombre"},
          {field: "solicitudProveedor.solicitud.nombreEstablecimiento", title: "Establecimiento"},
          {field: "solicitudProveedor.solicitud.direccion", title: "Dirección"},
          {field: "solicitudProveedor.solicitud.tipoCaso", title: "Tipo de caso"},
        ],
      });
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    rol() {
      return this.$store.state.auth.rol;
    },
    usuarioBodega() {
      return this.$store.getters["usuariosBodega/usuarioBodega"]
          ? this.$store.getters["usuariosBodega/usuarioBodega"]
          : {};
    },
    rutas() {
      return this.$store.getters["ruta/rutas"]
          ? this.$store.getters["ruta/rutas"]
          : [];
    },
    totalRegistros() {
      return this.rutas.length;
    },
  },
};
</script>
<style>
.bgFila {
  background-color: rgb(197, 210, 236) !important;
}
</style>
