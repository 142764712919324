<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión Adjuntos</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0"></p>
            </div>
            <div class="col-md-8">
              <button
                  @click="agregar"
                  class="btn btn-general btn-primary float-right"
              >
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="tipoSolicitud">Adjuntos para procesos *</label>
                <CustomVariableList
                    variable="ADJUNTOS"
                    :selectedOptionValue="tipoSolicitud"
                    :selectVariable="handleSelectChange"
                />
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th width="5%"></th>
                <th>Tipo</th>
                <th>Descripción</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in atencionAdjuntos" :key="item.id">
                <td>
                  <a title="Eliminar" href="javascript:void(0)" @click="eliminar(item)"
                  ><i class="ti-trash btn-icon-append"></i
                  ></a>

                  <Modal
                      mensaje="Se eliminará el archivo, ¿Está seguro?"
                      :method="procesarEliminar"
                  />
                  <button
                      type="button"
                      v-show="false"
                      id="exampleModalButton"
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      data-backdrop="static"
                      data-keyboard="false"
                  >
                    Modal messages
                  </button>

                </td>
                <td>{{ item.tipo }}</td>
                <td>{{ item.descripcion }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomVariableList from "@/components/CustomVariableList.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "GestionAdjuntos",
  components: {
    Modal,
    CustomVariableList
  },
  data() {
    return {
      tipoSolicitud: "CAMBIO",
      itemSelected: null
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }

    this.tipoSolicitud=this.$store.getters["proveedores/proveedorSeleccionado"];
    if(!this.tipoSolicitud)
    {
      this.tipoSolicitud="CAMBIO";
    }
    this.handleSelectChange(this.tipoSolicitud);
  },

  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async handleSelectChange(item) {
      this.loaderSpinner();
      this.tipoSolicitud = item;
      this.$store.commit("atencionAdjunto/setAtencionAdjuntos", []);
      this.$store.commit("proveedores/setProveedorSeleccionado", item);
      try {
        await this.$store.dispatch("atencionAdjunto/consultar", {
          tipoSolicitud: this.tipoSolicitud,
        });
      } catch (error) {
        alert("No se pudo consultar los filtros");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      this.$router.push({name: "AtencionAdjuntoForm"});
    },

    async eliminar(item) {
      this.itemSelected = item
      document.getElementById("exampleModalButton").click();
    },

    async procesarEliminar() {
      try {
        await this.$store.dispatch("atencionAdjunto/eliminar", {id: this.itemSelected.id});
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    atencionAdjuntos() {
      return this.$store.getters["atencionAdjunto/atencionAdjuntos"]
          ? this.$store.getters["atencionAdjunto/atencionAdjuntos"]
          : [];
    },
  },
};
</script>
