<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Configurar equipos para un evento</h3>
          </div>
        </div>

        <div class="white-content-table mb-4">
          <p>Datos del evento</p>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th>Cliente</th>
                <th>Gerencia General</th>
                <th>Gerencia Ventas</th>
                <th>Provincia</th>
                <th>Sector</th>
                <th>Dirección</th>
                <th>Teléfono cliente</th>
                <th>Nombre evento</th>
                <th>Fecha de entrega</th>
                <th>Fecha de retiro</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ eventoSeleccionado.codigoCliente }}</td>
                <td>{{ eventoSeleccionado.gerenciaGeneral }}</td>
                <td>{{ eventoSeleccionado.gerenciaVentas }}</td>
                <td>{{ eventoSeleccionado.provincia }}</td>
                <td>{{ eventoSeleccionado.sectorBarrio }}</td>
                <td>{{ eventoSeleccionado.direccion }}</td>
                <td>{{ eventoSeleccionado.telefono }}</td>
                <td>{{ eventoSeleccionado.nombreEvento }}</td>
                <td>{{ eventoSeleccionado.fechaEntrega }}</td>
                <td>{{ eventoSeleccionado.fechaRetiro }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="mb-4">
            <p>Detalle equipos solicitado</p>
            <ul>
              <li
                  v-for="(item, $index) in eventoSeleccionado.detalles"
                  :key="$index"
              >
                <strong>Cantidad:</strong> {{ item.numeroEeff }}
                <strong>Capacidad pies:</strong> {{ item.capacidadPies }}
                <strong>Logo:</strong> {{ item.logo }}
              </li>
            </ul>
          </div>
        </div>

        <div class="white-content-table mb-4">
          <p class="txt-celeste mb-3">Lista de equipos seleccionados</p>
          <div
              class="table-responsive mb-3"
              v-if="equiposSeleccionadosAnteriores.length || equiposSeleccionados.length"
          >
            <table class="table table-sm">
              <thead>
              <tr>
                <th></th>
                <th>Placa</th>
                <th>Serie</th>
                <th>Marca</th>
                <th>Estado</th>
                <th>Capacidad (Cajas)</th>
                <th>Bodega</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in equiposSeleccionadosAnteriores" :key="item.id">
                <td>

                </td>
                <td>{{ item?.placa }}</td>
                <td>{{ item?.serie }}</td>
                <td>{{ item?.marca }}</td>
                <td>{{ item?.estadoEquipo }}</td>
                <td>{{ item?.capacidadCajas }}</td>
                <td>{{ item?.nombreBodega }}</td>
              </tr>
              <tr v-for="item in equiposSeleccionados" :key="item.id">
                <td>
                  <a
                      href="javascript:void(0)"
                      class="text-danger"
                      @click="eliminarEquipo(item)"
                  ><i class="ti-close"></i
                  ></a>
                </td>
                <td>{{ item?.placa }}</td>
                <td>{{ item?.serie }}</td>
                <td>{{ item?.marca }}</td>
                <td>{{ item?.estadoEquipo }}</td>
                <td>{{ item?.capacidadCajas }}</td>
                <td>{{ item?.nombreBodega }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="white-content-general mb-4">

          <div class="row mb-4">
            <div class="col-lg-12">
              <button
                  type="button"
                  id="form-submit"
                  class="btn btn-primary btn-general mr-2"
                  @click="guardar"
                  :disabled="!equiposSeleccionados.length"
              >
                <i class="ti-save"></i>
                Guardar
              </button>
              <button type="button" class="btn btn-secondary btn-general-off" @click="cerrar">
                <i class="ti-close"></i> Cerrar
              </button>
            </div>
          </div>

          <p class="txt-celeste">Seleccione los equipos</p>
          <div class="row mb-4">
            <div class="col-md-12">
              <div class="form-check-inline">
                <input
                    class="form-check-input"
                    type="radio"
                    id="placa"
                    value="PLACA"
                    v-model="tipoFiltro"
                    @click="mostrarBusquedaInput"
                />
                <label class="form-check-label radio-margin" for="placa">
                  Placa
                </label>

                <input
                    class="form-check-input"
                    type="radio"
                    id="serie"
                    value="SERIE"
                    v-model="tipoFiltro"
                    @click="mostrarBusquedaInput"
                />
                <label class="form-check-label radio-margin" for="serie">
                  Serie
                </label>

                <div class="form-check-inline">
                  <input
                      class="form-check-input"
                      type="radio"
                      id="bodegas"
                      value="BODEGA"
                      v-model="tipoFiltro"
                      @click="mostrarBodegas"
                  />
                  <label class="form-check-label radio-margin" for="bodegas">
                    Bodega
                  </label>
                </div>

              </div>
            </div>
          </div>
          <div
              class="input-group col-md-6 mt-2 no-padding"
              v-if="habilitarInputBusqueda"
          >
            <input
                type="text"
                class="form-control"
                v-model="valorBusqueda"
                aria-label="Text input with segmented dropdown button"
            />
            <div class="input-group-append">
              <button
                  type="button"
                  class="btn btn-outline-secondary"
                  @click="buscarEquipo"
                  :disabled="valorBusqueda.length === 0 || tipoFiltro === 'TODOS'"
              >
                Buscar
              </button>
            </div>
          </div>
          <div class="row mt-2" v-if="habilitarBodegas">
            <div class="col-md-3">
              <div class="form-group">
                <fieldset>
                  <select
                      name="bodega"
                      class="form-control"
                      v-model="bodega"
                      @change="filtrarXBodega"
                  >
                    <option value="" disabled>Seleccione una bodega</option>
                    <option
                        v-for="bodega in bodegas"
                        :key="bodega.id"
                        :value="bodega.id"
                    >
                      {{ bodega.nombre }}
                    </option>
                  </select>
                </fieldset>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <fieldset>
                  <select
                      name="estado"
                      class="form-control"
                      v-model="estadoEquipo"
                      @change="filtrarXEstado"
                      :disabled="!bodega"
                  >
                    <option value="" disabled>Seleccione un estado</option>
                    <option
                        v-for="estado in estados"
                        :key="estado"
                        :value="estado"
                    >
                      {{ estado }}
                    </option>
                  </select>
                </fieldset>
              </div>
            </div>
          </div>
        </div>

        <div class="white-content-table">
          <div class="table-responsive" v-if="equiposFiltrados.length">
            <table class="table table-striped">
              <thead>
              <tr>
                <th></th>
                <th>Ubicación</th>
                <th></th>
                <th>Cliente</th>
                <th>Placa</th>
                <th>Serie</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Logo</th>
                <th>Año fabricación</th>
                <th>Tipo</th>
                <th>Fabricante Genesis</th>
                <th>Status Neveras</th>
                <th>Valor Libros</th>
                <th>Valor Comercial</th>
                <th>Status Libros</th>
                <th>Año Adquisición</th>
                <th>Capacidad Botellas</th>
                <th>Capacidad Cajas</th>
                <th>Capacidad Pies</th>
                <th>Activo Fijo</th>
                <th>Número Equipo</th>
                <th>VH</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in equiposFiltrados" :key="item.id">
                <td>
                  <a href="javascript:void(0)" @click="seleccionarEquipo(item)"
                  ><i class="ti-plus"></i
                  ></a>
                </td>
                <td>{{ item.ubicacion }}</td>
                <td>
                  <div
                      class="badge"
                      :class="
                        item.estadoEquipo === 'DISPONIBLE'
                          ? 'badge-success'
                          : 'badge-secondary'
                      "
                  >
                    {{ item.estadoEquipo }}
                  </div>
                </td>
                <td>{{ item.cliente }}</td>
                <td style="font-weight: bold">
                  <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Visualizar detalles del equipo"
                      href="#"
                  >{{ item.placa }}</a
                  >
                </td>
                <td>{{ item.serie }}</td>
                <td>{{ item.marca }}</td>
                <td>{{ item.modelo }}</td>
                <td>{{ item.logo }}</td>
                <td>{{ item.anioFabricacion }}</td>
                <td>{{ item.tipoEquipo }}</td>
                <td>{{ item.fabricanteGenesis }}</td>
                <td>{{ item.statusNeveras }}</td>
                <td>{{ item.valorLibros }}</td>
                <td>{{ item.valorComercial }}</td>
                <td>{{ item.statusLibros }}</td>
                <td>{{ item.anioAdquisicion }}</td>
                <td>{{ item.capacidadBotellas }}</td>
                <td>{{ item.capacidadCajas }}</td>
                <td>{{ item.capacidadPies }}</td>
                <td>{{ item.activoFijo }}</td>
                <td>{{ item.numeroEquipo }}</td>
                <td>{{ item.vh }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GestionEventos",
  data() {
    return {
      pagina: 1,
      mensaje: "",
      id: null,
      valorBusqueda: "",
      tipoFiltro: "",
      habilitarInputBusqueda: false,
      habilitarBodegas: false,
      bodega: "",
      nombreBodega: "",
      estadoEquipo: "",
      estados: [
        "DISPONIBLE",
        "REPARACION",
        "ENCI",
        "BAJA",
        "RESERVADO",
        "GARANTIA",
      ],
      equiposSeleccionados: [],
      equiposSeleccionadosAnteriores: [],
      equiposFiltrados: [],
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.$store.commit("equipos/inicializar");
  },
  mounted() {
    this.consultarBodegasXProveedor();
    this.equiposSeleccionadosAnteriores = this.eventoSeleccionado?.equiposFrios;
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    habilitarFormulario() {
      this.mostrarFormulario = !this.mostrarFormulario;
    },
    async consultarBodegasXProveedor() {
      try {
        await this.$store.dispatch("bodegas/consultarXProveedor", {proveedor: this.eventoSeleccionado?.proveedor});
      } catch (error) {
        alert("No se pudo realizar la consulta de las bodegas");
      }
    },
    async buscarEquipo() {
      this.loaderSpinner();
      try {
        if (this.tipoFiltro && this.tipoFiltro === "SERIE") {
          await this.$store.dispatch("equipos/consultarEquipoXSerie", {
            serie: this.valorBusqueda,
          });
        }
        if (this.tipoFiltro && this.tipoFiltro === "PLACA") {
          await this.$store.dispatch("equipos/consultarEquipoXPlaca", {
            placa: this.valorBusqueda,
          });
        }

        await this.$store.commit(
            "equipos/setEquipos",
            Object.keys(this.equipoSeleccionado).length === 0
                ? null
                : [this.equipoSeleccionado]
        );
        this.equiposFiltrados = [this.equipoSeleccionado];
        await this.$store.commit("equipos/setTotalPaginas", 1);
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    mostrarBusquedaInput() {
      this.habilitarBodegas = false;
      this.habilitarInputBusqueda = true;
    },
    mostrarBodegas() {
      this.habilitarBodegas = true;
      this.habilitarInputBusqueda = false;
    },
    async filtrarXBodega() {
      this.loaderSpinner();
      this.estadoEquipo = "";
      try {
        /* await this.$store.dispatch("equipos/consultarEquipoXBodega", {
          bodega: this.bodega,
        }); */
        await this.$store.dispatch("equipos/consultarEquipoXBodegaDisponibles", {
          bodega: this.bodega,
        });
        await this.$store.commit("equipos/setTotalPaginas", 1);
        this.equiposFiltrados = this.equipos;
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    filtrarXEstado() {
      this.equiposFiltrados = this.equipos;
      const equiposXEstado = this.equiposFiltrados?.filter(
          (item) => item?.estadoEquipo === this.estadoEquipo
      );
      this.equiposFiltrados = equiposXEstado;
    },
    seleccionarEquipo(equipo) {
      //Buscar si ya está esa bodega en la estructura
      const equipoEncontrado = this.equiposSeleccionados?.find(
          (item) => item?.id === equipo?.id
      );

      if (equipoEncontrado) {
        return;
      }

      //si no existe agregar
      //cambiar color en equipo seleccionado de la tabla
      const nombreBodega = this.bodegas?.find(
          (item) => item?.id === equipo?.bodega
      );

      this.nombreBodega = nombreBodega?.nombre || "";
      equipo.nombreBodega = nombreBodega?.nombre || "";
      this.equiposSeleccionados.push(equipo);
    },
    eliminarEquipo(equipo) {
      const equiposFiltrados = this.equiposSeleccionados?.filter(
          (item) => item?.id !== equipo?.id
      );
      this.equiposSeleccionados = equiposFiltrados;
    },
    async guardar() {
      this.loaderSpinner();
      let idEquipos = [];
      this.equiposSeleccionados?.map((item) => idEquipos.push(item.id));
      const data = {
        idEvento: this.eventoSeleccionado?.id,
        equipos: idEquipos,
      };
      try {
        await this.$store.dispatch("eventos/agregarEquipos", data);
        this.$router.push({name: "EventosPendientes"});
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    equipos() {
      return this.$store.getters["equipos/equipos"]
          ? this.$store.getters["equipos/equipos"]
          : [];
    },
    equipoSeleccionado() {
      return this.$store.getters["equipos/equipoSeleccionado"]
          ? this.$store.getters["equipos/equipoSeleccionado"]
          : {};
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
          ? this.$store.getters["bodegas/bodegas"]
          : [];
    },
    eventoSeleccionado() {
      return this.$store.getters["eventos/eventoSeleccionado"]
          ? this.$store.getters["eventos/eventoSeleccionado"]
          : null;
    },
  },
};
</script>
<style>
.no-padding {
  padding: 0;
  margin: 0;
}

.radio-margin {
  margin-right: 15px;
}
</style>
