<template>
  <div
      class="modal fade"
      id="modalFiltros"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalFiltrosLabel"
      aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title txt-celeste" id="modalFiltrosLabel">
              <strong>{{ titulo }}</strong>
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="method"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div
                  class="form-group col-md-6"
                  v-for="filtro in filtros"
                  :key="filtro?.id"
              >
                <fieldset v-if="filtro.tipo === 'LIST'">
                  <label>{{ filtro?.nombre }}</label>
                  <select
                      class="form-control"
                      v-model="filtro.valor"
                      @change="handleSelectChange($event, filtro)"
                  >
                    <option value="TODOS">TODOS</option>
                    <option
                        v-for="option in filtro.opciones"
                        :key="option.descripcion"
                        :value="option.descripcion"
                    >
                      {{ option.descripcion }}
                    </option>
                  </select>
                </fieldset>


                <fieldset v-if="filtro.tipo === 'LIST_NUMBER'">
                  <label>{{ filtro?.nombre }}</label>
                  <select
                      class="form-control"
                      v-model="filtro.valor"
                      @change="handleSelectChangeListNumber($event, filtro)"
                  >
                    <option value="TODOS">TODOS</option>
                    <option
                        v-for="option in filtro.opciones"
                        :key="option.descripcion"
                        :value="option.descripcion"
                    >
                      {{ option.descripcion }}
                    </option>
                  </select>
                </fieldset>

                <fieldset v-if="filtro.tipo === 'BOOLEAN'">
                  <label>{{ filtro?.nombre }}</label>
                  <select
                      class="form-control"
                      v-model="filtro.valor"
                      @change="handleSelectChangeBoolean($event, filtro)"
                  >
                    <option value="TODOS">TODOS</option>
                    <option value="true">SI</option>
                    <option value="false">NO</option>

                  </select>
                </fieldset>
                <fieldset v-else-if="filtro.tipo === 'TEXT'">
                  <label>{{ filtro?.nombre }}</label>
                  <input type="text" class="form-control" v-model="filtro.valor" @input="updateText($event, filtro)"/>
                </fieldset>

                <fieldset v-else-if="filtro.tipo === 'NUMBER'">
                  <label>{{ filtro?.nombre }}</label>
                  <input type="text" class="form-control" v-model="filtro.valor" @input="updateNumber($event, filtro)"/>
                </fieldset>

                <fieldset v-else-if="filtro.tipo === 'DATE'">
                  <label>{{ filtro?.nombre }}</label>
                  <input type="date" class="form-control" v-model="filtro.valor"
                         @input="handleSelectChangeDate($event, filtro)"/>
                </fieldset>

              </div>

            </div>
            <div class="row mt-2">
              <div class="form-group col-md-6">
                <a
                    href="javascript:void(0)"
                    @click="limpiarFiltros"
                >
                  <i class="ti-eraser"></i> Limpiar filtros</a
                >
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-primary btn-general"
                data-dismiss="modal"
                @click="procesar"
            >
              <i class="icon-search"></i> Consultar
            </button>
            <button
                type="button"
                class="btn btn-secondary btn-general-off"
                data-dismiss="modal"
                @click="cerrar"
            >
              <i class="ti-close"></i> Cerrar
            </button>
          </div>
          <button
              type="button"
              v-show="false"
              id="btProcesar"
              class="btn btn-primary btn-general"
              data-dismiss="modal"
              @click="
              consultar({
                condiciones,
                inicializarPagina: true,
              })
            "
          >
            Procesar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cerrar: {type: Function},
    consultar: {type: Function},
    titulo: String,
    entidad: String,
  },
  data() {
    return {
      condiciones: [],
    };
  },
  mounted() {
    this.loadFilters();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    limpiarFiltros() {
      this.filtros.forEach(filtro => {
        if (filtro.tipo == 'LIST') {
          filtro.valor = "TODOS"
        } else {
          filtro.valor = '';
        }
        this.condiciones = [];
      });
    },
    async loadFilters() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("filtros/consultar", {
          entidad: this.entidad,
        });
      } catch (error) {
        alert("No se pudo consultar los filtros");
      } finally {
        this.loader.hide();
      }
    },
    handleSelectChange(event, filtro) {
      const selectedOption = event.target.value;
      if (selectedOption === "TODOS") {
        this.condiciones = this.condiciones?.filter(item => item?.field !== filtro?.nombreBase);
        return;
      }
      const condicion = {
        field: filtro.nombreBase,
        comparator: filtro.comparator,
        value: selectedOption,
      };
      //Buscar si existe el filtro en condiciones
      const filterIndex = this.condiciones?.findIndex(
          (item) => item?.field === filtro?.nombreBase
      );

      if (filterIndex !== -1) {
        this.condiciones?.splice(filterIndex, 1, condicion);
      } else {
        this.condiciones = [...this.condiciones, condicion];
      }
    },

    handleSelectChangeListNumber(event, filtro) {
      const selectedOption = event.target.value;
      if (selectedOption === "TODOS") {
        this.condiciones = this.condiciones?.filter(item => item?.field !== filtro?.nombreBase);
        return;
      }
      const condicion = {
        field: filtro.nombreBase,
        comparator: filtro.comparator,
        value: selectedOption,
        type: 'int'
      };
      //Buscar si existe el filtro en condiciones
      const filterIndex = this.condiciones?.findIndex(
          (item) => item?.field === filtro?.nombreBase
      );

      if (filterIndex !== -1) {
        this.condiciones?.splice(filterIndex, 1, condicion);
      } else {
        this.condiciones = [...this.condiciones, condicion];
      }
    },
    handleSelectChangeBoolean(event, filtro) {
      const selectedOption = event.target.value;
      if (selectedOption === "TODOS") {
        this.condiciones = this.condiciones?.filter(item => item?.field !== filtro?.nombreBase);
        return;
      }
      const condicion = {
        field: filtro.nombreBase,
        comparator: filtro.comparator,
        value: selectedOption,
        type: "boolean"
      };
      //Buscar si existe el filtro en condiciones
      const filterIndex = this.condiciones?.findIndex(
          (item) => item?.field === filtro?.nombreBase
      );

      if (filterIndex !== -1) {
        this.condiciones?.splice(filterIndex, 1, condicion);
      } else {
        this.condiciones = [...this.condiciones, condicion];
      }
    },
    handleSelectChangeDate(event, filtro) {
      const selectedOption = event.target.value;
      const condicion = {
        field: filtro.nombreBase,
        comparator: filtro.comparator,
        value: selectedOption,
        type: "date"
      };
      //Buscar si existe el filtro en condiciones
      const filterIndex = this.condiciones?.findIndex(
          (item) => item?.field === filtro?.nombreBase && item?.comparator === filtro?.comparator
      );

      if (filterIndex !== -1) {
        this.condiciones?.splice(filterIndex, 1, condicion);
      } else {
        this.condiciones = [...this.condiciones, condicion];
      }
    },
    updateText(event, filtro) {
      const selectedOption = event.target.value;
      if (!selectedOption?.length) {
        this.condiciones = this.condiciones?.filter(item => item?.field !== filtro?.nombreBase);
        return;
      }
      const condicion = {
        field: filtro.nombreBase,
        comparator: filtro.comparator,
        value: selectedOption,
      };
      //Buscar si existe el filtro en condiciones
      const filterIndex = this.condiciones?.findIndex(
          (item) => item?.field === filtro?.nombreBase
      );

      if (filterIndex !== -1) {
        this.condiciones?.splice(filterIndex, 1, condicion);
      } else {
        this.condiciones = [...this.condiciones, condicion];
      }
    },


    updateNumber(event, filtro) {
      const selectedOption = event.target.value;
      if (!selectedOption?.length) {
        this.condiciones = this.condiciones?.filter(item => item?.field !== filtro?.nombreBase);
        return;
      }
      const condicion = {
        field: filtro.nombreBase,
        comparator: filtro.comparator,
        value: selectedOption,
        type: 'int'
      };
      //Buscar si existe el filtro en condiciones
      const filterIndex = this.condiciones?.findIndex(
          (item) => item?.field === filtro?.nombreBase
      );

      if (filterIndex !== -1) {
        this.condiciones?.splice(filterIndex, 1, condicion);
      } else {
        this.condiciones = [...this.condiciones, condicion];
      }
    },

    procesar() {
      document.getElementById("btProcesar").click();
    },
  },
  computed: {
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
          ? this.$store.getters["bodegas/bodegas"]
          : [];
    },
    filtros() {
      return this.$store.getters["filtros/filtros"]
          ? this.$store.getters["filtros/filtros"]
          : [];
    },
  },
  watch: {
    entidad() {
      this.loadFilters();
    },
  },
};
</script>
