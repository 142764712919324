<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Gestión de Solicitudes
            </h3>
          </div>
          <div class="col-md-6">

          </div>
        </div>

        <div class="white-content-general mb-4">

          <img
              title="Descargar Template"
              @click="descargarTemplate"
              src="../../../assets/icons/icon-excel.png"
              alt="Descargar Template"
              class=" mr-2"
              style="cursor: pointer; width: 35px"
          />

          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0">
                <span class="card-title">Total registros: </span><span
                  class="txt-celeste text-primary font-weight-bold fs-l">{{ totalRegistros }}</span>
              </p>
            </div>
            <div class="col-md-8">

              <button
                  class="btn btn-primary btn-general float-right"
                  @click="abrirModalArchivo"
              >
                <span class="icon-upload"></span> Cargar archivo
              </button>
              <button
                  class="btn btn-primary btn-general float-right mr-2"
                  @click="agregarRequerimiento"
              >
                <span class="icon-plus"></span> Agregar
              </button>
              <div class="dropdown">
                <button
                    class="btn btn-primary btn-general dropdown-toggle float-right mr-2"
                    type="button"
                    id="tipo"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  <i class="ti-filter"></i> Filtrar {{ filtroTipo }}
                </button>
                <div class="dropdown-menu" aria-labelledby="tipo">
                  <a class="dropdown-item" href="#" @click="seleccionarFiltro('TIPO', 'TODOS')"
                  >TODOS</a
                  >
                  <a
                      class="dropdown-item"
                      href="#"
                      @click="seleccionarFiltro('TIPO', item)"
                      v-for="(item, $index) in listaFiltros"
                      :key="$index"
                  >{{ item }}</a
                  >
                </div>
              </div>
              <div class="d-flex mb-2" v-if="mostrarFiltros">


                <div class="dropdown mr-3" v-if="mostrarPrioridad">
                  <button
                      class="btn btn-outline-primary btn-sm dropdown-toggle"
                      type="button"
                      id="prioridad"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                  >
                    Nivel de riesgo {{ filtroPrioridad }}
                  </button>
                  <div class="dropdown-menu" aria-labelledby="prioridad">

                    <div
                        class="form-check"
                        v-for="prioridad in rango"
                        :key="prioridad"
                    >
                      <input
                          class="form-check-input ml-1"
                          type="checkbox"
                          :id="prioridad"
                          :value="prioridad"
                          @click="configurarPrioridad"
                      />
                      <label class="form-check-label" :for="prioridad">
                        {{ prioridad }}
                      </label>
                    </div>

                  </div>
                </div>
                <div
                    class="d-flex justify-content-center my-2"
                    v-if="mostrarPrioridad"
                >
                    <span class="font-weight-bold indigo-text mr-2 mt-1">{{
                        minimo
                      }}</span>
                  <form class="range-field">
                    <input
                        class="border-0"
                        type="range"
                        :min="minimo"
                        :max="maximo"
                        @change="moverRango"
                    />
                  </form>
                  <span class="font-weight-bold indigo-text ml-2 mt-1">{{
                      maximo
                    }}</span>
                </div>
              </div>

              <div class="row">
                <div
                    class="modal"
                    tabindex="-1"
                    :style="displayModal ? 'display: block;' : 'display: none;'"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">Cargar archivo</h5>
                      </div>
                      <div class="modal-body">
                        <p>Seleccione el archivo</p>
                        <div class="input-group mb-3">
                          <input
                              type="file"
                              @change="uploadFile"
                              ref="file"
                              class="form-control"
                          />
                        </div>
                        <div class="alert alert-danger" role="alert" v-if="error">
                          No se pudo cargar el archivo
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-general-off btn-secondary"
                            data-bs-dismiss="modal"
                            @click="cerrarModalArchivo"
                        >
                          <i class="ti-close"></i> Cerrar
                        </button>
                        <button
                            type="button"
                            class="btn btn-general btn-primary"
                            @click="cargarArchivo"
                            :disabled="archivo ? false : true"
                        >
                          <i class="ti-ti-upload"></i> Cargar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="white-content-table">
          <form name="f1" id="formElement">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th></th>
                  <th>Id</th>
                  <th>{{ this.$config.labelTicket }}</th>
                  <th>{{ this.$config.labelCodigoCliente }}</th>
                  <th>{{ this.$config.labelTipo }}</th>
                  <th>Capacidad</th>
                  <th>{{ this.$config.labelFechaSolicitud }}</th>
                  <th>Dias Trascurridos</th>
                  <th v-if="$config.countryFlag==='EC'">Nivel de riesgo</th>
                  <th v-if="$config.countryFlag==='EC'">Mediana</th>
                  <th>{{ $config.labelGerenciaGeneral }}</th>
                  <th>{{ $config.labelGerenciaVentas }}</th>
                  <th>ECB</th>
                  <th>Nombre</th>
                  <th>Establecimiento</th>
                  <th>Dirección</th>
                  <th>{{ this.$config.labelTipoCaso }}</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in solicitudes"
                    :key="item.id"
                    :class="
                      item?.tipoCaso === 'COOL INVENTORY BOT' ? 'bgFila' : ''
                    "
                >
                  <td>
                    <a title="Eliminar"
                       href="javascript:void(0)"
                       @click="cerrarIndividual(item?.id)"
                       data-toggle="modal"
                       data-target="#exampleModal"
                    ><i class="ti-trash"></i
                    ></a>
                  </td>
                  <td>{{ item.id }}</td>
                  <td>{{ item.numeroTicket }}</td>
                  <td>{{ item.codigoCliente }}</td>
                  <td>{{ item.tipoSolicitud }}</td>
                  <td>{{ item.capacidadEeffMediana }}</td>
                  <td>{{ item.fechaSolicitud }}</td>
                  <td>
                    <div
                        class="badge"
                        :class="
                          item.diasTranscurridosLabolables < 3
                            ? 'badge-success'
                            : item.diasTranscurridosLabolables > 5
                            ? 'badge-danger'
                            : 'badge-warning'
                        "
                    >
                      {{ item.diasTranscurridosLabolables }} día(s)
                    </div>
                  </td>
                  <td v-if="$config.countryFlag==='EC'">{{ item.prioridad }}</td>
                  <td v-if="$config.countryFlag==='EC'">{{ item.medianaUltimoTrimestre }}</td>
                  <td>{{ item.gerenciaGeneral }}</td>
                  <td>{{ item.gerenciaVentas }}</td>
                  <td>{{ item.ecb }}</td>
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.nombreEstablecimiento }}</td>
                  <td>{{ item.direccion }}</td>
                  <td>{{ item.tipoCaso }}</td>
                </tr>
                </tbody>
              </table>
              <br/>
              <div class="d-flex justify-content-center">
                <nav aria-label="Page navigation example" v-if="paginas">
                  <ul class="pagination">
                    <li class="page-item" :disabled="pagina === 1">
                      <a class="page-link" @click="setPreviewPage">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li
                        v-for="page in paginas"
                        :key="page"
                        class="page-item"
                        :class="{ active: pagina === page }"
                    >
                      <a class="page-link" @click="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" :disabled="pagina === paginas">
                      <a class="page-link" @click="setNextPage">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </form>
          <Modal :mensaje="mensaje" :method="procesarSeleccionados"/>
          <ModalMessages :mensaje="mensajeProceso" :titulo="titulo"/>
          <Alerta v-if="mostrar" titulo="" :mensaje="mensaje"/>
          <div class="row justify-content-center" v-if="rol !== 'USER'">
            <div class="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-6">
              <button
                  class="btn btn-primary btn-block"
                  @click="asignar('ASIGNAR')"
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModal"
              >
                <span class="icon-circle-check"></span> Asignar Todos
              </button>
            </div>
            <div class="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-6 mt-2 mt-sm-0">
              <button
                  class="btn btn-primary btn-block"
                  @click="asignar('CERRAR')"
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModal"
              >
                <i class="ti-minus"></i> Cerrar Todos
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Alerta from "../../../components/Alerta.vue";
import Modal from "../../../components/Modal.vue";
import ModalMessages from "../../../components/ModalMessages.vue";

export default {
  name: "GestionSolicitudes",
  components: {Alerta, Modal, ModalMessages},
  data() {
    return {
      archivo: null,
      displayModal: false,
      error: false,
      idSeleccionadosProxy: [],
      idSeleccionados: [],
      mostrar: false,
      displayConfirmacion: false,
      mensaje: "",
      mostrarFiltros: false,
      filtroTipo: null,
      filtroPrioridad: null,
      rango: [],
      comndiciones: [],
      badge: true,
      mostrarPrioridad: false,
      mensajeProceso: [],
      titulo: "",
      prioridadesSeleccionadas: [],
      mediana: null,
      pagina: 1,
      totalRegistros: 0,
      tipoProceso: "",
      listaFiltros: [],
      idSeleccionado: null,
      archivoURL: "/SolicitudesTemplate.xlsx"
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },

  mounted() {
    this.consultar(1);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    agregarRequerimiento() {
      this.$router.push({name: "SolicitudForm"});
    },
    async consultar(pagina) {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("solicitudes/consultarXPagina", {pagina});
        await this.$store.dispatch("solicitudes/consultarTotalPaginas");
        let tipoSolicitud = [];
        let prioridades = [];
        this.solicitudes.map((item) => {
          tipoSolicitud.push(item.tipoSolicitud);
          prioridades.push(item.prioridad);
        });
        const tipoSolicitudReduce = tipoSolicitud.reduce((acc, item) => {
          if (!acc.includes(item)) {
            acc.push(item);
          }
          return acc;
        }, []);
        this.listaFiltros = tipoSolicitudReduce;
        const prioridadesReduce = prioridades.reduce((acc, item) => {
          if (!acc.includes(item)) {
            acc.push(item);
          }
          return acc;
        }, []);
        this.rango = prioridadesReduce;
        this.totalRegistros = this.$store.getters["solicitudes/totalRegistros"];
        this.mostrarPrioridad = false;
        this.filtroPrioridad = null;
        this.mediana = null;
        this.filtroTipo = null;
        this.prioridadesSeleccionadas = [];
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    abrirModalArchivo() {
      this.displayModal = true;
      this.error = false;
    },
    cerrarModalArchivo() {
      this.displayModal = false;
    },
    uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
    },
    async cargarArchivo() {
      try {
        this.loaderSpinner();
        const formData = new FormData();
        formData.append("file", this.archivo);
        const resp = await this.$store.dispatch(
            "solicitudes/cargarArchivo",
            formData
        );
        if (resp === "error") {
          this.error = true;
          this.loader.hide();
          return;
        }
        this.displayModal = false;
        this.loader.hide();
        this.titulo = "Cargar archivo";
        this.mensajeProceso = [resp];
        document.getElementById("modalButton").click();
        this.consultar(1);
      } catch (error) {
        this.error = true;
        this.loader.hide();
      } finally {
        //this.loader.hide();
      }
    },
    seleccionarFila(e) {
      let checked = e?.target?.checked;
      let valor = e?.target?.value;
      if (checked) {
        this.idSeleccionados.push(parseInt(valor));
      } else {
        const filtrados = this.idSeleccionados.filter(
            (item) => item !== parseInt(valor)
        );
        this.idSeleccionados = filtrados;
      }
    },
    asignar(tipoProceso) {
      this.tipoProceso = tipoProceso;
      if (tipoProceso === "ASIGNAR") {
        this.mensaje =
            "Se realizará la asignación de bodega para las solicitudes seleccionadas. Está seguro?";
        return;
      }
      if (tipoProceso === "CERRAR") {
        this.mensaje =
            "Se realizará el cierre de las solicitudes seleccionadas. Está seguro?";
        return;
      }
    },
    cerrarIndividual(id) {
      this.tipoProceso = "CERRAR INDIVIDUAL";
      this.idSeleccionado = id;
      this.mensaje =
          "Se realizará el cierre de la solicitud seleccionada. Está seguro?";
    },
    async procesarSeleccionados() {
      if (this.tipoProceso === "ASIGNAR") {
        this.procesar();
        return;
      }
      if (this.tipoProceso === "CERRAR") {
        this.cerrar();
        return;
      }
      if (this.tipoProceso === "CERRAR INDIVIDUAL") {
        this.cerrarRequerimiento();
        return;
      }
    },
    async procesar() {
      let seleccionados = [];
      this.solicitudes.map((item) => seleccionados.push(item.id));

      try {
        this.loaderSpinner();
        const resp = await this.$store.dispatch(
            "solicitudes/procesar",
            seleccionados
        );
        this.loader.hide();
        this.consultar(1);
        this.titulo = "Procesar requerimiento";
        this.mensajeProceso = resp.result.contenido;
        document.getElementById("modalButton").click();
      } catch (error) {
        this.loader.hide();
        this.titulo = "Procesar requerimiento";
        this.mensajeProceso = ["No se pudo concluir con la asignación"];
        document.getElementById("modalButton").click();
      }
    },
    async cerrar() {
      let seleccionados = [];
      this.solicitudes.map((item) => seleccionados.push(item.id));

      try {
        this.loaderSpinner();
        await this.$store.dispatch("solicitudes/cerrar", seleccionados);
        this.loader.hide();
        this.consultar(1);
        this.titulo = "Cerrar requerimiento";
        this.mensajeProceso = ["El proceso se realizó con éxito"];
        document.getElementById("modalButton").click();
      } catch (error) {
        this.loader.hide();
        this.titulo = "Cerrar requerimiento";
        this.mensajeProceso = ["No se pudo concluir el proceso"];
        document.getElementById("modalButton").click();
      }
    },
    async cerrarRequerimiento() {
      let seleccionados = [this.idSeleccionado];

      try {
        this.loaderSpinner();
        await this.$store.dispatch("solicitudes/cerrar", seleccionados);
        this.loader.hide();
        this.consultar(1);
        this.titulo = "Cerrar requerimiento";
        this.mensajeProceso = ["El proceso se realizó con éxito"];
        document.getElementById("modalButton").click();
      } catch (error) {
        this.loader.hide();
        this.titulo = "Cerrar requerimiento";
        this.mensajeProceso = ["No se pudo concluir el proceso"];
        document.getElementById("modalButton").click();
      }
    },
    desmarcarChecks() {
      for (let i = 0; i < document.f1.elements.length; i++) {
        if (document.f1.elements[i].type == "checkbox") {
          document.f1.elements[i].checked = false;
        }
      }
    },
    async asignarIndividual(solicitud) {
      try {
        const resp = await this.$store.dispatch(
            "solicitudes/asignarIndividual",
            solicitud
        );
        const mostrarComo = resp.result.mostrarComo;
        if ("MENSAJE" === mostrarComo) {
          this.mostrar = true;
          this.mensaje = resp.result.contenido.toString();
        } else if ("DIALOGO" === mostrarComo) {
          this.mensaje = resp.result.contenido[6];
        }
      } catch (error) {
        this.mensaje = "No se pudo concluir con la asignación";
      }
    },
    presentarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    seleccionarFiltro(filtro, valor) {
      if (filtro === "TIPO") {
        this.filtroTipo = `${valor}`;
        if (valor === "SOLICITUD") {
          this.mostrarPrioridad = true;
        } else {
          this.mostrarPrioridad = false;
          this.filtroPrioridad = null;
          this.mediana = null;
        }
      }
      this.aplicarFiltros();
    },
    configurarPrioridad(e) {
      let checked = e?.target?.checked;
      let valor = e?.target?.value;
      if (checked) {
        this.prioridadesSeleccionadas.push(parseInt(valor));
      } else {
        const filtrados = this.prioridadesSeleccionadas.filter(
            (item) => item !== parseInt(valor)
        );
        this.prioridadesSeleccionadas = filtrados;
      }
      let filtroPrioridad = null;
      this.prioridadesSeleccionadas.sort().map((item) => {
        filtroPrioridad = filtroPrioridad
            ? filtroPrioridad + `,${item}`
            : `${item}`;
      });
      this.filtroPrioridad = filtroPrioridad;

      this.aplicarFiltros();
    },
    moverRango(e) {
      this.mediana = e.target.value;
      this.aplicarFiltros();
    },
    async aplicarFiltros() {
      let condiciones = [];
      this.totalRegistros = null;

      if (this.filtroTipo && this.filtroTipo === "TODOS") {
        this.consultar(1);
        return;
      }

      if (this.filtroTipo && this.filtroTipo !== "TODOS") {
        condiciones.push({
          field: "tipo_solicitud",
          comparator: "=",
          value: this.filtroTipo,
        });
      }
      if (this.filtroPrioridad && this.filtroPrioridad !== "TODOS") {
        condiciones.push({
          field: "prioridad",
          comparator: "in",
          value: `(${this.filtroPrioridad})`,
        });
      }
      if (this.mediana) {
        condiciones.push({
          field: "mediana_ultimo_trimestre",
          comparator: ">=",
          value: this.mediana,
        });
      }
      try {
        this.loaderSpinner();
        await this.$store.dispatch("solicitudes/filtrar", {
          condiciones: condiciones,
        });
        this.$store.commit("solicitudes/setTotalPaginas", 1);
        this.totalRegistros = this.solicitudes.length;
        let prioridades = [];
        this.solicitudes.map((item) => {
          prioridades.push(item.prioridad);
        });
        const prioridadesReduce = prioridades.reduce((acc, item) => {
          if (!acc.includes(item)) {
            acc.push(item);
          }
          return acc;
        }, []);
        this.rango = prioridadesReduce;
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    setPage(page) {
      this.pagina = page;
    },
    setNextPage() {
      if (this.pagina < this.totalPaginas) {
        this.pagina = this.pagina + 1;
      }
    },
    setPreviewPage() {
      if (this.pagina > 1) {
        this.pagina = this.pagina - 1;
      }
    },
    async descargarTemplate() {
      const enlace = document.createElement('a');
      enlace.href = this.archivoURL;
      enlace.download = 'SolicitudesTemplate.xlsx';
      enlace.click();
      enlace.remove();
    },
  },

  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    solicitudes() {
      return this.$store.getters["solicitudes/solicitudes"]
          ? this.$store.getters["solicitudes/solicitudes"]
          : [];
    },
    rol() {
      return this.$store.state.auth.rol;
    },
    minimo() {
      return this.$store.getters["solicitudes/minimo"]
          ? this.$store.getters["solicitudes/minimo"]
          : 0;
    },
    maximo() {
      return this.$store.getters["solicitudes/maximo"]
          ? this.$store.getters["solicitudes/maximo"]
          : 0;
    },
    totalPaginas() {
      return this.$store.getters["solicitudes/totalPaginas"]
          ? this.$store.getters["solicitudes/totalPaginas"]
          : 0;
    },
    paginas() {
      let numeroPaginas = 10;
      numeroPaginas = Math.min(numeroPaginas, this.totalPaginas);
      let first = this.pagina - Math.floor(numeroPaginas / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.totalPaginas - numeroPaginas + 1);
      return [...Array(numeroPaginas)].map((k, i) => i + first);
    },

  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
};
</script>
