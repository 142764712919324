<template>
  <div>
    <StreamBarcodeReader
        torch
        no-front-cameras
        @decode="onDecode"
        @loaded="onLoaded"
    />
    <div class="form-group">
      <label for="exampleFormControlTextarea1">ActivoCodBarras</label>
      <input
          class="form-control"
          id="exampleFormControlTextarea1"
          v-model="decodedText"
      />
    </div>
  </div>
</template>

<script>
import {StreamBarcodeReader} from '@teckel/vue-barcode-reader'

export default {
  components: {StreamBarcodeReader},
  data() {
    return {
      decodedText: '',
    }
  },
  methods: {
    onDecode(result) {
      this.decodedText = result
    },
    onLoaded() {
      console.log('loaded')
    },
  }
}
</script>
