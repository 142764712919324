<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión Regularizaciones</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0"></p>
            </div>
            <div class="col-md-12">
              <button
                  class="btn btn-primary btn-general float-right"
                  @click="abrirModalArchivo"
              >
                <span class="icon-upload"></span> Cargar archivo
              </button>
              <ButtonDescargarTemplate template="RegularizacionesTemplate.xlsx"/>

            </div>
          </div>
        </div>
        <div class="white-content-table">
          <div class="table-responsive">

            <table class="table table-striped">
              <thead>
              <tr>
                <th></th>
                <th>Fecha</th>
                <th>Usuario</th>
                <th>Correctos</th>
                <th>Fallidos</th>
                <th>Detalle Fallidos</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item) in regularizaciones" :key="item.id">

                <th width="2%">
                  <FileDownload
                      v-if="item.nombreArchivo && item.rutaArchivo"
                      title="Archivo Datos"
                      icon="ti-file"
                      :file-name="item.nombreArchivo"
                      :folder-name="item.rutaArchivo"/>
                  <FileDownload v-if="item.nombreAdjunto && item.rutaAdjunto"
                                title="Adjunto"
                                icon="ti-clip"
                                :file-name="item.nombreAdjunto"
                                :folder-name="item.rutaAdjunto"/>
                </th>

                <td>{{ item.fecha }}</td>
                <td>{{ item.usuario }}</td>
                <td>{{ item.correctos }}</td>
                <td>{{ item.fallidos }}</td>
                <td>
                  <a
                      href="javascript:void(0)"
                      @click="visualizarDetalleFallido(item.fallidosDetalle)"
                  ><i class="ti-search" title="Visualizar Detalle"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
            <Paginacion :updatePage="updatePage"/>

          </div>
        </div>
        <div class="row">
          <div
              class="modal"
              tabindex="-1"
              :style="displayModal ? 'display: block;' : 'display: none;'"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Cargar archivo</h5>
                </div>
                <div class="modal-body">
                  <p>Seleccione archivo de datos</p>
                  <div class="input-group mb-3">
                    <input
                        type="file"
                        @change="uploadFile"
                        ref="file"
                        class="form-control"
                    />
                  </div>
                  <p>Seleccione adjunto</p>
                  <div class="input-group mb-3">
                    <input
                        type="file"
                        @change="uploadFileTemplate"
                        ref="fileTemplate"
                        class="form-control"
                    />
                  </div>
                  <div class="alert alert-danger" role="alert" v-if="error">
                    No se pudo cargar el archivo
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                      type="button"
                      class="btn btn-general-off btn-secondary"
                      data-bs-dismiss="modal"
                      @click="cerrarModalArchivo"
                  >
                    <i class="ti-close"></i> Cerrar
                  </button>
                  <button
                      type="button"
                      class="btn btn-general btn-primary"
                      @click="cargarArchivo"
                      :disabled="archivo ? false : true"
                  >
                    <i class="ti-ti-upload"></i> Cargar
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <ModalDetalleFallidos v-model:display="displayModalDetalle" :detalle="detalleFallidos"/>

      </div>
    </div>
  </div>
</template>
<script>
import Paginacion from "@/components/Paginacion.vue";
import FileDownload from "@/components/FileDownload.vue";
import ButtonDescargarTemplate from "@/components/ButtonDescargarTemplate.vue";
import ModalDetalleFallidos from "@/components/ModalDetalleFallidos.vue";

export default {
  name: "GestionRegularizacionesList",
  components: {
    ModalDetalleFallidos,
    ButtonDescargarTemplate,
    FileDownload,
    Paginacion
  },
  data() {
    return {
      pagina: 1,
      selectedItems: [],
      checkAll: false,
      displayModal: false,
      displayModalDetalle: false,
      error: false,
      archivo: null,
      archivoTemplate: null,
      mensajeProceso: "",
      detalleFallidos: [],
    };
  },
  async created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    await this.consultar(1);

  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      const data = {
        pagina: pagina || 1,
      };
      try {
        this.loaderSpinner();
        await this.$store.dispatch("regularizaciones/consultarRegularizacionesPagina", data);
        await this.$store.dispatch(
            "regularizaciones/consultarRegularizacionesTotalPagina"
        );
        let paginas = await this.$store.getters["regularizaciones/totalPaginas"].totalPaginas;
        await this.$store.commit("pagination/setTotalPaginas", paginas);
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    visualizarDetalleFallido(fallido) {
      this.displayModalDetalle = true;
      this.error = false;
      this.detalleFallidos = JSON.parse(fallido);

    },
    abrirModalArchivo() {
      this.displayModal = true;
      this.error = false;
    },

    cerrarModalArchivo() {
      this.displayModal = false;
    },

    updatePage(page) {
      this.pagina = page;
    },

    async uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];


    },
    async uploadFileTemplate() {
      this.archivoTemplate = this?.$refs?.fileTemplate?.files[0];


    },
    async cargarArchivo() {
      try {
        this.loaderSpinner();
        const formData = new FormData();
        formData.append("file", this.archivo);
        formData.append("adjunto", this.archivoTemplate);
        const resp = await this.$store.dispatch(
            "regularizaciones/cargarArchivo",
            formData
        );
        if (resp === "error") {
          this.error = true;
          this.loader.hide();
          return;
        }
        this.displayModal = false;
        this.loader.hide();
        this.titulo = "Cargar archivo";
        this.mensajeProceso = ["Se ha cargado el archivo con éxito"];
        document.getElementById("modalButton").click();
        await this.consultar(1);

      } catch (error) {
        this.error = true;
        this.loader.hide();
      } finally {
        this.loader.hide();

        this.$refs.file.value = null;
        this.$refs.fileTemplate.value = null;
        this.archivo = null;
        this.archivoTemplate = null;

      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    regularizaciones() {
      return this.$store.getters["regularizaciones/regularizaciones"]
          ? this.$store.getters["regularizaciones/regularizaciones"]
          : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
};
</script>
<style></style>
