<template>
  <div>

    <table class="table table-striped">
      <thead>
      <tr>
        <th></th>
        <th>ActivoCodBarras</th>
        <th>Número de guía</th>
        <th>Bodega Origen</th>
        <th>Bodega Destino</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in detalles" :key="item.id">
        <td style="width: 2%">  <div
            class="badge"
            :class="
                      item?.estadoProceso === 'PENDIENTE'
                        ? 'badge-secondary'
                        :  item?.estadoProceso === 'APROBADO' ? 'badge-success': 'badge-success'
                    "
        >
          {{ item?.estadoProceso }}
        </div></td>
        <td>{{ item?.equiposFrios?.placa }}</td>
        <td>{{ item?.numeroGuia }}</td>
        <td>{{ item.bodegaOrigen?.nombre }}</td>
        <td>{{ item.bodegaDestino?.nombre }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

export default {
  components: {},
  props: {
    idArchivo: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      detalles: []
    }
  },

  created() {
    this.consultar();
  },

  methods: {
    async consultar() {
      try {
        const resp = await this.$store.dispatch("traslados/consultarTraslados", this.idArchivo);
        this.detalles = resp?.data?.result
      } catch (error) {
        alert("No se pudo realizar la consulta");
      }
    }
  },
}
</script>
