<template>
  <div class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-12">
            <h3 class="font-weight-bold">Reasignar a Bodega</h3>
          </div>
        </div>
        <div class="white-content-general mt-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="solicitud">Solicitud *</label>
                <fieldset>
                  <Field
                      type="text"
                      name="solicitud"
                      id="solicitud"
                      class="form-control"
                      v-model="idSolicitud"
                      @blur="buscarSolicitud"
                  />
                </fieldset>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">

                <label>Proveedor *</label>
                <fieldset>
                  <select
                      name="proveedor"
                      class="form-control mb-2 mb-md-0"
                      v-model="proveedor"
                      @change="consultarXProveedor"
                  >
                    <option value="" disabled>Seleccione un proveedor</option>
                    <option
                        v-for="proveedor in proveedores"
                        :key="proveedor.id"
                        :value="proveedor.id"
                    >
                      {{ proveedor.nombreProveedor }}
                    </option>
                  </select>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Bodega *</label>
                <div class="form-group">
                  <fieldset>
                    <select
                        name="bodega"
                        class="form-control"
                        v-model="bodega"
                        @change="seleccionarBodega"
                    >
                      <option value="" disabled>Seleccione una bodega</option>
                      <option
                          v-for="bodega in bodegas"
                          :key="bodega.id"
                          :value="bodega.id"
                      >
                        {{ bodega.nombre }}
                      </option>
                    </select>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Acciones</label>
                <div class="form-group">
                  <a href="javascript:void(0)" @click="agregar" style="vertical-align:middle"><i class="ti-plus"></i>
                    Agregar</a>
                </div>
              </div>
            </div>
          </div>

          <div class="row">

          </div>
          <br/>
          <div class="table-responsive" v-if="requerimientos.length > 0">

            <table class="table table-sm">
              <thead>
              <tr>
                <th></th>
                <th>Solicitud</th>
                <th>Tipo Solicitud</th>
                <th>Estado</th>
                <th>Proveedor</th>
                <th>Bodega Origen</th>
                <th>Bodega Destino</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in requerimientos" :key="item.id">
                <td>
                  <a
                      title="Eliminar"
                      href="javascript:void(0)"
                      class="text-danger"
                      @click="eliminar(item)"
                  ><i class="ti-close"></i
                  ></a>
                </td>
                <td>{{ item?.solicitud }}</td>
                <td>{{ solicitudProveedor.solicitud?.tipoSolicitud }}</td>
                <td>{{ solicitudProveedor.estadoSolicitud }}</td>
                <td>{{ solicitudProveedor?.bodega?.proveedor?.nombreProveedor }}</td>
                <td>{{ solicitudProveedor?.bodega?.nombre }}</td>
                <td>{{ item?.nombreBodega }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <br/>
          <div class="row">
            <div class="col-lg-12">
              <button
                  type="button"
                  id="form-submit"
                  class="btn btn-primary btn-general mr-2"
                  @click="guardar"
                  :disabled="!requerimientos.length"
              >
                <i class="ti-save"></i> Reasignar
              </button>
            </div>
          </div>
          <ModalMessages
              :mensaje="mensajeProceso"
              :titulo="titulo"
          />

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../components/ModalMessages.vue";
import {Field} from "vee-validate";

export default {
  name: "ReasignarRequerimiento",
  components: {ModalMessages, Field},
  data() {
    return {
      bodega: "",
      nombreBodega: "",
      idSolicitud: "",
      requerimientos: [],
      requerimientosPost: [],
      mensajeProceso: null,
      solicitudes: [],
      proveedor: "",
      titulo: "",
      solicitudProveedor: null
    };
  },
  async created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    await this.consultarProveedores();
    await this.consultarXProveedor();

  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarProveedores() {
      try {
        await this.$store.dispatch("proveedores/consultar");
        if (this.proveedorSeleccionado) {
          this.proveedor = this.proveedorSeleccionado.id;
        } else {
          this.proveedor = this.proveedores[0].id;
        }
      } catch (error) {
        alert("No se pudo realizar la consulta de proveedores");
      }
    },
    async consultarXProveedor() {
      this.loaderSpinner();
      try {
        if (this.proveedor) {
          await this.$store.dispatch("bodegas/consultarXProveedor", {proveedor: this.proveedor});
          const proveedorSeleccionado = this.proveedores.find(item => item.id === this.proveedor);
          await this.$store.commit("proveedores/setProveedorSeleccionado", proveedorSeleccionado);
        }
      } catch (error) {
        alert("No se pudo realizar la consulta de las bodegas");
      } finally {
        this.loader.hide();
      }
    },

    seleccionarBodega(e) {
      this.nombreBodega = e.target.options[e.target.selectedIndex].innerHTML;
    },
    async agregar() {

      if (!this.idSolicitud || !this.bodega) {
        return;
      }
      try {
        if (this.solicitudProveedor) {
          const nuevoRequerimiento = {
            solicitud: this.solicitudProveedor.solicitud.id,
            bodega: this.bodega,
            nombreBodega: this.nombreBodega
          };

          const nuevoRequerimientoPost = {
            idSolicitud: this.solicitudProveedor.solicitud.id,
            idBodega: this.bodega,
          };

          const existeRequerimiento = this.requerimientos.some(
              req => req.solicitud === nuevoRequerimiento.solicitud
          );

          const existeSolicitud = this.solicitudes.some(
              sol => sol.id === this.solicitudProveedor.solicitud.id
          );

          if (!existeRequerimiento) {
            this.requerimientos.push(nuevoRequerimiento);
            this.requerimientosPost.push(nuevoRequerimientoPost);
          }

          if (!existeSolicitud) {
            this.solicitudes.push(this.solicitudProveedor.solicitud.id);
          }

          this.idSolicitud = "";
        } else {
          this.mensajeProceso = ["No se encuentra la solicitud"];
          this.titulo = "AGREGAR SOLICITUD";
          document.getElementById("modalButton").click();
        }
      } catch (error) {
        this.mensajeProceso = ["No se encuentra la solictud"];
        this.titulo = "AGREGAR SOLICITUD";
        document.getElementById("modalButton").click();
      }

    },
    eliminar(requerimiento) {
      const requerimientosFiltrados = this.requerimientos?.filter(
          (item) => item !== requerimiento
      );
      this.requerimientos = requerimientosFiltrados;
      const solicitudesFiltrados = this.solicitudes?.filter(
          (item) => item !== requerimiento.solicitud
      );
      this.solicitudes = solicitudesFiltrados;
      this.bodega = "";
      this.proveedor = "";
    },
    async guardar() {

      try {
        this.loaderSpinner();
        const resp = await this.$store.dispatch(
            "solicitudesProveedor/reasignarRequerimientoZone",
            {data: this.requerimientosPost}
        );

        if (resp === "ERROR") {
          this.mensajeProceso = ["No se pudo procesar el Reasignamiento"];
        } else {
          this.mensajeProceso = [resp];
          this.titulo = "Reasignar requerimiento";
          this.solicitud = "";
          this.bodega = "";
          this.nombreBodega = "";
          this.requerimientos = [];
          this.solicitudes = [];
        }
        this.ejecutarCerrar = false;
        document.getElementById("modalButton").click();
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async buscarSolicitud() {
      const resp = await this.$store.dispatch(
          "solicitudesProveedor/buscarSolicitudProveedorBySolicitud",
          {
            idSolicitud: this.idSolicitud
          }
      );
      this.solicitudProveedor = resp;
      this.proveedor = resp?.bodega?.proveedor?.id

      await this.consultarXProveedor();
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    rol() {
      if (!this?.$store?.state?.auth?.rol) {
        this.logout();
        return;
      }
      return this.$store.state.auth.rol;
    },

    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
          ? this.$store.getters["bodegas/bodegas"]
          : [];
    },
    proveedores() {
      return this.$store.getters["proveedores/proveedores"]
          ? this.$store.getters["proveedores/proveedores"]
          : [];
    },
    proveedorSeleccionado() {
      return this.$store.getters["proveedores/proveedorSeleccionado"]
          ? this.$store.getters["proveedores/proveedorSeleccionado"]
          : null;
    },
  },
};
</script>
<style></style>
