<template>
  <div class="white-content-table">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Acciones</th>
          <th v-for="(col, index) in columns" :key="index">{{ col.nombre }}</th>
        </tr>
        <tr>
          <td></td>
          <td v-for="(col, index) in columns" :key="index">
            <div>
              <Field
                  name="col.campo"
                  as="select"
                  class="form-control custom-select"
                  v-model.trim="filters[col.campo]"
                  @change="filterData"
                  v-if="col.tipo === 'String' || col.tipo === 'Array'"
              >
                <option value="">Todos</option>
                <option
                    v-if="hasEmptyValues[col.campo]"
                    value="__VACIO__"
                >Vacío
                </option>
                <option v-for="(option, idx) in uniqueValues[col.campo]" :key="idx" :value="option">
                  {{ option }}
                </option>
              </Field>

              <Field
                  v-if="col.tipo === 'Text'"
                  name="col.campo"
                  as="input"
                  type="text"
                  class="form-control"
                  v-model.trim="filters[col.campo]"
                  @keyup="filterData"
                  placeholder="Buscar..."
              />

            </div>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in filteredData" :key="index">
          <td>
            <a
                v-if="showEdit"
                href="javascript:void(0)"
                @click="handleEdit(row)"
            ><i class="ti-pencil btn-icon-append" title="Editar"></i></a
            >&nbsp;
            <a
                href="javascript:void(0)"
                @click="handleDelete(row)"
            ><i class="ti-trash btn-icon-append" title="Eliminar"></i
            ></a>&nbsp;
            <a
                href="javascript:void(0)"
                @click="handleReset(row)"
                v-if="showIcono"
            ><i class="ti-lock btn-icon-append" title="Resetear"></i
            ></a>

            <a
                href="javascript:void(0)"
                @click="handleShowModal(row)"
                v-if="showModal"
            ><i class="ti-home btn-icon-append" title="Bodegas Gerencia"></i
            ></a>

          </td>
          <td v-for="col in columns" :key="col.campo">

            <template v-if="Array.isArray(row[col.campo])">
              <div v-for="item in row[col.campo]" :key="item" class="badge badge-light">{{ item }}</div>
            </template>
            <template v-else>
              {{ row[col.campo] }}
            </template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {Field} from "vee-validate";


export default {

  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    showIcono: {
      type: Boolean,
      default: false
    },
    showModal: {
      type: Boolean,
      default: false
    },
    showEdit : {
      type: Boolean,
      default: true
    }
  },
  components: {
    Field
  },
  data() {
    return {
      filters: {},
      uniqueValues: {},
      filteredData: this.data,
      hasEmptyValues: {},
    };
  },
  watch: {
    data: {
      handler() {
        this.initializeFilters();
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    initializeFilters() {
      this.uniqueValues = {};
      this.filters = {};

      if (!this.data || this.data.length === 0) {
        this.filteredData = [];
        return;
      }
      this.columns.forEach(col => {
        const allValues = this.data.map(row => row?.[col.campo]);
        this.uniqueValues[col.campo] = [...new Set(allValues)].filter(value => value !== null && value !== undefined && value !== '');
        this.hasEmptyValues[col.campo] = allValues.some(value => value === null || value === undefined || value === '');
        this.filters[col.campo] = '';
      });
      this.filterData();
    },
    filterData() {


      const allFiltersEmpty = Object.values(this.filters).every(filter => filter === '');
      if (allFiltersEmpty) {
        this.filteredData = [...this.data];
        return;
      }

      this.filteredData = this.data.filter(row => {
        return this.columns.every(col => {
          const filterValue = this.filters[col.campo];
          const cellValue = row?.[col.campo];

          if (!filterValue) return true;

          if (filterValue === "__VACIO__") {
            return cellValue === null || cellValue === undefined || cellValue === '';
          }

          if (Array.isArray(cellValue)) {
            return cellValue.includes(filterValue);
          } else if (col.tipo === 'Text') {
            if(cellValue!==null){
              return cellValue.toString().toLowerCase().includes(filterValue.toString().toLowerCase());
            }
          } else {
            return cellValue === filterValue;

          }
        });
      });
    },
    handleEdit(row) {
      this.$emit('edit', row);
    },
    handleDelete(row) {
      this.$emit('delete', row.id);
    },
    handleReset(row) {
      this.$emit('reset', row.usuario);
    },
    handleShowModal(row) {
      this.$emit('modal', row);
    },

  }
};
</script>

<style>
.custom-select {
  display: inline-block;
  width: 90%;
  padding: 0 0 0 5px;
  font-size: smaller;
  line-height: 1.5;
  vertical-align: middle;
}

.custom-select:hover {
  background-color: #ededed !important;
  color: #000;
  border: 1px solid #ededed !important;
}

.custom-select option:checked {
  background-color: #ededed !important;
  color: #000;
  border: 1px solid #ededed !important;
}
</style>
