<template>
  <img
      title="Descargar Template"
      @click="descargarTemplate"
      src="../assets/icons/icon-excel.png"
      alt="Descargar Template"
      class=" mr-2"
      style="cursor: pointer; width: 35px"
  />
</template>
<script>
export default {
  props: {
    template: {
      type: String,
      required: true,
    },
  },
  methods: {
    async descargarTemplate() {
      const enlace = document.createElement('a');
      enlace.href = "/" + this.template;
      enlace.download = this.template;
      enlace.click();
      enlace.remove();
    },
  }
}
</script>
