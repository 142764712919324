<template>
    <div class="col-md-12" v-if="country!=='EC'">
      <GestionUsuarioBodega/>
    </div>
    <div class="col-md-12" v-else>
      <GestionUsuariosVehiculosTransporte/>
    </div>
</template>

<script>
import GestionUsuariosVehiculosTransporte from "@/views/admin/usuarios/components/GestionUsuariosVehiculosTransporte";
import GestionUsuarioBodega from "@/views/admin/usuarios/GestionUsuarioBodega.vue";

export default {
  components: {GestionUsuarioBodega, GestionUsuariosVehiculosTransporte},
  data() {
    return {
      country: this.$config.countryFlag,
    }
  }
}
</script>
