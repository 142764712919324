<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-4">
            <h3 class="font-weight-bold">Solicitudes Baja</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">

            <div class="col-md-12">

              <button
                  class="btn btn-primary btn-general dropdown-toggle float-right mr-2"
                  type="button"
                  id="tipo"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
              >
                <i class="ti-plus"></i> Acción
              </button>
              <div class="dropdown-menu" aria-labelledby="tipo">
                <a class="dropdown-item" href="#" @click="seleccionarAccion('APROBAR')"
                >APROBAR</a
                >
                <a class="dropdown-item" href="#" @click="seleccionarAccion('RECHAZAR')"
                >RECHAZAR</a
                >
              </div>

              <button
                  @click="descargarBajas"
                  class="btn btn-primary btn-general dropdown-toggle float-right mr-2"
                  type="button"
                  id="tipo"
              >
                <i class="icon-download"></i> Descargar
              </button>

            </div>
          </div>
        </div>

        <div class="white-content-table">

          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th></th>
                <th>Estado</th>
                <th>{{ this.$config.labelPlaca }}</th>
                <th>Garantía</th>
                <th>{{ this.$config.labelActivoId }}</th>
                <th>{{ this.$config.labelGerenciaGeneral }}</th>
                <th>{{ this.$config.labelGerenciaVentas }}</th>
                <th>Proveedor</th>
                <th>Solicitante (Técnico)</th>
                <th>Motivo</th>
                <th>Adjuntos</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in bajaSolicitadas" :key="item.id">
                <td>
                  <input type="checkbox" :checked="isSelected(item.id)" @change="selectItem(item)"
                         v-if="item.estadoProceso==='PENDIENTE'"/>
                </td>
                <td>
                  <div
                      class="badge"
                      :class="
                      item.estadoProceso === 'PENDIENTE'
                        ? 'badge-secondary'
                        :  item.estadoProceso === 'APROBADO' ? 'badge-success': 'badge-danger'
                    "
                  >
                    {{ item.estadoProceso }}
                  </div>
                </td>
                <td>{{ item?.placa }}</td>
                <td>{{ item?.garantia===true?'SI':'NO'}}</td>
                <td>{{ item?.idEquipo }}</td>
                <td>{{ item?.direccionVentas }}</td>
                <td>{{ item?.gerenciaVentas }}</td>
                <td>{{ item?.proveedor }}</td>
                <td>{{ item.solicitante }}</td>
                <td>{{ item.motivo }}</td>
                <td>
                  <FileDownloadMultiple title="Adjuntos" icon="ti-clip" :files="item.adjuntos"/>
                </td>

              </tr>
              </tbody>
            </table>
            <Paginacion :updatePage="updatePage"/>
          </div>

          <Modal
              :mensaje="mensaje"
              :method="procesarAccion"
          />
          <ModalMessageOnly
              :mensaje="mensaje"
          />
          <button
              type="button"
              v-show="false"
              id="exampleModalButton"
              class="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal"
              data-backdrop="static"
              data-keyboard="false"
          >
            Modal messages
          </button>
          <button
              type="button"
              v-show="false"
              id="modalMessageOnlyButton"
              class="btn btn-primary"
              data-toggle="modal"
              data-target="#modalMessageOnly"
              data-backdrop="static"
              data-keyboard="false"
          >
            Modal messages
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Paginacion from "@/components/Paginacion.vue";
import FileDownloadMultiple from "@/components/FileDownloadMultiple.vue";
import Modal from "@/components/Modal.vue";
import ModalMessageOnly from "@/components/ModalMessageOnly.vue";

export default {
  components: {ModalMessageOnly, Modal, FileDownloadMultiple, Paginacion},
  data() {
    return {
      pagina: 1,
      checkAll: false,
      selectedItems: new Set(),
      accion: "",
      mensaje: ""
    }
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  mounted() {
    this.consultar(1);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },

    updatePage(page) {
      this.pagina = page;
    },
    async consultar(pagina) {

      const data = {
        pagina: pagina || 1,
      };
      this.loaderSpinner();
      try {
        await this.$store.dispatch("bajaSolicitadaTecnico/consultarBajasSolicitadaAllPaginado", data);
        await this.$store.dispatch(
            "bajaSolicitadaTecnico/consultarBajasSolicitadaAllTotalPaginas"
        );
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },

    isSelected(id) {
      return this.selectedItems.has(id);
    },

    selectItem(check) {
      if (this.selectedItems.has(check.id)) {
        this.selectedItems.delete(check.id);
      } else {
        this.selectedItems.add(check.id);
      }
    },

    async seleccionarAccion(accion) {
      this.accion = accion
      this.mensaje = `¿Desea ${this.accion === "APROBAR" ? "aprobar" : "rechazar"} ${this.codigosSeleccionados.length} registros?`;
      document.getElementById("exampleModalButton").click();
    },

    async procesarAccion() {

      const proceso = this.accion === "APROBAR" ? "APROBADO" : "RECHAZADO";
      const successText = `Registros ${this.accion === "APROBAR" ? "aprobados" : "rechazados"} con éxito.`;

      try {
        await this.$store.dispatch("bajaSolicitadaTecnico/procesarBajasSolicitadas", {
          proceso: proceso,
          solicitudesBaja: this.codigosSeleccionados
        });
        await this.consultar(1);
        this.selectedItems = [];

        this.mensaje = successText;
        document.getElementById("modalMessageOnlyButton").click();

      } catch (error) {
        this.$swal("", error.message, "error");
      } finally {
        this.loader.hide();
      }
    },
    async descargarBajas() {
      if (this.condiciones === undefined) {
        this.condiciones = [];
      }
      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch("exportar/exportarV2", {
          entidad: "bajas_view",
          filtro: {
            condiciones: this.condiciones
          }
        });

        if (resp.success) {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `bajas.csv`);
          document.body.appendChild(link);
          link.click();
        }else{
          this.mensaje = "Se produjo un error al descargar el archivo";
          document.getElementById("modalMessageOnlyButton").click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    }
    ,
    rol() {
      if (!this?.$store?.state?.auth?.rol) {
        this.logout();
        return;
      }
      return this.$store.state.auth.rol;
    }
    ,
    bajaSolicitadas() {
      return this.$store.getters["bajaSolicitadaTecnico/bajas"]
          ? this.$store.getters["bajaSolicitadaTecnico/bajas"]
          : [];
    },
    codigosSeleccionados() {
      return Array.from(this.selectedItems);
    }
  }
  ,
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    }
  }
}
</script>
