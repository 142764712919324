<template>
  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12 ">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Bienvenido
            </h3>
          </div>
          <div class="col-md-6">
          
          </div>
        </div>
      </div>
    </div>

    <div class="white-content-general mb-4">
      <div class="row">
        <div class="col-12 mb-4 ">
          <h6 class="font-weight-normal txt-celeste mb-0">
            Eventos y requerimientos pendientes
          </h6>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 stretch-card grid-margin grid-margin-md-0" @click="redirect('EventosPendientesTecnico')">
          <div class="card data-icon-card-primary bg-celeste">
            <div class="card-body">
              <p class="card-title text-white">Eventos</p>
              <div class="row">
                <div class="col-8 text-white">
                  <h3>{{totalEventos}}</h3>
                  <p class="text-white font-weight-500 mb-0"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 stretch-card grid-margin grid-margin-md-0" @click="redirect('SolicitudesTecnico')">
          <div class="card card-dark-blue bg-azul-dark">
            <div class="card-body">
              <p class="card-title text-white">Requerimientos</p>
              <div class="row">
                <div class="col-8 text-white">
                  <h3>{{totalRequerimientos}}</h3>
                  <p class="text-white font-weight-500 mb-0"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DashboardTecnico",
  data() {
    return {};
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("rutaHome/consultarTotales");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    redirect(ruta){
        this.$router.push({name: ruta});
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    totalEventos() {
      return this.$store.getters["rutaHome/totalEventos"]
        ? this.$store.getters["rutaHome/totalEventos"]
        : "0";
    },
    totalRequerimientos() {
      return this.$store.getters["rutaHome/totalRequerimientos"]
        ? this.$store.getters["rutaHome/totalRequerimientos"]
        : "0";
    },
  },
};
</script>
