import axios from "axios";
import store from "@/store";

const state = {
  archivos: null,
  archivoSeleccionado: null,
  totalPaginas: null,
  totalRegistros: null,
};

const getters = {
  archivos: (state) => state.archivos,
  archivoSeleccionado: (state) => state.archivoSeleccionado,
  totalPaginas: (state) => state.totalPaginas,
  totalRegistros: (state) => state.totalRegistros,
};

const mutations = {
  setArchivos: (state, archivos) => {
    state.archivos = archivos;
  },
  setArchivoSeleccionado: (state, archivoSeleccionado) => {
    state.archivoSeleccionado = archivoSeleccionado;
  },

  setTotalPaginas: (state, data) => {
    state.totalPaginas = data;
  },
  setTotalRegistros: (state, data) => {
    state.totalRegistros = data;
  },
};

const actions = {
  async consultarSolicitudesXPagina({ commit }, data) {
    try {
      let res = await axios.get(`/v1/solicitudes/upload/${data.pagina}/pagina`);
      commit("setArchivos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarTotalPaginas({ commit }) {
    try {
      const resp = await axios.get(`/v1/solicitudes/upload/total`);
      commit("setTotalPaginas", resp?.data?.totalPaginas);
      return resp?.data?.totalPaginas || 0;
    } catch (error) {
      return error;
    }
  },
  async eliminarArchivo({ commit }, data) {
    try {
      await axios.delete(`v1/solicitudes/${data.idArchivo}/upload`);
      let res = await axios.get(`/v1/solicitudes/upload/1/pagina`);
      commit("setArchivos", res?.data?.result);
      const resp = await axios.get(`/v1/solicitudes/upload/total`);
      commit("setTotalPaginas", resp?.data?.totalPaginas);
      return "Se ha eliminado con éxito";
    } catch (error) {
      return error?.response?.data?.errors?.[0] || "No se pudo eliminar el archivo";
    }
  },

  async consultarSolicitudesByFiltros({ commit }, data) {
    try {
      let resp = await axios.post(`/v1/solicitudes/upload/filtros`, data);
      commit("setArchivos", resp?.data?.result?.solicitudFileUploads);
      commit("setTotalPaginas", resp?.data?.result?.paginacion?.totalPaginas || 1);
      commit("setTotalRegistros", resp?.data?.result?.paginacion?.totalRegistros || 0);
      store.dispatch("pagination/updateTotales", {
        totalPaginas: resp?.data?.result?.paginacion?.totalPaginas,
        totalRegistros: resp?.data?.result?.paginacion?.totalRegistros
      })
    } catch (error) {
      return error;
    }
  },

};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
