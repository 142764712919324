<template>
  <GestionSolicitudesZone v-if="$config.countryFlag!=='EC'"/>
  <GestionSolicitudesEC v-else/>
</template>
<script>
import GestionSolicitudesZone from "@/views/solicitudes/components/GestionSolicitudesZone.vue";
import GestionSolicitudesEC from "@/views/solicitudes/components/GestionSolicitudesEC.vue";

export default {
  components: {GestionSolicitudesZone, GestionSolicitudesEC}

}
</script>
