<template>
  <div class="col-md-12 col-sm-12 stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-12">
            <h3 class="font-weight-bold">Dar de baja equipos</h3>
          </div>
          <div class="col-md-6">
            
          </div>
        </div>

        <div class="white-content-general mb-4">
          <form name="f1" id="formElement" class="mb-4">
            <div class="card" >
              <div class="card-body">
                <div class="form-check-inline mb-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="tipoBusqueda"
                    id="exampleRadios1"
                    value="PLACA"
                    v-model="tipoBusqueda"
                  />
                  <label class="form-check-label mr-2" for="exampleRadios1">
                    Buscar por placa
                  </label>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="tipoBusqueda"
                    id="exampleRadios2"
                    value="SERIE"
                    v-model="tipoBusqueda"
                  />
                  <label class="form-check-label mr-2" for="exampleRadios2">
                    Buscar por serie
                  </label>
                </div>
          
                <div class="input-group col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="valorBusqueda"
                    aria-label="Text input with segmented dropdown button"
                  />
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-outline-secondary btn-general btn-buscar"
                      @click="buscarEquipo"
                    >
                    <span class="icon-search"></span> Buscar
                    </button>
                  </div>
                </div>
              </div>
            </div>  
            <div class="card" >
              <div class="card-body">
                <h5 class="card-title txt-celeste">Datos equipo</h5>
                <h6 class="card-subtitle mb-2 text-muted">
                  Marca: {{ equipoSeleccionado.marca }}
                </h6>
                <h6 class="card-subtitle mb-2 text-muted">
                  Placa: {{ equipoSeleccionado.placa }}
                </h6>
                <h6 class="card-subtitle mb-2 text-muted">
                  Serie: {{ equipoSeleccionado.serie }}
                </h6>
                <h6 class="card-subtitle mb-2 text-muted">
                  Modelo: {{ equipoSeleccionado.modelo }}
                </h6>
                <h6 class="card-subtitle mb-2 text-muted">
                  Capacidad (pies): {{ equipoSeleccionado.capacidadPies }}
                </h6>
                <h6 class="card-subtitle mb-2 text-muted">
                  Tipo: {{ equipoSeleccionado.tipoEquipo }}
                </h6>
              </div>
            </div>
            <div class="card" >
              <div class="card-body">
                <h5 class="card-title txt-celeste">Cargar archivo</h5>
                <div class="input-group mb-3">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="inputGroupFile01"
                      ref="file"
                      @change="uploadFile"
                    />
                    <label class="custom-file-label" for="inputGroupFile01"
                      >Archivo</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Observación</label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    v-model="observacion"
                  ></textarea>
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-general"
                  @click="procesar"
                >
                <span class="icon-play"></span> Ejecutar proceso
                </button>
              </div>
            </div>
          </form>
          <ModalMessages :mensaje="mensaje" titulo="Asignación" />
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../../components/ModalMessages.vue";
export default {
  name: "GestionBajas",
  components: { ModalMessages },
  data() {
    return {
      archivo: null,
      valorBusqueda: "",
      observacion: "",
      tipoBusqueda: "",
      mensaje: [],
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  mounted() {},
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async buscarEquipo() {
      try {
        this.loaderSpinner();

        if (this.tipoBusqueda && this.tipoBusqueda === "SERIE") {
          await this.$store.dispatch("equipos/consultarEquipoXSerie", {
            serie: this.valorBusqueda,
          });
        }
        if (this.tipoBusqueda && this.tipoBusqueda === "PLACA") {
          await this.$store.dispatch("equipos/consultarEquipoXPlaca", {
            placa: this.valorBusqueda,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
    },
    async procesar() {
      try {
        this.loaderSpinner();
        const formData = new FormData();
        formData.append("file", this.archivo);
        const resp = await this.$store.dispatch("procesos/procesarBaja", {
          idEquipo: this.equipoSeleccionado.id,
          archivo: formData,
        });
        
        if (resp[0] === "error") {
          this.loader.hide();
          this.mensaje = [resp[1]];
          document.getElementById("modalButton").click();
          return;
        }
        await this.$store.commit("equipos/setEquipoSeleccionado", {});
        this.observacion = "";
        this.valorBusqueda = "";
        this.loader.hide();
        this.mensaje = ["Proceso de baja exitoso"];
        document.getElementById("modalButton").click();
      } catch (error) {
        this.error = true;
        this.loader.hide();
      } finally {
        //this.loader.hide();
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    equipoSeleccionado() {
      return this.$store.getters["equipos/equipoSeleccionado"]
        ? this.$store.getters["equipos/equipoSeleccionado"]
        : {};
    },
  },
};
</script>
<style></style>
