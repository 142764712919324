<template>
  <div class="row">
    <div
      :class="estilo"
      v-for="adjunto in adjuntos"
      :key="adjunto?.id"
    >
      <fieldset v-if="adjunto.tipo === 'FILE'">
        <label :for="adjunto?.id" class="form-label">{{
            adjunto?.descripcion
          }}</label>
          <input
            class="form-control"
            type="file"
            :id="adjunto.id"
            ref="file"
            @change="uploadFile"
          />
      </fieldset>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    columna:String,
    tipoSolicitud: String,
    uploadFile: { type: Function },
  },
  data() {
    return {
      estilo:"form-group col-md-12"
    };
  },
  created(){
    this.estilo="form-group col-md-"+this.columna;
  },
  mounted() {
    this.loadAdjuntos();
    this.estilo="form-group col-md-"+this.columna;
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async loadAdjuntos() {
      try {
        await this.$store.dispatch("atencionAdjunto/consultar", {
          tipoSolicitud: this.tipoSolicitud,
        });
      } catch (error) {
        alert("No se pudo consultar los adjuntos");
      }
    }
  },
  computed: {
    adjuntos() {
      return this.$store.getters["atencionAdjunto/atencionAdjuntos"]
        ? this.$store.getters["atencionAdjunto/atencionAdjuntos"]
        : [];
    },
  },
};
</script>
