<template>
  <GestionEquipos/>
</template>
<script>
import GestionEquipos from "./components/GestionEquipos"

export default {
  components: {GestionEquipos},
  mounted() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$router.push({name: "Login"});
    }
  }

};
</script>
