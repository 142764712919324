<template>
  <fieldset>
    <select
      class="form-control"
      v-model="selectedOption"
      @change="handleSelectChange"
    >
      <option value="" disabled>Seleccione una opción</option>
      <option
        v-for="option in variables"
        :key="option.id"
        :value="option.atributo"
      >
        {{ option.atributo }}
      </option>
    </select>
  </fieldset>
</template>

<script>
export default {
  props: {
    variable: String,
    selectVariable: { type: Function },
    selectedOptionValue: String, // Prop para recibir el valor seleccionado
  },
  data() {
    return {
      variables: [],
            selectedOption: this.selectedOptionValue, // Enlaza el valor seleccionado con la variable 'selectedOption'
          };
        },
        mounted() {
          this.loadVariables();
        },
        methods: {
          async loadVariables() {
            try {
              const resp = await this.$store.dispatch("variables/consultar", {
                variable: this.variable,
              });
              this.variables = resp;
              if (this.selectedOptionValue && this.variables.some(option => option.atributo === this.selectedOptionValue)) {
                        this.selectedOption = this.selectedOptionValue;
                    }
            } catch (error) {
              alert("No se pudo consultar los variables");
            }
          },
          handleSelectChange() {
            this.selectVariable(this.selectedOption);
          },
          limpiarCampos() {

            this.selectedOption="";
          }
        },
      };
      </script>
