<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Agregar cliente a lista negra</h3>
          </div>
          <div class="col-md-6"></div>
        </div>
        
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <form>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="name">{{ $config.labelCodigoCliente }} *</label>
                            <div class="input-group">
                              <input
                                  type="text"
                                  class="form-control"
                                  v-model="codigoCliente"
                                  aria-label="Text input with segmented dropdown button"
                              />
                              <div class="input-group-append">
                                <button
                                    type="button"
                                    class="btn btn-outline-secondary btn-general btn-buscar"
                                    @click="buscar"
                                >
                                  <span class="icon-search"></span> Buscar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <h5 class="card-title">
                            {{ $config.labelCodigoCliente }}:
                            <span v-if="$config.countryFlag!=='EC'">{{ cliente?.pocId }} </span>
                            <span v-else>{{ cliente?.clienteSap }} </span>
                          </h5>
                          <h6 class="card-subtitle mb-2 text-muted">
                            {{ $config.labelNombreCliente }}:

                            <span v-if="$config.countryFlag!=='EC'">{{ cliente?.pocOwnerName }} </span>
                            <span v-else>{{ cliente?.nombresCliente }} </span>

                          </h6>
                          <h6 class="card-subtitle mb-2 text-muted">
                            {{ $config.labelEstablecimiento }}:

                            <span v-if="$config.countryFlag!=='EC'">{{ cliente?.pocName }} </span>
                            <span v-else>{{ cliente?.nombrePoc }} </span>

                          </h6>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="tipo">Motivo *</label>
                            <CustomVariableList
                                variable="MOTIVO_LISTA_NEGRA"
                                :selectVariable="selectMotivo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                          type="button"
                          id="form-submit"
                          @click="submit"
                          class="btn btn-primary btn-general mr-2"
                      >
                        <i class="ti-save"></i> Guardar
                      </button>
                      <button
                          type="button"
                          class="btn btn-secondary btn-general-off"
                          @click="cerrar"
                      >
                        <i class="ti-close"></i> Cerrar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalMessageOnly
        :mensaje="mensaje"
    />
    <button
        type="button"
        v-show="false"
        id="modalMessageOnlyButton"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#modalMessageOnly"
        data-backdrop="static"
        data-keyboard="false"
    >
      Modal messages
    </button>


  </div>
</template>
<script>
import CustomVariableList from "../../../../components/CustomVariableList.vue";
import ModalMessageOnly from "@/components/ModalMessageOnly.vue";

export default {
  name: "FiltroForm",
  components: {
    ModalMessageOnly,
    CustomVariableList,
  },
  data() {
    return {
      codigoCliente: null,
      motivo: null,
      country: "EC",
      mensaje:""
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.$store.commit("rutero/setCliente", null);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async buscar() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("rutero/consultarRuteroXCliente", {
          codigoCliente: this.codigoCliente,
        });
      } catch (error) {
        alert("No se pudo consultar el cliente");
      } finally {
        this.loader.hide();
      }
    },
    selectMotivo(motivo) {
      this.motivo = motivo;
    },
    async submit() {
      if (!this.cliente) {
        this.mensaje="Revise los campos y vuelva a intentarlo"
        document.getElementById("modalMessageOnlyButton").click();
        return;
      }
      this.loaderSpinner();
      this.country = this.$config.countryFlag
      let data = null;
      if (this.country === 'EC') {
        data = {
          cliente: this.cliente?.clienteSap,
          motivo: this.motivo,
        };
      } else {
        data = {
          cliente: this.cliente?.pocId,
          motivo: this.motivo,
        };
      }
      try {
        const resp = await this.$store.dispatch("listaNegra/crear", data);
        if(resp.success){
          this.$router.push({name: "ListaNegra"});
        }else{
          this.mensaje=resp.data[0]
          document.getElementById("modalMessageOnlyButton").click();
        }
      } catch (error) {
        this.mensaje = "No se pudo guardar la información";
        document.getElementById("modalMessageOnlyButton").click();
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    cliente() {
      return this.$store.getters["rutero/cliente"]
          ? this.$store.getters["rutero/cliente"]
          : null;
    },
  },
};
</script>
