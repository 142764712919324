import axios from "axios";

const state = {
    solicitudesTecnico: null,
    solicitudTecnicoSeleccionada: null,
    coordenadas: [],
    puntosSeleccionados: [],
    mensaje: "",
    solicitudesCerradas: [],
    totalPaginas: 0
};

const getters = {
    solicitudesTecnico: (state) => state.solicitudesTecnico,
    solicitudTecnicoSeleccionada: (state) => state.solicitudTecnicoSeleccionada,
    coordenadas: (state) => state.coordenadas,
    puntosSeleccionados: (state) => state.puntosSeleccionados,
    solicitudesCerradas: (state) => state.solicitudesCerradas,
    totalPaginas: (state) => state.totalPaginas,
};

const mutations = {
    setSolicitudes: (state, solicitudes) => {
        state.solicitudesTecnico = solicitudes;
    },
    setSolicitudPSeleccionada: (state, solicitudTecnicoSeleccionada) => {
        state.solicitudTecnicoSeleccionada = solicitudTecnicoSeleccionada;
    },
    setCoordenadas: (state, coordenadas) => {
        state.coordenadas = coordenadas;
    },
    setPuntosSeleccionados: (state, punto) => {
        state.puntosSeleccionados = [...state.puntosSeleccionados, punto];
    },
    updatePuntosSeleccionados: (state, puntos) => {
        state.puntosSeleccionados = [...puntos];
    },
    deletePuntoSeleccionado: (state, punto) => {
        const puntosActualizados = state.puntosSeleccionados.filter(
            (item) => item !== punto
        );
        state.puntosSeleccionados = [...puntosActualizados];
    },
    inicializar: (state) => {
        state.solicitudesProveedor = null
    },
    inicializarPuntosSeleccionados: (state) => {
        state.puntosSeleccionados = []
    },
    setMensaje: (state, mensaje) => {
        state.mensaje = mensaje;
    },
    setSolicitudesCerradas: (state, solicitudesCerradas) => {
        state.solicitudesCerradas = solicitudesCerradas;
    },
    setTotalPaginas: (state, data) => {
        state.totalPaginas = data;
    }
};

const actions = {
    async consultar({commit}) {
        try {
            let res = await axios.get(`/v1/solicitudes-asignadas`);
            commit("setSolicitudes", res?.data?.result);
        } catch (error) {
            return error;
        }
    },
    async procesarRetiro({commit}, data) {
        try {
            await axios.post(`v1/solicitudes-asignadas/procesar-retiro`, data?.data);
            commit("setMensaje", "Retiro exitoso");
            return "Proceso de retiro exitoso";
        } catch (error) {
            return "No se pudo procesar el requerimiento";
        }
    },
    async procesarInstalacion({commit}, data) {
        try {
            await axios.post(`v1/solicitudes-asignadas/procesar-instalacion`, data?.data);
            commit("setMensaje", "Instalación exitosa");
            return "Proceso de instalación exitoso";
        } catch (error) {
            return "No se pudo procesar el requerimiento";
        }
    },
    async consultarSolicitudesCerradasXPagina({commit}, data) {
        try {
            let res = await axios.get(`/v1/rutas/1/tecnico/atendidos/${data.pagina}/pagina`);
            commit("setSolicitudesCerradas", res?.data?.result);
        } catch (error) {
            return error;
        }
    },
    async consultarSolicitudesCerradasXPaginaZone({commit}, data) {
        try {
            let res = await axios.get(`/v2/rutas/tecnico/atendidos/${data.pagina}/pagina`);
            commit("setSolicitudesCerradas", res?.data?.result);
        } catch (error) {
            return error;
        }
    },
    async consultarTotalPaginas({commit}, data) {
        try {
            const resp = await axios.get(`/v1/rutas/${data.idBodegaTecnico}/tecnico/atendidos/total`);
            commit("setTotalPaginas", resp?.data?.totalPaginas);
            return resp?.data?.totalPaginas || 0;
        } catch (error) {
            return error;
        }
    },
    async consultarTotalPaginasZone({commit}) {
        try {
            const resp = await axios.get(`/v2/rutas/tecnico/atendidos/total`);
            commit("setTotalPaginas", resp?.data?.totalPaginas);
            return resp?.data?.totalPaginas || 0;
        } catch (error) {
            return error;
        }
    },
    async consultarSolicitudesAtendidasXFechas({commit}, data) {
        try {
            let res = await axios.get(`/v1/rutas/${data.idBodegaTecnico}/tecnico/atendidos/${data.fechaInicio}/${data.fechaFin}`);
            commit("setSolicitudesCerradas", res?.data?.result);
            commit("setTotalPaginas", 1);
        } catch (error) {
            commit("setSolicitudesCerradas", []);
            commit("setTotalPaginas", 1);
            return error;
        }
    },

    async consultarSolicitudesAtendidasXFechasZone({commit}, data) {
        try {
            let res = await axios.get(`/v2/rutas/tecnico/atendidos/${data.fechaInicio}/${data.fechaFin}`);
            commit("setSolicitudesCerradas", res?.data?.result);
            commit("setTotalPaginas", 1);
        } catch (error) {
            commit("setSolicitudesCerradas", []);
            commit("setTotalPaginas", 1);
            return error;
        }
    },

    async devolucion({commit}, data) {
        try {
            await axios.put(`v1/solicitudes-proveedor/${data?.idSolicitudProveedor}/devolver`);
            commit("setMensaje", "Devolución exitosa");
        } catch (error) {
            return "No se pudo procesar la devolución";
        }
    },
    async procesarCambio({commit}, data) {
        try {
            const headers = {'Content-Type': 'multipart/form-data'};
            await axios.post(`v1/solicitudes-asignadas/procesar-cambio`, data?.data, {headers});
            commit("setMensaje", "Cambio exitoso");
            return "Proceso de cambio exitoso";
        } catch (error) {
            return error?.response?.data?.errors?.[0] || "No se pudo procesar el requerimiento";
        }
    }

};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
