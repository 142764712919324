<template>
  <div class="col-md-12 grid-margin stretch-card">

    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión Traslados Bodega</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0"></p>
            </div>
            <div class="col-md-12">

              <button
                  class="btn btn-primary btn-general float-right ml-2"
                  @click="cambiarVista"
              >
                <span class="icon-expand"></span> {{ titleButtonView }}
              </button>

              <button
                  v-if="displayEnvio===true"
                  class="btn btn-primary btn-general float-right"
                  @click="abrirModalArchivo"
              >
                <span class="icon-upload"></span> Cargar archivo
              </button>
              <ButtonDescargarTemplate template="TrasladosTemplate.xlsx"/>
            </div>
          </div>
        </div>

        <div
            class="modal"
            tabindex="-1"
            :style="displayModal ? 'display: block;' : 'display: none;'"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Cargar archivo</h5>
              </div>
              <div class="modal-body">
                <p>Seleccione archivo de datos</p>
                <div class="input-group mb-3">
                  <input
                      type="file"
                      @change="uploadFile"
                      ref="file"
                      class="form-control"
                  />
                </div>
                <p>Seleccione adjunto</p>
                <div class="input-group mb-3">
                  <input
                      type="file"
                      @change="uploadFileTemplate"
                      ref="fileTemplate"
                      class="form-control"
                  />
                </div>
                <div class="alert alert-danger" role="alert" v-if="error">
                  No se pudo cargar el archivo
                </div>
              </div>
              <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-general-off btn-secondary"
                    data-bs-dismiss="modal"
                    @click="cerrarModalArchivo"
                >
                  <i class="ti-close"></i> Cerrar
                </button>
                <button
                    type="button"
                    class="btn btn-general btn-primary"
                    @click="cargarArchivo"
                    :disabled="!archivo"
                >
                  <i class="ti-ti-upload"></i> Cargar
                </button>
              </div>
            </div>
          </div>
        </div>

        <TrasladoEnvio v-if="displayEnvio"/>
        <TrasladoRecepcion v-else/>

      </div>
    </div>
  </div>
</template>
<script>

import TrasladoEnvio from "@/views/procesos/traslados/components/TrasladoEnvio.vue";
import ButtonDescargarTemplate from "@/components/ButtonDescargarTemplate.vue";
import TrasladoRecepcion from "@/views/procesos/traslados/components/TrasladoRecepcion.vue";

export default {
  name: "GestionTraslados",
  components: {
    TrasladoRecepcion,
    ButtonDescargarTemplate,
    TrasladoEnvio,

  },
  data() {
    return {
      displayModal: false,
      error: false,
      archivo: null,
      archivoTemplate: null,
      mensajeProceso: "",
      titleButtonView: "Vista Envíos",
      displayEnvio: false
    };
  },
  async created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    await this.consultar(1);

  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      const data = {
        pagina: pagina || 1,
      };
      try {
        this.loaderSpinner();
        await this.$store.dispatch("traslados/consultarTrasladosPagina", data);
        await this.$store.dispatch(
            "traslados/consultarTrasladosTotalPagina"
        );
        let paginas = await this.$store.getters["traslados/totalPaginas"].totalPaginas;
        await this.$store.commit("pagination/setTotalPaginas", paginas);
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },

    abrirModalArchivo() {
      this.displayModal = true;
      this.error = false;
    },

    cerrarModalArchivo() {
      this.displayModal = false;
    },

    async uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
    },
    async uploadFileTemplate() {
      this.archivoTemplate = this?.$refs?.fileTemplate?.files[0];
    },
    async cargarArchivo() {
      try {
        this.loaderSpinner();
        const formData = new FormData();
        formData.append("file", this.archivo);
        formData.append("adjunto", this.archivoTemplate);
        const resp = await this.$store.dispatch(
            "traslados/cargarArchivo",
            formData
        );
        if (resp === "error") {
          this.error = true;
          this.loader.hide();
          return;
        }
        this.displayModal = false;
        this.loader.hide();
        this.titulo = "Cargar archivo";
        this.mensajeProceso = ["Se ha cargado el archivo con éxito"];
        document.getElementById("modalButton").click();
        await this.consultar(1);
      } catch (error) {
        this.error = true;
        this.loader.hide();
      } finally {
        this.loader.hide();
      }
    },
    cambiarVista() {
      this.displayEnvio = !this.displayEnvio;
      if (this.displayEnvio === true) {
        this.titleButtonView = "Vista Recepción"
      } else {
        this.titleButtonView = "Vista Envíos"
      }
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
  },
};
</script>
