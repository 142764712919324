<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Gestión de Solicitudes Cargadas
            </h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-12">
              <div class="col-md-12">
                <a
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#modalFiltros"
                    data-backdrop="static"
                    data-keyboard="false"
                    class="btn btn-primary btn-general float-right mb-2 mb-md-0"
                >
                  <i class="ti-filter menu-icon"></i> Filtrar</a
                >
              </div>

            </div>
          </div>
        </div>

        <Filtros
            titulo="Seleccionar filtros"
            :consultar="consultarXFiltros"
            entidad="solicitud_file_upload"
        />

        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th></th>
                <th>Id</th>
                <th>Nombre Archivo</th>
                <th>Fecha</th>
                <th>Hora</th>
                <th>Usuario</th>
                <th>Correctos</th>
                <th>Fallidos</th>
                <th>Detalle Fallidos</th>

              </tr>
              </thead>
              <tbody>
              <tr v-for="item in archivos" :key="item.id">
                <td style="width: 2%">
                  <a title="Eliminar" href="javascript:void(0)" @click="eliminar(item)"
                  ><i class="ti-trash"></i
                  ></a>
                </td>
                <td>{{ item.id }}</td>
                <td>{{ item.nombreArchivo }}</td>
                <td>{{ item.fecha }}</td>
                <td>{{ item.hora }}</td>
                <td>{{ item.usuario }}</td>
                <td>{{ item.correctos }}</td>
                <td>{{ item.fallidos }}</td>
                <td>
                  <a
                      href="javascript:void(0)"
                      @click="visualizarDetalleFallido(item.fallidosDetalle)"
                  ><i class="ti-search" title="Visualizar Detalle"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
            <br/>
            <Paginacion :updatePage="updatePage"/>
          </div>
        </div>
        <Modal
            mensaje="Se eliminará el archivo, ¿Está seguro?"
            :method="procesarEliminar"
        />
        <ModalMessages :mensaje="mensajeProceso" titulo="Eliminar archivo"/>
        <button
            type="button"
            v-show="false"
            id="exampleModalButton"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#exampleModal"
            data-backdrop="static"
            data-keyboard="false"
        >
          Modal messages
        </button>
      </div>
    </div>

    <div
        class="modal"
        tabindex="-1"
        :style="displayModalDetalle ? 'display: block;' : 'display: none;'"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detalle de Filas Fallidas</h5>
          </div>
          <div class="modal-body">
            <p v-for="(index,detalle) in detalleFallidos" :key="detalle">
              Linea {{ detalle }} : {{ index }} </p>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-general-off btn-secondary"
                data-bs-dismiss="modal"
                @click="cerrarModalDetalle"
            >
              <i class="ti-close"></i> Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "../../../components/Modal.vue";
import ModalMessages from "../../../components/ModalMessages.vue";
import Filtros from "@/components/CustomFilters.vue";
import Paginacion from "@/components/Paginacion.vue";

export default {
  name: "GestionArchivosSolicitudes",
  components: {Paginacion, Filtros, Modal, ModalMessages},
  data() {
    return {
      pagina: 1,
      paginaFiltros: 1,
      condiciones: [],
      mensaje: "",
      id: null,
      valorBusqueda: "",
      tipoFiltro: "",
      mensajeProceso: [],
      detalleFallidos: [],
      displayModalDetalle: false,
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  mounted() {
    this.consultarXFiltros(this.data);
  },
  methods: {

    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      this.loaderSpinner();
      this.valorBusqueda = "";
      try {
        const data = {
          pagina: pagina || 1,
        };
        await this.$store.dispatch(
            "archivosSolicitudes/consultarSolicitudesXPagina",
            data
        );
        await this.$store.dispatch("archivosSolicitudes/consultarTotalPaginas");
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },

    eliminar(archivo) {
      this.$store.commit("archivosSolicitudes/setArchivoSeleccionado", archivo)
      document.getElementById("exampleModalButton").click();
    },
    async procesarEliminar() {
      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch("archivosSolicitudes/eliminarArchivo", {idArchivo: this.archivoSeleccionado.id});
        this.mensajeProceso = [resp];
        document.getElementById("modalButton").click();
      } catch (error) {
        alert("No se pudo eliminar");
      } finally {
        this.loader.hide();
      }
    },
    visualizarDetalleFallido(fallido) {
      this.displayModalDetalle = true;
      this.error = false;
      this.detalleFallidos = JSON.parse(fallido);

    },
    cerrarModalDetalle() {
      this.displayModalDetalle = false;
    },
    async consultarXFiltros(data) {
      this.condiciones = data?.condiciones;
      this.modoFiltros = true;

      this.loaderSpinner();
      try {
        await this.$store.dispatch("archivosSolicitudes/consultarSolicitudesByFiltros", {
          pagina: data?.inicializarPagina ? 1 : this.paginaFiltros,
          condiciones: data?.condiciones || [],
        });
        this.totalRegistros = this.$store.getters["archivosSolicitudes/totalRegistros"];
        this.totalPaginas = this.$store.getters["archivosSolicitudes/totalPaginas"];
        this.totales = this.$store.getters["archivosSolicitudes/totales"];
      } catch (error) {
        console.log(error);
        this.equipos = null;
      } finally {
        this.loader.hide();
      }
    },
    updatePage(page) {
      this.pagina = page;
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    archivos() {
      return this.$store.getters["archivosSolicitudes/archivos"]
          ? this.$store.getters["archivosSolicitudes/archivos"]
          : [];
    },
    archivoSeleccionado() {
      return this.$store.getters["archivosSolicitudes/archivoSeleccionado"]
          ? this.$store.getters["archivosSolicitudes/archivoSeleccionado"]
          : [];
    },
    totalPaginas() {
      return this.$store.getters["archivosSolicitudes/totalPaginas"]
          ? this.$store.getters["archivosSolicitudes/totalPaginas"]
          : [];
    },

  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
};
</script>
