import {createRouter, createWebHistory} from "vue-router";
import Login from "../views/auth/Login.vue";
import ConoceATuTecnicoProd from "../views/redirect/prod/ConoceTuTecnico.vue";
import CulturaFriaProd from "../views/redirect/prod/CulturaFria.vue";
import ConoceATuTecnicoQa from "../views/redirect/qa/ConoceTuTecnico.vue";
import CulturaFriaQa from "../views/redirect/qa/CulturaFria.vue";
import MainQa from "../views/redirect/qa/Main.vue";
import MainProd from "../views/redirect/prod/Main.vue";
import ConoceATuTecnicoDev from "../views/redirect/dev/ConoceTuTecnico.vue";
import CulturaFriaDev from "../views/redirect/dev/CulturaFria.vue";
import MainDev from "../views/redirect/dev/Main.vue";
import Inicio from "@/components/Inicio";
import Welcome from "@/components/Welcome";
import Equipos from "../views/admin/equipos/Equipos.vue";
import EquipoForm from "../views/admin/equipos/components/EquipoForm.vue";
import Proveedores from "../views/admin/proveedores/Proveedores.vue";
import ProveedorForm from "../views/admin/proveedores/components/ProveedorForm.vue";
import Bodegas from "../views/admin/bodegas/Bodegas.vue";
import BodegaForm from "../views/admin/bodegas/components/BodegaForm.vue";
import BodegaGerencia from "../views/admin/bodegaGerencia/BodegaGerencia.vue";
import BodegaGerenciaForm from "../views/admin/bodegaGerencia/components/BodegaGerenciaForm.vue";
import SolicitudForm from "../views/solicitudes/components/SolicitudForm.vue";
import Solicitudes from "../views/solicitudes/Solicitudes.vue";
import ConsultaSolicitudes from "../views/solicitudes/components/ConsultaSolicitudes.vue";
import SolicitudesProveedor from "../views/solicitudesProveedor/SolicitudesProveedor.vue";
import Usuarios from '../views/admin/usuarios/Usuarios.vue'
import UsuarioForm from "../views/admin/usuarios/components/UsuarioForm.vue";
import ProcesarRetiroForm from "../views/solicitudesProveedor/components/ProcesarRetiroForm";
import ProcesarInstalacionForm from "../views/solicitudesProveedor/components/ProcesarInstalacionForm";
import CambiarClaveForm from "../views/admin/usuarios/components/CambiarClaveForm.vue";
import Bajas from "../views/procesos/bajas/Bajas.vue";
import Reservas from "../views/procesos/reservas/Reservas.vue";
import Mapa from "../views/solicitudesProveedor/components/Mapa.vue";
import Regularizaciones from "@/views/procesos/regularizaciones/Regularizaciones";
import SolicitudesRechazadas from "@/views/solicitudesRechazadas/SolicitudesRechazadas";
import ArchivosSolicitudes from "@/views/archivosSolicitudes/ArchivosSolicitudes";
import SolicitudesTecnico from "@/views/solicitudesTecnico/SolicitudesTecnico";
import SolicitudesCerradasTecnico from "@/views/solicitudesTecnico/SolicitudesCerradasTecnico";
import SolicitudesTransporte from "@/views/solicitudesTransporte/SolicitudesTransporte";
import SolicitudesCerradasTransporte from "@/views/solicitudesTransporte/SolicitudesCerradasTransporte";
import ProcesarRetiroTecnicoForm from "../views/solicitudesTecnico/components/ProcesarRetiroTecnicoForm";
import ProcesarInstalacionTecnicoForm from "../views/solicitudesTecnico/components/ProcesarInstalacionTecnicoForm";
import ProcesarRetiroTransporteForm from "../views/solicitudesTransporte/components/ProcesarRetiroTransporteForm";
import ProcesarInstalacionTransporteForm
    from "../views/solicitudesTransporte/components/ProcesarInstalacionTransporteForm";
import EquiposProveedor from "../views/equiposProveedor/EquipoProveedor";
import EditEquipoProveedor from "../views/equiposProveedor/components/EditEquipoProveedor.vue";
import SolicitudesProveedorAtendidas from "../views/solicitudesProveedor/SolicitudProveedorAtendidas";
import ProcesarMantenimientoForm from "../views/solicitudesProveedor/components/ProcesarMantenimientoForm";
import ProcesarMantenimientoTecnicoForm from "../views/solicitudesTecnico/components/ProcesarMantenimientoTecnicoForm";
import EquiposSupervisor from "../views/equiposSupervisor/EquiposSupervisor.vue";
import Analisis from "../views/analisis/Analisis.vue";
import AnalisisMultiple from "../views/analisis/AnalisisMultiple.vue";
import Evento from "../views/evento/Evento.vue";
import EventoForm from "../views/evento/components/EventoForm.vue";
import EventosPendientes from "../views/evento/EventosPendientes.vue";
import AgregarEquipos from "../views/evento/components/AgregarEquipos.vue";
import Reparaciones from "@/views/reparaciones/Reparaciones";
import UsuarioVehiculoTransporteForm from "../views/admin/usuarios/components/UsuarioVehiculoTransporteForm.vue";
import ReasignarRequerimiento from "../views/solicitudesProveedor/components/ReasignarRequerimiento";
import BaseObjetivo from "../views/admin/cartera/BaseObjetivo.vue";
import Bloqueos from "../views/admin/cartera/Bloqueos.vue";
import ObjetivoVisita from "../views/admin/objetivoVisita/ObjetivoVisita.vue";
import Perfil from "../views/perfil/Perfil.vue";
import ResultadosEncuesta from "../views/encuesta/ResultadosEncuesta.vue";
import Notificaciones from "../views/notificaciones/Notificaciones.vue";
import GestionBajas from "../views/bajas/Bajas.vue";
import EventosPendientesTecnico from "../views/evento/EventosPendientesTecnico.vue";
import EventosUsuario from "../views/evento/EventosUsuario.vue";
import DashboardTecnico from "../views/dashboardTecnico/DashboardTecnico.vue";
import ProcesarCambioForm from "../views/solicitudesProveedor/components/ProcesarCambioForm";
import ProcesarCambioTecnicoForm from "../views/solicitudesTecnico/components/ProcesarCambioTecnicoForm";
import ActualizacionMasiva from "@/views/actualizacionmasiva/ActualizacionMasiva.vue";
import Menu from "@/views/admin/menu/Menu.vue";
import OpcionesMenu from "@/views/admin/menu/components/OpcionesMenu.vue";
import OpcionesMenuRol from "@/views/admin/menu/components/OpcionesMenuRol.vue";
import MenuForm from "@/views/admin/menu/components/MenuForm.vue";
import AtencionAdjuntos from "@/views/admin/atencionAdjuntos/AtencionAdjuntos.vue";
import Filtros from "@/views/admin/filtros/Filtros.vue";
import FiltroForm from "@/views/admin/filtros/components/FiltroForm.vue";
import AtencionAdjuntoForm from "@/views/admin/atencionAdjuntos/components/AtencionAdjuntoForm.vue";
import Variables from "@/views/admin/variables/Variables.vue";
import VariableForm from "@/views/admin/variables/components/VariableForm.vue";
import ListaNegra from "@/views/admin/listaNegra/ListaNegra.vue";
import ListaNegraForm from "@/views/admin/listaNegra/components/ListaNegraForm.vue";
import GestionBajasBloque from "../views/bajasBloque/BajasBloque.vue";
import AnalisisMultipleColumna from "@/views/analisis/AnalisisMultipleColumna.vue";
import WaitingList from "@/views/waiting/WaitingList.vue";
import WaitingListForm from "@/views/waiting/components/WaitingListForm.vue";
import WaitingListArchivosCargados from "@/views/waiting/components/WaitingListArchivosCargados.vue";
import UsuariosVehiculosTransporte from "@/views/admin/usuarios/UsuariosVehiculosTransporte.vue";
import ProcesarPrimeraPosicion from "@/views/solicitudesTecnico/components/ProcesarPrimeraPosicion.vue";
import LoginAs from "@/views/auth/LoginAs.vue";
import BajaSolicitadaTecnico from '@/views/bajas/BajaSolicitadaTecnico.vue'
import BajaSolicitadaTecnicoForm from "@/views/bajas/components/BajaSolicitadaTecnicoForm.vue";
import BajaSolicitadaTecnicoAprobacion from "@/views/bajas/BajaSolicitadaTecnicoAprobacion.vue";
import Traslados from "@/views/procesos/traslados/Traslados.vue";
import ActivoCodigoBarrasReader from "@/components/ActivoCodigoBarrasReader.vue";
import TrasladosAprobacion from "@/views/procesos/traslados/GestionTrasladosAdmin.vue";
import ReporteSolicitudes from "@/views/reportes/ReporteSolicitudes.vue";
import PermisoUsuarioForm from "@/views/admin/usuarios/components/PermisoUsuarioForm.vue";
import SolicitudFormZone from "@/views/solicitudes/components/SolicitudFormZone.vue";
import Otp from "@/views/auth/components/Otp.vue";
import ProcesarPrimeraPosicionBodega from "@/views/solicitudesProveedor/components/ProcesarPrimeraPosicionBodega.vue";

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
    },

    {
        path: "/login-as",
        name: "LoginAs",
        component: LoginAs,
    },

    {
        path: "/prod-main",
        name: "prod-main",
        component: MainProd,
    },
    {
        path: "/prod-culturafria",
        name: "prod-culturafria",
        component: CulturaFriaProd,
    },
    {
        path: "/prod-conoceatutecnico",
        name: "prod-conoceatutecnico",
        component: ConoceATuTecnicoProd,
    },
    {
        path: "/qa-main",
        name: "qa-main",
        component: MainQa,
    },
    {
        path: "/qa-culturafria",
        name: "qa-culturafria",
        component: CulturaFriaQa,
    },
    {
        path: "/qa-conoceatutecnico",
        name: "qa-conoceatutecnico",
        component: ConoceATuTecnicoQa,
    },
    {
        path: "/dev-main",
        name: "dev-main",
        component: MainDev,
    },
    {
        path: "/dev-culturafria",
        name: "dev-culturafria",
        component: CulturaFriaDev,
    },
    {
        path: "/dev-conoceatutecnico",
        name: "dev-conoceatutecnico",
        component: ConoceATuTecnicoDev,
    },
    {
        path: "/prod-main",
        name: "prod-main",
        component: MainProd,
    },
    {
        path: "/prod-culturafria",
        name: "prod-culturafria",
        component: CulturaFriaProd,
    },
    {
        path: "/prod-conoceatutecnico",
        name: "prod-conoceatutecnico",
        component: ConoceATuTecnicoProd,
    },
    {
        path: "/qa-main",
        name: "qa-main",
        component: MainQa,
    },
    {
        path: "/qa-culturafria",
        name: "qa-culturafria",
        component: CulturaFriaQa,
    },
    {
        path: "/qa-conoceatutecnico",
        name: "qa-conoceatutecnico",
        component: ConoceATuTecnicoQa,
    },
    {
        path: "/dev-main",
        name: "dev-main",
        component: MainDev,
    },
    {
        path: "/dev-culturafria",
        name: "dev-culturafria",
        component: CulturaFriaDev,
    },
    {
        path: "/dev-conoceatutecnico",
        name: "dev-conoceatutecnico",
        component: ConoceATuTecnicoDev,
    },
    {
        path: "/otp",
        name: "OtpPage",
        component: Otp,
    },
    {
        path: "/",
        name: "Inicio",
        component: Inicio,
        meta: {
            auth: true,
        },
        children: [
            {
                path: "welcome",
                name: "Welcome",
                component: Welcome,
                meta: {
                    auth: true,
                },
            },
            {
                path: "usuarios",
                name: "Usuarios",
                component: Usuarios,
                meta: {
                    auth: true,
                },
            },
            {
                path: "usuarios-vehiculos-transporte",
                name: "UsuariosVehiculosTransporte",
                component: UsuariosVehiculosTransporte,
                meta: {
                    auth: true,
                },
            },
            {
                path: "manage-usuarios-vehiculos-transporte",
                name: "UsuarioVehiculoTransporteForm",
                component: UsuarioVehiculoTransporteForm,
                meta: {
                    auth: true,
                },
            },

            {
                path: "permiso-usuario",
                name: "PermisoUsuarioForm",
                component: PermisoUsuarioForm,
                meta: {
                    auth: true,
                },
            },

            {
                path: "cambiar-clave",
                name: "CambiarClaveForm",
                component: CambiarClaveForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "equipos",
                name: "Equipos",
                component: Equipos,
                meta: {
                    auth: true,
                },
            },

            {
                path: "/manage-equipo",
                name: "EquipoForm",
                component: EquipoForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/equipos-proveedor",
                name: "EquiposProveedor",
                component: EquiposProveedor,
                meta: {
                    auth: true,
                },
            },
            {
                path: "proveedores",
                name: "Proveedores",
                component: Proveedores,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/manage-proveedor",
                name: "ProveedorForm",
                component: ProveedorForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/manage-usuario",
                name: "UsuarioForm",
                component: UsuarioForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "bodegas",
                name: "Bodegas",
                component: Bodegas,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/manage-bodega",
                name: "BodegaForm",
                component: BodegaForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "solicitudes",
                name: "Solicitudes",
                component: Solicitudes,
                meta: {
                    auth: true,
                },
            },
            {
                path: "consulta-solicitudes",
                name: "ConsultaSolicitudes",
                component: ConsultaSolicitudes,
                meta: {
                    auth: true,
                },
            },
            {
                path: "archivos-solicitudes",
                name: "ArchivosSolicitudes",
                component: ArchivosSolicitudes,
                meta: {
                    auth: true,
                },
            },
            {
                path: "solicitudes-rechazadas",
                name: "SolicitudesRechazadas",
                component: SolicitudesRechazadas,
                meta: {
                    auth: true,
                },
            },
            {
                path: "solicitudes-proveedor",
                name: "SolicitudesProveedor",
                component: SolicitudesProveedor,
                meta: {
                    auth: true,
                },
            },

            {
                path: "solicitudes-proveedor-atendidas",
                name: "SolicitudesProveedorAtendidas",
                component: SolicitudesProveedorAtendidas,
                meta: {
                    auth: true,
                },
            },

            {
                path: "procesar-retiro",
                name: "ProcesarRetiroForm",
                component: ProcesarRetiroForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "procesar-solicitud",
                name: "ProcesarInstalacionForm",
                component: ProcesarInstalacionForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "procesar-primera-posicion",
                name: "ProcesarPrimeraPosicion",
                component: ProcesarPrimeraPosicion,
                meta: {
                    auth: true,
                },
            },
            {
                path: "procesar-primera-posicion-bodega",
                name: "ProcesarPrimeraPosicionBodega",
                component: ProcesarPrimeraPosicionBodega,
                meta: {
                    auth: true,
                },
            },
            {
                path: "bajas",
                name: "Bajas",
                component: Bajas,
                meta: {
                    auth: true,
                },
            },

            {
                path: "bajas-solicitada-tecnico",
                name: "BajaSolicitadaTecnico",
                component: BajaSolicitadaTecnico,
                meta: {
                    auth: true,
                },
            },
            {
                path: "agregar-baja",
                name: "BajaSolicitadaTecnicoForm",
                component: BajaSolicitadaTecnicoForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "aprobar-baja",
                name: "BajaSolicitadaTecnicoAprobacion",
                component: BajaSolicitadaTecnicoAprobacion,
                meta: {
                    auth: true,
                },
            },

            {
                path: "regularizaciones",
                name: "Regularizaciones",
                component: Regularizaciones,
                meta: {
                    auth: true,
                },
            },
            {
                path: "traslados",
                name: "Traslados",
                component: Traslados,
                meta: {
                    auth: true,
                },
            },
            {
                path: "traslados-aprobacion",
                name: "TrasladosAprobacion",
                component: TrasladosAprobacion,
                meta: {
                    auth: true,
                },
            },
            {
                path: "reservas",
                name: "Reservas",
                component: Reservas,
                meta: {
                    auth: true,
                },
            },
            {
                path: "mapa-solicitudes-asignadas",
                name: "Mapa",
                component: Mapa,
                meta: {
                    auth: true,
                },
            },
            {
                path: "solicitudes-transporte",
                name: "SolicitudesTransporte",
                component: SolicitudesTransporte,
                meta: {
                    auth: true,
                },
            },
            {
                path: "solicitudes-cerradas-transporte",
                name: "SolicitudesCerradasTransporte",
                component: SolicitudesCerradasTransporte,
                meta: {
                    auth: true,
                },
            },
            {
                path: "solicitudes-tecnico",
                name: "SolicitudesTecnico",
                component: SolicitudesTecnico,
                meta: {
                    auth: true,
                },
            },
            {
                path: "solicitudes-cerradas-tecnico",
                name: "SolicitudesCerradasTecnico",
                component: SolicitudesCerradasTecnico,
                meta: {
                    auth: true,
                },
            },
            {
                path: "procesar-retiro-transporte",
                name: "ProcesarRetiroTransporteForm",
                component: ProcesarRetiroTransporteForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "procesar-instalacion-transporte",
                name: "ProcesarInstalacionTransporteForm",
                component: ProcesarInstalacionTransporteForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "procesar-retiro-tecnico",
                name: "ProcesarRetiroTecnicoForm",
                component: ProcesarRetiroTecnicoForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "procesar-instalacion-tecnico",
                name: "ProcesarInstalacionTecnicoForm",
                component: ProcesarInstalacionTecnicoForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/manage-solicitud",
                name: "SolicitudForm",
                component: SolicitudForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/manage-solicitud-zone",
                name: "SolicitudFormZone",
                component: SolicitudFormZone,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/manage-equipo-proveedor",
                name: "EditEquipoProveedor",
                component: EditEquipoProveedor,
                meta: {
                    auth: true,
                },
            },
            {
                path: "procesar-mantenimiento",
                name: "ProcesarMantenimientoForm",
                component: ProcesarMantenimientoForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "procesar-mantenimiento-tecnico",
                name: "ProcesarMantenimientoTecnicoForm",
                component: ProcesarMantenimientoTecnicoForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "bodega-gerencia",
                name: "BodegaGerencia",
                component: BodegaGerencia,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/manage-bodega-gerencia",
                name: "BodegaGerenciaForm",
                component: BodegaGerenciaForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/equipos-supervisor",
                name: "EquiposSupervisor",
                component: EquiposSupervisor,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/analisis",
                name: "Analisis",
                component: Analisis,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/analisis-multiple",
                name: "AnalisisMultiple",
                component: AnalisisMultiple,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/analisis-multiple-columna",
                name: "AnalisisMultipleColumna",
                component: AnalisisMultipleColumna,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/evento",
                name: "Evento",
                component: Evento,
                meta: {
                    auth: true,
                },
            },

            {
                path: "/reparaciones",
                name: "Reparaciones",
                component: Reparaciones,
                meta: {
                    auth: true,
                },
            },

            {
                path: "/eventos-pendientes",
                name: "EventosPendientes",
                component: EventosPendientes,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/manage-evento",
                name: "EventoForm",
                component: EventoForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/equipos-evento",
                name: "AgregarEquipos",
                component: AgregarEquipos,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/reasignar-requerimiento",
                name: "ReasignarRequerimiento",
                component: ReasignarRequerimiento,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/base-objetivo",
                name: "BaseObjetivo",
                component: BaseObjetivo,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/bloqueos",
                name: "Bloqueos",
                component: Bloqueos,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/objetivos",
                name: "ObjetivoVisita",
                component: ObjetivoVisita,
                meta: {
                    auth: true,
                }
            },
            {
                path: "/perfil",
                name: "Perfil",
                component: Perfil,
                meta: {
                    auth: true,
                },
            },
            {
                path: '/encuesta',
                name: 'Encuesta',
                component: ResultadosEncuesta,
                meta: {
                    auth: true
                }

            },
            {
                path: '/notificaciones',
                name: 'Notificaciones',
                component: Notificaciones,
                meta: {
                    auth: true
                }

            },
            {
                path: '/gestion-bajas',
                name: 'GestionBajas',
                component: GestionBajas,
                meta: {
                    auth: true
                }

            },
            {
                path: "eventos-tecnico",
                name: "EventosPendientesTecnico",
                component: EventosPendientesTecnico,
                meta: {
                    auth: true,
                },
            },
            {
                path: "eventos-usuario",
                name: "EventosUsuario",
                component: EventosUsuario,
                meta: {
                    auth: true,
                },
            },
            {
                path: "dashboard-tecnico",
                name: "DashboardTecnico",
                component: DashboardTecnico,
                meta: {
                    auth: true,
                },
            },
            {
                path: "procesar-cambio",
                name: "ProcesarCambioForm",
                component: ProcesarCambioForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "procesar-cambio-tecnico",
                name: "ProcesarCambioTecnicoForm",
                component: ProcesarCambioTecnicoForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/actualizacion-masiva",
                name: "ActualizacionMasiva",
                component: ActualizacionMasiva,
                meta: {
                    auth: true,
                },
            },
            {
                path: "menu",
                name: "Menu",
                component: Menu,
                meta: {
                    auth: true,
                },
            },
            {
                path: "opciones-menu",
                name: "OpcionesMenu",
                component: OpcionesMenu,
                meta: {
                    auth: true,
                },
            },

            {
                path: "atencion-adjuntos",
                name: "AtencionAdjuntos",
                component: AtencionAdjuntos,
                meta: {
                    auth: true,
                },
            },

            {
                path: "/manage-atencion-adjunto",
                name: "AtencionAdjuntoForm",
                component: AtencionAdjuntoForm,
                meta: {
                    auth: true,
                },
            },

            {
                path: "filtros",
                name: "Filtros",
                component: Filtros,
                meta: {
                    auth: true,
                },
            },


            {
                path: "/manage-filtro",
                name: "FiltroForm",
                component: FiltroForm,
                meta: {
                    auth: true,
                },
            },

            {
                path: "opciones-menu-rol",
                name: "OpcionesMenuRol",
                component: OpcionesMenuRol,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/manage-menu",
                name: "MenuForm",
                component: MenuForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/manage-menu/:idPadre",
                name: "MenuForm",
                component: MenuForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "variables",
                name: "Variables",
                component: Variables,
                meta: {
                    auth: true,
                },
            },

            {
                path: "/manage-variable",
                name: "VariableForm",
                component: VariableForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "lista-negra",
                name: "ListaNegra",
                component: ListaNegra,
                meta: {
                    auth: true,
                },
            },

            {
                path: "/manage-lista-negra",
                name: "ListaNegraForm",
                component: ListaNegraForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: '/gestion-bajas-bloque',
                name: 'GestionBajasBloque',
                component: GestionBajasBloque,
                meta: {
                    auth: true
                }
            },
            {
                path: '/gestion-waiting-list',
                name: 'WaitingList',
                component: WaitingList,
                meta: {
                    auth: true
                }
            },
            {
                path: "/manage-waiting-list",
                name: "WaitingListForm",
                component: WaitingListForm,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/manage-waiting-archivos",
                name: "WaitingListArchivosCargados",
                component: WaitingListArchivosCargados,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/reporte-solicitudes",
                name: "ReporteSolicitudes",
                component: ReporteSolicitudes,
                meta: {
                    auth: true,
                },
            },


            {
                path: "/barcode-reader-2",
                name: "ActivoCodigoBarrasReader",
                component: ActivoCodigoBarrasReader,
                meta: {
                    auth: true,
                },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const authUser = window.localStorage.getItem("_token_eeff") ? true : false;
    if (to.meta.requiresAuth && !authUser) next({name: "Login"});
    else next();
});

export default router;
