<template>
  <div class="col-md-12 stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Procesar requerimiento</h3>
          </div>
          <div class="col-md-6">

          </div>
        </div>
        <div class="white-content-general mb-4">
          <CabeceraRequerimiento />
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Equipo sugerido</h5>
              <h5 class="card-title">
                {{
                  rutaSeleccionada?.solicitudProveedor?.solicitud?.equipoAsignado?.marca
                }}
              </h5>
              <h6 class="card-subtitle mb-2 text-muted">
                Placa:
                {{
                  rutaSeleccionada?.solicitudProveedor?.solicitud?.equipoAsignado?.placa
                }}
              </h6>
              <h6 class="card-subtitle mb-2 text-muted">
                Serie:
                {{
                  rutaSeleccionada.solicitudProveedor.solicitud?.equipoAsignado?.serie
                }}
              </h6>
              <h6 class="card-subtitle mb-2 text-muted">
                Modelo:
                {{
                  rutaSeleccionada.solicitudProveedor.solicitud?.equipoAsignado?.modelo
                }}
              </h6>
              <h6 class="card-subtitle mb-2 text-muted">
                Capacidad (cajas):
                {{
                  rutaSeleccionada.solicitudProveedor.solicitud?.equipoAsignado?.capacidadCajas
                }}
              </h6>
              <h6 class="card-subtitle mb-2 text-muted">
                Capacidad (pies):
                {{
                  rutaSeleccionada.solicitudProveedor.solicitud?.equipoAsignado?.capacidadPies
                }}
              </h6>
              <h6 class="card-subtitle mb-2 text-muted">
                Logo:
                {{
                  rutaSeleccionada.solicitudProveedor.solicitud?.equipoAsignado?.logo
                }}
              </h6>
              <h6 class="card-subtitle mb-2 text-muted">
                Estado:
                <div
                  class="badge"
                  :class="
                    rutaSeleccionada.solicitudProveedor.solicitud?.equipoAsignado?.estadoEquipo === 'DISPONIBLE'
                      ? 'badge-success'
                      : 'badge-secondary'
                  "
                >
                  {{
                    rutaSeleccionada.solicitudProveedor.solicitud?.equipoAsignado?.estadoEquipo
                  }}
                </div>
              </h6>
            </div>
          </div>
          <div class="card" v-if="mostrarDatosEquipo">
            <div class="card-body">
              <h5 class="card-title">Datos Equipos</h5>
              <p class="text-muted" v-if="!equipos.length">
                No se recuperaron equipos fríos en la bodega, por favor póngase
                en contacto con el Administrador
              </p>
              <BusquedaXPlaca :seleccionarEquipo="seleccionarEquipo"/>
              <div class="input-group col-md-12 mt-2 no-padding">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Filtrar por capacidad en pies"
                  v-model="filtro"
                  aria-label="Text input with segmented dropdown button"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="filtrar"
                    :disabled="filtro.length === 0"
                  >
                  <i class="ti-search"></i> Filtrar
                  </button>
                </div>
              </div>
              <div
                class="form-check"
                v-for="item in equiposFiltrados"
                :key="item.id"
              >
                <input
                  class="form-check-input ml-1"
                  type="checkbox"
                  :id="item.id"
                  :value="item.id"
                  v-model="idEquipos"
                />
                <label class="form-check-label" :for="item.id">
                  <strong>Placa:</strong>
                  {{ item.placa }}
                  <strong>Serie:</strong>
                  {{ item.serie }}
                  <strong>Modelo:</strong>
                  {{ item.modelo }}
                  <strong>Capacidad (cajas):</strong>
                  {{ item.capacidadCajas }}
                  <strong>Capacidad (pies):</strong>
                  {{ item.capacidadPies }}
                  <strong>Logo:</strong>
                  {{ item.logo }}
                  <strong>Estado: </strong>
                  <div
                    class="badge"
                    :class="
                      item.estadoEquipo === 'DISPONIBLE'
                        ? 'badge-success'
                        : 'badge-secondary'
                    "
                  >
                    {{ item.estadoEquipo }}
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Atender requerimiento</h5>

              <div class="form-check">
                <input
                  class="form-check-input ml-1"
                  type="radio"
                  id="ejecutado"
                  value="EJECUTADO"
                  v-model="requerimiento"
                  @click="seleccionarRequerimiento"
                />
                <label class="form-check-label" for="ejecutado">
                  EJECUTADO
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input ml-1"
                  type="radio"
                  id="fallido"
                  value="FALLIDO"
                  v-model="requerimiento"
                  @click="seleccionarRequerimiento"
                />
                <label class="form-check-label" for="fallido"> FALLIDO </label>
              </div>
              <div class="form-group" v-if="mostrarMotivo">
                <label>Motivo</label>
                <select class="form-control" v-model="motivo">
                  <option value="" disabled>Seleccione un motivo</option>
                  <option value="DIRECCIÓN NO ENCONTRADA">
                    DIRECCIÓN NO ENCONTRADA
                  </option>
                  <option value="EQUIPO NO ENCONTRADO">
                    EQUIPO NO ENCONTRADO
                  </option>
                  <option value="OTRO MOTIVO">OTRO MOTIVO</option>
                </select>
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Observación</label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model="observacion"
                ></textarea>
              </div>
              <div class="mb-3">
                <label for="formFile" class="form-label">Cargar archivo</label>
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  ref="file"
                  @change="uploadFile"
                />
              </div>

              <button
                type="button"
                class="btn btn-primary btn-block mt-3"
                data-toggle="modal"
                data-target="#exampleModal"
                @click="confirmar"
              >
              <i class="ti-check-box"></i> Atender requerimiento
              </button>
            </div>
          </div>
        </div>
        <Modal :mensaje="mensaje" :method="procesar" />
        <ModalMessages
          :mensaje="mensajeProceso"
          :titulo="titulo"
          :method="cerrar"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../components/ModalMessages.vue";
import Modal from "../../../components/Modal.vue";
import CabeceraRequerimiento from "./CabeceraRequerimiento.vue";
import BusquedaXPlaca from "../../../components/BusquedaXPlaca.vue";

export default {
  components: { ModalMessages, CabeceraRequerimiento, Modal, BusquedaXPlaca },
  data() {
    return {
      observacion: "",
      idEquipos: [],
      requerimiento: "EJECUTADO",
      mostrarMotivo: false,
      motivo: "",
      archivo: null,
      mensaje: "",
      mensajeProceso: null,
      titulo: "",
      ejecutarCerrar: false,
      equiposFiltrados: [],
      filtro: "",
      mostrarDatosEquipo: true,
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.$store.commit("equipos/setEquipos", null);
  },
  mounted() {
    this.consultarEquiposBodega();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarEquiposBodega() {
      try {
        this.loaderSpinner();
        /* await this.$store.dispatch("equipos/consultarEquipos");
        if (this.equipos) {
          this.equiposFiltrados = this.equipos?.filter(
            (item) => item?.estadoEquipo === "DISPONIBLE"
          );
        } */
        await this.$store.dispatch("equipos/consultarEquiposDisponibles");
        this.equiposFiltrados = this.$store.getters["equipos/equipos"];
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    seleccionarRequerimiento(e) {
      if (e.target.value === "FALLIDO") {
        this, (this.mostrarMotivo = true);
        this.mostrarDatosEquipo = false;
      } else {
        this.mostrarMotivo = false;
        this.mostrarDatosEquipo = true;
      }
    },
    uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
    },
    confirmar() {
      this.mensaje = "¿Desea continuar con el proceso de instalación?";
    },
    async procesar() {
      if (
        this.requerimiento === "" ||
        !this.rutaSeleccionada.id ||
        (this.requerimiento === "EJECUTADO" && this.idEquipos.length === 0) ||
        (this.requerimiento === "FALLIDO" && this.motivo.length === 0) ||
        this.observacion.length === 0
      ) {
        this.mensajeProceso = [
          "Por favor ingrese toda la información requerida antes de continuar con el proceso de retiro",
        ];
        this.titulo = "Advertencia";
        this.ejecutarCerrar = false;
        document.getElementById("modalButton").click();
        return;
      }

      const formData = new FormData();
      formData.append("file", this.archivo);
      formData.append("estadoSolicitud", this.requerimiento);
      formData.append(
        "motivo",
        this.requerimiento === "EJECUTADO" ? "" : this.motivo
      );
      formData.append(
        "idSolicitudProveedor",
        this.rutaSeleccionada.solicitudProveedor.id
      );
      formData.append(
        "idEquipoFrio",
        this.requerimiento === "EJECUTADO" ? this.idEquipos : 0
      );
      formData.append("observacion", this.observacion);
      formData.append("idRuta", this.rutaSeleccionada?.id);
      
      /* const data = {
        estadoSolicitud: this.requerimiento,
        motivo: this.requerimiento === "EJECUTADO" ? "" : this.motivo,
        idSolicitudProveedor: this.rutaSeleccionada.solicitudProveedor.id,
        idEquipoFrio: this.requerimiento === "EJECUTADO" ? this.idEquipos : 0,
        observacion: this.observacion,
        idRuta: this.rutaSeleccionada.id,
      }; */
      /*this.mensajeProceso = ["Proceso de instalación exitoso"];
      this.titulo = "Solicitud";
      this.ejecutarCerrar = true;
      document.getElementById("modalButton").click(); */

      try {
        this.loaderSpinner();
        await this.$store.dispatch("ruta/procesarInstalacion", {
          data: formData,
          idBodegaVehiculo: this.usuarioBodega.id,
        });
        this.mensajeProceso = ["Proceso de instalación exitoso"];
        //this.mensajeProceso = resp.contenido;
        this.titulo = "Solicitud";
        this.ejecutarCerrar = true;
        document.getElementById("modalButton").click();
      } catch (error) {
        this.mensaje = error;
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      if (this.ejecutarCerrar) {
        this.$router.go(-1);
      }
    },
    filtrar() {
      const equiposTemp = this.equiposFiltrados.filter(
        (item) => item.capacidadPies === parseInt(this.filtro)
      );
      if (equiposTemp.length > 0) {
        this.equiposFiltrados = equiposTemp;
      } else {
        this.equiposFiltrados = this.equipos;
      }
    },
    seleccionarEquipo(datos){
      if(datos.tipo === 'ADD'){
        this.idEquipos.push(datos.valor);
      }
      if(datos.tipo === 'DELETE'){
        const idEquiposFiltrados = this.idEquipos.filter(item => item !== datos.valor);
        this.idEquipos = idEquiposFiltrados;
      }
    }
  },
  computed: {
    rutaSeleccionada() {
      return this.$store.getters["ruta/rutaSeleccionada"]
        ? this.$store.getters["ruta/rutaSeleccionada"]
        : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    equipos() {
      return this.$store.getters["equipos/equipos"]
        ? this.$store.getters["equipos/equipos"]
        : {};
    },
    usuarioBodega() {
      return this.$store.getters["usuariosBodega/usuarioBodega"]
        ? this.$store.getters["usuariosBodega/usuarioBodega"]
        : {};
    },
  },
};
</script>
