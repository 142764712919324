export default {
    countryFlag: 'PE',
    welcomeMessage: "Bienvenido Cool Inventory PE",
    labelGerenciaGeneral:"Dirección Vtas",
    labelGerenciaVentas : "Gerencia Vtas ",
    labelPlaca: "Código de Barras",
    labelCodigoCliente: "POC ID",
    labelIdentificacionCliente:"Poc NIF",
    labelTelefono:"Poc Teléfono",
    labelTipo:"Tipo Solicitud",
    labelTipoCaso:"Origen Ticket",
    labelFechaSolicitud:"Fecha",
    labelTicket:"Ticket ID",
    labelActivoId:"Activo ID",
    labelNombreCliente:"Poc Name",
    labelEstablecimiento:"Poc Owner Name",
    labelDireccion:"Poc Dirección",
    labelMarca:"Marca Abi",
    labelEstadoEquipo:"Status",
    labelTipoEquipo:"Categoría",
    labelBodega:"Almacén",
    labelTitleBusquedaEquipo: "Búsqueda por código de barras"
};
