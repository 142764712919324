import axios from "axios";
import store from "../index";

const state = {
  equipos: null,
  equipoSeleccionado: null,
  totalPaginas: null,
  totalRegistros: null,
  mensaje: "",
  totales:null,
  equipoCambio:null
};

const getters = {
  equipos: (state) => state.equipos,
  equipoSeleccionado: (state) => state.equipoSeleccionado,
  totalPaginas: (state) => state.totalPaginas,
  totalRegistros: (state) => state.totalRegistros,
  totales:(state)=>state.totales,
  equipoCambio: (state) => state.equipoCambio,
};

const mutations = {
  setEquipos: (state, equipos) => {
    state.equipos = equipos;
  },
  setTotales: (state, totales) => {
    state.totales = totales;
  },
  setEquipoSeleccionado: (state, equipoSeleccionado) => {
    state.equipoSeleccionado = equipoSeleccionado;
  },
  inicializar: (state) => {
    (state.equipos = null);
    state.equipoSeleccionado = null;
    state.totalPaginas = null;
    state.totalRegistros = null;
  },
  setTotalPaginas: (state, data) => {
    state.totalPaginas = data;
  },
  setTotalRegistros: (state, data) => {
    state.totalRegistros = data;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
  setEquipoCambio: (state, equipoCambio) => {
    state.equipoCambio = equipoCambio;
  },
};

const actions = {
  async consultarEquipos({ commit }) {
    try {
      let res = await axios.get(`/v1/equipos/bodega`);
      commit("setEquipos", res?.data?.result);
      return res?.data?.result;
    } catch (error) {
      return error;
    }
  },
  async consultarEquiposXPagina({ commit }, data) {
    try {
      let res = await axios.get(`/v1/equipos/${data.pagina}/pagina`);
      commit("setEquipos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarTotalPaginas({ commit }) {
    try {
      const resp = await axios.get(`/v1/equipos/total`);
      commit("setTotalPaginas", resp?.data?.totalPaginas);
      commit("setTotalRegistros", resp?.data?.totalRegistros);
      return resp?.data?.totalPaginas || 0;
    } catch (error) {
      return error;
    }
  },
  async crearEquipo({ commit, state }, data) {
    try {
      let res = await axios.post(`/v1/equipos`, data);
      let nuevoEquipo = res?.data?.result;
      let equiposActualizados = [...state.equipos, nuevoEquipo];
      commit("setEquipos", equiposActualizados);
    } catch (error) {
      return error;
    }
  },
  async crearEquipoV2({ commit, state }, data) {
    try {
      let res = await axios.post(`/v2/equipos`, data);
      let nuevoEquipo = res?.data?.result;
      let equiposActualizados = [...state.equipos, nuevoEquipo];
      commit("setEquipos", equiposActualizados);
    } catch (error) {
      return error;
    }
  },
  async editarEquipo({ commit, state }, data) {
    try {
      let res = await axios.put(`/v1/equipos/${data.id}`, data.data);
      let equiposCopia = state.equipos;
      let equipoActualizado = equiposCopia.find(
        (equipo) => equipo.id === res?.data?.result?.id
      );
      let index = equiposCopia.indexOf(equipoActualizado);
      equiposCopia.splice(index, 1, res?.data);
      commit("setEquipos", equiposCopia);
    } catch (error) {
      return error;
    }
  },
  async editarEquipoV2({ commit, state }, data) {
    try {
      let res = await axios.put(`/v2/equipos/${data.id}`, data.data);
      let equiposCopia = state.equipos;
      let equipoActualizado = equiposCopia.find(
        (equipo) => equipo.id === res?.data?.result?.id
      );
      let index = equiposCopia.indexOf(equipoActualizado);
      equiposCopia.splice(index, 1, res?.data);
      commit("setEquipos", equiposCopia);
    } catch (error) {
      return error;
    }
  },
  async eliminarEquipo({ commit, state }, data) {
    try {
      let res = await axios.delete(`/v1/equipos/${data.id}`);
      let equiposCopia = state.equipos;
      const equiposFiltrados = equiposCopia.filter(
        (equipo) => equipo.id !== res?.data?.result?.id
      );
      commit("setEquipos", equiposFiltrados);
    } catch (error) {
      return error;
    }
  },
  async consultarEquipoXSerie({ commit }, data) {
    try {
      let res = await axios.get(`/v1/equipos/${data.serie}/serie`);
      commit("setEquipoSeleccionado", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarEquipoXPlaca({ commit }, data) {
    try {
      let res = await axios.get(`/v1/equipos/${data.placa}/placa`);
      commit("setEquipoSeleccionado", res?.data?.result);
      commit("setEquipoCambio", res?.data?.result);
      return res?.data?.result;
    } catch (error) {
      return error;
    }
  },

  async consultarEquipoXCliente({ commit }, data) {
    try {
      let res = await axios.get(`/v1/equipos/${data.cliente}/cliente`);
      commit("setEquipos", res?.data?.result);
      return res?.data?.result;
    } catch (error) {
      return error;
    }
  },
  async consultarEquipoXBodega({ commit }, data) {
    try {
      let res = await axios.get(`/v1/equipos/${data.bodega}/bodega`);
      commit("setEquipos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarEquipoXEstadoXPagina({ commit }, data) {
    try {
      let res = await axios.get(
        `/v1/equipos/${data.estado}/estado/${data.pagina}/pagina`
      );
      commit("setEquipos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarTotalPaginasEstado({ commit }, data) {
    try {
      const resp = await axios.get(`/v1/equipos/${data.estado}/estado/total`);
      commit("setTotalPaginas", resp?.data?.totalPaginas);
      return resp?.data?.totalPaginas || 0;
    } catch (error) {
      return error;
    }
  },
  async editarUbicacionEquipo({ commit }, data) {
    try {
      await axios.put(`/v1/equipos/${data.id}/actualizar-ubicacion`, data.data);
      let res = await axios.get(`/v1/equipos/1/pagina`);
      commit("setEquipos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarEquipoXFiltros({ commit }, data) {
    try {
      let resp = await axios.post(`/v1/equipos/filtros`, data);
       commit("setEquipos", resp?.data?.result?.equiposFrios);
       commit("setTotalPaginas", resp?.data?.result?.paginacion?.totalPaginas || 1);
       commit("setTotalRegistros", resp?.data?.result?.paginacion?.totalRegistros || 0);
       commit("setTotales", resp?.data?.result?.totalStatus);
      store.dispatch("pagination/updateTotales", {
        totalPaginas: resp?.data?.result?.paginacion?.totalPaginas,
        totalRegistros: resp?.data?.result?.paginacion?.totalRegistros
      })
    } catch (error) {
      return error;
    }
  },
  async exportar({commit}) {
    try {
      let resp = await axios.get(`/v1/exportar`, {responseType: "arraybuffer"});
      if (resp?.data) {
        commit("setMensaje", "Descarga exitosa");
        return resp?.data
      } else {
        commit("setMensaje", "Descarga fallida");
        return null;
      }
    } catch (error) {
      return error;
    }
  },
  async consultarEquiposXPaginaSupervisor({ commit }, data) {
    try {
      let res = await axios.get(`/v1/equipos/${data.pagina}/pagina/supervisor`);
      commit("setEquipos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarTotalPaginasSupervisor({ commit }) {
    try {
      const resp = await axios.get(`/v1/equipos/total/supervisor`);
      commit("setTotalPaginas", resp?.data?.totalPaginas);
      commit("setTotalRegistros", resp?.data?.totalRegistros);
      return resp?.data?.totalPaginas || 0;
    } catch (error) {
      return error;
    }
  },
  async exportarSupervisor({ commit }) {
    try {
      let resp = await axios.get(`/v1/exportar/supervisor`,{ responseType: "arraybuffer" });
      if(resp?.data){
        commit("setMensaje", "Descarga exitosa");
        return resp?.data
      }else{
        commit("setMensaje", "Descarga fallida");
        return null;
      }
    } catch (error) {
      return error;
    }
  },
  async consultarEquipoXEstadoXPaginaSupervisor({ commit }, data) {
    try {
      let res = await axios.get(
        `/v1/equipos/${data.estado}/estado/${data.pagina}/pagina/supervisor`
      );
      commit("setEquipos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarTotalPaginasEstadoSupervisor({ commit }, data) {
    try {
      const resp = await axios.get(`/v1/equipos/${data.estado}/estado/total/supervisor`);
      commit("setTotalPaginas", resp?.data?.totalPaginas);
      return resp?.data?.totalPaginas || 0;
    } catch (error) {
      return error;
    }
  },
  async consultarEquiposBodegaPaginado({ commit }, data) {
    try {
      let res = await axios.get(`/v1/equipos/bodega/pagina/${data.pagina}`);
      commit("setEquipos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarEquiposBodegaTotalPaginas() {
    try {
      const resp = await axios.get(`/v1/equipos/bodega/total`);
      store.dispatch("pagination/updateTotales", {
        totalPaginas: resp?.data?.totalPaginas,
        totalRegistros: resp?.data?.totalRegistros
      })
    } catch (error) {
      return error;
    }
  },
  async consultarEquiposDisponibles({ commit }) {
    try {
      let res = await axios.get(`/v1/equipos/bodega/disponibles`);
      commit("setEquipos", res?.data?.result);
      return res?.data?.result;
    } catch (error) {
      return error;
    }
  },
  async consultarEquipoXBodegaDisponibles({ commit }, data) {
    try {
      let res = await axios.get(`/v1/equipos/${data.bodega}/bodega/disponible`);
      commit("setEquipos", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
