<template>
  <GestionSolicitudesRechazadas />
</template>
<script>
import GestionSolicitudesRechazadas from "./components/GestionSolicitudesRechazadas";
export default {
  name: "SolicitudesRechazadas",
  components: { GestionSolicitudesRechazadas }
};
</script>
