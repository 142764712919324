<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Agregar Atencion Adjunto</h3>
          </div>
          <div class="col-md-6"></div>
        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <Form
                    @submit="submit"
                    :validation-schema="formValidation"
                    v-slot="{ errors }"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label >Tipo Solicitud *</label>
                            <CustomVariableList
                              variable="ADJUNTOS"
                              :selectedOptionValue="formData.tipoSolicitud"
                              :selectVariable="handleSelectChange"
                            />
                          </div>
                          </div>


                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="tipo">Tipo *</label>
                            <fieldset>
                              <Field
                                  name="tipo"
                                  as="select"
                                  class="form-control"
                                  v-model="formData.tipo"
                              >
                                <option value="" disabled>
                                  Seleccione un tipo
                                </option>
                                <option value="FILE">FILE</option>
                              </Field>
                              <MensajeValidacion :mensaje="errors.tipo"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="path">Descripcion *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="descripcion"
                                  id="descripcion"
                                  class="form-control"
                                  placeholder="Descripcion"
                                  v-model.trim="formData.descripcion"
                              />
                              <MensajeValidacion
                                  :mensaje="errors.descripcion"
                              />
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage :mensaje="mensaje" v-if="error"/>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                          type="submit"
                          id="form-submit"
                          class="btn btn-primary btn-general mr-2"
                      >
                        <i class="ti-save"></i> Guardar
                      </button>
                      <button
                          type="button"
                          class="btn btn-secondary btn-general-off"
                          @click="cerrar"
                      >
                        <i class="ti-close"></i>Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Form, Field} from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";
import CustomVariableList from "@/components/CustomVariableList.vue";
export default {
  name: "AtencionAdjuntoForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
    CustomVariableList
  },
  data() {
    const formValidation = {
      //tipoSolicitud: "required",
      //tipo: "required",
      descripcion: "required",
    };
    return {
      formData: {
        tipoSolicitud: "",
        tipo: "",
        descripcion: "",
      },
      error: false,
      mensaje: "",
      formValidation,
    };
  },
  mounted() {
    this.formData.tipoSolicitud=this.$store.getters["proveedores/proveedorSeleccionado"];
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    handleSelectChange(tipoSolicitud){
      this.formData.tipoSolicitud = tipoSolicitud;
    },
    async submit() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("atencionAdjunto/crear", this.formData);
        this.$router.push({name: "AtencionAdjuntos"});
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";

      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {},
};
</script>
