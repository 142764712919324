<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de menú por rol</h3>
          </div>
        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-0">
                <label>Rol *</label>
                <fieldset>
                  <select
                      name="rol"
                      class="form-control mb-2 mb-md-0"
                      v-model="rol"
                      @change="searchMenusByRole"
                  >
                    <option value="" disabled>Seleccione un rol</option>
                    <option
                        v-for="rol in roles"
                        :key="rol.id"
                        :value="rol.descripcion"
                    >
                      {{ rol.descripcion }}
                    </option>
                  </select>
                </fieldset>
              </div>
            </div>
          </div>
          <br/>
          <div class="row" v-if="rol">
            <div class="col-md-6">
              <h4>Opciones disponibles</h4>
              <hr/>
              <div
                  class="d-flex justify-content-between mb-2 "
                  v-for="menu in menus"
                  :key="menu.name"
              >
                <div>
                  <i :class="menu?.icon"></i> {{ menu.name }}
                  <div>
                    <!-- Segundo nivel -->
                    <div style="padding-left: 20%; width: 200%;" v-for="(submenus, index) in menu.submenus" :key="index">
                      {{ submenus.name }}

                      <div>
                        <!-- Tercer nivel -->
                        <div style="padding-left: 40%; width: 200%;" v-for="(subsubmenu, subIndex) in submenus.submenus" :key="subIndex">
                          {{ subsubmenu.name }}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div>
                  <a title="Agregar" href="javascript:void(0)" @click="agregar(menu)"
                  ><i class="ti-plus btn-icon-append ml-2"></i></a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h4>Opciones asignadas</h4>
              <hr/>
              <div
                  class="d-flex justify-content-between mb-2"
                  v-for="menu in menuByRole"
                  :key="menu.name"
              >
                <div>
                  <i :class="menu?.icon"></i> {{ menu.name }}
                  <div>
                    <!-- Segundo nivel -->
                    <div style="padding-left: 20%; width: 200%;" v-for="(submenus, index) in menu.submenus" :key="index">
                      {{ submenus.name }}

                      <div>
                        <!-- Tercer nivel -->
                        <div style="padding-left: 40%; width: 200%;" v-for="(subsubmenu, subIndex) in submenus.submenus" :key="subIndex">
                          {{ subsubmenu.name }}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div>
                  <a title="Subir Posición" href="javascript:void(0)" @click="subir(menu)"
                  ><i class="ti-arrow-up   btn-icon-append ml-2"></i></a>
                  <a title="Bajar Posición" href="javascript:void(0)" @click="bajar(menu)"
                  ><i class="ti-arrow-down   btn-icon-append ml-2"></i></a>

                  <a title="Eliminar" href="javascript:void(0)" @click="eliminar(menu)"
                  ><i class="ti-close   btn-icon-append ml-2"></i></a>

                  <Modal
                      mensaje="Se eliminará el archivo, ¿Está seguro?"
                      :method="procesarEliminar"
                  />
                  <button
                      type="button"
                      v-show="false"
                      id="exampleModalButton"
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      data-backdrop="static"
                      data-keyboard="false"
                  >
                    Modal messages
                  </button>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "OpcionesMenuRol",
  components: {Modal},
  data() {
    return {
      rol: "",
      itemSelected: null
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.$store.commit("menu/setMenuByRole", null);
  },
  mounted() {
    this.searchRoles();
    this.searchMenus();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async searchRoles() {
      this.loaderSpinner();
      try {
        await this.$store.commit("roles/setRolSeleccionado", null);
        await this.$store.dispatch("roles/consultarRoles");
      } catch (error) {
        alert("No se pudo realizar la consulta de los roles");
      } finally {
        this.loader.hide();
      }
    },
    async searchMenus() {
      try {
        await this.$store.dispatch("menu/searchAllMenus");
      } catch (error) {
        alert("No se pudo realizar la consulta de los menús");
      }
    },
    async searchMenusByRole() {
      this.loaderSpinner();
      try {
        if (this.rol) {
          await this.$store.dispatch("menu/searchMenuByRole", {
            rol: this.rol,
          });
        }
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar(menu) {
      try {
        await this.$store.dispatch("menu/asignar", {
          rol: this.rol,
          menu: menu?.id,
          orden: menu?.orden,
        });
        await this.searchMenusByRole();
      } catch (error) {
        alert("No se pudo asignar el menú al rol");
      }
    },
    async eliminar(menu) {
      this.itemSelected = menu
      document.getElementById("exampleModalButton").click();
    },

    async procesarEliminar() {
      try {
        await this.$store.dispatch("menu/desasignar", {
          id: this.itemSelected?.id,
          rol: this.rol
        });
        await this.searchMenusByRole();
      } catch (error) {
        alert("No se pudo asignar el menú al rol");
      }
    },
    async subir(item) {
      try {
        await this.$store.dispatch("menu/subir", {
          id: item.idMenuRol,
          rol: this.rol
        });
        await this.searchMenusByRole();
      } catch (error) {
        alert("No se pudo subir el menú al rol");
      }
    },
    async bajar(item) {
      try {
        await this.$store.dispatch("menu/bajar", {
          id: item.idMenuRol,
          rol: this.rol
        });
        await this.searchMenusByRole();
      } catch (error) {
        alert("No se pudo bajar el menú al rol");
      }
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    roles() {
      return this.$store.getters["roles/roles"]
          ? this.$store.getters["roles/roles"]
          : [];
    },
    roleSeleccionado() {
      return this.$store.getters["roles/rolSeleccionado"]
          ? this.$store.getters["roles/rolSeleccionado"]
          : [];
    },
    menus() {
      return this.$store.getters["menu/menus"]
          ? this.$store.getters["menu/menus"]
          : [];
    },
    menuByRole() {
      return this.$store.getters["menu/menuByRole"]
          ? this.$store.getters["menu/menuByRole"]
          : [];
    },
  },
};
</script>
<style></style>
