import axios from "axios";


const actions = {
    async consultar(_, data) {
        try {
            let resp = await axios.post(`/v1/reportes/solicitudes-por-bodega`, data);
            return {
                success: true,
                data: resp.data,
            }
        } catch (error) {
            return {
                success: false,
                data: error.response.data.errors
            }
        }
    },
};

export default {
    namespaced: true,
    actions,
};
