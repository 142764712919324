<template>
  <GestionRegularizacionesList />
</template>
<script>
import GestionRegularizacionesList from "./componentes/GestionRegularizacionesList";
export default {
  name: "Regularizaciones",
  components: { GestionRegularizacionesList }
};
</script>
