<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-lg-12">
            <div class="form-sample">
              <h3 class="font-weight-bold">
                {{ equipoSeleccionado ? "Editar equipo" : "Agregar equipo" }}
              </h3>
            </div>
          </div>
        </div>
        <div class="white-content-general mb-4">
          <Form
            @submit="submit"
            :validation-schema="formValidation"
            v-slot="{ errors }"
          >
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Placa</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.placa }}
                        </p>
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Serie</label>
                      <fieldset>
                        <!-- <p class="text-muted">
                              {{ equipoSeleccionado?.serie }}
                            </p> -->
                        <Field
                          type="text"
                          name="serie"
                          class="form-control"
                          placeholder="Serie"
                          v-model.trim="formData.serie"
                        />
                        <MensajeValidacion :mensaje="errors.serie" />
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Marca</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.marca }}
                        </p>
                        <!-- <Field
                              type="text"
                              name="marca"
                              class="form-control"
                              placeholder="Marca"
                              v-model.trim="formData.marca"
                            />
                            <MensajeValidacion :mensaje="errors.marca" /> -->
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="modelo">Modelo *</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.modelo }}
                        </p>
                        <!-- <Field
                              type="text"
                              name="modelo"
                              class="form-control"
                              placeholder="Modelo"
                              v-model.trim="formData.modelo"
                            />
                            <MensajeValidacion :mensaje="errors.modelo" /> -->
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                  <div class="form-group">
                    <label for="logo">Logo *</label>
                    <CustomVariableList
                      variable="LOGO"
                      :selectedOptionValue="formData.logo"
                      :selectVariable="handleSelectChange"
                    />
                  </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Tipo *</label>
                      <fieldset>
                        <Field
                          as="select"
                          name="tipoEquipo"
                          class="form-control"
                          placeholder="Tipo"
                          v-model.trim="formData.tipoEquipo"
                        >
                          <option value="" disabled>Seleccione una tipo</option>
                          <option
                            v-for="tipo in tipos"
                            :key="tipo"
                            :value="tipo"
                          >
                            {{ tipo }}
                          </option>
                        </Field>
                        <MensajeValidacion :mensaje="errors.tipoEquipo" />
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Año fabricación</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.anioFabricacion }}
                        </p>
                        <!-- <Field
                              type="text"
                              name="anioFabricacion"
                              class="form-control"
                              placeholder="Año de Fabricacion"
                              v-model.trim="formData.anioFabricacion"
                            />
                            <MensajeValidacion
                              :mensaje="errors.anioFabricacion"
                            /> -->
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Fabricante Génesis</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.fabricanteGenesis }}
                        </p>
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Status Neveras</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.statusNeveras }}
                        </p>
                      </fieldset>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                        <div class="form-group">
                          <label>Valor Libros</label>
                          <fieldset>
                            <p class="text-muted">
                              {{ equipoSeleccionado?.valorLibros }}
                            </p>
                          </fieldset>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Valor Comercial</label>
                          <fieldset>
                            <p class="text-muted">
                              {{ equipoSeleccionado?.valorComercial }}
                            </p>
                          </fieldset>
                        </div>
                      </div> -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Status Libros</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.statusLibros }}
                        </p>
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Año Adquisición</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.anioAdquisicion }}
                        </p>
                        <!-- <Field
                              type="text"
                              name="anioAdquisicion"
                              class="form-control"
                              placeholder="Año Adquisición"
                              v-model.trim="formData.anioAdquisicion"
                            />
                            <MensajeValidacion
                              :mensaje="errors.anioAdquisicion"
                            /> -->
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Capacidad Botellas</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.capacidadBotellas }}
                        </p>
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Capacidad Cajas</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.capacidadCajas }}
                        </p>
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Capacidad Pies</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.capacidadPies }}
                        </p>
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Activo Fijo</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.activoFijo }}
                        </p>
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Número Equipo</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.numeroEquipo }}
                        </p>
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Vh</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.vh }}
                        </p>
                      </fieldset>
                    </div>
                  </div>
                  <div class="col-md-6" v-if="!equipoSeleccionado">
                    <div class="form-group">
                      <label style="margin-right: 15px"
                        >Ubicación actual
                      </label>

                      <a href="javascript:void(0)"><i class="ti-pencil"></i></a>

                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.ubicacion }}
                          {{ equipoSeleccionado?.bodega }}
                        </p>
                      </fieldset>
                    </div>
                  </div>

                  <div class="col-md-6" v-else>
                    <div class="form-group">
                      <label>Ubicación actual</label>
                      <fieldset>
                        <p class="text-muted">
                          {{ equipoSeleccionado?.ubicacion }}
                          {{ equipoSeleccionado?.cliente }}
                        </p>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
              <ErrorMessage :mensaje="mensaje" v-if="error" />
            </div>
            <div class="row">
              <div class="col-lg-12">
                <button
                  type="submit"
                  id="form-submit"
                  class="btn btn-primary btn-general mr-2"
                >
                  <i class="ti-save"></i> Guardar
                </button>
                <button
                  type="button"
                  class="btn btn-secondary btn-general-off"
                  @click="cerrar"
                >
                  <i class="ti-close"></i> Cerrar
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../components/ErrorMessage";
import CustomVariableList from "@/components/CustomVariableList.vue";
export default {
  name: "EditEquipoProveedor",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
    CustomVariableList
  },
  data() {
    const formValidation = {
      //modelo: "required",
      //logo: "required",
      tipoEquipo: "required",
      //marca: "required",
      serie: "required",
      //anioFabricacion: "required",
      //anioAdquisicion: "required",
    };
    return {
      formData: {
        modelo: "",
        logo: "",
        tipoEquipo: "",
        marca: "",
        serie: "",
        anioFabricacion: "",
        anioAdquisicion: "",
      },
      error: false,
      mensaje: "",
      formValidation,
      tipoUbicacion: null,
      codigoCliente: "",
      bodega: 0,
      ubicacion: "",
      tipos: ["Draught", "Frosters", "Coolers"],
    };
  },
  mounted() {
    if (this.equipoSeleccionado) {
      this.formData = { ...this.equipoSeleccionado };
    }
    this.consultarBodegas();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarBodegas() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("bodegas/consultarTodas");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    handleSelectChange(logo){
        this.formData.logo = logo;
    },
    async submit() {
      if(!this.formData.logo.length) return;
      this.error = false;
      this.loaderSpinner();
      const data = {
        ...this.equipoSeleccionado,
        logo: this.formData.logo,
        modelo: this.formData.modelo,
        tipoEquipo: this.formData.tipoEquipo,
        marca: this.formData.marca,
        serie: this.formData.serie,
        anioFabricacion: this.formData.anioFabricacion,
        anioAdquisicion: this.formData.anioAdquisicion,
      };
      try {
        if (this.equipoSeleccionado) {
          await this.$store.dispatch("equipos/editarEquipo", {
            id: this.equipoSeleccionado.id,
            data,
          });
          await this.$store.commit("equipos/setEquipoSeleccionado", null);
        }
        if (this?.$route?.params?.path === "equipos") {
          this.$router.push({ name: "Equipos" });
        } else {
          this.$router.push({ name: "EquiposProveedor" });
        }
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    equipoSeleccionado() {
      return this.$store.getters["equipos/equipoSeleccionado"]
        ? this.$store.getters["equipos/equipoSeleccionado"]
        : null;
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
        ? this.$store.getters["bodegas/bodegas"]
        : [];
    },
  },
};
</script>
<style>
.radio-margin {
  margin-right: 15px;
}
</style>
