<template>
  <div>

    <div class="white-content-table">

      <button
          @click="confirmar"
          class="btn btn-general-off btn-secondary float-right mr-2 mb-3"
      >
        <span class="icon-check"></span> Recibir Seleccionados
      </button>

      <table class="table table-striped">
        <thead>
        <tr>
          <th><input type="checkbox" v-model="checkAll" @change="selectAll"/></th>
          <th></th>
          <th>ActivoCodBarras</th>
          <th>Número de guía</th>
          <th>Bodega Origen</th>
          <th>Bodega Destino</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in trasladosPorRecibir.traslados" :key="item.id">
          <td style="width: 2%">
            <input type="checkbox" :checked="isSelected(item.id)" @change="selectItem(item)"
            />
          </td>
          <td style="width: 2%">
            <div
                class="badge"
                :class="
                      item?.estadoProceso === 'PENDIENTE'
                        ? 'badge-secondary'
                        :  item?.estadoProceso === 'APROBADO' ? 'badge-success': 'badge-danger'
                    "
            >
              {{ item?.estadoProceso }}
            </div>
          </td>
          <td>{{ item?.equiposFrios?.placa }}</td>
          <td>{{ item?.numeroGuia }}</td>
          <td>{{ item.bodegaOrigen?.nombre }}</td>
          <td>{{ item.bodegaDestino?.nombre }}</td>
        </tr>
        </tbody>
      </table>

      <Modal
          mensaje="¿Desea aprobar los registros seleccionados?"
          :method="procesar"
      />
      <button
          type="button"
          v-show="false"
          id="exampleModalButton"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          data-backdrop="static"
          data-keyboard="false"
      >
        Modal messages
      </button>

    </div>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {Modal},
  data() {
    return {
      trasladosPorRecibir: [],
      selectedItems: [],
      checkAll: false
    }
  },

  async created() {
    await this.consultar()
  },

  methods: {
    async consultar() {
      try {
        const resp = await this.$store.dispatch("trasladosRecibir/porRecibir");
        this.trasladosPorRecibir = resp?.data?.result
      } catch (error) {
        alert("No se pudo realizar la consulta");
      }
    },
    isSelected(id) {
      return this.selectedItems.includes(id);
    },
    selectItem(check) {
      const findedOption = this.selectedItems?.findIndex(
          (item) => item === check.id
      );
      let arraySelectedItems = [];
      if (findedOption === -1) {
        arraySelectedItems = [...this.selectedItems, check.id];
      } else {
        arraySelectedItems = this.selectedItems.filter(
            (item) => item !== check.id
        );
      }
      this.selectedItems = arraySelectedItems;
    },
    selectAll() {
      this.selectedItems = [];
      if (this.checkAll) {
        for (let i = 0; i < this.trasladosPorRecibir.length; i++) {
          this.selectedItems.push(this.trasladosPorRecibir[i].id);
        }
      }
    },
    confirmar() {
      if (this.selectedItems.length === 0) {
        alert("Debe seleccionar al menos 1 elemento")
        return
      }
      document.getElementById("exampleModalButton").click();
    },

    async procesar() {
      const resp = await this.$store.dispatch(
          "trasladosRecibir/recibir",
          {
            idArchivo: this.trasladosPorRecibir.idTrasladoArchivo,
            idTraslados: this.selectedItems
          }
      );
      if (resp.success) {
        await this.consultar()
        await this.consultarDetalles()
      } else {
        alert("Error en el proceso de aprobación")
      }

    },
  }
}
</script>
