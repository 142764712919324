<template>
  <div
    class="col-md-12 grid-margin stretch-card grid-margin"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              {{ equipoSeleccionado ? "Editar equipo" : "Agregar equipo" }}
            </h3>
          </div>
          <div class="col-md-6">
          
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                
                <Form
                  @submit="submit"
                  :validation-schema="formValidation"
                  v-slot="{ errors }"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="placa">Placa *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="placa"
                                id="placa"
                                class="form-control"
                                placeholder="Placa"
                                v-model.trim="formData.placa"
                              />
                              <MensajeValidacion :mensaje="errors.placa" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="serie">Serie *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="serie"
                                class="form-control"
                                placeholder="Serie"
                                v-model.trim="formData.serie"
                              />
                              <MensajeValidacion :mensaje="errors.serie" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="marca">Marca *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="marca"
                                class="form-control"
                                placeholder="Marca"
                                v-model.trim="formData.marca"
                              />
                              <MensajeValidacion :mensaje="errors.marca" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="modelo">Modelo *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="modelo"
                                class="form-control"
                                placeholder="Modelo"
                                v-model.trim="formData.modelo"
                              />
                              <MensajeValidacion :mensaje="errors.modelo" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="logo">Logo *</label>
                            <CustomVariableList
                                variable="LOGO"
                                :selectedOptionValue="formData.logo"
                                :selectVariable="handleSelectChange"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="anioFabricacion"
                              >Año de fabricación *</label
                            >
                            <fieldset>
                              <Field
                                type="text"
                                name="anioFabricacion"
                                class="form-control"
                                placeholder="Año de Fabricacion"
                                v-model.trim="formData.anioFabricacion"
                              />
                              <MensajeValidacion
                                :mensaje="errors.anioFabricacion"
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Tipo *</label>
                            <fieldset>
                              <Field
                                as="select"
                                name="tipoEquipo"
                                class="form-control"
                                placeholder="Tipo"
                                v-model.trim="formData.tipoEquipo"
                              >
                                <option value="" disabled>
                                  Seleccione una tipo
                                </option>
                                <option
                                  v-for="tipo in tipos"
                                  :key="tipo"
                                  :value="tipo"
                                >
                                  {{ tipo }}
                                </option>
                              </Field>
                              <MensajeValidacion :mensaje="errors.tipoEquipo" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="fabricanteGenesis"
                              >Fabricante Genésis *</label
                            >
                            <fieldset>
                              <Field
                                type="text"
                                name="fabricanteGenesis"
                                class="form-control"
                                placeholder="Fabricante Genésis"
                                v-model.trim="formData.fabricanteGenesis"
                              />
                              <MensajeValidacion
                                :mensaje="errors.fabricanteGenesis"
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="statusNeveras">Status Neveras *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="statusNeveras"
                                class="form-control"
                                placeholder="Status Neveras"
                                v-model.trim="formData.statusNeveras"
                              />
                              <MensajeValidacion
                                :mensaje="errors.statusNeveras"
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="valorLibros">Valor Libros *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="valorLibros"
                                class="form-control"
                                placeholder="Valor Libros"
                                v-model.trim="formData.valorLibros"
                              />
                              <MensajeValidacion :mensaje="errors.valorLibros" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="valorComercial">Valor Comercial *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="valorComercial"
                                class="form-control"
                                placeholder="Valor Comercial"
                                v-model.trim="formData.valorComercial"
                              />
                              <MensajeValidacion
                                :mensaje="errors.valorComercial"
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="statusLibros">Status Libros *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="statusLibros"
                                class="form-control"
                                placeholder="Valor Comercial"
                                v-model.trim="formData.statusLibros"
                              />
                              <MensajeValidacion :mensaje="errors.statusLibros" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="anioAdquisicion">Año Adquisición *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="anioAdquisicion"
                                class="form-control"
                                placeholder="Año Adquisición"
                                v-model.trim="formData.anioAdquisicion"
                              />
                              <MensajeValidacion
                                :mensaje="errors.anioAdquisicion"
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="capacidadBotellas"
                              >Capacidad Botellas *</label
                            >
                            <fieldset>
                              <Field
                                type="text"
                                name="capacidadBotellas"
                                class="form-control"
                                placeholder="Capacidad Botellas"
                                v-model.trim="formData.capacidadBotellas"
                              />
                              <MensajeValidacion
                                :mensaje="errors.capacidadBotellas"
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="capacidadCajas">Capacidad Cajas *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="capacidadCajas"
                                class="form-control"
                                placeholder="Capacidad Cajas"
                                v-model.trim="formData.capacidadCajas"
                              />
                              <MensajeValidacion
                                :mensaje="errors.capacidadCajas"
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="capacidadPies">Capacidad Pies *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="capacidadPies"
                                class="form-control"
                                placeholder="Capacidad Pies"
                                v-model.trim="formData.capacidadPies"
                              />
                              <MensajeValidacion
                                :mensaje="errors.capacidadPies"
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="activoFijo">Activo Fijo *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="activoFijo"
                                class="form-control"
                                placeholder="Activo Fijo"
                                v-model.trim="formData.activoFijo"
                              />
                              <MensajeValidacion :mensaje="errors.activoFijo" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="numeroEquipo">Número Equipo *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="numeroEquipo"
                                class="form-control"
                                placeholder="Número Equipo"
                                v-model.trim="formData.numeroEquipo"
                              />
                              <MensajeValidacion :mensaje="errors.numeroEquipo" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="vh">VH *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="vh"
                                class="form-control"
                                placeholder="VH"
                                v-model.trim="formData.vh"
                              />
                              <MensajeValidacion :mensaje="errors.vh" />
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="fechaDisponible"
                              >Fecha disponible</label
                            >
                            <fieldset>
                              <Field
                                type="date"
                                name="fechaDisponible"
                                class="form-control"
                                placeholder="Fecha disponible"
                                v-model.trim="formData.fechaDisponible"
                              />
                              <MensajeValidacion
                                :mensaje="errors.fechaDisponible"
                              />
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6" v-if="!equipoSeleccionado">
                          <div class="form-group">
                            <label style="margin-right: 15px"
                              >Ubicación actual
                            </label>

                            <!-- <a href="javascript:void(0)"
                              ><i class="ti-pencil"></i
                            ></a> -->

                            <fieldset>
                              <p class="text-muted">
                                {{ equipoSeleccionado?.ubicacion }}
                                {{ equipoSeleccionado?.bodega }}
                              </p>
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6" v-else>
                          <div class="form-group">
                            <label>Ubicación actual</label>
                            <fieldset>
                              <p class="text-muted">
                                {{ equipoSeleccionado.ubicacion }}
                                {{ equipoSeleccionado?.cliente }}
                              </p>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage :mensaje="mensaje" v-if="error" />
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                        type="submit"
                        id="form-submit"
                        class="btn btn-primary btn-general mr-2"
                      >
                      <i class="ti-save"></i> Guardar
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary btn-general-off"
                        @click="cerrar"
                      >
                      <i class="ti-close"></i> Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";
import CustomVariableList from "@/components/CustomVariableList.vue";

export default {
  name: "EquipoForm",
  components: {
      CustomVariableList,
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {
    const formValidation = {
      placa: "required",
      serie: "required",
      marca: "required",
      modelo: "required",
      anioFabricacion: "required",
      tipoEquipo: "required",
      fabricanteGenesis: "required",
      statusNeveras: "required",
      valorLibros: "required",
      valorComercial: "required",
      statusLibros: "required",
      anioAdquisicion: "required",
      capacidadBotellas: "required",
      capacidadCajas: "required",
      capacidadPies: "required",
      activoFijo: "required",
      numeroEquipo: "required",
      vh: "required",
    };
    return {
      formData: {
        placa: "",
        serie: "",
        marca: "",
        modelo: "",
        logo: "",
        anioFabricacion: "",
        tipoEquipo: "",
        fabricanteGenesis: "",
        statusNeveras: "",
        valorLibros: "",
        valorComercial: "",
        statusLibros: "",
        anioAdquisicion: "",
        capacidadBotellas: "",
        capacidadCajas: "",
        capacidadPies: "",
        activoFijo: "",
        numeroEquipo: "",
        vh: "",
        fechaDisponible: ""
      },
      error: false,
      mensaje: "",
      formValidation,
      tipoUbicacion: null,
      codigoCliente: "",
      bodega: 0,
      ubicacion: "",
      mostrarCliente: false,
      mostrarBodega: false,
      modificarUbicacion: false,
      mostrarModificacionUbicacion: false,
      tipos: ["Draught", "Frosters", "Coolers"],
    };
  },
  created() {
    this.$store.dispatch("global/updateIsPreventNavigation", true);
  },
  mounted() {
    if (this.equipoSeleccionado) {
      this.formData = { ...this.equipoSeleccionado };

    }
    this.consultarBodegas();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarBodegas() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("bodegas/consultarTodas");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    onClickModificarUbicacion() {
      if (this.modificarUbicacion) {
        this.mostrarCliente = false;
        this.mostrarBodega = false;
        this.bodega = 0;
        this.codigoCliente = "";
        this.tipoUbicacion = "";
      }
    },
    seleccionarTipoUbicacion(e) {
      if (e.target.value === "CLIENTE") {
        this.mostrarCliente = true;
        this.mostrarBodega = false;
        this.bodega = 0;
        return;
      }
      if (e.target.value === "BODEGA") {
        this.mostrarCliente = false;
        this.mostrarBodega = true;
        this.codigoCliente = "";
        return;
      }
    },
    handleSelectChange(logo){
      this.formData.logo = logo;
    },
    async submit() {
      if(!this.formData.logo.length) return;
      if (this.equipoSeleccionado) {
        this.formData.cliente = this.equipoSeleccionado?.cliente;
        this.formData.bodega = this.equipoSeleccionado?.bodega;
      }
      this.error = false;
      this.loaderSpinner();
      try {
        if (this.equipoSeleccionado) {
          await this.$store.dispatch("equipos/editarEquipo", {
            id: this.equipoSeleccionado.id,
            data: this.formData,
          });
          await this.$store.commit("equipos/setEquipoSeleccionado", null);
        } else {
          await this.$store.dispatch("equipos/crearEquipo", this.formData);
        }
        this.$store.dispatch("global/updateIsPreventNavigation", false);
        this.$router.push({ name: "Equipos" });
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$store.dispatch("global/updateIsPreventNavigation", false);
      this.$router.go(-1);
    },
  },
  computed: {
    equipoSeleccionado() {
      return this.$store.getters["equipos/equipoSeleccionado"]
        ? this.$store.getters["equipos/equipoSeleccionado"]
        : null;
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
        ? this.$store.getters["bodegas/bodegas"]
        : [];
    },
  },
};
</script>
<style>
.radio-margin {
  margin-right: 15px;
}
</style>
