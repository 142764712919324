import axios from "axios";

const state = {
  bajas: null,
  mensaje: "",
  totalPaginas: null,
};

const getters = {
  bajas: (state) => state.bajas,
  totalPaginas: (state) => state.totalPaginas,
};

const mutations = {
  setBajas: (state, bajas) => {
    state.bajas = bajas;
  },
  setMensaje: (state, mensaje) => {
    state.mensaje = mensaje;
  },
  setTotalPaginas: (state, data) => {
    state.totalPaginas = data;
  },
};

const actions = {
  async consultarBajasContablesPaginado({ commit }, data) {
    try {
      let res = await axios.get(`/v1/bajas-contable/pagina/${data.pagina}`);
      commit("setBajas", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarBajasArchivoPaginado({ commit }, data) {
    try {
      let res = await axios.get(`/v1/bajas-contable/archivo/pagina/${data.pagina}`);
      commit("setBajas", res?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarBajasContablesTotalPaginas({commit}) {
    try {
      const resp = await axios.get(`/v1/bajas-contables/total`);
      commit("setTotalPaginas", resp?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async consultarBajasArchivoTotalPaginas({commit}) {
    try {
      const resp = await axios.get(`/v1/bajas-contables/archivo/total`);
      console.log(resp)
      commit("setTotalPaginas", resp?.data?.result);
    } catch (error) {
      return error;
    }
  },
  async cargarArchivo({commit}, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v1/bajas/upload`, data, { headers });
      commit("setMensaje", "Se ha cargado con éxito");
      return "success"
    } catch (error) {
      return "error";
    }
  },
  async cargarArchivoZone({commit}, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      await axios.post(`/v2/bajas/upload`, data, { headers });
      commit("setMensaje", "Se ha cargado con éxito");
      return "success"
    } catch (error) {
      return "error";
    }
  },
  async crearBaja(_, data) {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const resp  = await axios.post(`/v2/bajas`, data, { headers });
      return {
        success: true,
        data: resp.data,
      }
    } catch (error) {
      return {
        success: false,
        data: error.response.data.errors
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
