<template>
  <div>
    <EquipoFormEC v-if="country==='EC'"/>
    <EquipoFormZona v-else/>
  </div>
</template>
<script>
import EquipoFormEC from "@/views/admin/equipos/components/EquipoFormEC.vue";
import EquipoFormZona from "@/views/admin/equipos/components/EquipoFormZona.vue";

export default {
  components: {
    EquipoFormEC,
    EquipoFormZona
  },
  data() {
    return {
      country: this.$config.countryFlag,
    }
  }
}
</script>
