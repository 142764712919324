<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Menús</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0"></p>
            </div>
            <div class="col-md-8">
              <button
                  @click="agregarPadre"
                  class="btn btn-general btn-primary float-right"
              >
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>


        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th width="5%"></th>
                <th>Orden</th>
                <th>Nombre : Componente</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in menus" :key="item.id">
                <td>
                  <a href="javascript:void(0)" @click="agregar(item)"
                     title="Agregar"><i class="ti-plus   btn-icon-append mr-2"></i></a>


                  <a title="Eliminar" href="javascript:void(0)" @click="eliminar(item)"
                  ><i class="ti-trash btn-icon-append"></i></a>

                  <Modal
                      mensaje="Se eliminará el archivo, ¿Está seguro?"
                      :method="procesarEliminar"
                  />
                  <button
                      type="button"
                      v-show="false"
                      id="exampleModalButton"
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      data-backdrop="static"
                      data-keyboard="false"
                  >
                    Modal messages
                  </button>

                </td>
                <td>{{ item.order }}</td>
                <td>
                  <i :class="item?.icon"></i> {{ item.name }} : {{ item.componente }}

                  <div>
                    <!-- Segundo nivel -->
                    <div class="mt-2" style="padding-left: 20%;" v-for="(submenus, index) in item.submenus" :key="index">
                      {{ submenus.name }} : {{ submenus.componente }}

                      <div>
                        <!-- Tercer nivel -->
                        <div class="mt-2" style="padding-left: 20%;" v-for="(subsubmenu, subIndex) in submenus.submenus" :key="subIndex">
                          {{ subsubmenu.name }} : {{ subsubmenu.componente }}
                        </div>
                      </div>

                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "OpcionesMenu",
  components: {Modal},
  data() {
    return {
      itemSelected : null
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("menu/searchAllMenus");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar(item) {
      this.$router.push({name: "MenuForm", params: {idPadre: item.id}});
    },
    async agregarPadre() {
      this.$router.push({name: "MenuForm", params: {idPadre: 0}});
    },

    async eliminar(item) {
      this.itemSelected = item
      document.getElementById("exampleModalButton").click();
    },

    async procesarEliminar() {

      await this.$store.dispatch("menu/delete", {id: this.itemSelected.id});
      await this.consultar();
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    menus() {
      return this.$store.getters["menu/menus"]
          ? this.$store.getters["menu/menus"]
          : [];
    },
  },
};
</script>
<style></style>
