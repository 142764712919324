<template>
  <GestionBajasEC v-if=" $config.countryFlag==='EC'"/>
  <GestionBajasZone v-else/>
</template>
<script>

import GestionBajasEC from "../../bajas/components/GestionBajasEC.vue";
import GestionBajasZone from "../../bajas/components/GestionBajasZone.vue";

export default {
  components: {GestionBajasEC, GestionBajasZone}
}
</script>
