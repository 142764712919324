<template>
  <div class="form-check">
    <div>
      <div v-for="(item, index) in variables" :key="index">
        <input
            class="form-check-input ml-1"
            type="checkbox"
            :value="item.atributo"
            :id="item.atributo"
            v-model="selectedOptions"
            @click="handleSelectChange($event)"
        />
        <label class="form-check-label" :for="item.atributo">{{ item.atributo }}</label>
      </div>

    </div>

  </div>
</template>
<script>
export default {
  props: {
    variable: String,
    selectVariable: {type: Function},
    selectedOptionsProp: Array
  },
  data() {
    return {
      variables: [],
      selectedOptions: [],
    };
  },
  created() {
  },
  mounted() {
    this.loadVariables();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async loadVariables() {
      try {
        const resp = await this.$store.dispatch("variables/consultar", {
          variable: this.variable,
        });
        this.variables = resp;
        if (this.selectedOptionsProp) {
          this.selectedOptions = this.selectedOptionsProp;
        }
      } catch (error) {
        alert("No se pudo consultar los variables");
      }
    },
    handleSelectChange(event) {
      const findedOption = this.selectedOptions?.findIndex(
          (item) => item === event.target.value
      );
      let arraySelectedOptions = [];
      if (findedOption === -1) {
        arraySelectedOptions = [...this.selectedOptions, event.target.value];
      } else {
        arraySelectedOptions = this.selectedOptions.filter(
            (item) => item !== event.target.value
        );
      }
      this.selectVariable(arraySelectedOptions);
    },

  },
  watch: {
    variable() {
      this.selectedOptions = [];
      this.loadVariables();
    },
  }
};
</script>

<style scoped>

</style>
