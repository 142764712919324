<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Crear ruta ({{ tipoFiltro }})
            </h3>
          </div>
          <div class="col-md-6">

          </div>
        </div>
        <div class="white-content-general mb-4">

          <div class="form-group col-md-6">
            <label>Seleccione un técnico</label>
            <select class="form-control" v-model="tecnico">
              <option value="" disabled></option>
              <option v-for="item in tecnicos" :key="item.id" :value="item.id">
                {{ item?.tecnicoBodegaDescripcion }}
              </option>
            </select>
          </div>
          <div>
            <p>Lista de puntos seleccionados</p>
            <ul>
              <li v-for="item in puntosSeleccionados" :key="item.id">
                <strong>Solicitud:</strong>
                {{ item?.requerimiento?.solicitud?.id }}
                <strong>Cliente:</strong>
                {{ item?.requerimiento?.solicitud?.codigoCliente }}
                <strong>Establecimiento:</strong>
                {{ item?.requerimiento?.solicitud?.nombreEstablecimiento }}
                <strong>Dirección:</strong>
                {{ item?.requerimiento?.solicitud?.direccion }}
                <a
                    href="javascript:void(0)"
                    class="text-danger"
                    @click="eliminarPunto(item)"
                ><i class="ti-close"></i
                ></a>
              </li>
            </ul>
          </div>
          <div class="mt-3 mb-4">
            <button
                class="btn btn-primary btn-general"
                type="button"
                @click="crear"
                :disabled="puntosSeleccionados.length === 0 || tecnico === ''"
            >
              <span class="icon-circle-check"></span> Crear ruta
            </button>

            <button class="btn btn-secondary btn-general-off ml-2" type="button" @click="cerrar">
              <i class="ti-close"></i> Cerrar
            </button>
          </div>

          <GMapMap
              :center="center"
              :zoom="8"
              map-type-id="roadmap"
              style="width: 75vw; height: 40vw"
          >
            <GMapMarker
                :key="index"
                v-for="(m, index) in coordenadas"
                :position="m"
                :clickable="true"
                @click="seleccionarPunto(m)"
                :icon="`https://maps.google.com/mapfiles/ms/icons/${
                m?.requerimiento?.solicitud?.diasTranscurridosLabolables < 3
                  ? 'green'
                  : m?.requerimiento?.solicitud?.diasTranscurridosLabolables > 5
                  ? 'red'
                  : 'yellow'
              }-dot.png`"
                :title="`Solicitud: ${m.requerimiento?.solicitud?.id.toString()} \nCliente: ${m.requerimiento?.solicitud?.codigoCliente.toString()} \nEstablecimiento: ${m.requerimiento?.solicitud?.nombreEstablecimiento.toString()}`"
            />
          </GMapMap>
          <ModalMessages :mensaje="mensajeProceso" :titulo="titulo"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../components/ModalMessages.vue";

export default {
  name: "Mapa",
  components: {ModalMessages},
  data() {
    return {
      center: {lat: -10.792632, lng: -74.368513},
      vehiculo: "",
      tecnico: "",
      mensajeProceso: [],
      clusterIcon: {
        backgroundColor: "#456",
      },
      titulo: "Crear ruta",
      country: "EC"
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  mounted() {
    this.consultarVehiculos();
    this.consultarTecnicos();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarVehiculos() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("ruta/consultarVehiculosXBodega", {
          idBodega: this.coordenadas[0].requerimiento.bodega.id,
        });
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async consultarTecnicos() {
      this.country = this.$config.countryFlag
      if(this.country==="EC"){
        try {
          await this.$store.dispatch("ruta/consultarTecnicosXBodega", {
            idBodega: this.coordenadas[0].requerimiento.bodega.id,
          });
        } catch (error) {
          alert("No se pudo realizar la consulta");
        }
      }else{
        try {
          await this.$store.dispatch("ruta/consultarTecnicosXBodegaZona", {
            idBodega: this.coordenadas[0].requerimiento.bodega.id,
          });
        } catch (error) {
          alert("No se pudo realizar la consulta");
        }
      }

    },
    async seleccionarPunto(punto) {
      if (this.tipoFiltro === "MANTENIMIENTO") {
        //Verificar si tiene bloqueo el cliente
        const bloqueoCliente = await this.$store.dispatch(
            "cartera/consultarBloqueoCliente",
            {codigoCliente: punto?.requerimiento?.solicitud?.codigoCliente}
        );

        if (bloqueoCliente) {
          this.mensajeProceso = [bloqueoCliente];
          this.titulo = "Advertencia";
          document.getElementById("modalButton").click();
          return;
        }
      }

      this.$store.commit("solicitudesProveedor/setPuntosSeleccionados", punto);
      const coordenadasActualizadas = this.coordenadas.filter(
          (item) => item !== punto
      );
      this.$store.commit(
          "solicitudesProveedor/setCoordenadas",
          coordenadasActualizadas
      );
    },
    eliminarPunto(punto) {
      this.$store.commit("solicitudesProveedor/deletePuntoSeleccionado", punto);
      this.coordenadas.push(punto);
    },
    async crear() {
      try {
        this.loaderSpinner();
        let solicitudesProveedor = [];
        this.puntosSeleccionados.map((item) =>
            solicitudesProveedor.push(item.id)
        );
        const data = {
          solicitudesProveedor,
          vehiculo: 0,
          tecnico: this.tecnico,
        };

        await this.$store.dispatch("ruta/crearRuta", data);

        this.$router.go(-1);
      } catch (error) {
        console.log(error)
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {

    coordenadas() {
      return this.$store.getters["solicitudesProveedor/coordenadas"]
          ? this.$store.getters["solicitudesProveedor/coordenadas"]
          : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    puntosSeleccionados() {
      return this.$store.getters["solicitudesProveedor/puntosSeleccionados"]
          ? this.$store.getters["solicitudesProveedor/puntosSeleccionados"]
          : [];
    },
    tipoFiltro() {
      return this.$route.params.tipoFiltro;
    },
    tecnicos() {

      return this.$store.getters["ruta/tecnicos"]
          ? this.$store.getters["ruta/tecnicos"]
          : [];
    },
  },
};
</script>
