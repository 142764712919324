<template>
  <GestionTraslados/>
</template>
<script>
import GestionTraslados from "@/views/procesos/traslados/components/GestionTraslados.vue";

export default {
  components: {GestionTraslados}

}
</script>
