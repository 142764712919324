<template>
  <SolicitudMantenimientoEC v-if="$config.countryFlag==='EC'"/>
  <SolicitudMantenimientoZone v-else/>
</template>
<script>

import {defineComponent} from "vue";
import SolicitudMantenimientoEC from "@/views/solicitudesTecnico/components/SolicitudMantenimientoEC.vue";
import SolicitudMantenimientoZone from "@/views/solicitudesTecnico/components/SolicitudMantenimientoZone.vue";

export default defineComponent({
  components: {SolicitudMantenimientoZone, SolicitudMantenimientoEC}
})
</script>
