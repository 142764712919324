import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {defineRule, configure} from "vee-validate";
import {required, max} from "@vee-validate/rules";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";


defineRule("required", required);
defineRule("max", max);

configure({
    generateMessage: (context) => {
        const messages = {
            required: `Campo obligatorio`,
            max: `El campo ${context.field} supera el límite de ${context.rule.params[0]} caracteres permitido.`,
            /* integer: `El campo ${context.field} solo puede contener números enteros.`,
            numeric: `El campo ${context.field} solo puede contener números enteros o decimales.`,
            min: `El campo ${context.field} no cumple con los ${context.rule.params[0]} caracteres permitidos.`,
            max: `El campo ${context.field} supera el límite de ${context.rule.params[0]} caracteres permitido.`,
            alpha_spaces: `El campo ${context.field} solo puede contener letras y espacios.`,
            alpha_num: `El campo ${context.field} solo puede contener letras y números.`,
            email: `El campo ${context.field} no es una dirección de correo válido.`, */
        };
        const message = messages[context.rule.name]
            ? messages[context.rule.name]
            : `El campo ${context.field} no es válido`;
        return message;
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true,
});

let config;
if (process.env.VUE_APP_COUNTRY === 'EC') {
    config = require('@/config/config.EC.js').default;
} else if (process.env.VUE_APP_COUNTRY === 'PE') {
    config = require('@/config/config.PE.js').default;
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
    (config) => {
        const token = window.sessionStorage.getItem("_token_eeff");
        config.headers['X-resource'] = process.env.VUE_APP_API_HEADER;
        if (token != null) {
            if (jwt_decode(token).exp >= Date.now().valueOf() / 1000) {
                config.headers.Authorization = `Bearer ${token}`;
            } else {
                store.dispatch("auth/logout");
                router.replace("/");
            }
        } else {
            store.dispatch("auth/logout");
            router.replace("/");
        }
        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);

const options = {
    title: "Sistema EEFF",
    confirmButtonColor: "#003FA1",
    cancelButtonColor: "#20222E",
};

const app = createApp(App);
app.config.globalProperties.$config = config;

const googleMapsApiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

app.use(VueSweetalert2, options)
    .use(Loading, {
        canCancel: false,
        loader: "spinner",
        color: "#003FA1",
    })
    .use(VueGoogleMaps, {
        load: {
            key: googleMapsApiKey,
            autobindAllEvents: true,
        },
    })
    .use(store)
    .use(router)
    .mount("#app");
