<template>
  <GestionBodegaGerencia />
</template>
<script>
import GestionBodegaGerencia from "./components/GestionBodegaGerencia";
export default {
  components: { GestionBodegaGerencia },
  mounted() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>
