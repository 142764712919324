<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Gestión de Equipos Fríos
            </h3>
          </div>
        </div>

        <EquiposFriosData :rol="rol"/>

      </div>
    </div>
  </div>
</template>
<script>

import EquiposFriosData from "@/components/EquiposFriosData.vue";

export default {
  name: "GestionEquipos",
  components: {EquiposFriosData},
  data() {
    return {};
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  mounted() {
  },
  methods: {},
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    rol() {
      if (!this?.$store?.state?.auth?.rol) {
        this.logout();
        return;
      }
      return this.$store.state.auth.rol;
    },
  }
};
</script>
<style>
</style>
