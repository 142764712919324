<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Requerimientos atendidos</h3>
          </div>
          <div class="col-md-6">
            
          </div>
        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-3">
              <input type="date" class="form-control" v-model="fechaInicio" />
            </div>
            <div class="col-md-3 mb-2">
              <input type="date" class="form-control" v-model="fechaFin" />
            </div>
            <div class="col-md-3">
              <button
                type="button"
                class="btn btn-secondary"
                @click="consultarXFechas"
                :disabled="fechaInicio && fechaFin ? false : true"
              >
              <i class="ti-list"></i> Consultar
              </button>
            </div>
            <div class="col-md-3">
              <button @click="exportExcel" class="btn btn-primary btn-general float-right mb-2" :disabled="!solicitudesCerradas.length">
                <span class="icon-download"></span> Exportar
              </button>
            </div>
          </div>
        </div>
        
        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>Id</th>
                  <th>Ticket</th>
                  <th>Bodega</th>
                  <th>Cliente</th>
                  <th>Tipo</th>
                  <th>Fecha solicitud</th>
                  <th>Días Transcurridos</th>
                  <th>Gerencia general</th>
                  <th>Gerencia ventas</th>
                  <th>ECB</th>
                  <th>Nombre</th>
                  <th>Establecimiento</th>
                  <th>Dirección</th>
                  <th>Tipo de caso</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in solicitudesCerradas" :key="item.id">
                  <td>
                  </td>
                  <td>{{ item.solicitudProveedor.solicitud.id }}</td>
                  <td>{{ item.solicitudProveedor.solicitud.numeroTicket }}</td>
                  <td>{{ item.solicitudProveedor.bodega.nombre }}</td>
                  <td>{{ item.solicitudProveedor.solicitud.codigoCliente }}</td>
                  <td>
                    <strong>{{
                      item.solicitudProveedor.solicitud.tipoSolicitud
                    }}</strong>
                  </td>
                  <td>
                    {{ item.solicitudProveedor.solicitud.fechaSolicitud }}
                  </td>
                  <td>
                    <div
                      class="badge"
                      :class="
                        item.solicitudProveedor.solicitud
                          .diasTranscurridosLabolables < 3
                          ? 'badge-success'
                          : item.solicitudProveedor.solicitud
                              .diasTranscurridosLabolables > 5
                          ? 'badge-danger'
                          : 'badge-warning'
                      "
                    >
                      {{
                        item.solicitudProveedor.solicitud
                          .diasTranscurridosLabolables
                      }}
                      día(s)
                    </div>
                  </td>
                  <td>
                    {{ item.solicitudProveedor.solicitud.gerenciaGeneral }}
                  </td>
                  <td>
                    {{ item.solicitudProveedor.solicitud.gerenciaVentas }}
                  </td>
                  <td>{{ item.solicitudProveedor.solicitud.ecb }}</td>
                  <td>{{ item.solicitudProveedor.solicitud.nombre }}</td>
                  <td>
                    {{
                      item.solicitudProveedor.solicitud.nombreEstablecimiento
                    }}
                  </td>
                  <td>{{ item.solicitudProveedor.solicitud.direccion }}</td>
                  <td>{{ item.solicitudProveedor.solicitud.tipoCaso }}</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <div class="d-flex justify-content-center">
              <nav aria-label="Page navigation example" v-if="paginas">
                <ul class="pagination">
                  <li class="page-item" :disabled="pagina === 1">
                    <a class="page-link" @click="setPreviewPage">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li
                    v-for="page in paginas"
                    :key="page"
                    class="page-item"
                    :class="{ active: pagina === page }"
                  >
                    <a class="page-link" @click="setPage(page)">{{ page }}</a>
                  </li>
                  <li class="page-item" :disabled="pagina === paginas">
                    <a class="page-link" @click="setNextPage">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { saveExcel } from "@progress/kendo-vue-excel-export";
export default {
  name: "GestionSolicitudesCerradasTransporte",
  data() {
    return {
      mostrar: false,
      mensaje: "",
      tipoFiltro: "",
      pagina: 1,
      fechaInicio: '',
      fechaFin: '',
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  mounted() {
    this.consultar(1);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("solicitudesTransporte/consultarSolicitudesCerradasXPagina", {
          pagina,
        });
        await this.$store.dispatch("solicitudesTransporte/consultarTotalPaginas", {
          idBodegaVehiculo: this.usuarioBodega?.bodegaVehiculo?.id,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async consultarXFechas() {
      try {
        this.loaderSpinner();
        const data = {
          idBodegaVehiculo: this.usuarioBodega?.bodegaVehiculo?.id,
          fechaInicio: this.fechaInicio,
          fechaFin: this.fechaFin,
        };
        await this.$store.dispatch("solicitudesTransporte/consultarSolicitudesAtendidasXFechas", data);
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    setPage(page) {
      this.pagina = page;
    },
    setNextPage() {
      if (this.pagina < this.totalPaginas) {
        this.pagina = this.pagina + 1;
      }
    },
    setPreviewPage() {
      if (this.pagina > 1) {
        this.pagina = this.pagina - 1;
      }
    },
    exportExcel() {
      saveExcel({
        data: this.solicitudesCerradas,
        fileName: "requerimientos-atendidos",
        columns: [
          { field: "solicitudProveedor.solicitud.id", title: "Id" },
          { field: "solicitudProveedor.solicitud.numeroTicket", title: "Ticket" },
          { field: "solicitudProveedor.bodega.nombre", title: "Bodega" },
          { field: "solicitudProveedor.solicitud.codigoCliente", title: "Cliente" },
          { field: "solicitudProveedor.solicitud.tipoSolicitud", title: "Tipo" },
          { field: "solicitudProveedor.solicitud.fechaSolicitud", title: "Fecha solicitud" },
          { field: "solicitudProveedor.solicitud.diasTranscurridosLabolables", title: "Días Transcurridos" },
          { field: "solicitudProveedor.solicitud.gerenciaGeneral", title: "Gerencia general" },
          { field: "solicitudProveedor.solicitud.gerenciaVentas", title: "Gerencia ventas" },
          { field: "solicitudProveedor.solicitud.ecb", title: "ECB" },
          { field: "solicitudProveedor.solicitud.nombre", title: "Nombre" },
          { field: "solicitudProveedor.solicitud.nombreEstablecimiento", title: "Establecimiento" },
          { field: "solicitudProveedor.solicitud.direccion", title: "Dirección" },
          { field: "solicitudProveedor.solicitud.tipoCaso", title: "Tipo de caso" },
        ],
      });
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    rol() {
      return this.$store.state.auth.rol;
    },
    usuarioBodega() {
      return this.$store.getters["usuariosBodega/usuarioBodega"]
        ? this.$store.getters["usuariosBodega/usuarioBodega"]
        : {};
    },
    solicitudesCerradas() {
      return this.$store.getters["solicitudesTransporte/solicitudesCerradas"]
        ? this.$store.getters["solicitudesTransporte/solicitudesCerradas"]
        : [];
    },
    totalPaginas() {
      return this.$store.getters["solicitudesTransporte/totalPaginas"]
        ? this.$store.getters["solicitudesTransporte/totalPaginas"]
        : 0;
    },
    paginas() {
      let numeroPaginas = 10;
      numeroPaginas = Math.min(numeroPaginas, this.totalPaginas);
      let first = this.pagina - Math.floor(numeroPaginas / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.totalPaginas - numeroPaginas + 1);
      return [...Array(numeroPaginas)].map((k, i) => i + first);
    }
  },
};
</script>
<style></style>
