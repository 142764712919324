<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Agregar Variable</h3>
          </div>
          <div class="col-md-6"></div>
        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <Form
                  @submit="submit"
                  :validation-schema="formValidation"
                  v-slot="{ errors }"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="variable">Variable *</label>
                            <fieldset>
                              <Field
                                name="variable"
                                as="select"
                                class="form-control"
                                v-model="formData.variable"
                              >
                                <option value="" disabled>
                                  Seleccione una variable
                                </option>
                                <option
                                  v-for="item in variables"
                                  :key="item"
                                  :value="item"
                                >
                                  {{ item }}
                                </option>
                              </Field>
                              <MensajeValidacion :mensaje="errors.variable" />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="atributo">Atributo *</label>
                            <fieldset>
                              <Field
                                type="text"
                                name="atributo"
                                id="atributo"
                                class="form-control"
                                placeholder="Atributo"
                                v-model.trim="formData.atributo"
                              />
                              <MensajeValidacion :mensaje="errors.atributo" />
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage :mensaje="mensaje" v-if="error" />
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                        type="submit"
                        id="form-submit"
                        class="btn btn-primary btn-general mr-2"
                      >
                        <i class="ti-save"></i> Guardar
                      </button>
                      <button
                        type="button"
                        class="btn btn-secondary btn-general-off"
                        @click="cerrar"
                      >
                        <i class="ti-close"></i> Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";

export default {
  name: "VariableForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {
    const formValidation = {
      variable: "required",
      atributo: "required",
    };
    return {
      formData: {
        variable: "EXCLUSION_RETIRO",
        atributo: "",
      },
      error: false,
      mensaje: "",
      formValidation,
    };
  },
  async mounted() {
    await this.consultarVariables();
    this.formData.variable=this.$store.getters["variables/variable"]
        ? this.$store.getters["variables/variable"]
        : null;
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarVariables() {
      this.loaderSpinner();
      this.$store.commit("variables/setVariables", []);
      try {
        await this.$store.dispatch("variables/consultarTodos");
      } catch (error) {
        alert("No se pudo consultar las variables");
      } finally {
        this.loader.hide();
      }
    },
    async submit() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("variables/crear", this.formData);
        this.$store.commit("variables/setVariable", this.formData.variable);
        this.$router.push({ name: "Variables" });
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    variables() {
      return this.$store.getters["variables/variables"]
        ? this.$store.getters["variables/variables"]
        : [];
    },
  },
};
</script>
