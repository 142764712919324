<template>
  <div
    class="modal fade"
    id="modalExcepcion"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalExcepcionLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalExcepcionLabel">
            <strong>{{ titulo }}</strong>
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="method"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Observación</label>
                  <textarea
                    class="form-control"
                    id="observacion"
                    rows="3"
                    v-model="observacion"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-general btn-primary"
            data-dismiss="modal"
            @click="
              agregar({
                observacion,
              })
            "
          >
            <i class="ti-plus"></i> Agregar
          </button>
          <button
            type="button"
            class="btn btn-general-off btn-secondary"
            data-dismiss="modal"
            @click="cerrar"
          >
            <i class="ti-close"></i> Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cerrar: { type: Function },
    agregar: { type: Function },
    titulo: String,
  },
  data() {
    return {
      observacion: null,
    };
  },
};
</script>
