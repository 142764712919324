<template>
  <div
      class="col-md-12 grid-margin stretch-card grid-margin"
  >

    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Agregar Solicitud de Baja
            </h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">

                <Form @submit="submit"
                      :validation-schema="formValidation"
                      v-slot="{ errors }">
                  <div class="row">
                    <div class="col-lg-12">

                      <div class="row">

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="placa">{{ this.$config.labelPlaca }} *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="placa"
                                  id="placa"
                                  class="form-control"
                                  placeholder="Código de barras"
                                  v-model.trim="formData.placa"
                              />
                              <MensajeValidacion :mensaje="errors.placa"/>
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="placa">Motivo *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="motivo"
                                  id="motivo"
                                  class="form-control"
                                  placeholder="Motivo"
                                  v-model.trim="formData.motivo"
                              />
                              <MensajeValidacion :mensaje="errors.motivo"/>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <h4 class="text-black">Adjuntos</h4>
                    <hr>
                  </div>

                  <div class="col-md-12">
                    <CustomAdjuntos
                        tipoSolicitud="CREACION_BAJA"
                        :uploadFile="uploadFile"
                        columna="6"
                    />
                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <button
                          type="submit"
                          id="form-submit"
                          class="btn btn-primary btn-general mr-2"
                      >
                        <i class="ti-save"></i> Guardar
                      </button>
                      <button
                          type="button"
                          class="btn btn-secondary btn-general-off"
                          @click="cerrar"
                      >
                        <i class="ti-close"></i> Cerrar
                      </button>
                    </div>
                  </div>

                </Form>

              </div>
            </div>
          </div>
        </div>

        <ModalMessages :method="cerrarModal" :mensaje="mensaje" titulo="Error al crear la solicitud de baja"/>

      </div>
    </div>
  </div>

</template>
<script>


import {Field, Form} from "vee-validate";
import CustomAdjuntos from "@/components/CustomAdjuntos.vue";
import MensajeValidacion from "@/components/MensajeValidacion.vue";
import ModalMessages from "@/components/ModalMessages.vue";

export default {
  components: {ModalMessages, MensajeValidacion, CustomAdjuntos, Field, Form},
  data() {
    const formValidation = {
      placa: "required",
      motivo: "required"
    };
    return {
      formData: {
        placa: "",
        motivo: "",
        adjuntos: [],

      },
      archivosArray: [],
      formValidation,
      mensaje: []
    }
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  methods: {
    async submit() {
      this.loaderSpinner();
      try {
        const data = new FormData();
        for (const key in this.formData) {
          data.append(key, this.formData[key]);
        }
        this.archivosArray?.map((item) => data.append("adjuntos", item));
        let resp = await this.$store.dispatch("bajas/crearBaja", data);
        if (resp.success) {
          this.$router.push({name: "BajaSolicitadaTecnico"});
        } else {
          this.mensaje = resp.data;
          document.getElementById("modalButton").click();
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loader.hide();
      }
    },
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    uploadFile(file) {
      const selectedFile = file?.target?.files[0];
      if (!selectedFile) return;
      const findedFile = this.archivosArray?.findIndex(
          (item) => item.name === selectedFile.name
      );
      let arraySelectedFiles = [];
      if (findedFile === -1) {
        arraySelectedFiles = [...this.archivosArray, selectedFile];
      } else {
        arraySelectedFiles = [...this.archivosArray];
      }
      this.archivosArray = arraySelectedFiles;
    },
    procesar() {

    },

    cerrarModal() {
      if (this.ejecutarCerrar) {
        this.$router.go(-1);
      }
    },
    cerrar() {
      this.$store.dispatch("global/updateIsPreventNavigation", false);
      this.$router.go(-1);
    },
  }

}
</script>
