<template>
  <div
      class="modal fade"
      id="modalEditUbicacion"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalEditUbicacionLabel"
      aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalEditUbicacionLabel">
            <strong>{{ titulo }}</strong>
          </h5>
          <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="method"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div>

                <EditarUbicacionInfoEquipoEc v-if="$config.countryFlag==='EC'" :equipo-seleccionado="equipoSeleccionado"/>
                <EditarUbicacionInfoEquipoZona e-else :equipo-seleccionado="equipoSeleccionado"/>
                <p class="text-center mb-3">Seleccione la nueva ubicación física del equipo</p>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-check-inline mb-3">
                      <input
                          class="form-check-input"
                          type="radio"
                          id="cliente"
                          value="ENCI"
                          v-model="tipoUbicacion"
                          @click="seleccionarTipoUbicacion"
                      />
                      <label
                          class="form-check-label radio-margin"
                          for="cliente"
                      >
                        Cliente
                      </label>
                      <input
                          class="form-check-input"
                          type="radio"
                          id="bodega"
                          value="BODEGA"
                          v-model="tipoUbicacion"
                          @click="seleccionarTipoUbicacion"
                      />
                      <label class="form-check-label radio-margin" for="bodega">
                        Bodega
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group" v-if="mostrarCliente">
                  <label>Ingrese el Código</label>
                  <input
                      type="text"
                      class="form-control"
                      v-model="codigoCliente"
                  />
                </div>
                <div class="form-group" v-if="mostrarBodega">
                  <label>Seleccione una Bodega</label>
                  <select class="form-control" v-model="bodega">
                    <option value="" disabled></option>
                    <option
                        v-for="item in bodegas"
                        :key="item.id"
                        :value="item.id"
                    >
                      {{ item.nombre }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Seleccione el estado del equipo</label>
                  <CustomVariableList
                      ref="customVariableList"
                      variable="ESTADO_EQUIPO_BODEGA"
                      :selectedOptionValue="estadoUbicacion"
                      :selectVariable="handleSelectChange"
                      v-if="tipoUbicacion==='BODEGA'"
                  />
                  <CustomVariableList
                      ref="customVariableList"
                      variable="ESTADO_EQUIPO_CLIENTE"
                      :selectedOptionValue="estadoUbicacion"
                      :selectVariable="handleSelectChange"
                      v-else
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-primary btn-general"
              data-dismiss="modal"
              @click="handleUpdate"
          >
            <i class="ti-pencil"></i> Actualizar
          </button>
          <button
              type="button"
              class="btn btn-secondary btn-general-off"
              data-dismiss="modal"
              @click="cerrar"
          >
            <i class="ti-close"></i> Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomVariableList from "@/components/CustomVariableList.vue";
import EditarUbicacionInfoEquipoZona from "@/views/admin/equipos/components/EditarUbicacionInfoEquipoZona.vue";
import EditarUbicacionInfoEquipoEc from "@/views/admin/equipos/components/EditarUbicacionInfoEquipoEc.vue";

export default {
  props: {
    cerrar: {type: Function},
    titulo: String,
  },
  components: {
    EditarUbicacionInfoEquipoEc,
    EditarUbicacionInfoEquipoZona,
    CustomVariableList
  },
  data() {
    return {
      tipoUbicacion: null,
      codigoCliente: "",
      bodega: 0,
      ubicacion: "",
      mostrarCliente: false,
      mostrarBodega: false,
      estadoUbicacion: 0
    };
  },
  mounted() {
    this.consultarBodegas();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    handleSelectChange(estado) {
      this.estadoUbicacion = estado;
    },
    async handleUpdate() {
       this.loaderSpinner();
      try {
        await this.$store.dispatch("equipos/editarUbicacionEquipo", {
          data: {
            codigoCliente: this.codigoCliente,
            bodega: this.bodega,
            ubicacion: this.tipoUbicacion,
            estadoEquipo: this.estadoUbicacion,
          },
          id: this.equipoSeleccionado.id,
        });

        this.codigoCliente = '';
        this.$refs.customVariableList.limpiarCampos();
      } catch (error) {
        this.$swal("", error.message, "error");
      } finally {
        this.loader.hide();
      }
    },
    async consultarBodegas() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("bodegas/consultarTodas");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    seleccionarTipoUbicacion(e) {
      if (e.target.value === "ENCI") {
        this.mostrarCliente = true;
        this.mostrarBodega = false;
        this.bodega = 0;
        this.estadoUbicacion="";
        return;
      }
      if (e.target.value === "BODEGA") {
        this.mostrarCliente = false;
        this.mostrarBodega = true;
        this.codigoCliente = "";
        return;
      }
    },

  },
  computed: {
    equipoSeleccionado() {
      return this.$store.getters["equipos/equipoSeleccionado"]
          ? this.$store.getters["equipos/equipoSeleccionado"]
          : null;
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
          ? this.$store.getters["bodegas/bodegas"]
          : [];
    },
  },
  watch: {
    equipoSeleccionado(equipoAnterior, equipoNuevo) {
      if (equipoAnterior !== equipoNuevo) {
        this.tipoUbicacion = null;
        this.codigoCliente = "";
        this.bodega = 0;
        this.ubicacion = "";
        this.mostrarCliente = false;
        this.mostrarBodega = false;
        this.estadoUbicacion = "";

      }
    },
  },
};
</script>
