<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-3">
            <h3 class="font-weight-bold">Gestión de Eventos</h3>
          </div>
          <div class="col-md-6">
          
          </div>
        </div>
        
        <div class="white-content-general mb-4">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th ></th>
                  <th>Fecha de entrega</th>
                  <th>Hora de entrega</th>
                  <th>Fecha de retiro</th>
                  <th>Hora de retiro</th>
                  <th>Cliente</th>
                  <th>Nombre Cliente</th>
                  <th>Requiere permiso</th>
                  <th>Detalle permiso</th>
                  <th>Responsable</th>
                  <th>Gerencia General</th>
                  <th>Gerencia Ventas</th>
                  <th>Provincia</th>
                  <th>Sector</th>
                  <th >Dirección</th>
                  <th>Teléfono cliente</th>
                  <th>Nombre evento</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in eventos" :key="item.id">
                  <td v-if="item?.aprobado">
                    <a
                      class="mr-3"
                      href="javascript:void(0)"
                      @click="editar(item)"
                      v-if="item?.estadoEvento === 'GENERADO'"
                      ><i class="ti-pencil btn-icon-append"></i
                    ></a>
                    <a
                      href="javascript:void(0)"
                      @click="ver(item?.equiposFrios)"
                      v-if="
                        item?.estadoEvento === 'PROCESADO' ||
                        item?.estadoEvento === 'INSTALAR'
                      "
                      ><i class="ti-search btn-icon-append"></i
                    ></a>
                  </td>
                  <td v-else></td>
                  <td v-if="item?.aprobado">

                    <button
                      type="button"
                      :class="item?.asignado ? 'btn btn-success btn-sm mr-1' : 'btn btn-secondary btn-sm mr-1'"
                      v-if="item?.estadoEvento === 'PROCESADO'"
                      data-toggle="modal"
                      data-target="#modalAsignarBodegaTecnico"
                      data-backdrop="static"
                      data-keyboard="false"
                      @click="asignarInstalacion(item)"
                      :disabled="item?.asignado"
                    >
                      Asignar Instalación
                    </button>
                    <button
                      type="button"
                      :class="item?.asignado ? 'btn btn-success btn-sm' : 'btn btn-secondary btn-sm'"
                      v-if="item?.estadoEvento === 'INSTALAR'"
                      data-toggle="modal"
                      data-target="#modalAsignarBodegaTecnico"
                      data-backdrop="static"
                      data-keyboard="false"
                      @click="asignarRetiro(item)"
                      :disabled="item?.asignado"
                    >
                      Asignar Retiro
                    </button>
                  </td>
                  <td v-else><button
                      type="button"
                      class="btn btn-warning btn-sm"
                      disabled
                    >
                      <span class="text-white">Pendiente de aprobación</span>
                    </button></td>
                  <td>{{ item.fechaEntrega }}</td>
                  <td>{{ item.horaEntrega }}</td>
                  <td>{{ item.fechaRetiro }}</td>
                  <td>{{ item.horaRetiro }}</td>
                  <td>{{ item.codigoCliente }}</td>
                  <td>{{ item.nombreCliente }}</td>
                  <td>{{ item.requierePermiso }}</td>
                  <td>{{ item.detallePermiso }}</td>
                  <td>{{ item.responsable }}</td>
                  <td>{{ item.gerenciaGeneral }}</td>
                  <td>{{ item.gerenciaVentas }}</td>
                  <td>{{ item.provincia }}</td>
                  <td>{{ item.sectorBarrio }}</td>
                  <td width="100px">{{ item.direccion }}</td>
                  <td>{{ item.telefono }}</td>
                  <td>{{ item.nombreEvento }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <ListaEquiposEvento titulo="Lista de equipos" :listaEquipos="equipos" />
        <button
          id="equiposId"
          type="button"
          data-toggle="modal"
          data-target="#modalListaEquipos"
          v-show="false"
        ></button>
        <ProcesarEvento
          titulo="Lista de equipos"
          :listaEquipos="equipos"
          :procesar="tipo === 'INSTALAR' ? procesarInstalar : procesarRetirar"
        />
        <button
          id="eventosId"
          type="button"
          data-toggle="modal"
          data-target="#modalEventos"
          v-show="false"
        ></button>
        <AsignarBodegaTecnico
          titulo="Asignar Técnico"
          :asignar="asignar"
          :tipo="tipo"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AsignarBodegaTecnico from "./AsignarBodegaTecnico.vue";
import ListaEquiposEvento from "./ListaEquiposEvento.vue";
import ProcesarEvento from "./ProcesarEvento.vue";
export default {
  name: "GestionEventosPendientes",
  data() {
    return {
      equipos: [],
      idEventoSeleccionado: null,
      tipo: "",
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("eventos/consultarEventosXProveedor");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      await this.$store.commit("eventos/setEventoSeleccionado", null);
      this.$router.push({ name: "EventoForm" });
    },
    async editar(evento) {
      await this.$store.commit("eventos/setEventoSeleccionado", evento);
      this.$router.push({ name: "AgregarEquipos" });
    },
    async instalar(evento) {
      this.idEventoSeleccionado = evento?.id;
      this.tipo = "INSTALAR";
      this.equipos = evento?.equiposFrios;
      document.getElementById("eventosId").click();
    },
    async procesarInstalar(data) {
      this.loaderSpinner();
      const formData = new FormData();
      data?.archivosArray?.map((item) => formData.append("file", item));
      formData.append("idEvento", this.idEventoSeleccionado);
      formData.append("estadoEvento", data?.estadoEvento);
      formData.append("observacion", data?.observacion);
      try {
        await this.$store.dispatch("eventos/procesarEventoInstalar", {
          data: formData,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async retirar(evento) {
      this.idEventoSeleccionado = evento?.id;
      this.tipo = "RETIRAR";
      this.equipos = evento?.equiposFrios;
      document.getElementById("eventosId").click();
    },
    async procesarRetirar(data) {
      this.loaderSpinner();
      const formData = new FormData();
      data?.archivosArray?.map((item) => formData.append("file", item));
      formData.append("idEvento", this.idEventoSeleccionado);
      formData.append("estadoEvento", data?.estadoEvento);
      formData.append("observacion", data?.observacion);
      try {
        await this.$store.dispatch("eventos/procesarEventoRetirar", {
          data: formData,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    ver(equipos) {
      this.equipos = equipos;
      document.getElementById("equiposId").click();
    },
    asignarInstalacion(evento) {
      this.tipo = "INSTALAR";
      this.$store.commit("eventos/setEventoSeleccionado", evento);
    },
    asignarRetiro(evento) {
      this.tipo = "RETIRO";
      this.$store.commit("eventos/setEventoSeleccionado", evento);
    },
    async asignar(data) {
      try {
        await this.$store.dispatch("eventos/asignarTecnico", {
          evento: this.eventoSeleccionado?.id,
          bodegaTecnico: data?.tecnico,
          tipoAsignacion: this.tipo
        });
        this.consultar();
      } catch (error) {
        alert("No se pudo asignar el técnico");
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    eventos() {
      return this.$store.getters["eventos/eventos"]
        ? this.$store.getters["eventos/eventos"]
        : [];
    },
    eventoSeleccionado() {
      return this.$store.getters["eventos/eventoSeleccionado"]
        ? this.$store.getters["eventos/eventoSeleccionado"]
        : [];
    },
  },
  components: { ListaEquiposEvento, ProcesarEvento, AsignarBodegaTecnico },
};
</script>
<style></style>
