<template>
  <div
      class="col-md-12 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Agregar requerimiento
            </h3>
          </div>
          <div class="col-md-6">

          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <Form
                    @submit="submit"
                    :validation-schema="formValidation"
                    v-slot="{ errors }"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="codigo">{{ $config.labelCodigoCliente }} *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="codigo"
                                  id="codigo"
                                  class="form-control"
                                  :placeholder="$config.labelCodigoCliente"
                                  v-model.trim="formData.codigo"
                                  @blur="consultarCliente"
                              />
                              <MensajeValidacion :mensaje="errors.codigo"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="identificacion"
                            >{{ $config.labelIdentificacionCliente }} *</label
                            >
                            <fieldset>
                              <Field
                                  disabled="true"
                                  type="text"
                                  name="identificacion"
                                  class="form-control"
                                  :placeholder="$config.labelIdentificacionCliente"
                                  v-model.trim="formData.identificacion"
                              />
                              <MensajeValidacion
                                  :mensaje="errors.identificacion"
                              />
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="telefono">{{ $config.labelTelefono }} *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="telefono"
                                  class="form-control"
                                  :placeholder="$config.labelTelefono"
                                  v-model.trim="formData.telefono"
                              />
                              <MensajeValidacion :mensaje="errors.telefono"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="solicitante">Solicitante *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="solicitante"
                                  class="form-control"
                                  placeholder="Solicitante"
                                  v-model.trim="formData.solicitante"
                              />
                              <MensajeValidacion :mensaje="errors.solicitante"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="placa">{{ $config.labelPlaca }} *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="placa"
                                  class="form-control"
                                  :placeholder="$config.labelPlaca"
                                  v-model.trim="formData.placa"
                                  @keyup="validarPlaca"
                              />
                              <MensajeValidacion :mensaje="mensajeErrorPlaca"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">

                          <div v-if="$config.countryFlag==='EC'" class="form-group">
                            <label for="tipo">{{ $config.labelTipo }} *</label>
                            <fieldset>
                              <Field
                                  name="tipo"
                                  as="select"
                                  class="form-control"
                                  v-model="formData.tipo"
                              >
                                <option value="" disabled>
                                  Seleccione un tipo
                                </option>
                                <option value="RETIRO">RETIRO</option>
                                <option value="SOLICITUD">SOLICITUD</option>
                                <option value="MANTENIMIENTO">
                                  MANTENIMIENTO
                                </option>
                                <option value="PERDIDO">PERDIDO</option>
                                <option value="ENCONTRADO">ENCONTRADO</option>
                                <option value="CAMBIO">CAMBIO</option>
                              </Field>
                              <MensajeValidacion :mensaje="errors.tipo"/>
                            </fieldset>
                          </div>

                          <div v-else class="form-group">
                            <label for="tipo">{{ $config.labelTipo }} *</label>
                            <fieldset>
                              <Field
                                  name="tipo"
                                  as="select"
                                  class="form-control"
                                  v-model="formData.tipo"
                                  @change="validarPlaca"
                              >
                                <option value="" disabled>
                                  Seleccione un tipo
                                </option>
                                <option value="CAMBIO">CAMBIO</option>
                                <option value="INSTALACION">INSTALACION</option>
                                <option value="MANTENIMIENTO">
                                  MANTENIMIENTO
                                </option>
                                <option value="PRIMERA_POSICION">PRIMERA_POSICION</option>
                                <option value="RETIRO">RETIRO</option>
                              </Field>
                              <MensajeValidacion :mensaje="errors.tipo"/>
                            </fieldset>
                          </div>


                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="cantidad">Cantidad *</label>
                            <fieldset>
                              <Field
                                  type="number"
                                  name="cantidad"
                                  class="form-control"
                                  placeholder="Cantidad"
                                  v-model.trim="formData.cantidad"
                              />
                              <MensajeValidacion :mensaje="errors.cantidad"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="capacidad">Capacidad *</label>
                            <fieldset>
                              <Field
                                  type="number"
                                  name="capacidad"
                                  class="form-control"
                                  placeholder="Capacidad"
                                  v-model.trim="formData.capacidad"
                              />
                              <MensajeValidacion :mensaje="errors.capacidad"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="fecha">{{ $config.labelFechaSolicitud }} *</label>
                            <fieldset>
                              <Field
                                  type="date"
                                  name="fecha"
                                  class="form-control"
                                  placeholder="Fecha"
                                  v-model.trim="formData.fecha"
                              />
                              <MensajeValidacion :mensaje="errors.fecha"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">


                          <div v-if="$config.countryFlag==='EC'" class="form-group">
                            <label for="tipoCaso">{{ $config.labelTipoCaso }} *</label>
                            <fieldset>
                              <Field
                                  name="tipoCaso"
                                  as="select"
                                  class="form-control"
                                  v-model="formData.tipoCaso"
                              >
                                <option value="" disabled>
                                  Seleccione un tipo
                                </option>
                                <option value="CHATBOT">CHATBOT</option>
                                <option value="BEESCARE">BEESCARE</option>
                                <option value="COOL INVENTORY">
                                  COOL INVENTORY
                                </option>
                                <option value="COOL INVENTORY BOT">COOL INVENTORY BOT</option>
                                <option value="SANITIZACION">SANITIZACION</option>
                                <option value="PREDICTIVO">PREDICTIVO</option>
                                <option value="PREVENTIVO">PREVENTIVO</option>
                                <option value="QUEJA">QUEJA</option>
                              </Field>
                              <MensajeValidacion :mensaje="errors.tipoCaso"/>
                            </fieldset>
                          </div>

                          <div v-else class="form-group">
                            <label for="tipoCaso">{{ $config.labelTipoCaso }} *</label>
                            <fieldset>
                              <Field
                                  name="tipoCaso"
                                  as="select"
                                  class="form-control"
                                  v-model="formData.tipoCaso"
                              >
                                <option value="" disabled>
                                  Seleccione un tipo
                                </option>
                                <option value="BEESCARE">BEESCARE</option>
                                <option value="COOL INVENTORY">
                                  COOL INVENTORY
                                </option>
                                <option value="BEES FORCE">
                                  BEES FORCE
                                </option>
                                <option value="EXCEPCIONES">
                                  EXCEPCIONES
                                </option>
                                <option value="CHATBOT">
                                  CHATBOT
                                </option>
                              </Field>
                              <MensajeValidacion :mensaje="errors.tipoCaso"/>
                            </fieldset>
                          </div>


                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="ticket">{{ $config.labelTicket }}</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="ticket"
                                  class="form-control"
                                  :placeholder="$config.labelTicket"
                                  v-model.trim="formData.ticket"
                              />
                              <MensajeValidacion :mensaje="errors.ticket"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="motivo">Motivo</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="motivo"
                                  class="form-control"
                                  placeholder="Motivo"
                                  v-model.trim="formData.motivo"
                              />
                              <MensajeValidacion :mensaje="errors.motivo"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="franjaHoraria">Franja horaria</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="franjaHoraria"
                                  class="form-control"
                                  placeholder="Franja horaria"
                                  v-model.trim="formData.franjaHoraria"
                              />
                              <MensajeValidacion :mensaje="errors.franjaHoraria"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="referencia">Referencia</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="referencia"
                                  class="form-control"
                                  placeholder="Referencia"
                                  v-model.trim="formData.referencia"
                              />
                              <MensajeValidacion :mensaje="errors.referencia"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <label for="formFile" class="form-label"
                          >Cargar foto *</label
                          >
                          <div class="input-group mb-3">
                            <div class="custom-file">
                              <input
                                  class="form-control custom-file-input"
                                  type="file"
                                  id="formFile"
                                  ref="file"
                                  @change="uploadFile"
                              />
                              <label class="custom-file-label" for="formFile">Archivo</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="archivosArray.length>0">
                          <div class="mb-3">
                            <label for="formFile" class="form-label"
                            >Lista de archivos</label
                            >
                            <ul>
                              <li
                                  v-for="(archivo, $index) in archivosArray"
                                  :key="$index"
                              >
                                {{ archivo?.name }}
                                <a
                                    href="javascript:void(0)"
                                    class="text-danger"
                                    @click="eliminarArchivo(archivo)"
                                ><i class="ti-close"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage :mensaje="mensaje" v-if="error"/>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-12">
                      <label for="exampleFormControlTextarea1">Observación</label>
                      <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          v-model.trim="formData.observacion"
                      ></textarea>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                          type="submit"
                          id="form-submit"
                          class="btn btn-primary btn-general mr-2"
                          :disabled="!archivosArray.length>0"
                      >

                        <i class="ti-save"></i> Guardar
                      </button>
                      <button
                          type="button"
                          class="btn btn-secondary btn-general-off"
                          @click="cerrar"
                      >
                        <i class="ti-close"></i> Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Field, Form} from "vee-validate";
import MensajeValidacion from "../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../components/ErrorMessage.vue";

const formValidation = {
  codigo: "required",
  identificacion: "required",
  telefono: "required",
  solicitante: "required",
  tipo: "required",
  cantidad: "required",
  fecha: "required",
  tipoCaso: "required",
  capacidad: "required",
};

export default {
  name: "SolicitudForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {
    return {
      formData: {
        codigo: "",
        identificacion: "",
        telefono: "",
        solicitante: "",
        placa: "",
        tipo: "",
        cantidad: "",
        motivo: "",
        ticket: "",
        fecha: "",
        tipoCaso: "",
        observacion: "",
        franjaHoraria: "",
        referencia: ""
      },
      error: false,
      mensaje: "",
      formValidation,
      archivo: null,
      archivosArray: [],
      gerenciaGeneral: "",
      gerenciaVentas: "",
      mensajeErrorPlaca: ""
    };
  },
  created() {
    this.$store.dispatch("global/updateIsPreventNavigation", true);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarCliente() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("rutero/consultarRuteroXCliente", {
          codigoCliente: this.formData?.codigo,
        });
        this.formData.identificacion = this.cliente?.pocNif;
        this.formData.telefono = this.cliente?.pocTelefono;
        this.gerenciaGeneral = this.cliente?.direccionVentas ?? "";
        this.gerenciaVentas = this.cliente?.gerenciaVentas ?? "";
      } catch (error) {
        this.formData.identificacion = "";
        this.formData.telefono = "";
      } finally {
        this.loader.hide();
      }
    },
    uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
      this.archivosArray.push(this?.$refs?.file?.files[0]);
    },
    async submit() {

      if (!this.validarPlaca()) {
        return;
      }
      let fechaFormateada = this.formatDate(this.formData.fecha);
      const data = new FormData();
      this.archivosArray?.map((item) => data.append("file", item));
      data.append("codigo", this.formData.codigo);
      data.append("identificacion", this.formData.identificacion);
      data.append("telefono", this.formData.telefono);
      data.append("solicitante", this.formData.solicitante);
      data.append("placa", this.formData.placa);
      data.append("tipo", this.formData.tipo);
      data.append("cantidad", this.formData.cantidad);
      data.append("capacidad", this.formData.capacidad);
      data.append("motivo", this.formData.motivo);
      data.append("ticket", this.formData.ticket);
      data.append("fecha", fechaFormateada);
      data.append("tipoCaso", this.formData.tipoCaso);
      data.append("observacion", this.formData.observacion);
      data.append("gerenciaGeneral", this.gerenciaGeneral);
      data.append("gerenciaVentas", this.gerenciaVentas);
      data.append("franjaHoraria", this.formData.franjaHoraria);
      data.append("referencia", this.formData.referencia);
      this.loaderSpinner();
      try {
        await this.$store.dispatch("solicitudes/crearSolicitud", data);
        this.$router.push({name: "Solicitudes"});
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.$store.dispatch("global/updateIsPreventNavigation", false);
        this.loader.hide();
      }
    },
    cerrar() {
      this.$store.dispatch("global/updateIsPreventNavigation", false);
      this.$router.go(-1);
    },
    eliminarArchivo(archivo) {
      this.archivosArray = this.archivosArray?.filter(item => item !== archivo);
    },

    validarPlaca() {
      if (this.formData.tipo === "MANTENIMIENTO" && !this.formData.placa) {
        this.mensajeErrorPlaca = "El campo es requerido";
        return false;
      }
      this.mensajeErrorPlaca = "";
      return true;
    },
    formatDate(dateString) {
      const [year, month, day] = dateString.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      const dayFormatted = String(date.getDate()).padStart(2, '0');
      const monthFormatted = String(date.getMonth() + 1).padStart(2, '0');
      const yearFormatted = date.getFullYear();
      return `${dayFormatted}/${monthFormatted}/${yearFormatted}`;
    },
  },
  computed: {
    cliente() {
      return this.$store.getters["rutero/cliente"]
          ? this.$store.getters["rutero/cliente"]
          : null;
    },
  },
  mounted() {
    const today = new Date();
    today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
    this.formData.fecha = today.toISOString().split('T')[0];
  }
};
</script>
<style>
</style>
