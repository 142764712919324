import axios from "axios";

const state = {
    traslados: null,
    totalPaginas: null,
    trasladosPendientes:null
};

const getters = {
    traslados: (state) => state.traslados,
    trasladosPendientes: (state) => state.trasladosPendientes,
    totalPaginas: (state) => state.totalPaginas,
};

const mutations = {
    setTraslados: (state, traslados) => {
        state.traslados = traslados;
    },
    setTrasladosPendientes: (state, trasladosPendientes) => {
        state.trasladosPendientes = trasladosPendientes;
    },

    setTotalPaginas: (state, data) => {
        state.totalPaginas = data;
    },
};

const actions = {

    async consultarTraslados(_,data) {
        try {
            let resp = await axios.get(`/v1/traslados/${data}/detalle`);
            return {
                success: true,
                data: resp.data,
            }
        } catch (error) {
            return {
                success: false,
                data: error.response.data.errors
            }
        }
    },
    async consultarTrasladosPagina({commit}, data) {
        try {
            let res = await axios.get(`/v1/traslados/pagina/${data.pagina}`);
            commit("setTraslados", res?.data?.result);
        } catch (error) {
            return error;
        }
    },
    async consultarTrasladosTotalPagina({commit}) {
        try {
            let res = await axios.get(`/v1/traslados/total`);
            commit("setTotalPaginas", res?.data);
        } catch (error) {
            return error;
        }
    },
    async cargarArchivo({commit}, data) {
        try {
            const headers = { 'Content-Type': 'multipart/form-data' };
            await axios.post(`/v1/traslados/upload`, data, { headers });
            commit("setMensaje", "Se ha cargado con éxito el archivo");
            return "success"
        } catch (error) {
            console.log(error);
            return "error";
        }
    },

    async anular(_, data) {
        try {
            let resp = await axios.delete(`/v1/traslados/${data.id}/anular`);
            return {
                success: true,
                data: resp.data,
            }
        } catch (error) {
            return {
                success: false,
                data: error.response.data.errors
            }
        }
    },

    async consultarTrasladosPendientes({commit}) {
        try {
            let resp = await axios.get(`/v1/traslados/pendientes`);
            commit("setTrasladosPendientes", resp.data.result);
            return {
                success: true,
                data: resp.data,
            }
        } catch (error) {
            return {
                success: false,
                data: error.response.data.errors
            }
        }
    },

}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
