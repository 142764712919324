<template>
  <div class="container-scroller bg-cool">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="brand-logo">
              <img
                  src="../../assets/images/imgs/logo-fondo-marino.png"
                  alt="logo"
                  class="d-block"
              />
            </div>
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <h4 class="font-weight-bold-blue">Bienvenido</h4>
              <h6 class="font-weight-light">Ingrese sus credenciales.</h6>
              <form @submit.prevent="login" class="pt-3">
                <div class="form-group mb-3">
                  <input
                      type="text"
                      v-model="formData.usuario"
                      class="form-control form-control input-coolers"
                      id="exampleInputEmail1"
                      placeholder="Username"
                  />
                </div>


                <div class="form-group mb-3">
                  <input
                      type="text"
                      v-model="formData.targetUsername"
                      class="form-control form-control input-coolers"
                      id="targetUsername"
                      placeholder="Usuario Target"
                  />
                </div>

                <div class="form-group mb-3">
                  <input
                      type="password"
                      v-model="formData.clave"
                      class="form-control form-control input-coolers"
                      id="exampleInputPassword1"
                      placeholder="Password"
                  />
                </div>
                <p class="text-danger text-center" v-if="error">
                  Revise el usuario o contraseña
                </p>


                <div class="mb-3">
                  <button
                      class="btn btn-block btn-primary btn font-weight-medium auth-form-btn"
                      type="submit"
                  >
                    Iniciar Sesión
                  </button>
                </div>
                <div
                    class=" text-center"
                >
                  <div class="form-check"></div>
                  <a href="#" class="text-black font-weight-light">¿Olvidó su contraseña?</a>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>
<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      formData: {},
      error: false,
    };
  },
  methods: {
    async login() {
      this.error = false;
      try {
        const resp = await this.$store.dispatch("auth/loginAs", this.formData);
        const { error } = resp;
        if (error) {
          this.error = true;
          this.$router.push({ name: "Login" });
          return;
        }
        const ruta=await this.$store.dispatch("auth/buscarRuta");
        if(this.cambiarClave){
          this.$router.push("/cambiar-clave");
          return;
        }
        if(ruta.home){
          this.$router.replace({ name: ruta.home });
        }else{
          this.$router.replace({ name: "Welcome" });
        }

      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    cambiarClave() {
      return this.$store.getters["auth/cambiarClave"]
          ? this.$store.getters["auth/cambiarClave"]
          : null;
    },
  }
};
</script>
