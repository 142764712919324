<template>
  <div class="white-content-table">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
        <tr>
          <th>Acciones</th>
          <th class="cabecera" v-for="(col, index) in columns" :key="index">{{ col.nombre }}</th>
        </tr>
        <tr>
          <td></td>
          <td v-for="(col, index) in columns" :key="index">
            <div>
              <Field
                  name="col.campo"
                  as="select"
                  class="form-control custom-select"
                  v-model.trim="filters[col.campo]"
                  @change="filterData"
                  v-if="col.tipo === 'String' || col.tipo === 'Array'"
              >
                <option value="">Todos</option>
                <option
                    v-if="hasEmptyValues[col.campo]"
                    value="__VACIO__"
                >Vacío
                </option>
                <option v-for="(option, idx) in uniqueValues[col.campo]" :key="idx" :value="option">
                  {{ option }}
                </option>
              </Field>
              <Field
                  v-if="col.tipo === 'Text'"
                  name="col.campo"
                  as="input"
                  type="text"
                  class="form-control"
                  v-model.trim="filters[col.campo]"
                  @keyup="filterData"
                  placeholder="Buscar..."
              />


            </div>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row, index) in filteredData" :key="index">
          <td>
            <div class="icon-row">
              <div v-for="opcion in opciones" :key="opcion">
                <a
                    v-if="rol === 'ROLE_ADMIN' || opcion==='ubicacion'"
                    href="javascript:void(0)"
                    @click="handleUbicacion(row)"
                    data-toggle="modal"
                    data-target="#modalEditUbicacion"
                    data-backdrop="static"
                    data-keyboard="false"

                ><i class="ti-location-pin" title="Actualizar Ubicación"></i></a
                ><a
                  href="javascript:void(0)"
                  @click="handleEdit(row)"
                  v-if="opcion==='editar'"
              ><i class="ti-pencil btn-icon-append" title="Editar"></i></a
              >
                <a
                    href="javascript:void(0)"
                    @click="handleDelete(row)"
                    v-if="opcion==='eliminar'"
                ><i class="ti-trash txt-rojo btn-icon-append" title="Eliminar"></i
                ></a>
                <a
                    href="javascript:void(0)"
                    @click="handleReset(row)"
                    v-if="opcion==='resetear'"
                ><i class="ti-lock btn-icon-append" title="Resetear"></i
                ></a>
                <a
                    href="javascript:void(0)"
                    @click="handleMovimiento(row.id)"
                    data-toggle="modal"
                    data-target="#modalMovimientos"
                    v-if="opcion==='movimientos'"
                ><i class="ti-exchange-vertical" title="Histórico Movimientos"></i
                ></a>

                <a
                    href="javascript:void(0)"
                    @click="handleAdjuntos(row)"
                    v-if="opcion==='adjuntos'"
                >
                  <FileDownloadMultiple title="File" icon="ti-clip" :files="row.adjuntos"/>
                </a>

              </div>
            </div>
          </td>
          <td v-for="col in columns" :key="col.campo">
            <div v-if="col.campo==='estadoEquipo'" class="badge" :class="
                  row[col.campo] === 'DISPONIBLE'
                    ? 'badge-success'
                    : 'badge-secondary'
                ">{{ row[col.campo] }}
            </div>
            <template v-else>
              {{ row[col.campo] }}
            </template>
          </td>
        </tr>
        </tbody>
      </table>
      <paginacion :updatePage="updatePage" v-if="paginacion"/>
    </div>
  </div>
</template>

<script>
import {Field} from "vee-validate";
import Paginacion from "@/components/Paginacion.vue";
import FileDownloadMultiple from "@/components/FileDownloadMultiple.vue";

export default {

  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    showIcono: {
      type: Boolean,
      default: false
    },
    showIconoEquipo: {
      type: Boolean,
      default: false
    },
    rol: {
      type: String,
      required: false,
    },
    opciones: {
      type: Array,
      required: true
    },
    paginacion: {
      type: Boolean,
      required: true
    }
  },
  components: {
    FileDownloadMultiple,

    Field,
    Paginacion
  },
  data() {
    return {
      filters: {},
      uniqueValues: {},
      filteredData: this.data,
      hasEmptyValues: {},
    };
  },
  watch: {
    data: {
      handler() {
        this.initializeFilters();
      },
      immediate: true
    }
  },
  methods: {
    initializeFilters() {
      this.uniqueValues = {};
      this.filters = {};
      if (!this.data || this.data.length === 0) {
        this.filteredData = [];
        return;
      }
      this.columns.forEach(col => {
        const allValues = this.data.map(row => row?.[col.campo]);
        const uniqueValues = [...new Set(allValues)].filter(value => value !== null && value !== undefined && value !== '');
        this.uniqueValues[col.campo] = uniqueValues;
        this.hasEmptyValues[col.campo] = allValues.some(value => value === null || value === undefined || value === '');
        this.filters[col.campo] = '';
      });
      this.filterData();
    },
    filterData() {

      const allFiltersEmpty = Object.values(this.filters).every(filter => filter === '');
      if (allFiltersEmpty) {
        this.filteredData = [...this.data];
        return;
      }

      this.filteredData = this.data.filter(row => {
        return this.columns.every(col => {
          const filterValue = this.filters[col.campo];
          const cellValue = row?.[col.campo];

          if (!filterValue) return true;

          if (filterValue === "__VACIO__") {
            return cellValue === null || cellValue === undefined || cellValue === '';
          }

          if (Array.isArray(cellValue)) {
            return cellValue.includes(filterValue);
          }else if (col.tipo === 'Text') {
            if(cellValue!==null){
              return cellValue.toString().toLowerCase().includes(filterValue.toString().toLowerCase());
            }
          } else {
            return cellValue === filterValue;
          }
        });
      });
    },
    handleEdit(row) {
      this.$emit('edit', row);
    },
    handleDelete(row) {
      this.$emit('delete', row.id);
    },
    handleReset(row) {
      this.$emit('reset', row.usuario);
    },
    handleUbicacion(row) {
      this.$emit('ubicacion', row);
      this.$store.commit("equipos/setEquipoSeleccionado", row);
    },
    handleMovimiento(id) {
      this.$emit('movimiento', id);
    },
    handleAdjuntos(row) {
      this.$emit('adjuntos', row.id);
    },
    updatePage(page) {
      this.$emit('updatePagina', page);
    },

  }
};
</script>

<style>
.custom-select {
  display: inline-block;
  width: 100%;
  padding: 0 0 0 5px;
  font-size: smaller;
  line-height: 1.5;
  vertical-align: middle;
}

.custom-select:hover {
  background-color: #ededed !important;
  color: #000;
  border: 1px solid #ededed !important;
}

.custom-select option:checked {
  background-color: #ededed !important;
  color: #000;
  border: 1px solid #ededed !important;
}

.icon-row {
  display: flex; /* Flexbox layout */
  gap: 5px; /* Espaciado entre los íconos */
}

.icon-row a {
  display: inline-flex; /* Para alinear el contenido del <a> correctamente */
  align-items: center; /* Centra verticalmente el contenido dentro del <a> */
}

.icon-row i {
  margin: 0 1px; /* Opcional: Espaciado alrededor de los íconos */
}

.table thead th, .jsgrid .jsgrid-table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  font-weight: 600;
  font-size: .875rem;
  color: #003FA1;
  min-width: 150px;
}
</style>
