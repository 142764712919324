import axios from "axios";

const state = {
  bodegasGerencias: null,
  bodegaGerenciaSeleccionada: null,
};

const getters = {
  bodegasGerencias: (state) => state.bodegasGerencias,
  bodegaGerenciaSeleccionada: (state) => state.bodegaGerenciaSeleccionada,
};

const mutations = {
  setBodegas: (state, bodegasGerencias) => {
    state.bodegasGerencias = bodegasGerencias;
  },
  setBodegaGerenciaSeleccionada: (state, bodegaGerenciaSeleccionada) => {
    state.bodegaGerenciaSeleccionada = bodegaGerenciaSeleccionada;
  },
  inicializar: (state) => {
    (state.bodegasGerencias = null), (state.bodegaGerenciaSeleccionada = null);
  },
};

const actions = {
  async crear({ commit, state }, data) {
    try {
      let res = await axios.post(`/v1/bodega-gerencia`, data);
      let nuevo = res?.data?.result;
      let actualizados = [...state.bodegasGerencias, nuevo];
      commit("setBodegas", actualizados);
    } catch (error) {
      return error?.response?.data?.errors ? {
        error: true,
        detalle: error?.response?.data?.errors
      } : {
        error: true,
        detalle: ["No se pudo guardar la información"]
      } ;
    }
  },
  async editar({ commit, state }, data) {
    try {
      let res = await axios.put(`/v1/bodega-gerencia/${data.id}`, data.data);
      let copia = state.bodegasGerencias;
      let actualizado = copia.find(
        (item) => item.id === res?.data?.result?.id
      );
      let index = copia.indexOf(actualizado);
      copia.splice(index, 1, res?.data);
      commit("setBodegas", copia);
    } catch (error) {
      return error?.response?.data?.errors ? {
        error: true,
        detalle: error?.response?.data?.errors
      } : {
        error: true,
        detalle: ["No se pudo guardar la información"]
      } ;
    }
  },

  async consultarGerenciaXBodega({ commit }, data) {
    try {
    const res = await axios.get(`/v1/bodega-gerencia/${data?.idBodega}/bodega`);
      commit("setBodegas", res?.data?.result);
    } catch (error) {
      commit("setBodegas", []);
      return error;
    }
  },
  async eliminar({ commit, state }, data) {
    try {
      let res = await axios.delete(`/v1/bodega-gerencia/${data.id}`);
      let copia = state.bodegasGerencias;
      const filtrados = copia.filter(
        (item) => item.id !== res?.data?.result?.id
      );
      commit("setBodegas", filtrados);
    } catch (error) {
      commit("setBodegas", []);
      return error;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
