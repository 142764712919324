<template>
  <div class="col-md-12">
    <div class="mt-5">

      <div>
        <div class="form-group">
          <label for="anio">Año:</label>
          <select @change="consultar" class="form-control" v-model="selectedAnio">
            <option v-for="anio in anios" :key="anio" :value="anio">{{ anio }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="mes">Mes:</label>
          <select @change="consultar" class="form-control" v-model="selectedMes">
            <option value="Todos">Todos</option>
            <option v-for="mes in meses" :key="mes" :value="mes">{{ mes }}</option>
          </select>
        </div>
      </div>

      <div class="white-content-table">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Bodega</th>
              <th>PENDIENTE</th>
              <th>EJECUTADO</th>
              <th>FALLIDO</th>
              <th>EN_RUTA</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, bodega) in datos" :key="bodega">
              <td>{{ item.bodega }}</td>
              <td>{{ item.pendiente }}</td>
              <td>{{ item.ejecutado }}</td>
              <td>{{ item.fallido }}</td>
              <td>{{ item.enRuta }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="charts-container">
          <div v-for="(item, datos) in datos" :key="datos">
            <PieChart :chart-data="item" :title="item.bodega"/>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>


import PieChart from "@/views/reportes/components/PieCharts.vue";

export default {
  components: {PieChart},
  data() {
    const currentYear = new Date().getFullYear();
    return {
      selectedMes: "Todos",
      selectedAnio: currentYear,
      meses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      anios: [currentYear - 2, currentYear - 1, currentYear],
      datos: []
    };
  },
  mounted() {
    this.consultar()
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        let condiciones;
        if (this.selectedMes === 'Todos') {
          condiciones = {
            anio: this.selectedAnio,
            mes: 0
          }
        } else {
          condiciones = {
            anio: this.selectedAnio,
            mes: this.selectedMes
          }
        }

        const resp = await this.$store.dispatch("reportes/consultar", condiciones);
        this.datos = resp?.data?.result
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
  }
};
</script>
<style scoped>
.charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

</style>
