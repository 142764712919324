import axios from "axios";
import store from "../index";

const state = {
    bajasSolicitadas: null,
    mensaje: ""
};

const getters = {
    bajas: (state) => state.bajasSolicitadas,
};

const mutations = {
    setBajasSolicitadas: (state, bajas) => {
        state.bajasSolicitadas = bajas;
    }
};

const actions = {
    async consultarBajasSolicitadaPaginado({commit}, data) {
        try {
            let res = await axios.get(`/v1/bajas-solicitadas/tecnico/pagina/${data.pagina}`);
            commit("setBajasSolicitadas", res?.data?.result);
        } catch (error) {
            return error;
        }
    },

    async consultarBajasSolicitadaTotalPaginas() {
        try {
            const resp = await axios.get(`/v1/bajas-solicitadas/tecnico/total`);
            store.dispatch("pagination/updateTotales", {
                totalPaginas: resp?.data?.result?.totalPaginas,
                totalRegistros: resp?.data?.result?.totalRegistros
            })
        } catch (error) {
            return error;
        }
    },

    async consultarBajasSolicitadaAllPaginado({commit}, data) {
        try {
            let res = await axios.get(`/v1/bajas-solicitadas/pagina/${data.pagina}`);
            commit("setBajasSolicitadas", res?.data?.result);
        } catch (error) {
            return error;
        }
    },

    async consultarBajasSolicitadaAllTotalPaginas() {
        try {
            const resp = await axios.get(`/v1/bajas-solicitadas/total`);
            store.dispatch("pagination/updateTotales", {
                totalPaginas: resp?.data?.result?.totalPaginas,
                totalRegistros: resp?.data?.result?.totalRegistros
            })
        } catch (error) {
            return error;
        }
    },
    async procesarBajasSolicitadas(_, data) {
        try {
            const resp = await axios.post(`/v1/bajas-solicitadas/procesar`,
                data
            );
            return resp;
        } catch (error) {
            return error;
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
