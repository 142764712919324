<template>
  <div>
    <div class="white-content-general mb-4">
      <div class="row">

        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12">
              <span class="card-title">Total de inventario: </span> <span
                class="txt-celeste font-weight-bold fs-l">{{ totalRegistros }}</span>
            </div>
          </div>
          <div class="row">
            <div v-for="(item, index) in totales" :key="index" class="col-6 text-grid">
              <span class="txt-gris font-weight-bold">{{ item.estado_agrupado }}:</span> <span
                class="txt-gris">{{ item.total }}</span>
            </div>
          </div>
        </div>

        <div class="col-md-8">
          <a
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#modalFiltros"
              data-backdrop="static"
              data-keyboard="false"
              class="btn btn-primary btn-general float-right mb-2 mb-md-0"
          >
            <i class="ti-filter menu-icon"></i> Filtrar</a
          >

          <button
              v-if="rol === 'ROLE_ADMIN'"
              @click="agregar"
              class="btn btn-primary btn-general float-right mr-2"
          >
            <span class="icon-plus"></span> Agregar
          </button>
          <div class="dropdown">
            <button
                class="btn btn-primary btn-general dropdown-toggle float-right mr-2"
                type="button"
                id="tipo"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              <i class="icon-download"></i> Descargar
            </button>
            <div class="dropdown-menu" aria-labelledby="movimiento">
              <a class="dropdown-item" href="#" @click="seleccionarAccion('ULTIMOS MOVIMIENTOS')"
              >ÚLTIMOS MOVIMIENTOS</a
              >
              <a class="dropdown-item" href="#" @click="seleccionarAccion('HISTORICO')"
              >HISTÓRICO</a
              >
              <a class="dropdown-item" href="#" @click="seleccionarAccion('INVENTARIO')"
                 v-if="rol === 'ROLE_BODEGA' || rol === 'ROLE_ADMIN' || 'ROLE_ANALISTA_PRINCIPAL'"
              >INVENTARIO</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>

    <Filtros v-if=" $config.countryFlag==='EC'"
             titulo="Seleccionar filtros"
             :consultar="consultarXFiltros"
             entidad="equipos_frios_view"
    />

    <Filtros v-else
             titulo="Seleccionar filtros"
             :consultar="consultarXFiltros"
             entidad="equipos_frios_view_zona"
    />


    <Modal :mensaje="mensaje" :method="procesarEliminacion"/>

    <EditUbicacionEquipo
        titulo="Actualizar ubicación del equipo"
        :actualizar="actualizar"
    />

    <Movimientos :equipo="equipo" titulo="Movimientos"/>

    <TablaFiltroPaginacion v-if="$config.countryFlag==='EC'" :columns="columnas" :data="equipos" @edit="editar"
                           @delete="eliminar" :opciones="opciones" @movimiento="consultarMovimientos"
                           @updatePagina="updatePage" :paginacion="true"/>

    <TablaFiltroPaginacion v-else :columns="columnasPE" :data="equipos" @edit="editar"
                           @delete="eliminar" :opciones="opcionesFiltradas" @movimiento="consultarMovimientos"
                           @updatePagina="updatePage" :paginacion="true"/>
  </div>
</template>
<script>
import Movimientos from "@/components/Movimientos.vue";
import Modal from "@/components/Modal.vue";
import Filtros from "@/components/CustomFilters.vue";
import EditUbicacionEquipo from "@/views/admin/equipos/components/EditUbicacionEquipo.vue";
import TablaFiltroPaginacion from "@/components/TablaFiltroPaginacion.vue";

export default {
  name: "EquiposFriosData",
  components: {Modal, EditUbicacionEquipo, Filtros, Movimientos, TablaFiltroPaginacion},
  props: {
    rol: String,
  },
  data() {
    return {
      pagina: 1,
      mensaje: "",
      id: null,
      valorBusqueda: "",
      tipoFiltro: "",
      habilitarInputBusqueda: false,
      habilitarBodegas: false,
      bodega: "",
      habilitarEstados: false,
      estadoEquipo: "",
      totalRegistros: 0,
      modoFiltros: false,
      condiciones: [],
      paginaFiltros: 1,
      equipo: null,
      totales: null,
      columnas: [
        {campo: "ubicacion", tipo: "Arreglo", nombre: "Ubicación"},
        {campo: "estadoEquipo", tipo: "Arreglo", nombre: this.$config.labelEstadoEquipo},
        {campo: "activoFijo", tipo: "Strings", nombre: "Activo Fijo"},
        {campo: "cliente", tipo: "String", nombre: this.$config.labelCodigoCliente},
        {campo: "diasAlmacenados", tipo: "Number", nombre: "Días Almacenados"},
        {campo: "placa", tipo: "String", nombre: this.$config.labelPlaca},
        {campo: "tipoEquipo", tipo: "Strings", nombre: this.$config.labelTipoEquipo},
        {campo: "serie", tipo: "Strings", nombre: "Serie"},
        {campo: "marca", tipo: "Strings", nombre: this.$config.labelMarca},
        {campo: "logo", tipo: "Strings", nombre: "Logo"},
        {campo: "modelo", tipo: "Strings", nombre: "Modelo"},
        {campo: "anioFabricacion", tipo: "Strings", nombre: "Año Fabricación"},
        {campo: "statusNeveras", tipo: "Strings", nombre: "Status Nevera"},
        {campo: "valorLibros", tipo: "Strings", nombre: "Valor Libros"},
        {campo: "valorComercial", tipo: "Strings", nombre: "Valor Comercial"},
        {campo: "statusLibros", tipo: "Strings", nombre: "Status Libros"},
        {campo: "anioAdquisicion", tipo: "Strings", nombre: "Año Adquisición"},
        {campo: "capacidadBotellas", tipo: "Strings", nombre: "Capacidad Botellas"},
        {campo: "capacidadCajas", tipo: "Strings", nombre: "Capacidad Cajas"},
        {campo: "capacidadPies", tipo: "Strings", nombre: "Capacidad Pies"},
        {campo: "numeroEquipo", tipo: "Strings", nombre: "Número Equipo"},
        {campo: "vh", tipo: "Strings", nombre: "VH"},
      ],
      columnasPE: [
        {campo: "ubicacionZone", tipo: "Arreglo", nombre: "Ubicación"},
        {campo: "estadoEquipo", tipo: "Arreglo", nombre: this.$config.labelEstadoEquipo},
        {campo: "activoFijo", tipo: "Arreglo", nombre: "Activo Fijo"},
        {campo: "cliente", tipo: "Arreglo", nombre: this.$config.labelCodigoCliente},
        {campo: "diasAlmacenados", tipo: "Arreglo", nombre: "Días Almacenados"},
        {campo: "placa", tipo: "Arreglo", nombre: this.$config.labelPlaca},
        {campo: "tipoEquipo", tipo: "Strings", nombre: this.$config.labelTipoEquipo},
        {campo: "subCategoria", tipo: "Strings", nombre: "Subcategoria"},
        {campo: "activoDescripcion", tipo: "Strings", nombre: "Descripción"},
        {campo: "serie", tipo: "Strings", nombre: "Serie"},
        {campo: "serieAnt", tipo: "Strings", nombre: "Serie Ant"},
        {campo: "numeroTasacion", tipo: "Strings", nombre: "Número Tasación"},
        {campo: "fabricante", tipo: "Strings", nombre: "Fabricante"},
        {campo: "logo", tipo: "Strings", nombre: "Logo"},
        {campo: "marca", tipo: "Strings", nombre: this.$config.labelMarca},
        {campo: "fechaAdquisicion", tipo: "Strings", nombre: "Fecha Adquisición"},
        {campo: "valorAdquisicion", tipo: "Strings", nombre: "Valor Adquisición"},
        {campo: "moneda", tipo: "Strings", nombre: "Moneda"},
        {campo: "facturaCompra", tipo: "Strings", nombre: "Factura Compra"},
        {campo: "guiaCompra", tipo: "Strings", nombre: "Guía Compra"},
        {campo: "activoControlador", tipo: "Strings", nombre: "Controlador"},
        {campo: "repotenciado", tipo: "Strings", nombre: "Repotenciado"},
        {campo: "responsableRepotenciado", tipo: "Strings", nombre: "Responsable Repotenciado"},
        {campo: "fechaModStatus", tipo: "Strings", nombre: "Fecha Mod Status"},
        {campo: "activoModStatusResp", tipo: "Strings", nombre: "Mod Status Resp"},
        {campo: "numeroGuia", tipo: "Strings", nombre: "Número Guía"},
        {campo: "fechaContrato", tipo: "Strings", nombre: "Fecha Contrato"},
      ],
      opciones: [
        "ubicacion",
        "editar",
        "eliminar",
        "movimientos",
        "adjuntos",
        "paginador"
      ],

      opcionesAnalista: [
        "movimientos",
        "adjuntos",
        "paginador"
      ],
      country: this.$config.countryFlag,
    };
  },
  mounted() {
    this.consultarXFiltros(this.data);

  },

  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async seleccionarAccion(accion) {
      if (accion === "ULTIMOS MOVIMIENTOS") {

        if ("EC" === this.country) {
          await this.descargarMovimientos("movimientos_ultimo_view");
        } else {
          await this.descargarMovimientos("movimientos_ultimo_view_zona");
        }
      } else if (accion === "HISTORICO") {
        if ("EC" === this.country) {
          await this.descargarMovimientos("movimientos_view");
        } else {
          await this.descargarMovimientos("movimientos_view_zona");
        }

      } else {
        await this.descargar();
      }
    },
    async agregar() {
      await this.$store.commit("equipos/setEquipoSeleccionado", null);
      this.$router.push({name: "EquipoForm"});
    },
    async editar(equipo) {
      await this.$store.commit("equipos/setEquipoSeleccionado", equipo);
      this.$router.push({name: "EquipoForm"});
    },

    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la información?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("equipos/eliminarEquipo", {id: id});

            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      });
    },

    async procesarEliminacion() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("equipos/eliminarEquipo", {id: this.id});
        this.id = null;
        this.$swal({
          text: "Registro eliminado con éxito.",
          icon: "success",
        });
      } catch (error) {
        this.$swal("", error.message, "error");
      } finally {
        this.loader.hide();
      }
    },


    async actualizar(data) {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("equipos/editarUbicacionEquipo", {
          data: {
            codigoCliente: data.codigoCliente,
            bodega: data.bodega,
            ubicacion: data.tipoUbicacion,
            estadoEquipo: data.estadoUbicacion,
          },
          id: data.id,
        });
      } catch (error) {
        this.$swal("", error.message, "error");
      } finally {
        this.loader.hide();
      }
    },

    async consultarXFiltros(data) {
      this.condiciones = data?.condiciones;
      this.modoFiltros = true;

      this.loaderSpinner();
      try {
        await this.$store.dispatch("equipos/consultarEquipoXFiltros", {
          pagina: data?.inicializarPagina ? 1 : this.paginaFiltros,
          condiciones: data?.condiciones || [],
        });
        this.totalRegistros = this.$store.getters["equipos/totalRegistros"];
        this.totalPaginas = this.$store.getters["equipos/totalPaginas"];
        this.totales = this.$store.getters["equipos/totales"];
      } catch (error) {
        console.log(error);
        this.equipos = null;
      } finally {
        this.loader.hide();
      }
    },
    async descargar() {
      if (this.condiciones === undefined) {
        this.condiciones = [];
      }
      this.loaderSpinner();
      try {
        let entidad
        if ("EC" === this.country) {
          entidad = "inventario_view"
        } else {
          entidad = "inventario_view_zona"
        }
        const resp = await this.$store.dispatch("exportar/exportar", {
          entidad: entidad,
          filtro: {
            condiciones: this.condiciones
          }
        });
        if (resp) {
          const url = window.URL.createObjectURL(new Blob([resp]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `inventario.csv`);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async descargarMovimientos(vista) {
      if (this.condiciones === undefined) {
        this.condiciones = [];
      }
      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch("exportar/exportar", {
          entidad: vista,
          filtro: {
            condiciones: this.condiciones
          }
        });
        if (resp) {
          const url = window.URL.createObjectURL(new Blob([resp]));
          const link = document.createElement("a");
          link.href = url;
          if (vista === "movimientos_view" || vista === "movimientos_view_zona") {
            link.setAttribute("download", `movimientos.csv`);
          } else {
            link.setAttribute("download", `ultimos_movimientos.csv`);
          }

          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async consultarMovimientos(equipo) {
      this.equipo = equipo;
    },
    updatePage(page) {
      this.pagina = page;
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    equipos() {
      return this.$store.getters["equipos/equipos"]
          ? this.$store.getters["equipos/equipos"]
          : [];
    },
    opcionesFiltradas() {
      if (this.rol === 'ROLE_ADMIN') {
        return this.opciones;
      } else {
        return this.opcionesAnalista;

      }
    }
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        if (this.modoFiltros) {
          this.paginaFiltros = newPage;
          this.consultarXFiltros({
            condiciones: this.condiciones,
            inicializarPagina: false,
          });
          return;
        }
        this.consultar(newPage);
      }
    },

  },
};
</script>
