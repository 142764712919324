<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              {{ $config.welcomeMessage }}
            </h3>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>


export default {
  name: "Welcome",
  data() {
    return {};
  },

};
</script>
<style>
</style>
