<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Notificaciones</h3>
          </div>

        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-2 mb-2 mb-md-0">
              <input type="date" class="form-control" v-model="fechaInicio"/>
            </div>
            <div class="col-md-2 mb-2 mb-md-0">
              <input type="date" class="form-control" v-model="fechaFin"/>
            </div>
            <div class="col-md-2 mb-2 mb-md-0">
              <button
                  type="button"
                  class="btn btn-general btn-secondary float-right"
                  @click="consultar"
                  :disabled="fechaInicio && fechaFin ? false : true"
              >
                <span class="icon-check"></span> Consultar
              </button>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-4">

                </div>
                <div class="col-4">
                  <a
                      href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#modalFiltros"
                      data-backdrop="static"
                      data-keyboard="false"
                      class="btn btn-primary btn-general float-right"
                  >
                    <i class="ti-filter menu-icon"></i
                    > Filtrar</a>
                </div>
                <div class="col-4">
                  <button
                      @click="exportExcel"
                      class="btn btn-primary btn-general float-right"
                      :disabled="notificaciones.length === 0"
                  >
                    <span class="icon-download"></span> Exportar
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th>Cliente</th>
                <th>Fecha Creación</th>
                <th>Solicitud</th>
                <th>Ticket</th>
                <th>Teléfono</th>
                <th>Encuesta</th>
                <th>Requerimiento atendido</th>
                <th>Comentarios</th>
                <th>Explica manejo</th>
                <th>Explica conexión</th>
                <th>Ip</th>
                <th>Nivel de satisfacción</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in listaNotificaciones" :key="item.id">
                <td>{{ item?.codigoCliente ?? "" }}</td>
                <td>{{ item?.fechaCreacion ?? "" }}</td>
                <td>{{ item?.solicitud ?? "" }}</td>
                <td>{{ item?.ticket ?? "" }}</td>
                <td>{{ item?.telefono ?? "" }}</td>
                <td>{{ item?.encuesta ?? "" }}</td>
                <td>{{ item?.atendioRequerimiento ?? "" }}</td>
                <td>{{ item?.comentarios ?? "" }}</td>
                <td>{{ item?.explicoManejo ?? "" }}</td>
                <td>{{ item?.explicoConexion ?? "" }}</td>
                <td>{{ item?.ip ?? "" }}</td>
                <td>{{ item?.nivelSatisfaccion ?? "" }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {saveExcel} from "@progress/kendo-vue-excel-export";

export default {
  name: "GestionNotificaciones",
  data() {
    return {
      fechaInicio: "",
      fechaFin: "",
      codigoCliente: "",
      listaNotificaciones: [],
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    sumarDia(fecha, dias) {
      fecha.setDate(fecha.getDate() + dias);
      return fecha;
    },
    async consultar() {
      const fechaFinCalculada = new Date(this.fechaFin + "T00:00:00");
      const fechaFinalWS = this.sumarDia(fechaFinCalculada, 1)
          ?.toISOString()
          .split("T")[0];

      const criterio = {
        condiciones: [
          {field: "fecha_creacion", comparator: ">", value: this.fechaInicio, type: "date"},
          {field: "fecha_creacion", comparator: "<", value: fechaFinalWS, type : "date"},
        ],
      };
      this.loaderSpinner();
      try {
        await this.$store.dispatch(
            "notificaciones/consultarNotificaciones",
            criterio
        );
        this.listaNotificaciones = this.notificaciones;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    filtrar() {
      if (this.codigoCliente?.length > 0) {
        const listaNotificacionesFiltrada = this.notificaciones?.filter(
            (item) => item?.codigoCliente?.includes(this.codigoCliente)
        );
        if (!listaNotificacionesFiltrada?.length) {
          this.listaNotificaciones = this.notificaciones;
          return;
        }
        this.listaNotificaciones = listaNotificacionesFiltrada;
      }
    },
    exportExcel() {
      saveExcel({
        data: this.notificaciones,
        fileName: "notificaciones",
        columns: [
          {field: "codigoCliente", title: "Cliente"},
          {field: "fechaCreacion", title: "Fecha Creación"},
          {field: "solicitud", title: "Solicitud"},
          {field: "ticket", title: "Ticket"},
          {field: "telefono", title: "Teléfono"},
          {field: "encuesta", title: "Encuesta"},
          {field: "atendioRequerimiento", title: "Requerimiento atendido"},
          {field: "comentarios", title: "Comentarios"},
          {field: "explicoManejo", title: "Explica manejo"},
          {field: "explicoConexion", title: "Explica conexión"},
          {field: "ip", title: "Ip"},
          {field: "nivelSatisfaccion", title: "Nivel de satisfacción"},
        ],
      });
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    notificaciones() {
      return this.$store.getters["notificaciones/notificaciones"]
          ? this.$store.getters["notificaciones/notificaciones"]
          : [];
    },
  },
  watch: {
    codigoCliente(prevState, newState) {
      if (prevState !== newState) {
        this.filtrar();
      }
    },
  },
};
</script>
<style></style>
