<template>
  <div>

    <div class="mt-4 ml-4">
      <h5 class="card-title">
        Ticket
        {{ solicitudProveedorSeleccionada.solicitud.numeroTicket }}
      </h5>
      <h6 class="card-subtitle mb-2 text-muted">
        Tipo Solicitud:
        {{ solicitudProveedorSeleccionada.solicitud.tipoSolicitud }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Fecha Solicitud:
        {{ solicitudProveedorSeleccionada.solicitud.fechaSolicitud }}
      </h6>
    </div>

    <hr/>

    <div class="mt-4 ml-4">
      <h5 class="card-title">
        {{ $config.labelCodigoCliente }}
        {{ solicitudProveedorSeleccionada.solicitud.codigoCliente }}
      </h5>

      <h5 v-if="solicitudProveedorSeleccionada?.solicitud?.placa" class="card-title">
        {{ $config.labelPlaca }}
        {{ solicitudProveedorSeleccionada?.solicitud?.placa }}
      </h5>

      <h6 class="card-subtitle mb-2 text-muted">
        {{ $config.labelIdentificacionCliente }}: {{ solicitudProveedorSeleccionada.solicitud.identificacion }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        {{ $config.labelNombreCliente }}: {{ solicitudProveedorSeleccionada.solicitud.nombre }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        {{ $config.labelEstablecimiento }}:
        {{ solicitudProveedorSeleccionada.solicitud.nombreEstablecimiento }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Dirección:
        {{ solicitudProveedorSeleccionada.solicitud.direccion }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Teléfono:
        {{
          solicitudProveedorSeleccionada.solicitud.telefono || solicitudProveedorSeleccionada.solicitud.telefonoCliente
        }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Sector desarrollo:
        {{ solicitudProveedorSeleccionada.solicitud.ecb }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Franja horaria:
        {{ solicitudProveedorSeleccionada.solicitud.franjaHoraria }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Referencia:
        {{ solicitudProveedorSeleccionada.solicitud.referencia }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Observación:
        {{ solicitudProveedorSeleccionada.solicitud.observacion }}
      </h6>
      <div style="display: flex; flex-direction: row;  justify-content: start; height: 100%;">
        <a
            href="javascript:void(0)"
            @click="abrirMapa(solicitudProveedorSeleccionada.solicitud)"
        ><i class="ti-map menu-icon" style="color: red; font-size: 30px"></i
        ></a>
        <a v-if="solicitudProveedorSeleccionada.solicitud?.foto"
            href="javascript:void(0)"
            @click="abrirFoto(solicitudProveedorSeleccionada.solicitud?.foto)"
        ><i class="icon-image menu-icon" style="color: blue; font-size: 25px"></i
        ></a>
      </div>
    </div>
    <hr/>
  </div>
  <div v-if="$config.countryFlag==='EC'">
    <div class="mt-4 ml-4">
      <h5 class="card-title">
        Análisis
      </h5>
      <h6 class="card-subtitle mb-2 text-muted">
        Capacidad: {{ solicitudProveedorSeleccionada.solicitud.capacidadEeffMediana }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Nivel de riesgo:
        {{ solicitudProveedorSeleccionada.solicitud.prioridad }}
      </h6>
      <h6 class="card-subtitle mb-2 text-muted">
        Mediana últimos seis meses:
        {{ solicitudProveedorSeleccionada.solicitud.medianaUltimoTrimestre }}
      </h6>
    </div>
  </div>

</template>
<script>
export default {
  methods: {
    abrirMapa(solicitud) {
      const {latitud, longitud} = solicitud;
      window.open(`https://maps.google.com/maps?q=loc:${latitud},${longitud}`);
    },
    abrirFoto(url) {
      window.open(url);
    },
  },
  computed: {
    solicitudProveedorSeleccionada() {
      return this.$store.getters[
          "solicitudesProveedor/solicitudProveedorSeleccionada"
          ]
          ? this.$store.getters[
              "solicitudesProveedor/solicitudProveedorSeleccionada"
              ]
          : [];
    }
  },
};
</script>
