<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Equipos Fríos</h3>
          </div>
        </div>
        <div class="container overflow-auto">
          <div class="row">
            <div class="col-md-12">
              <div class="form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="placa"
                  value="PLACA"
                  v-model="tipoFiltro"
                  @click="mostrarBusquedaInput"
                />
                <label class="form-check-label radio-margin" for="placa">
                  Placa
                </label>

                <input
                  class="form-check-input"
                  type="radio"
                  id="serie"
                  value="SERIE"
                  v-model="tipoFiltro"
                  @click="mostrarBusquedaInput"
                />
                <label class="form-check-label radio-margin" for="serie">
                  Serie
                </label>

                <div class="form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="todos"
                    value="BODEGA"
                    v-model="tipoFiltro"
                    @click="mostrarBodegas"
                  />
                  <label class="form-check-label radio-margin" for="todos">
                    Bodega
                  </label>
                </div>
                <div class="form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="estados"
                    value="ESTADO"
                    v-model="tipoFiltro"
                    @click="mostrarEstados"
                  />
                  <label class="form-check-label radio-margin" for="estados">
                    Estado
                  </label>
                </div>
                <div class="form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="todos"
                    value="TODOS"
                    v-model="tipoFiltro"
                    @click="consultar(1)"
                  />
                  <label class="form-check-label radio-margin" for="todos">
                    Todos
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="input-group col-md-6 mt-2 no-padding"
          v-if="habilitarInputBusqueda"
        >
          <input
            type="text"
            class="form-control"
            v-model="valorBusqueda"
            aria-label="Text input with segmented dropdown button"
          />
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-outline-secondary"
              @click="buscarEquipo"
              :disabled="valorBusqueda.length === 0 || tipoFiltro === 'TODOS'"
            >
              Buscar
            </button>
          </div>
        </div>
        <div class="row mt-2" v-if="habilitarBodegas">
          <div class="col-md-6">
            <div class="form-group">
              <fieldset>
                <select
                  name="bodega"
                  class="form-control"
                  v-model="bodega"
                  @change="filtrarXBodega"
                >
                  <option value="" disabled>Seleccione una bodega</option>
                  <option
                    v-for="bodega in bodegas"
                    :key="bodega.id"
                    :value="bodega.id"
                  >
                    {{ bodega.nombre }}
                  </option>
                </select>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="row mt-2" v-if="habilitarEstados">
          <div class="col-md-6">
            <div class="form-group">
              <fieldset>
                <select
                  name="estado"
                  class="form-control"
                  v-model="estadoEquipo"
                  @change="filtrarXEstado(1)"
                >
                  <option value="" disabled>Seleccione un estado</option>
                  <option
                    v-for="estado in estados"
                    :key="estado"
                    :value="estado"
                  >
                    {{ estado }}
                  </option>
                </select>
              </fieldset>
            </div>
          </div>
        </div>
        <br />
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Ubicación</th>
                <th></th>
                <th>Cliente</th>
                <th>Placa</th>
                <th>Serie</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Logo</th>
                <th>Año fabricación</th>
                <th>Tipo</th>
                <th>Fabricante Genesis</th>
                <th>Status Neveras</th>
                <th>Valor Libros</th>
                <th>Valor Comercial</th>
                <th>Status Libros</th>
                <th>Año Adquisición</th>
                <th>Capacidad Botellas</th>
                <th>Capacidad Cajas</th>
                <th>Capacidad Pies</th>
                <th>Activo Fijo</th>
                <th>Número Equipo</th>
                <th>VH</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in equipos" :key="item.id">
                <td>{{ item.ubicacion }}</td>
                <td>
                  <div
                    class="badge"
                    :class="
                      item.estadoEquipo === 'DISPONIBLE'
                        ? 'badge-success'
                        : 'badge-secondary'
                    "
                  >
                    {{ item.estadoEquipo }}
                  </div>
                </td>
                <td>{{ item.cliente }}</td>
                <td style="font-weight: bold">
                  <a
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Visualizar detalles del equipo"
                    href="#"
                    >{{ item.placa }}</a
                  >
                </td>
                <td>{{ item.serie }}</td>
                <td>{{ item.marca }}</td>
                <td>{{ item.modelo }}</td>
                <td>{{ item.logo }}</td>
                <td>{{ item.anioFabricacion }}</td>
                <td>{{ item.tipoEquipo }}</td>
                <td>{{ item.fabricanteGenesis }}</td>
                <td>{{ item.statusNeveras }}</td>
                <td>{{ item.valorLibros }}</td>
                <td>{{ item.valorComercial }}</td>
                <td>{{ item.statusLibros }}</td>
                <td>{{ item.anioAdquisicion }}</td>
                <td>{{ item.capacidadBotellas }}</td>
                <td>{{ item.capacidadCajas }}</td>
                <td>{{ item.capacidadPies }}</td>
                <td>{{ item.activoFijo }}</td>
                <td>{{ item.numeroEquipo }}</td>
                <td>{{ item.vh }}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <div class="d-flex justify-content-center">
            <nav aria-label="Page navigation example" v-if="paginas">
              <ul class="pagination">
                <li class="page-item" :disabled="pagina === 1">
                  <a class="page-link" @click="setPreviewPage">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li
                  v-for="page in paginas"
                  :key="page"
                  class="page-item"
                  :class="{ active: pagina === page }"
                >
                  <a class="page-link" @click="setPage(page)">{{ page }}</a>
                </li>
                <li class="page-item" :disabled="pagina === paginas">
                  <a class="page-link" @click="setNextPage">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <button @click="descargar" class="btn btn-primary float-right">
              <span class="icon-download"></span> Descargar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GestionEquiposSupervisor",
  data() {
    return {
      pagina: 1,
      mensaje: "",
      id: null,
      valorBusqueda: "",
      tipoFiltro: "",
      habilitarInputBusqueda: false,
      habilitarBodegas: false,
      bodega: "",
      habilitarEstados: false,
      estadoEquipo: "",
      estados: [
        "DISPONIBLE",
        "REPARACION",
        "BAJA",
        "RESERVADO",
        "GARANTIA",
        "BAJA_SAP"
      ],
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  mounted() {
    this.consultar(1);
    this.consultarBodegas();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      this.loaderSpinner();
      this.habilitarBodegas = false;
      this.habilitarInputBusqueda = false;
      this.habilitarEstados = false;
      this.valorBusqueda = "";
      try {
        const data = {
          pagina: pagina || 1,
        };
        await this.$store.dispatch("equipos/consultarEquiposXPaginaSupervisor", data);
        await this.$store.dispatch("equipos/consultarTotalPaginasSupervisor");
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async consultarBodegas() {
      try {
        await this.$store.dispatch("bodegas/consultarTodasSupervisor");
      } catch (error) {
        alert("No se pudo realizar la consulta de las bodegas");
      }
    },
    async buscarEquipo() {
      this.loaderSpinner();
      try {
        if (this.tipoFiltro && this.tipoFiltro === "SERIE") {
          await this.$store.dispatch("equipos/consultarEquipoXSerie", {
            serie: this.valorBusqueda,
          });
        }
        if (this.tipoFiltro && this.tipoFiltro === "PLACA") {
          await this.$store.dispatch("equipos/consultarEquipoXPlaca", {
            placa: this.valorBusqueda,
          });
        }

        await this.$store.commit(
          "equipos/setEquipos",
          Object.keys(this.equipoSeleccionado).length === 0
            ? null
            : [this.equipoSeleccionado]
        );
        await this.$store.commit("equipos/setTotalPaginas", 1);
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    mostrarBusquedaInput() {
      this.habilitarBodegas = false;
      this.habilitarInputBusqueda = true;
      this.habilitarEstados = false;
    },
    mostrarBodegas() {
      this.habilitarBodegas = true;
      this.habilitarInputBusqueda = false;
      this.habilitarEstados = false;
    },
    mostrarEstados() {
      this.habilitarBodegas = false;
      this.habilitarInputBusqueda = false;
      this.habilitarEstados = true;
    },
    async filtrarXBodega() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("equipos/consultarEquipoXBodega", {
          bodega: this.bodega,
        });
        await this.$store.commit("equipos/setTotalPaginas", 1);
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async filtrarXEstado(pagina) {
      console.log(pagina);
      this.loaderSpinner();
      try {
        await this.$store.dispatch("equipos/consultarEquipoXEstadoXPaginaSupervisor", {
          estado: this.estadoEquipo,
          pagina: pagina || 1,
        });
        await this.$store.dispatch("equipos/consultarTotalPaginasEstadoSupervisor", {
          estado: this.estadoEquipo,
        });
        this.pagina = pagina;
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      await this.$store.commit("equipos/setEquipoSeleccionado", null);
      this.$router.push({ name: "EquipoForm" });
    },
    async editar(equipo) {
      await this.$store.commit("equipos/setEquipoSeleccionado", equipo);
      this.$router.push({ name: "EquipoForm" });
    },
    async eliminar(id) {
      //this.id = id;
      //this.mensaje = "¿Está seguro de eliminar el equipo?";
      this.$swal({
        text: "¿Está seguro de eliminar la información?",
        //icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("equipos/eliminarEquipo", { id: id });

            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      });
    },
    async procesarEliminacion() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("equipos/eliminarEquipo", { id: this.id });
        this.id = null;
        this.$swal({
          text: "Registro eliminado con éxito.",
          icon: "success",
        });
      } catch (error) {
        this.$swal("", error.message, "error");
      } finally {
        this.loader.hide();
      }
    },
    setPage(page) {
      this.pagina = page;
    },
    setNextPage() {
      if (this.pagina < this.totalPaginas) {
        this.pagina = this.pagina + 1;
      }
    },
    setPreviewPage() {
      if (this.pagina > 1) {
        this.pagina = this.pagina - 1;
      }
    },
    editarUbicacion(equipo) {
      this.$store.commit("equipos/setEquipoSeleccionado", equipo);
    },
    async actualizar(data) {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("equipos/editarUbicacionEquipo", {
          data: {
            codigoCliente: data.codigoCliente,
            bodega: data.bodega,
            estadoEquipo: data.tipoUbicacion,
          },
          id: data.id,
        });
      } catch (error) {
        this.$swal("", error.message, "error");
      } finally {
        this.loader.hide();
      }
    },
    async descargar() {
      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch("equipos/exportarSupervisor");
        if (resp) {
          const url = window.URL.createObjectURL(new Blob([resp]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `inventario.csv`);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    equipos() {
      return this.$store.getters["equipos/equipos"]
        ? this.$store.getters["equipos/equipos"]
        : [];
    },
    totalPaginas() {
      return this.$store.getters["equipos/totalPaginas"]
        ? this.$store.getters["equipos/totalPaginas"]
        : [];
    },
    paginas() {
      let numeroPaginas = 10;
      numeroPaginas = Math.min(numeroPaginas, this.totalPaginas);
      let first = this.pagina - Math.floor(numeroPaginas / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.totalPaginas - numeroPaginas + 1);
      return [...Array(numeroPaginas)].map((k, i) => i + first);
    },
    equipoSeleccionado() {
      return this.$store.getters["equipos/equipoSeleccionado"]
        ? this.$store.getters["equipos/equipoSeleccionado"]
        : {};
    },
    bodegas() {
      return this.$store.getters["bodegas/bodegas"]
        ? this.$store.getters["bodegas/bodegas"]
        : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        if (this.tipoFiltro && this.tipoFiltro === "ESTADO") {
          this.filtrarXEstado(newPage);
          return;
        }
        this.consultar(newPage);
      }
    },
  },
};
</script>
<style>
.no-padding {
  padding: 0;
  margin: 0;
}

.radio-margin {
  margin-right: 15px;
}
</style>
