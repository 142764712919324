<template>
  <div class="mb-3">
    <h5>{{ equipoSeleccionado?.marca }}</h5>
    <h6 class="text-black">
      {{ $config.labelPlaca }}: {{ equipoSeleccionado?.placa }}
    </h6>
    <h6 class="text-black">
      Serie: {{ equipoSeleccionado?.serie }}
    </h6>
    <h6 class="text-black">
      Modelo: {{ equipoSeleccionado?.modelo }}
    </h6>
    <h6 class="text-black">
      Capacidad (pies): {{ equipoSeleccionado?.capacidadPies }}
    </h6>
    <h6 class="text-black">
      Tipo: {{ equipoSeleccionado?.tipoEquipo }}
    </h6>
    <h6 class="text-black">
      Ubicación actual: {{ equipoSeleccionado?.ubicacion }}
    </h6>
  </div>
</template>
<script>
export default {
  props : {
    equipoSeleccionado: Object
  }
}
</script>
