<template>
  <div>

    <div
        class="modal modal-dialog-scrollable"
        tabindex="-1"
        :style="displayModal ? 'display: block;' : 'display: none;'"
    >
      <div class="modal-dialog" style="max-width: 700px">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Gestión de Bodegas y Gerencias</h5>
          </div>
          <div class="modal-body">

            <div class="row">
              <div class="col-lg-12">
                <div class="form-sample">
                  <Form
                      @submit="submit"
                      :validation-schema="formValidation"
                      v-slot="{ errors }"
                  >

                    <div class="row">

                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="gerenciaGeneral">{{ $config.labelGerenciaGeneral }} *</label>
                          <fieldset>
                            <Field
                                name="gerenciaGeneral"
                                as="select"
                                class="form-control"
                                v-model="formData.gerenciaGeneral"
                                @change="consultarGerenciasVentas"
                            >
                              <option value="" disabled>
                                Seleccione una gerencia
                              </option>
                              <option
                                  v-for="gerencia in gerenciasGenerales"
                                  :key="gerencia.id"
                                  :value="gerencia.descripcion"
                              >
                                {{ gerencia.descripcion }}
                              </option>
                            </Field>
                            <MensajeValidacion :mensaje="errors.gerenciaGeneral"/>
                          </fieldset>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ $config.labelGerenciaVentas }} *</label>
                          <fieldset>
                            <Field
                                as="select"
                                name="gerenciaVentas"
                                class="form-control"
                                placeholder="Gerencia Ventas"
                                v-model="formData.gerenciaVentas"
                            >
                              <option value="" disabled>
                                Seleccione una gerencia
                              </option>
                              <option
                                  v-for="item in gerenciasVentas"
                                  :key="item.descripcion"
                                  :value="item.descripcion"
                              >
                                {{ item.descripcion }}
                              </option>
                            </Field>
                            <MensajeValidacion :mensaje="errors.gerenciaVentas"/>
                          </fieldset>
                        </div>
                      </div>
                    </div>

                    <ErrorMessage :mensaje="mensaje" v-if="error"/>
                    <div class="row">
                      <div class="col-lg-12">
                        <button
                            type="submit"
                            id="form-submit"
                            class="btn btn-primary btn-general mr-2"
                        >
                          <i class="ti-save"></i> {{ titleButtonGuardar }}
                        </button>

                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>


            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th style="width: 2%"></th>
                  <th>{{ $config.labelGerenciaGeneral }}</th>
                  <th>{{ $config.labelGerenciaVentas }}</th>

                </tr>
                </thead>
                <tbody>
                <tr v-for="item in bodegaGerencias" :key="item.id">
                  <td>
                    <a
                        title="Editar"
                        href="javascript:void(0)"
                        @click="editar(item)"
                    ><i class="ti-pencil btn-icon-append"></i></a
                    >&nbsp;
                    <a
                        title="Eliminar"
                        href="javascript:void(0)"
                        @click="eliminar(item.id)"
                    ><i class="ti-trash btn-icon-append"></i
                    ></a>
                  </td>
                  <td>{{ item.gerenciaGeneral }}</td>
                  <td>{{ item.gerenciaVentas }}</td>

                </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-general-off btn-secondary"
                data-bs-dismiss="modal"
                @click="cerrarModalArchivo"
            >
              <i class="ti-close"></i> Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {Form, Field} from "vee-validate";
import MensajeValidacion from "../components/MensajeValidacion.vue";
import ErrorMessage from "../components/ErrorMessage";

export default {
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {
    const formValidation = {
      gerenciaGeneral: "required",
    };
    return {
      formData: {
        gerenciaGeneral: "",
        gerenciaVentas: "",
      },
      error: false,
      mensaje: "",
      formValidation,
      accion: 'nuevo',
      idBodegaGerenciaSelected: null,
      titleButtonGuardar : 'Nuevo'
    }
  },
  props: {
    displayModal: {
      required: true,
      type: Boolean,
      default: false
    },
    idBodega: {
      required: true,
      type: Number,
    }
  },

  methods: {
    cerrarModalArchivo() {
      this.$emit('close-modal');
    },
    async consultarGerenciasXBodega() {

      try {
        await this.$store.dispatch("bodegaGerencia/consultarGerenciaXBodega", {idBodega: this.idBodega});
      } catch (error) {
        console.log("No se pudo realizar la consulta");
      }
    },
    async agregar() {
      await this.$store.commit("bodegaGerencia/setBodegaGerenciaSeleccionada", null);
      this.$router.push({name: "BodegaGerenciaForm"});
    },
    async editar(item) {
      this.idBodegaGerenciaSelected = item.id
      this.accion = 'editar'
      this.titleButtonGuardar = 'Actualizar'
      this.formData = {
        gerenciaGeneral: item.gerenciaGeneral,
        gerenciaVentas: item.gerenciaVentas
      };
      await this.consultarGerenciasVentas()
    },
    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la información?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("bodegaGerencia/eliminar", {id: id});
            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            await this.consultarGerenciasXBodega();
          }
        }
      });
    },
    async consultarCatalogos() {

      try {
        await this.$store.dispatch("rutero/consultarGerenciasGenerales");
        if (this.bodegaGerenciaSeleccionada) {
          this.formData.gerenciaGeneral =
              this.bodegaGerenciaSeleccionada?.gerenciaGeneral ?? "";
          await this.consultarGerenciasVentas();
          this.formData.gerenciaVentas =
              this.bodegaGerenciaSeleccionada?.gerenciaVentas ?? "";
        }
      } catch (error) {
        alert(error);
      }
    },
    async submit() {
      this.error = false;
      try {
        const data = {
          ...this.formData,
          bodega: this.idBodega,
        };
        let respuesta = null;
        if ('editar' === this.accion) {
          respuesta = await this.$store.dispatch("bodegaGerencia/editar", {
            id: this.idBodegaGerenciaSelected,
            data,
          });
          await this.consultarGerenciasXBodega();
        } else {
          respuesta = await this.$store.dispatch("bodegaGerencia/crear", data);
        }
        if (respuesta?.error) {
          alert(respuesta?.detalle);
        }
      } catch (error) {
        console.log(error)
        this.error = true;
        this.mensaje = "No se pudo guardar la información";

      }
    },
    async consultarGerenciasVentas() {
      try {
        await this.$store.dispatch("rutero/consultarGerenciasVentas", {
          gerenciaGeneral: this.formData.gerenciaGeneral.toString(),
        });
      } catch (error) {
        alert(error);
      }
    },
  },
  computed: {
    bodegaGerencias() {
      return this.$store.getters["bodegaGerencia/bodegasGerencias"]
          ? this.$store.getters["bodegaGerencia/bodegasGerencias"]
          : [];
    },
    gerenciasGenerales() {
      return this.$store.getters["rutero/gerenciasGenerales"]
          ? this.$store.getters["rutero/gerenciasGenerales"]
          : [];
    },
    gerenciasVentas() {
      return this.$store.getters["rutero/gerenciasVentas"]
          ? this.$store.getters["rutero/gerenciasVentas"]
          : [];
    },
  },
  watch: {
    async displayModal(newVal) {
      if (newVal) {
        await this.consultarGerenciasXBodega();
        await this.consultarCatalogos()
        this.accion = 'nuevo'
        this.titleButtonGuardar = "Nuevo"
        this.formData = {
          gerenciaGeneral: "",
          gerenciaVentas: "",
        }
      }
    },
  },
}
</script>
