<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Eventos Técnico</h3>
          </div>
          <div class="col-md-6">
          
          </div>
        </div>
        
        <div class="white-content-general mb-4">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th width="5%"></th>
                  <th width="5%"></th>
                  <th>Fecha de entrega</th>
                  <th>Fecha de retiro</th>
                  <th>Cliente</th>
                  <th>Gerencia General</th>
                  <th>Gerencia Ventas</th>
                  <th>Provincia</th>
                  <th>Sector</th>
                  <th>Dirección</th>
                  <th>Teléfono cliente</th>
                  <th>Nombre evento</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in eventos" :key="item.id">
                  <td>
                    <a href="javascript:void(0)" @click="abrirMapa(item?.evento)"
                      ><i
                        class="ti-map menu-icon"
                        style="color: red; font-size: 30px"
                      ></i
                    ></a>
                  </td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      @click="ver(item?.evento?.equiposFrios)"
                      v-if="
                        item?.evento?.estadoEvento === 'PROCESADO' ||
                        item?.evento?.estadoEvento === 'INSTALAR'
                      "
                      ><i class="ti-search btn-icon-append"></i
                    ></a>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm mr-1"
                      v-if="item?.evento?.estadoEvento === 'PROCESADO'"
                      @click="instalar(item)"
                    >
                      Instalación
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      v-if="item?.evento?.estadoEvento === 'INSTALAR'"
                      @click="retirar(item)"
                    >
                      Retiro
                    </button>
                  </td>

                  <td>
                    <div
                      class="badge"
                      :class="
                        verificarDias(
                          item?.evento?.fechaEntrega,
                          item?.evento?.horaEntrega
                        )
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                      v-if="item?.evento?.estadoEvento === 'PROCESADO'"
                    >
                      {{ item?.evento?.fechaEntrega }}
                    </div>
                    <span v-else>{{ item?.evento?.fechaEntrega }}</span>
                  </td>
                  <td>
                    <div
                      class="badge"
                      :class="
                        verificarDias(
                          item?.evento?.fechaRetiro,
                          item?.evento?.horaRetiro
                        )
                          ? 'badge-success'
                          : 'badge-danger'
                      "
                      v-if="item?.evento?.estadoEvento === 'INSTALAR'"
                    >
                      {{ item?.evento?.fechaRetiro }}
                    </div>
                    <span v-else>{{ item?.evento?.fechaRetiro }}</span>
                  </td>
                  <td>{{ item?.evento?.codigoCliente }}</td>
                  <td>{{ item?.evento?.gerenciaGeneral }}</td>
                  <td>{{ item?.evento?.gerenciaVentas }}</td>
                  <td>{{ item?.evento?.provincia }}</td>
                  <td>{{ item?.evento?.sectorBarrio }}</td>
                  <td>{{ item?.evento?.direccion }}</td>
                  <td>{{ item?.evento?.telefono }}</td>
                  <td>{{ item?.evento?.nombreEvento }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <ListaEquiposEvento titulo="Lista de equipos" :listaEquipos="equipos" />
        <button
          id="equiposId"
          type="button"
          data-toggle="modal"
          data-target="#modalListaEquipos"
          v-show="false"
        ></button>
        <ProcesarEvento
          titulo="Lista de equipos"
          :listaEquipos="equipos"
          :procesar="tipo === 'INSTALAR' ? procesarInstalar : procesarRetirar"
        />
        <button
          id="eventosId"
          type="button"
          data-toggle="modal"
          data-target="#modalEventos"
          v-show="false"
        ></button>
      </div>
    </div>
  </div>
</template>
<script>
import ListaEquiposEvento from "./ListaEquiposEvento.vue";
import ProcesarEvento from "./ProcesarEvento.vue";

export default {
  name: "GestionEventosPendientesTecnico",
  data() {
    return {
      equipos: [],
      idEventoSeleccionado: null,
      idEventoRutaSeleccionado: null,
      tipo: "",
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("eventos/consultarEventosXTecnico");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async instalar(item) {
      this.idEventoSeleccionado = item?.evento?.id;
      this.idEventoRutaSeleccionado = item?.id;
      this.tipo = "INSTALAR";
      this.equipos = item?.evento?.equiposFrios;
      document.getElementById("eventosId").click();
    },
    async procesarInstalar(data) {
      this.loaderSpinner();
      const formData = new FormData();
      data?.archivosArray?.map((item) => formData.append("file", item));
      formData.append("idEvento", this.idEventoSeleccionado);
      formData.append("idEventoRuta", this.idEventoRutaSeleccionado);
      formData.append("estadoEvento", data?.estadoEvento);
      formData.append("observacion", data?.observacion);
      try {
        await this.$store.dispatch("eventos/procesarEventoInstalarTecnico", {
          data: formData,
        });
      } catch (error) {
        alert("No se pudo procesar la instalación");
      } finally {
        this.loader.hide();
      }
    },
    async retirar(item) {
      this.idEventoSeleccionado = item?.evento?.id;
      this.idEventoRutaSeleccionado = item?.id;
      this.tipo = "RETIRAR";
      this.equipos = item?.evento?.equiposFrios;
      document.getElementById("eventosId").click();
    },
    async procesarRetirar(data) {
      this.loaderSpinner();
      const formData = new FormData();
      data?.archivosArray?.map((item) => formData.append("file", item));
      formData.append("idEvento", this.idEventoSeleccionado);
      formData.append("idEventoRuta", this.idEventoRutaSeleccionado);
      formData.append("estadoEvento", data?.estadoEvento);
      formData.append("observacion", data?.observacion);
      try {
        await this.$store.dispatch("eventos/procesarEventoRetirarTecnico", {
          data: formData,
        });
      } catch (error) {
        alert("No se pudo procesar el retiro");
      } finally {
        this.loader.hide();
      }
    },
    ver(equipos) {
      this.equipos = equipos;
      document.getElementById("equiposId").click();
    },

    abrirMapa(evento) {
      const { latitud, longitud } = evento;
      window.open(`https://maps.google.com/maps?q=loc:${latitud},${longitud}`);
    },
    verificarDias(fecha, hora) {
      if (fecha && hora) {
        const fecha_aux = fecha?.split("-");
        const hora_aux = hora?.split(":");
        const fecha1 = new Date(
          parseInt(fecha_aux[0]),
          parseInt(fecha_aux[1] - 1),
          parseInt(fecha_aux[2]),
          hora_aux[0],
          hora_aux[1]
        );
        const hoy = new Date();
        const diff = fecha1?.getTime() - hoy?.getTime();
        const dias = Math.round(diff / (1000 * 60 * 60 * 24));
        return dias > 1;
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    eventos() {
      return this.$store.getters["eventos/eventos"]
        ? this.$store.getters["eventos/eventos"]
        : [];
    },
    eventoSeleccionado() {
      return this.$store.getters["eventos/eventoSeleccionado"]
        ? this.$store.getters["eventos/eventoSeleccionado"]
        : [];
    },
  },
  components: { ListaEquiposEvento, ProcesarEvento },
};
</script>
<style></style>
