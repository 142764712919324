<template>
  <div>
    <div class="white-content-table">


      <i @click="consultar" class="icon-reload" title="Refrescar" style="cursor: pointer"></i>

      <div class="table-responsive">

        <table class="table table-striped">
          <thead>
          <tr>
            <th></th>
            <th>Estado</th>
            <th>Archivos</th>
            <th>Fecha</th>
            <th>Usuario</th>
            <th>Detalle</th>

          </tr>
          </thead>
          <tbody>
          <template v-for="(item) in traslados" :key="item.id">
            <tr>
              <td>
                <a class="mr-3"
                   v-if="item.estadoProceso ==='SOLICITADO'" title="Aprobar Todos"
                   href="javascript:void(0)"
                   @click.stop="seleccionarAccion({accion:'APROBAR', idArchivo: item.id})"
                > <i class="ti-check"></i></a>

                <a v-if="item.estadoProceso ==='SOLICITADO'" title="Rechazar Todos"
                   href="javascript:void(0)"
                   @click.stop="seleccionarAccion({accion:'RECHAZAR', idArchivo: item.id})"
                > <i class="ti-close text-danger"></i></a>
              </td>
              <td>
                <div
                    class="badge"
                    :class="
                      item?.estadoProceso === 'SOLICITADO'
                        ? 'badge-secondary'
                        :  item?.estadoProceso === 'APROBADO' ? 'badge-success': 'badge-secondary'
                    "
                >
                  {{ item?.estadoProceso }}
                </div>
              </td>
              <td>
                <FileDownload
                    v-if="item.nombreArchivo && item.rutaArchivo"
                    title="Archivo Datos"
                    icon="ti-file"
                    :file-name="item.nombreArchivo"
                    :folder-name="item.rutaArchivo"/>
                <FileDownload v-if="item.nombreAdjunto && item.rutaAdjunto"
                              title="Adjunto"
                              icon="ti-clip"
                              :file-name="item.nombreAdjunto"
                              :folder-name="item.rutaAdjunto"/>
              </td>

              <td>{{ item.fecha }}</td>
              <td>{{ item.usuario }}</td>
              <td>
                <a title="Visualizar"
                   href="javascript:void(0)"
                   @click.stop="toggleDetail(item.id)"
                > Ver Detalle</a>
              </td>

            </tr>

            <tr v-if="selectedId === item.id" :key="`detail-${item.id}`">
              <td colspan="6">
                <TrasladosDetalleAprobacion :id-archivo="item.id"/>
              </td>
            </tr>

          </template>

          </tbody>
        </table>

      </div>
    </div>

    <Modal
        :mensaje="mensaje"
        :method="procesarAccion"
    />
    <button
        type="button"
        v-show="false"
        id="exampleModalButton"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#exampleModal"
        data-backdrop="static"
        data-keyboard="false"
    >
      Modal messages
    </button>
  </div>
</template>
<script>
import FileDownload from "@/components/FileDownload.vue";
import TrasladosDetalleAprobacion from "@/views/procesos/traslados/components/TrasladosDetalleAprobacion.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: {Modal, TrasladosDetalleAprobacion, FileDownload},
  data() {
    return {
      displayModal: false,
      error: false,
      mensajeProceso: "",
      mensaje: "",
      idArchivo: null,
      selectedId: null,
    }
  },
  async created() {
    await this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("traslados/consultarTrasladosPendientes");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },

    async uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
    },


    toggleDetail(id) {
      this.selectedId = this.selectedId === id ? null : id;
    },

    seleccionarAccion(data) {
      this.accion = data?.accion
      this.idArchivo = data?.idArchivo
      this.mensaje = `¿Desea ${this.accion === "APROBAR" ? "aprobar" : "rechazar"} la solicitud `;
      document.getElementById("exampleModalButton").click();
    },
    async procesarAccion() {
      if (this.accion === 'APROBAR') {
        const resp = await this.$store.dispatch(
            "trasladosAprobacion/aprobarTodo",
            {
              idArchivo: this.idArchivo
            }
        );

        if (resp.success) {
          await this.consultar()
        } else {
          alert("Error en el proceso de aprobación")
        }

      } else {
        const resp = await this.$store.dispatch(
            "trasladosAprobacion/rechazarTodo",
            {
              idArchivo: this.idArchivo
            }
        );
        if (resp.success) {
          await this.consultar()
        } else {
          alert("Error en el proceso de rechazo")
        }
      }
    },
    cambiarVista() {
      this.displayEnvio = !this.displayEnvio;
      if (this.displayEnvio === true) {
        this.titleButtonView = "Vista Solicitudes"
      } else {
        this.titleButtonView = "Vista Aprobación"
      }
    }
  },
  computed: {
    traslados() {
      return this.$store.getters["traslados/trasladosPendientes"]
          ? this.$store.getters["traslados/trasladosPendientes"]
          : [];
    },
  }
}
</script>
