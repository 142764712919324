<template>
  <div class="chart-container">
    <h4 class="title">{{ title }}</h4>
    <div class="pie-chart"></div>
    <div class="legend">
      <div><span class="pendiente"></span>Pendiente ({{ chartData.pendiente }})</div>
      <div><span class="ejecutado"></span>Ejecutado ({{ chartData.ejecutado }})</div>
      <div><span class="fallido"></span>Fallido ({{ chartData.fallido }})</div>
      <div><span class="en-ruta"></span>En Ruta ({{ chartData.enRuta }})</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PieChart',
  props: {
    chartData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    conicGradient() {
      const { pendiente, ejecutado, fallido, enRuta } = this.chartData;
      let segments = [];
      let currentPercentage = 0;

      if (pendiente > 0) {
        segments.push(`#f87979 ${currentPercentage}% ${currentPercentage + pendiente}%`);
        currentPercentage += pendiente;
      }
      if (ejecutado > 0) {
        segments.push(`#a4c639 ${currentPercentage}% ${currentPercentage + ejecutado}%`);
        currentPercentage += ejecutado;
      }
      if (fallido > 0) {
        segments.push(`#FFCE56 ${currentPercentage}% ${currentPercentage + fallido}%`);
        currentPercentage += fallido;
      }
      if (enRuta > 0) {
        segments.push(`#36A2EB ${currentPercentage}% ${currentPercentage + enRuta}%`);
        currentPercentage += enRuta;
      }

      return `conic-gradient(${segments.join(', ')})`;
    }
  },
  mounted() {
    this.setChartBackground();
  },
  methods: {
    setChartBackground() {
      this.$el.querySelector('.pie-chart').style.background = this.conicGradient;
    }
  },
  watch: {
    chartData: {
      handler() {
        this.setChartBackground();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-right: 3rem;
  margin-left: 3rem;
}

.title {
  text-align: center;
  margin-bottom: 1rem;
}

.pie-chart {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
}

.pie-chart::before {
  content: '';
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.legend {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.legend div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend div span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.legend .pendiente {
  background-color: #f87979;
}

.legend .ejecutado {
  background-color: #a4c639;
}

.legend .fallido {
  background-color: #FFCE56;
}

.legend .en-ruta {
  background-color: #36A2EB;
}
</style>
