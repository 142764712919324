<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Agregar menú</h3>
          </div>
          <div class="col-md-6"></div>
        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <Form
                    @submit="submit"
                    :validation-schema="formValidation"
                    v-slot="{ errors }"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="name">Name *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="name"
                                  id="name"
                                  class="form-control"
                                  placeholder="Name"
                                  v-model.trim="formData.name"
                              />
                              <MensajeValidacion :mensaje="errors.name"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="path">Path *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="path"
                                  id="path"
                                  class="form-control"
                                  placeholder="Path"
                                  v-model.trim="formData.path"
                              />
                              <MensajeValidacion :mensaje="errors.path"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="telefono">Componente</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="componente"
                                  id="componente"
                                  class="form-control"
                                  placeholder="Componente"
                                  v-model.trim="formData.componente"
                              />
                              <MensajeValidacion :mensaje="errors.componente"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="telefono">Ícono *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="icono"
                                  id="icono"
                                  class="form-control"
                                  placeholder="Ícono"
                                  v-model.trim="formData.icon"
                              />
                              <MensajeValidacion :mensaje="errors.icono"/>
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">

                          <div class="form-check">
                            <input disabled
                                class="form-check-input ml-1"
                                type="checkbox"
                                v-model="isChecked"
                                name="padre"
                            />
                            <label class="form-check-label" for="padre">
                              Menú padre
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage :mensaje="mensaje" v-if="error"/>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                          type="submit"
                          id="form-submit"
                          class="btn btn-primary btn-general mr-2"
                      >
                        Guardar
                      </button>
                      <button
                          type="button"
                          class="btn btn-secondary btn-general-off"
                          @click="cerrar"
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Form, Field} from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";

export default {
  name: "ProveedorForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {

    const formValidation = {
      name: "required",
      icono: "required"
    };
    return {
      formData: {
        name: "",
        componente: "",
        icono: "",
      },
      error: false,
      mensaje: "",
      formValidation,
      isChecked: false
    };
  },
  mounted() {
    if (this.menuSeleccionado) {
      this.formData = {...this.menuSeleccionado};
    }
  },

  created() {
    this.id = this.$route.params.idPadre;
    if (this.id == 0) {
      this.isChecked = true
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async submit() {
      this.error = false;
      this.loaderSpinner();
      try {

        if (this.isChecked) {
          await this.$store.dispatch("menu/crear", this.formData);
        } else {
          console.log("entra en opcion con padre")
          this.formData.padre = this.id;
          await this.$store.dispatch("menu/crear", this.formData);
        }

        this.$router.push({name: "OpcionesMenu"});
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    menuSeleccionado() {
      return this.$store.getters["menu/menuSeleccionado"]
          ? this.$store.getters["menu/menuSeleccionado"]
          : null;
    },
  },
};
</script>
