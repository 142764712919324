<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de catálogos</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0"></p>
            </div>
            <div class="col-md-8">
              <button
                  @click="agregar"
                  class="btn btn-general btn-primary float-right"
              >
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-0">
                <label>Variable*</label>
                <fieldset>
                  <select
                      name="variable"
                      class="form-control"
                      v-model="variable"
                      @change="consultarByVariable"
                  >
                    <option value="" disabled>Seleccione una variable</option>
                    <option
                        v-for="item in variables"
                        :key="item"
                        :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </fieldset>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th width="5%"></th>
                <th>Variable</th>
                <th>Atributo</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in variablesByVariable" :key="item.id">
                <td>
                  <a title="Eliminar" href="javascript:void(0)" @click="eliminar(item)"
                  ><i class="ti-trash btn-icon-append"></i
                  ></a>

                  <Modal
                      mensaje="Se eliminará el archivo, ¿Está seguro?"
                      :method="procesarEliminar"
                  />
                  <button
                      type="button"
                      v-show="false"
                      id="exampleModalButton"
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      data-backdrop="static"
                      data-keyboard="false"
                  >
                    Modal messages
                  </button>


                </td>
                <td>{{ item.variable }}</td>
                <td>{{ item.atributo }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "GestionVariables",
  components: {Modal},
  data() {
    return {
      variable: "EXCLUSION_RETIRO",
      itemSelected: null
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  async mounted() {
    await this.consultarVariables();
    await this.consultarByVariable();

  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarVariables() {
      this.loaderSpinner();

      this.$store.commit("variables/setVariables", []);
      this.$store.commit("variables/setVariablesByVariable", []);

      try {
        await this.$store.dispatch("variables/consultarTodos");
        this.variable = this.$store.getters["variables/variable"]
            ? this.$store.getters["variables/variable"]
            : "EXCLUSION_RETIRO";
      } catch (error) {
        alert("No se pudo consultar las variables");
      } finally {
        this.loader.hide();
      }
    },
    async consultarByVariable() {
      this.loaderSpinner();
      this.$store.commit("variables/setVariablesByVariable", []);
      try {

        await this.$store.dispatch("variables/consultar", {
          variable: this.variable,
        });
        console.log(this.variable);
        this.$store.commit("variables/setVariable", this.variable);
      } catch (error) {
        alert("No se pudo consultar con la variable seleccionada");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      this.$router.push({name: "VariableForm"});
    },
    async editar() {
    },
    async eliminar(item) {

      this.itemSelected = item
      document.getElementById("exampleModalButton").click();


    },
    async procesarEliminar() {
      try {
        await this.$store.dispatch("variables/eliminar", {id: this.itemSelected.id});
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    variables() {
      return this.$store.getters["variables/variables"]
          ? this.$store.getters["variables/variables"]
          : [];
    },
    variablesByVariable() {
      return this.$store.getters["variables/variablesByVariable"]
          ? this.$store.getters["variables/variablesByVariable"]
          : [];
    },
  },
};
</script>
