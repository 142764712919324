<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              {{ proveedorSeleccionado ? "Editar proveedor" : "Agregar proveedor" }}
            </h3>
          </div>
          <div class="col-md-6">

          </div>
        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <Form
                    @submit="submit"
                    :validation-schema="formValidation"
                    v-slot="{ errors }"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="nombreProveedor">Nombre *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="nombreProveedor"
                                  id="nombreProveedor"
                                  class="form-control"
                                  placeholder="Nombre"
                                  v-model.trim="formData.nombreProveedor"
                              />
                              <MensajeValidacion :mensaje="errors.nombreProveedor"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="direccion">Dirección *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="direccion"
                                  id="direccion"
                                  class="form-control"
                                  placeholder="Dirección"
                                  v-model.trim="formData.direccion"
                              />
                              <MensajeValidacion :mensaje="errors.direccion"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="telefono">Teléfono *</label>
                            <fieldset>
                              <Field
                                  type="text"
                                  name="telefono"
                                  id="telefono"
                                  class="form-control"
                                  placeholder="Teléfono"
                                  v-model.trim="formData.telefono"
                              />
                              <MensajeValidacion :mensaje="errors.telefono"/>
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="tipoProveedor">Tipo Proveedor *</label>
                            <CustomVariableList
                                variable="TIPO_PROVEEDOR"
                                :selectedOptionValue="formData.tipoProveedor"
                                :selectVariable="handleSelectChange1"
                                v-model.trim="formData.tipoProveedor"
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                    <ErrorMessage :mensaje="mensaje" v-if="error"/>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button
                          type="submit"
                          id="form-submit"
                          class="btn btn-primary btn-general mr-2"
                      >
                        <i class="ti-save"></i> Guardar
                      </button>
                      <button
                          type="button"
                          class="btn btn-secondary btn-general-off"
                          @click="cerrar"
                      >
                        <i class="ti-close"></i> Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Form, Field} from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";
import CustomVariableList from "@/components/CustomVariableList.vue";

export default {
  name: "ProveedorForm",
  components: {
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
    CustomVariableList
  },
  data() {
    const formValidation = {
      telefono: "required",
      nombreProveedor: "required",
      direccion: "required",
    };
    return {
      formData: {
        telefono: "",
        nombreProveedor: "",
        direccion: "",
        tipoProveedor: ""

      },
      error: false,
      mensaje: "",
      formValidation,
    };
  },
  mounted() {
    if (this.proveedorSeleccionado) {
      this.formData = {...this.proveedorSeleccionado};
      if (this.proveedorSeleccionado.tipoSolicitudes) {
        this.formData.tipoSolicitudes = this.proveedorSeleccionado.tipoSolicitudes;
      }
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    handleSelectChange1(tipoProveedor) {
      this.formData.tipoProveedor = tipoProveedor;
    },
    async submit() {
      this.error = false;
      this.loaderSpinner();

      try {
        if (this.proveedorSeleccionado) {
          await this.$store.dispatch("proveedores/editar", {
            id: this.proveedorSeleccionado.id,
            data: this.formData,
          });
          await this.$store.commit("proveedores/setProveedorSeleccionado", null);
        } else {
          await this.$store.dispatch("proveedores/crear", this.formData);
        }
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
        await this.$store.dispatch("global/updateIsPreventNavigation", false);
        this.$router.push({name: "Proveedores"});
      }

    },

    cerrar() {
      this.$store.dispatch("global/updateIsPreventNavigation", false);
      this.$router.go(-1);
    },
  },
  computed: {
    proveedorSeleccionado() {
      return this.$store.getters["proveedores/proveedorSeleccionado"]
          ? this.$store.getters["proveedores/proveedorSeleccionado"]
          : null;
    },
  },
  created() {
    this.$store.dispatch("global/updateIsPreventNavigation", true);
  }
};
</script>
