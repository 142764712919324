import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {

    async aprobarTodo(_, data) {
        try {
            let resp = await axios.get(`/v1/traslados/${data.idArchivo}/aprobar`);
            return {
                success: true,
                data: resp.data,
            }
        } catch (error) {
            return {
                success: false,
                data: error.response.data.errors
            }
        }
    },


    async rechazarTodo(_, data) {
        try {
            let resp = await axios.get(`/v1/traslados/${data.idArchivo}/rechazar`);
            return {
                success: true,
                data: resp.data,
            }
        } catch (error) {
            return {
                success: false,
                data: error.response.data.errors
            }
        }
    },

    async aprobarSeleccion(_, data) {
        try {
            let resp = await axios.post(`/v1/traslados/aprobar-seleccion`, data);
            return {
                success: true,
                data: resp.data,
            }
        } catch (error) {
            return {
                success: false,
                data: error.response.data.errors
            }
        }
    },

}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
