<template>
  <div
      class="form-check"
      v-for="item in equipos"
      :key="item.id"
  >

    <input
        class="form-check-input ml-1"
        type="checkbox"
        :id="item.id"
        :value="item.id"
        v-model="idEquipos"
    />
    <label class="form-check-label" :for="item.id">
      <strong>{{ $config.labelPlaca }}:</strong>
      {{ item.placa }}
      <strong>Serie:</strong>
      {{ item.serie }}
      <strong>Modelo:</strong>
      {{ item.modelo }}
      <strong>Capacidad (cajas):</strong>
      {{ item.capacidadCajas }}
      <strong v-if="$config.countryFlag==='EC'">Capacidad (pies):</strong>
      {{ item.capacidadPies }}
      <strong>Logo:</strong>
      {{ item.logo }}
      <strong>Estado: </strong>
      <div
          class="badge"
          :class="
                      item.estadoEquipo === 'DISPONIBLE'
                        ? 'badge-success'
                        : 'badge-secondary'
                    "
      >
        {{ item.estadoEquipo }}
      </div>
    </label>
  </div>
</template>
<script>
export default {
  props : {
    equipos : {
      type : Object,
      required: true
    },
    selectedIdEquipos: {
      type: Array,
      required: true
    }
  },
  data (){
    return{
      idEquipos: [...this.selectedIdEquipos]
    }
  },
  methods: {
    emitIdEquipos() {
      this.$emit('update-id-equipos', this.idEquipos);
    }
  },
  emits: ['update-id-equipos'],
  watch: {
    selectedIdEquipos: {
      handler(newVal) {

        if (JSON.stringify(newVal) !== JSON.stringify(this.idEquipos)) {
          this.idEquipos = [...newVal];
        }
      },
      deep: true,
      immediate: true
    },
    idEquipos: {
      handler(newVal) {

        if (JSON.stringify(newVal) !== JSON.stringify(this.selectedIdEquipos)) {
          this.emitIdEquipos();
        }
      },
      deep: true
    }
  }
}
</script>
