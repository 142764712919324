import axios from "axios";

const state = {};

const getters = {};

const mutations = {};

const actions = {

    async porRecibir() {
        try {
            let resp = await axios.get(`/v1/traslados/por-recibir`);
            return {
                success: true,
                data: resp.data,
            }
        } catch (error) {
            return {
                success: false,
                data: error.response.data.errors
            }
        }
    },
    async recibir(_,data) {
        try {
            let resp = await axios.post(`/v1/traslados/recibir`, data);
            return {
                success: true,
                data: resp.data,
            }
        } catch (error) {
            return {
                success: false,
                data: error.response.data.errors
            }
        }
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
