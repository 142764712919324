<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Lista Negra</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0"></p>
            </div>
            <div class="col-md-12">
              <button
                  @click="agregar"
                  class="btn btn-general btn-primary float-right ml-2"
              >
                <span class="icon-plus"></span> Agregar
              </button>

              <button
                  class="btn btn-primary btn-general float-right"
                  @click="abrirModalArchivo"
              >
                <span class="icon-upload"></span> Cargar archivo
              </button>

              <ButtonDescargarTemplate template="ListaNegraTemplate.xlsx"/>

            </div>
          </div>
        </div>
        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th width="5%"></th>
                <th>{{$config.labelCodigoCliente}}</th>
                <th>{{$config.labelNombreCliente}}</th>
                <th>{{$config.labelGerenciaGeneral}}</th>
                <th>{{$config.labelGerenciaVentas}}</th>
                <th>Motivo</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in listaNegra" :key="item.id">
                <td>
                  <a href="javascript:void(0)" @click="eliminar(item.id)"
                  ><i class="ti-trash btn-icon-append"></i
                  ></a>
                </td>
                <td>{{ item?.cliente }}</td>
                <td>{{ item?.nombreCliente}}</td>
                <td>{{ item?.gerenciaGeneral}}</td>
                <td>{{ item?.gerenciaVentas}}</td>
                <td>{{ item?.motivo }}</td>
              </tr>
              </tbody>
            </table>
            <Paginacion :updatePage="updatePage"/>

            <div
                class="modal"
                tabindex="-1"
                :style="displayModal ? 'display: block;' : 'display: none;'"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Cargar archivo</h5>
                  </div>
                  <div class="modal-body">
                    <p>Seleccione archivo de datos</p>
                    <div class="input-group mb-3">
                      <input
                          type="file"
                          @change="uploadFile"
                          ref="file"
                          class="form-control"
                      />
                    </div>

                  </div>
                  <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-general-off btn-secondary"
                        data-bs-dismiss="modal"
                        @click="cerrarModalArchivo"
                    >
                      <i class="ti-close"></i> Cerrar
                    </button>
                    <button
                        type="button"
                        class="btn btn-general btn-primary"
                        @click="cargarArchivo"
                        :disabled="!archivo"
                    >
                      <i class="ti-ti-upload"></i> Cargar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Paginacion from '../../../../components/Paginacion.vue';
import ButtonDescargarTemplate from "@/components/ButtonDescargarTemplate.vue";

export default {
  name: "GestionListaNegra",
  components: {
    ButtonDescargarTemplate,
    Paginacion
  },
  data() {
    return {
      pagina: 1,
      displayModal: false,
      archivo: null,
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar(1);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      const data = {
        pagina: pagina || 1,
      };
      this.loaderSpinner();
      try {
        await this.$store.dispatch("listaNegra/consultarListaNegraPaginado", data);
        await this.$store.dispatch(
            "listaNegra/consultarListaNegraTotalPaginas"
        );
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      this.$router.push({name: "ListaNegraForm"});
    },
    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la información?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loaderSpinner();
          try {
            await this.$store.dispatch("listaNegra/eliminar", {id});
            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      });
    },
    updatePage(page) {
      this.pagina = page;
    },
    abrirModalArchivo() {
      this.displayModal = true;
      this.error = false;
    },

    cerrarModalArchivo() {
      this.displayModal = false;
      this.archivo = null;
      if (this.$refs.file) {
        this.$refs.file.value = null;
      }
    },
    async uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
    },
    async cargarArchivo() {
      try {
        this.loaderSpinner();
        const formData = new FormData();
        formData.append("file", this.archivo);
        const resp = await this.$store.dispatch(
            "listaNegra/cargarArchivo",
            formData
        );
        if (resp === "error") {
          this.error = true;
          this.loader.hide();
          return;
        }
        this.displayModal = false;
        this.archivo = null;
        if (this.$refs.file) {
          this.$refs.file.value = null;
        }
        this.loader.hide();
        this.titulo = "Cargar archivo";
        this.mensajeProceso = ["Se ha cargado el archivo con éxito"];
        document.getElementById("modalButton").click();
        await this.consultar(1);
        this.displayAprobacion = false;
      } catch (error) {
        this.error = true;
        this.loader.hide();
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    listaNegra() {
      return this.$store.getters["listaNegra/listaNegra"]
          ? this.$store.getters["listaNegra/listaNegra"]
          : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
};
</script>
<style></style>
