<template>
  <div
      class="modal fade"
      id="modalAgregarActividadZone"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAgregarActividadLabel"
      aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <form>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalAgregarActividadLabel">
              <strong>{{ titulo }}</strong>
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="method"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <h5>Tipo</h5>
              <select class="form-control" v-model="tipo">
                <option value="" disabled>Seleccione un tipo</option>
                <option value="MANTENIMIENTO PREVENTIVO">
                  MANTENIMIENTO PREVENTIVO
                </option>
                <option value="MANTENIMIENTO CORRECTIVO">
                  MANTENIMIENTO CORRECTIVO
                </option>
                <option value="CAMBIO">
                  CAMBIO
                </option>

              </select>
            </div>
            <div class="form-check" v-if="tipoTrabajo.length">
              <input
                  class="form-check-input ml-1"
                  type="radio"
                  id="tipoCorrectivo"
                  checked
              />

            </div>
            <div>
              <hr/>
              <div>
                <div v-if="tipo==='MANTENIMIENTO CORRECTIVO' || tipo==='MANTENIMIENTO PREVENTIVO' || tipo==='CAMBIO'">
                  <h5>Buscar Equipo Mantenimiento</h5>

                  <BusquedaXPlaca
                      :seleccionarEquipo="seleccionarEquipo"
                      origen="MANTENIMIENTO"
                  />

                  <DetalleEquipoLineaSingle :equipos="equipos" @update-id-equipos="updateIdEquipos"
                                            :selectedIdEquipos="idEquipos"/>

                  <hr/>

                  <h5>Checklist</h5>
                  <div class="row">
                    <div v-for="(item, index) in enunciados" :key="index"
                         class="form-group col-md-6 d-flex align-items-center justify-content-between ">
                      <label class="mb-0" :for="'input-' + index">{{ item.enunciado }}</label>
                      <input
                          v-if="item.type === 'TEXT'"
                          :id="'input-' + index"
                          type="text"
                          v-model="checklist[index].value"
                          class="form-control ml-2"
                          :placeholder="item.enunciado"
                      />
                      <div class="ml-auto">
                        <input
                            v-if="item.type === 'CHECK'"
                            :id="'input-' + index"
                            type="checkbox"
                            v-model="checklist[index].value"
                            class="form-check-input"
                        />
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <hr/>
              <h6>Observación</h6>
              <div class="form-group">

                <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    v-model="observacion"
                ></textarea>
              </div>

            </div>
          </div>
          <div class="modal-footer">

            <button
                type="button"
                class="btn btn-primary btn-general"
                data-dismiss="modal"
                @click="procesar"
                :disabled="!tipo || idEquipos===0 ||  !observacion"
            >
              <i class="ti-plus"></i> Agregar
            </button>
            <button
                type="button"
                class="btn btn-secondary btn-general-off"
                data-dismiss="modal"
                @click="cerrar"
            >
              <i class="ti-close"></i> Cerrar
            </button>
          </div>
          <button
              type="button"
              v-show="false"
              id="btProcesar"
              class="btn btn-primary btn-general"
              data-dismiss="modal"
              @click="
              agregar({
                tipo,
                tipoTrabajo,
                observacion,
                idEquipos,
                equipoCambio,
                archivosArray,
                solicitud:
                  origen === 'BODEGA'
                    ? solicitudProveedorSeleccionada.solicitud.id
                    : rutaSeleccionada.solicitudProveedor.solicitud.id,
                checklist
              })
            "
          >
            Procesar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import BusquedaXPlaca from "./BusquedaXPlaca.vue";
import DetalleEquipoLineaSingle from "@/components/DetalleEquipoLineaSingle.vue";
import {id} from "postcss-selector-parser";

export default {
  components: {DetalleEquipoLineaSingle, BusquedaXPlaca},
  props: {
    cerrar: {type: Function},
    agregar: {type: Function},
    titulo: String,
    origen: String,
  },
  data() {
    return {
      codigoCliente: "",
      tipo: "",
      observacion: "",
      idEquipos: 0,
      tipoTrabajo: "",
      archivosArray: [],
      equipoCambio: 0,
      checklist: [],
      enunciados: [
        {
          enunciado: 'Amperaje del compresor: A',
          type: 'TEXT'
        },
        {
          enunciado: 'Voltaje del local: V',
          type: 'TEXT'
        },
        {
          enunciado: 'Condensador limpio: %',
          type: 'TEXT'
        },
        {
          enunciado: 'Limpieza del ventilador',
          type: 'CHECK'
        },
        {
          enunciado: 'Calibración del controlador',
          type: 'CHECK'
        },
        {
          enunciado: 'Verificación de sistemas LED',
          type: 'CHECK'
        },
        {
          enunciado: 'Revisión de contactos eléctricos',
          type: 'CHECK'
        },
        {
          enunciado: 'Revisión y limpieza del sistema de drenaje',
          type: 'CHECK'
        },
        {
          enunciado: 'Revisión, limpieza y ajuste de hermeticidad',
          type: 'CHECK'
        },
        {
          enunciado: 'Accesorios de imagen. Desgaste %',
          type: 'TEXT'
        },
        {
          enunciado: 'Malas practicas de operación (ver Wellington)',
          type: 'CHECK'
        },
        {
          enunciado: 'Prueba de funcionamiento',
          type: 'CHECK'
        },

      ]
    };
  },
  mounted() {
    this.consultarEquiposCliente();
  },
  created() {
    this.inicializarCheckList()
  },
  methods: {
    id,
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarEquiposCliente() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("equipos/consultarEquipoXCliente", {
          cliente:
              this.origen === "BODEGA"
                  ? this.solicitudProveedorSeleccionada?.solicitud?.codigoCliente
                  : this.rutaSeleccionada?.solicitudProveedor?.solicitud
                      ?.codigoCliente,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    seleccionarEquipo(datos) {
      if (datos.tipo === "ADD") {
        this.idEquipos = datos.valor;
      }
      if (datos.tipo === "DELETE") {
        this.idEquipos = 0;
      }
    },

    inicializarCampos() {
      this.tipo = "";
      this.observacion = "";
      this.idEquipos = 0;
      this.tipoTrabajo = "";
      this.inicializarCheckList();


    },

    procesar() {
      document.getElementById("btProcesar").click();
      this.inicializarCampos();
    },
    updateIdEquipos(newIdEquipos) {
      this.idEquipos = newIdEquipos;

    },
    inicializarCheckList() {
      this.checklist = this.enunciados.map(item => ({
        enunciado: item.enunciado,
        value: item.type === 'CHECK' ? false : ''
      }));
    }
  },
  computed: {
    solicitudProveedorSeleccionada() {
      return this.$store.getters[
          "solicitudesProveedor/solicitudProveedorSeleccionada"
          ]
          ? this.$store.getters[
              "solicitudesProveedor/solicitudProveedorSeleccionada"
              ]
          : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    equipos() {
      return this.$store.getters["equipos/equipos"]
          ? this.$store.getters["equipos/equipos"]
          : {};
    },
    rutaSeleccionada() {
      return this.$store.getters["ruta/rutaSeleccionada"]
          ? this.$store.getters["ruta/rutaSeleccionada"]
          : [];
    },
  },
};
</script>
<style scoped>
@media (min-width: 992px) {
  .modal-dialog {
    max-width: 50%;
  }
}
</style>
