<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Procesar requerimiento</h3>

          </div>
        </div>
        <div class="white-content-general mb-4">
          <form name="f1" id="formElement">

            <CabeceraRequerimiento/>

            <div class="ml-4 mt-4">


            </div>
            <div class="mt-4 ml-4">
              <div>
                <h5 class="card-title">Actividades</h5>
                <div>
                  <a
                      href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#modalAgregarActividadZone"
                      data-backdrop="static"
                      data-keyboard="false"
                  >
                    <i class="icon ti-plus"></i> Agregar actividad</a
                  >
                </div>
                <br/>
                <div
                    class="table-responsive mb-3"
                    v-if="actividades.length > 0"
                >
                  <table class="table table-sm table-borderless">
                    <thead>
                    <tr>
                      <th></th>
                      <th>Tipo Actividad</th>
                      <th>Equipo Mantenimiento</th>
                      <th>Observación</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in actividades" :key="item.id">
                      <td style="width: 10%">

                        <a title="Eliminar" href="javascript:void(0)" @click="eliminar(item)"
                        ><i class="ti-trash btn-icon-append"></i
                        ></a>

                        <Modal id="modalEliminar"
                               mensaje="Se eliminará el registro, ¿Está seguro?"
                               :method="procesarEliminar"
                        />
                        <button
                            type="button"
                            v-show="false"
                            id="exampleModalButton"
                            class="btn btn-primary"
                            data-toggle="modal"
                            data-target="#modalEliminar"
                            data-backdrop="static"
                            data-keyboard="false"
                        >
                          Modal messages
                        </button>

                      </td>
                      <td>{{ item?.tipoActividad }}</td>
                      <td>{{ item?.equipo?.placa }}</td>
                      <td>{{ item?.observacion }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div>
              <div class="mt-4 ml-4">
                <h5 class="card-title">Atender requerimiento</h5>
                <div class="form-check">
                  <input
                      class="form-check-input ml-1"
                      type="radio"
                      id="ejecutado"
                      value="EJECUTADO"
                      v-model="requerimiento"
                      @click="seleccionarRequerimiento"
                  />
                  <label class="form-check-label" for="ejecutado">
                    EJECUTADO
                  </label>
                </div>
                <div class="form-check">
                  <input
                      class="form-check-input ml-1"
                      type="radio"
                      id="fallido"
                      value="FALLIDO"
                      v-model="requerimiento"
                      @click="seleccionarRequerimiento"
                  />
                  <label class="form-check-label" for="fallido">
                    FALLIDO
                  </label>
                </div>
                <div class="form-group" v-if="mostrarMotivo">
                  <label>Motivo</label>
                  <select class="form-control" v-model="motivo">
                    <option value="" disabled>Seleccione un motivo</option>
                    <option value="DIRECCIÓN NO ENCONTRADA">
                      DIRECCIÓN NO ENCONTRADA
                    </option>
                    <option value="EQUIPO NO ENCONTRADO">
                      EQUIPO NO ENCONTRADO
                    </option>
                    <option value="OTRO MOTIVO">OTRO MOTIVO</option>
                  </select>
                </div>
                <h5 v-if="variable">Novedad</h5>
                <CustomVariableMultiple
                    :variable="variable"
                    :selectVariable="selectEjecutadoFallido"
                />

                <CustomAdjuntos
                    :tipoSolicitud="
                  solicitudProveedorSeleccionada.solicitud.tipoSolicitud
                "
                    :uploadFile="uploadFile"
                    columna="12"
                />


                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Observación</label>
                  <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      v-model="observacion"
                  ></textarea>
                </div>
                <button
                    type="button"
                    class="btn btn-primary btn-block mt-3"
                    data-toggle="modal"
                    data-target="#modalFinalizarRequerimiento"
                    @click="confirmar"
                    :disabled="!requerimiento"
                >
                  <i class="ti-check-box"></i>
                  Finalizar requerimiento
                </button>
              </div>
            </div>
          </form>
        </div>

        <Modal id="modalFinalizarRequerimiento" :mensaje="mensaje" :method="procesar"/>

        <ModalMessages
            :mensaje="mensajeProceso"
            :titulo="titulo"
            :method="cerrar"
        />
        <AgregarActividadZone
            titulo="Agregar actividad"
            :agregar="agregarActividad"
            origen="BODEGA"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ModalMessages from "../../../components/ModalMessages.vue";
import Modal from "../../../components/Modal.vue";
import CabeceraRequerimiento from "./CabeceraRequerimiento.vue";
import CustomVariableMultiple from "@/components/CustomVariableMultiple.vue";
import AgregarActividadZone from "@/components/AgregarActividadZone.vue";
import CustomAdjuntos from "@/components/CustomAdjuntos.vue";

export default {
  components: {
    CustomAdjuntos,

    AgregarActividadZone,
    ModalMessages,
    CabeceraRequerimiento,
    Modal,
    CustomVariableMultiple,
  },
  data() {
    return {
      observacion: "",
      idEquipos: [],
      requerimiento: "",
      mostrarMotivo: false,
      motivo: "",
      mensaje: "",
      mensajeProceso: null,
      titulo: "",
      ejecutarCerrar: false,
      tipoTrabajo: "",
      tipoProceso: "",
      mostrarActividad: false,
      mostrarRequerimiento: false,
      infoAdicional: [],
      variable: null,
      archivosArray: [],
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.$store.commit("equipos/setEquipos", null);
  },
  mounted() {
    this.consultarActividadesXSolicitud();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarActividadesXSolicitud() {

      try {
        this.loaderSpinner();
        await this.$store.dispatch(
            "actividadesMantenimiento/consultarXSolicitud",
            {
              idSolicitud:
              this.solicitudProveedorSeleccionada?.solicitud?.id,
            }
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    seleccionarRequerimiento(e) {
      if (e.target.value === "FALLIDO") {
        this.mostrarMotivo = true;
        this.variable = `FALLIDO_MANTENIMIENTO`;
      } else {
        this.mostrarMotivo = false;
        this.variable = `EJECUTADO_MANTENIMIENTO`;
      }
    },

    confirmar() {
      this.mensaje = "¿Desea continuar con el proceso de mantenimiento?";
    },
    async procesar() {
      if (
          this.requerimiento === "" ||
          !this.solicitudProveedorSeleccionada?.id ||
          (this.requerimiento === "FALLIDO" && this.motivo.length === 0) ||
          this.observacion.length === 0 ||
          this.infoAdicional.length === 0
      ) {
        this.mensajeProceso = [
          "Por favor ingrese toda la información requerida antes de continuar con el proceso de mantenimiento",
        ];
        this.titulo = "Advertencia";
        this.ejecutarCerrar = false;
        document.getElementById("modalButton").click();
        return;
      }

      const formData = new FormData();
      this.archivosArray?.map((item) => formData.append("file", item));
      formData.append("estadoSolicitud", this.requerimiento);
      formData.append(
          "motivo",
          this.requerimiento === "EJECUTADO" ? "" : this.motivo
      );
      formData.append("infoAdicional", this.infoAdicional);
      formData.append(
          "idSolicitudProveedor",
          this.solicitudProveedorSeleccionada.id
      );
      formData.append("observacion", this.observacion);

      try {
        this.loaderSpinner();

        const resp = await this.$store.dispatch(
            "solicitudesProveedor/procesarMantenimientoZone",
            {
              data: formData
            }
        );
        this.mensajeProceso = [resp];
        this.titulo = "Mantenimiento";
        this.ejecutarCerrar = true;
        document.getElementById("modalButton").click();
      } catch (error) {
        this.mensaje = error;
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      if (this.ejecutarCerrar) {
        this.$router.go(-1);
      }
    },
    seleccionarEquipo(datos) {
      if (datos.tipo === "ADD") {
        this.idEquipos.push(datos.valor);
      }
      if (datos.tipo === "DELETE") {
        const idEquiposFiltrados = this.idEquipos.filter(
            (item) => item !== datos.valor
        );
        this.idEquipos = idEquiposFiltrados;
      }
    },
    async agregarActividad(data) {
      try {

        this.loaderSpinner();
        const formData = new FormData();
        data?.archivosArray?.map((item) => formData.append("file", item));
        formData.append(
            "equipo", data?.idEquipos
        );
        formData.append(
            "equipoCambio", data?.equipoCambio
        );
        formData.append("observacion", data?.observacion);
        formData.append("tipoActividad", data?.tipo);
        formData.append("tipoCorrectivo", data?.tipoTrabajo);
        formData.append("solicitud", this.solicitudProveedorSeleccionada?.solicitud?.id);


        data?.checklist?.map((item, index) => formData.append(`checklist[${index}].enunciado`, item.enunciado));
        data?.checklist?.map((item, index) => formData.append(`checklist[${index}].value`, item.value));


        const resp = await this.$store.dispatch(
            "actividadesMantenimiento/crearActividad",
            {
              data: formData,
              idSolicitud:
              this.solicitudProveedorSeleccionada?.solicitud?.id,
            }
        );
        if (resp[0] === "ERROR") {
          this.mensajeProceso = [resp[1]];
          this.titulo = "Actividades";
          this.ejecutarCerrar = false;
          document.getElementById("modalButton").click();
        }
        this.idEquipos = null;

      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    selectEjecutadoFallido(infoAdicional) {
      this.infoAdicional = infoAdicional;
    },

    uploadFile(file) {
      const selectedFile = file?.target?.files[0];
      if (!selectedFile) return;
      const findedFile = this.archivosArray?.findIndex(
          (item) => item.name === selectedFile.name
      );

      let arraySelectedFiles = [];
      if (findedFile === -1) {
        arraySelectedFiles = [...this.archivosArray, selectedFile];
      } else {
        arraySelectedFiles = [...this.archivosArray];
      }
      this.archivosArray = arraySelectedFiles;
    },

    async eliminar(item) {
      this.itemSelected = item
      document.getElementById("exampleModalButton").click();
    },
    async procesarEliminar() {
      try {
        await this.$store.dispatch("actividadesMantenimiento/eliminar", {id: this.itemSelected.id});
        await this.consultarActividadesXSolicitud();
      } catch (error) {
        console.log(error)
      }
    }
  },
  computed: {
    solicitudProveedorSeleccionada() {
      return this.$store.getters["solicitudesProveedor/solicitudProveedorSeleccionada"]
          ? this.$store.getters["solicitudesProveedor/solicitudProveedorSeleccionada"]
          : [];
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    equipos() {
      return this.$store.getters["equipos/equipos"]
          ? this.$store.getters["equipos/equipos"]
          : [];
    },
    actividades() {
      return this.$store.getters["actividadesMantenimiento/actividades"]
          ? this.$store.getters["actividadesMantenimiento/actividades"]
          : [];
    },
  },
};
</script>
