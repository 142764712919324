<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-3">
            <h3 class="font-weight-bold">Gestión de Usuarios Bodega</h3>
          </div>

        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0">

              </p>
            </div>
            <div class="col-md-8">
              <button @click="agregar" class="btn btn-primary btn-general float-right">
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>
        <TablaFiltro :data="usuariosBodega" :columns="columnas"
                     @delete="eliminar" :show-edit="false"/>
      </div>
    </div>
  </div>
</template>
<script>
import TablaFiltro from "@/components/TablaFiltro.vue";

export default {
  name: "GestionUsuarios",
  components:{
    TablaFiltro
  },
  data() {
    return {
      columnas: [
        { campo: "nombre", tipo: "Text", nombre:"Nombre" },
        { campo: "usuario", tipo: "Text", nombre:"Usuario" },
        { campo: "email", tipo: "Text",  nombre:"E-mail"  },
        { campo: "rol", tipo: "String",  nombre:"Rol"  },
        { campo: "proveedor", tipo: "String",  nombre:"Proveedor"  },
        { campo: "bodega", tipo: "String",  nombre:"Bodega"  },
      ],
      usuariosBodega : []
    };

  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      this.loaderSpinner();
      try {
       const resp =  await this.$store.dispatch("usuariosBodega/consultarTodos");
       if(resp.success){
         this.usuariosBodega = resp.data.result
       }else{
         alert("No se pudo realizar la consulta");
       }
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    async agregar() {
      this.$router.push({ name: "PermisoUsuarioForm" });
    },

    async eliminar(id) {
      this.$swal({
        text: "¿Está seguro de eliminar la asignación de bodega?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {

          try {
            await this.$store.dispatch("usuariosBodega/eliminar", {id: id});
            this.$swal({
              text: "Registro eliminado con éxito.",
              icon: "success",
            });
            await this.consultar()
            this.loader.hide();
          } catch (error) {
            this.$swal("", error.message, "error");
          } finally {
            this.loader.hide();
          }
        }
      });
    },
  },
  computed: {

    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
  },
};
</script>
