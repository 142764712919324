<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Requerimientos Asignados</h3>
          </div>
          <div class="col-md-6">
            
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <div class="mt-3">
                <span class="card-title">Total registros: </span>
                <span class="text-primary txt-celeste txt-bold">{{ totalRegistros }}</span>
              </div>
            </div>
            <div class="col-md-6" v-if="rutas.length">
              <button @click="abrirMapa" class="btn btn-primary btn-general float-right" v-if="!mostrarMapa">
                <span class="icon-map"></span> Mapa
              </button>
              <button @click="abrirTabla" class="btn btn-primary btn-general float-right" v-if="!mostrarTabla">
                <span class="icon-file"></span> Tabla
              </button>
            </div>
          </div>

        </div>

        <div class="white-content-table">
          <div v-if="mostrarTabla">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th>Id</th>
                    <th>Ticket</th>
                    <th>Bodega</th>
                    <th>Cliente</th>
                    <th>Tipo</th>
                    <th>Fecha solicitud</th>
                    <th>Días Transcurridos</th>
                    <th>Gerencia general</th>
                    <th>Gerencia ventas</th>
                    <th>ECB</th>
                    <th>Nombre</th>
                    <th>Establecimiento</th>
                    <th>Dirección</th>
                    <th>Tipo de caso</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in rutas" :key="item.id">
                    <td>
                      <a href="javascript:void(0)" @click="procesar(item)"
                        ><i class="ti-angle-right"></i
                      ></a>
                    </td>
                    <td>{{ item.solicitudProveedor.solicitud.id }}</td>
                    <td>{{ item.solicitudProveedor.solicitud.numeroTicket }}</td>
                    <td>{{ item.solicitudProveedor.bodega.nombre }}</td>
                    <td>{{ item.solicitudProveedor.solicitud.codigoCliente }}</td>
                    <td>
                      <strong>{{
                        item.solicitudProveedor.solicitud.tipoSolicitud
                      }}</strong>
                    </td>
                    <td>
                      {{ item.solicitudProveedor.solicitud.fechaSolicitud }}
                    </td>
                    <td>
                      <div
                        class="badge"
                        :class="
                          item.solicitudProveedor.solicitud
                            .diasTranscurridosLabolables < 3
                            ? 'badge-success'
                            : item.solicitudProveedor.solicitud
                                .diasTranscurridosLabolables > 5
                            ? 'badge-danger'
                            : 'badge-warning'
                        "
                      >
                        {{
                          item.solicitudProveedor.solicitud
                            .diasTranscurridosLabolables
                        }}
                        día(s)
                      </div>
                    </td>
                    <td>
                      {{ item.solicitudProveedor.solicitud.gerenciaGeneral }}
                    </td>
                    <td>
                      {{ item.solicitudProveedor.solicitud.gerenciaVentas }}
                    </td>
                    <td>{{ item.solicitudProveedor.solicitud.ecb }}</td>
                    <td>{{ item.solicitudProveedor.solicitud.nombre }}</td>
                    <td>
                      {{
                        item.solicitudProveedor.solicitud.nombreEstablecimiento
                      }}
                    </td>
                    <td>{{ item.solicitudProveedor.solicitud.direccion }}</td>
                    <td>{{ item.solicitudProveedor.solicitud.tipoCaso }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <MapaTransporte
          :coordenadas="coordenadas"
          :seleccionarPunto="procesarMapa"
          v-if="mostrarMapa"
        />
      </div>
    </div>
  </div>
</template>
<script>
import MapaTransporte from "./MapaTransporte.vue";

export default {
  name: "GestionSolicitudesTransporte",
  components: { MapaTransporte },
  data() {
    return {
      displayModal: false,
      error: false,
      idSeleccionadosProxy: [],
      idSeleccionados: [],
      mostrar: false,
      displayConfirmacion: false,
      mensaje: "",
      solicitudesFiltradas: [],
      tipoFiltro: "",
      mostrarTabla: true,
      mostrarMapa: false,
      coordenadas: []
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  mounted() {
    this.consultar();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar() {
      try {
        this.loaderSpinner();
        await this.$store.dispatch("ruta/consultarRutaVehiculos", {
          idBodegaVehiculo: this.usuarioBodega?.bodegaVehiculo?.id,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    abrirMapa() {
      this.configurarCoordenadas();
      this.mostrarTabla = false;
      this.mostrarMapa = true;
    },
    abrirTabla() {
      this.mostrarTabla = true;
      this.mostrarMapa = false;
    },
    configurarCoordenadas() {
      let coordenadas = [];
      this.rutas.map((item) =>
        coordenadas.push({
          lat: item.solicitudProveedor.solicitud.latitud,
          lng: item.solicitudProveedor.solicitud.longitud,
          id: item.id,
          requerimiento: item,
        })
      );
      this.coordenadas = coordenadas;
    },
    async procesar(item) {
      await this.$store.commit("ruta/setRutaSeleccionada", item);
      if (item.solicitudProveedor.solicitud.tipoSolicitud === "RETIRO") {
        this.$router.push({
          name: "ProcesarRetiroTransporteForm",
        });
        return;
      }
      if (item.solicitudProveedor.solicitud.tipoSolicitud === "SOLICITUD") {
        this.$router.push({
          name: "ProcesarInstalacionTransporteForm",
        });
        return;
      }
    },
    async procesarMapa(item){
      await this.$store.commit("ruta/setRutaSeleccionada", item.requerimiento);
      if (item.requerimiento.solicitudProveedor.solicitud.tipoSolicitud === "RETIRO") {
        this.$router.push({
          name: "ProcesarRetiroTransporteForm",
        });
        return;
      }
      if (item.requerimiento.solicitudProveedor.solicitud.tipoSolicitud === "SOLICITUD") {
        this.$router.push({
          name: "ProcesarInstalacionTransporteForm",
        });
        return;
      }
    }
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    rol() {
      return this.$store.state.auth.rol;
    },
    usuarioBodega() {
      return this.$store.getters["usuariosBodega/usuarioBodega"]
        ? this.$store.getters["usuariosBodega/usuarioBodega"]
        : {};
    },
    rutas() {
      return this.$store.getters["ruta/rutas"]
        ? this.$store.getters["ruta/rutas"]
        : [];
    },
    totalRegistros() {
      return this.rutas.length;
    },
  },
};
</script>
<style></style>
