<template>
  <div>

    <!-- Modal -->
    <div v-if="mostrarModal && enableScanner===true" class="modal fade show" style="display: block;"
         tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Lector de Código de Barras</h5>
            <button type="button" class="close" @click="cerrarModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <StreamBarcodeReader
                torch
                no-front-cameras
                @decode="onDecode"
                @loaded="onLoaded"
            />
          </div>
        </div>
      </div>
    </div>


    <div class="form-check">
      <input
        class="form-check-input ml-1"
        type="checkbox"
        @click="habilitarBusquedaXPlaca"
        :id="`busqueda${origen}`"
      />
      <label class="form-check-label" for="busqueda">
        {{ $config.labelTitleBusquedaEquipo }}
        <a v-if="enableScanner===true" title="Scan" style="cursor: pointer" @click="mostrarScanner">
            <span class="icon-camera ml-2" style="color: black; font-size: x-large">
        </span>
        </a>
      </label>

    </div>
    <div
      class="input-group col-md-12 mt-2 no-padding"
      v-if="mostrarBuscarXPlaca"
    >
      <input
        type="text"
        class="form-control"
        placeholder="Buscar por placa"
        v-model="placa"
        aria-label="Text input with segmented dropdown button"
      />

      <div class="input-group-append">
        <button
          type="button"
          class="btn btn-outline-secondary btn-general btn-buscar"
          @click="buscarXPlaca"
          :disabled="placa.length === 0"
        >
          <span class="icon-search"></span> Buscar
        </button>
      </div>
    </div>
    <div
      class="form-check"
      v-for="(item, $index) in equiposEncontrados"
      :key="$index"
    >
      <input
        v-if="origen !== 'MANTENIMIENTO'  && $config.countryFlag==='EC'"
        class="form-check-input ml-1"
        type="checkbox"
        :id="item?.id"
        :value="item?.id"
        @click="seleccionarFila"
        :disabled="
          (origen === 'CAMBIO' && item?.estadoEquipo !== 'ENCI')
        "
      />

      <input
          v-else
          class="form-check-input ml-1"
          type="checkbox"
          :id="item?.id"
          :value="item?.id"
          @click="seleccionarFila"

      />


      <label class="form-check-label" :for="item?.id">
        <strong>Placa:</strong>
        {{ item?.placa }}
        <strong>Serie:</strong>
        {{ item?.serie }}
        <strong>Modelo:</strong>
        {{ item?.modelo }}
        <strong>Capacidad (cajas):</strong>
        {{ item?.capacidadCajas }}
        <strong>Capacidad (pies):</strong>
        {{ item?.capacidadPies }}
        <strong>Logo:</strong>
        {{ item?.logo }}
        <strong>Estado: </strong>
        <div
          class="badge"
          :class="
            item?.estadoEquipo === 'DISPONIBLE'
              ? 'badge-success'
              : 'badge-secondary'
          "
        >
          {{ item?.estadoEquipo }}
        </div>
      </label>
      <hr/>
    </div>
    <div>
      <p class="text-danger">{{ mensaje }}</p>
    </div>
  </div>
</template>
<script>
import {StreamBarcodeReader} from "@teckel/vue-barcode-reader";

export default {
  components: {StreamBarcodeReader},
  data() {
    return {
      equiposEncontrados: [],
      mostrarBuscarXPlaca: false,
      placa: "",
      mensaje: "",
      mostrarModal: false,
      enableScanner: false
    };
  },
  props: {
    seleccionarEquipo: { type: Function },
    origen: String,
  },
  mounted(){
    this.consultarParametros()
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    habilitarBusquedaXPlaca() {
      this.mostrarBuscarXPlaca = !this.mostrarBuscarXPlaca;
      this.reset()
      this.equiposEncontrados = []
    },
    async buscarXPlaca() {
      this.mensaje = "";
      try {
        this.loaderSpinner();
        const resp = await this.$store.dispatch(
          "equipos/consultarEquipoXPlaca",
          { placa: this.placa }
        );
        if (!resp) {
          return;
        }

        const equipoEncontrado = this.equiposEncontrados?.find(
          (item) => item?.id === resp?.id
        );

        if (!equipoEncontrado) {
          this.equiposEncontrados.push(resp);

          await this.$nextTick(() => {
            const checkbox = document.getElementById(resp.id);
            if (checkbox) {
              checkbox.checked = true;
              this.seleccionarEquipo({tipo: "ADD", valor: parseInt(resp.id)});
            }
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    seleccionarFila(e) {
      let checked = e?.target?.checked;
      let valor = e?.target?.value;
      if (checked) {
        this.seleccionarEquipo({ tipo: "ADD", valor: parseInt(valor) });
      } else {
        this.seleccionarEquipo({ tipo: "DELETE", valor: parseInt(valor) });
      }
    },
    reset() {
      this.placa = "";
    },
    cerrarModal() {
      this.mostrarModal = false;
    },
    playBeep() {
      const context = new (window.AudioContext || window.webkitAudioContext)();
      const oscillator = context.createOscillator();
      oscillator.type = 'square';  // 'sine', 'triangle', 'sawtooth'
      oscillator.frequency.setValueAtTime(440, context.currentTime);
      oscillator.connect(context.destination);
      oscillator.start();
      oscillator.stop(context.currentTime + 0.1);
    },
    onDecode(result) {
      this.placa = result
      this.playBeep()
      this.cerrarModal()
      this.buscarXPlaca()
    },

    onLoaded() {
      console.log('loaded')
    },
    mostrarScanner() {
      this.mostrarModal = true
    },
    async consultarParametros() {
      const resp = await this.$store.dispatch(
          "commons/consultarParametro",
          {clave: "BARCODE_ENABLE"}
      );
      if (resp) {
        if ("1" === resp?.data?.result) {
          this.enableScanner = true
        }
      }
    }
  },
  computed: {
    solicitudProveedorSeleccionada() {
      return this.$store.getters[
        "solicitudesProveedor/solicitudProveedorSeleccionada"
      ]
        ? this.$store.getters[
            "solicitudesProveedor/solicitudProveedorSeleccionada"
          ]
        : {};
    },
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 40%;
}

.modal-content {
  max-height: 90vh;
  overflow-y: auto;
}
</style>
