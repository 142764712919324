import axios from "axios";

const state = {
  variablesByVariable: [],
  variables: [],
  variable:null
};

const getters = {
  variables: (state) => state.variables,
  variablesByVariable: (state) => state.variablesByVariable,
  variable: (state) => state.variable,
};

const mutations = {
  setVariables: (state, variables) => {
    state.variables = variables;
  },
  setVariablesByVariable: (state, variablesByVariable) => {
    state.variablesByVariable = variablesByVariable;
  },
  setVariable: (state, variable) => {
    state.variable = variable;
  },
};

const actions = {

  async crear(_, data) {
    try {
      await axios.post(`/v1/variables`, data);
    } catch (error) {
      return error;
    }
  },

  async consultarTodos({ commit }) {
    try {
      let res = await axios.get(`/v1/variables`);
      commit("setVariables", res?.data?.result);
    } catch (error) {
      return error;
    }
  },

  async consultar({ commit }, data) {
    try {
      let res = await axios.get(`/v1/variables/${data.variable}`);
      commit("setVariablesByVariable", res?.data?.result);
      return res?.data?.result;
    } catch (error) {
        commit("setVariablesByVariable", []);
      return [];
    }
  },
  async eliminar({ commit, state }, data) {
    try {
      let res = await axios.delete(`/v1/variables/${data.id}`);
      let copia = state.variablesByVariable;
      const filtrados = copia.filter(
        (item) => item.id !== res?.data?.result?.id
      );
      commit("setVariablesByVariable", filtrados);
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
