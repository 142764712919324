<template>
  <div class="col-md-8 col-sm-12 offset-lg-2 offset-sm-0 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              {{ usuarioSeleccionado ? "Editar usuario" : "Agregar usuario" }}
            </h3>
          </div>
        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <Form @submit="submit" :validation-schema="formValidation" v-slot="{ errors }">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="nombre">Nombre *</label>
                            <fieldset>
                              <Field type="text" name="nombre" id="nombre" class="form-control"
                                     placeholder="Nombre" v-model.trim="formData.nombre"/>
                              <MensajeValidacion :mensaje="errors.nombre"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="email">Email *</label>
                            <fieldset>
                              <Field type="text" name="email" id="email" class="form-control" placeholder="Email"
                                     v-model.trim="formData.email" @keyup="validateEmailDomain"/>
                              <MensajeValidacion :mensaje="errors.email"/>
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6" v-show="false">
                          <div class="form-group">
                            <label for="clave">Clave *</label>
                            <fieldset :disabled="usuarioSeleccionado || disablePasswd">
                              <Field type="password" name="clave" id="clave" class="form-control" placeholder="Clave"
                                     v-model.trim="formData.clave"/>
                              <MensajeValidacion :mensaje="errors.clave"/>
                            </fieldset>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Rol *</label>
                            <fieldset>
                              <Field
                                  as="select"
                                  name="rol"
                                  class="form-control"
                                  placeholder="Rol"
                                  v-model.trim="formData.rol"
                              >
                                <option value="" disabled>
                                  Seleccione un rol
                                </option>
                                <option
                                    v-for="rol in roles"
                                    :key="rol?.id"
                                    :value="rol?.descripcion"
                                >
                                  {{ rol?.descripcion }}
                                </option>
                              </Field>
                              <MensajeValidacion :mensaje="errors.rol"/>
                            </fieldset>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="!this.usuarioSeleccionado" >
                          <div class="form-group">
                            <label for="usuario">Clave *</label>

                            <PasswordValidation id="clave1" v-model.trim="formData.clave"
                                                @validation-changed="handlePasswordValidation"
                                                @password-changed="handlePasswordChange"
                                                :is-disabled="disablePasswd"
                            />
                            <MensajeValidacion :mensaje="errors.clave"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage :mensaje="mensaje" v-if="error"/>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <button type="submit" id="form-submit" :disabled="error"
                              class="btn btn-primary btn-general mr-2">
                        Guardar
                      </button>
                      <button type="button"
                              class="btn btn-secondary btn-general-off" @click="cerrar">
                        Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Form, Field} from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";
import PasswordValidation from "../../../../components/PasswordValidation.vue";

export default {
  name: "UsuarioForm",
  components: {
    PasswordValidation,
    ErrorMessage,
    Form,
    Field,
    MensajeValidacion,
  },
  data() {

    return {
      formData: {
        nombre: "",
        email: "",
        clave: "",
        rol: ""
      },
      error: false,
      mensaje: "",
      errorMail: "",
      disablePasswd: false,
      validDomains: ['ab-inbev.com']
    };
  },
  mounted() {
    if (this.usuarioSeleccionado) {
      this.formData = {...this.usuarioSeleccionado};
    }
    this.consultarRoles();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async handlePasswordValidation(isValid) {
      this.error = !isValid;

    },
    async handlePasswordChange(clave) {
      this.formData.clave = clave;
    },
    async consultarRoles() {
      try {
        await this.$store.dispatch("roles/consultarRoles");
      } catch (error) {
        alert("No se pudo realizar la consulta de roles");
      }
    },
    async submit() {
      this.error = false
      this.loaderSpinner();
      try {
        if (this.usuarioSeleccionado) {
          await this.$store.dispatch("usuarios/editar", {
            id: this.usuarioSeleccionado.id,
            data: this.formData,
          });
          await this.$store.commit("usuarios/setUsuarioSeleccionado", null);
          this.$router.push({name: "Usuarios"});
        } else {
          const resp = await this.$store.dispatch("usuarios/crear", this.formData);
          if (!resp.error) {
            this.$router.push({name: "Usuarios"});
          } else {
            alert(resp.message)
          }
        }
      } catch (error) {
        alert(error)
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    validateEmailDomain() {
      const email = this.formData.email;
      const domain = email.split('@')[1];
      if (domain && this.validDomains.includes(domain)) {
        this.formValidation.clave = '';
        this.formData.clave = '';
        this.disablePasswd = true;
      } else {
        this.formValidation.clave = 'required';
        this.disablePasswd = false;
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    usuarioSeleccionado() {
      return this.$store.getters["usuarios/usuarioSeleccionado"]
          ? this.$store.getters["usuarios/usuarioSeleccionado"]
          : null;
    },
    roles() {
      return this.$store.getters["roles/roles"]
          ? this.$store.getters["roles/roles"]
          : null;
    },

    formValidation() {
      if (this.usuarioSeleccionado) {
        return {
          nombre: "required",
          email: "required",
          rol: "required"
        };
      } else {
        return {
          nombre: "required",
          email: "required",
          clave: "required",
          rol: "required"
        };
      }
    },
  }
};
</script>
