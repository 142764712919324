<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-4">
            <h3 class="font-weight-bold">Solicitudes Baja</h3>
          </div>
        </div>

        <div v-if="mostrarAgregar" class="white-content-general mb-4">
          <div class="row">

            <div class="col-md-12">
              <button  @click="agregar" class="btn btn-primary btn-general float-right">
                <span class="icon-plus"></span> Agregar
              </button>
            </div>
          </div>
        </div>

        <div class="white-content-table">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>

                <th>Estado</th>
                <th>{{ this.$config.labelPlaca }}</th>
                <th>{{ this.$config.labelActivoId }}</th>
                <th>{{ this.$config.labelGerenciaGeneral }}</th>
                <th>{{ this.$config.labelGerenciaVentas }}</th>
                <th>Proveedor</th>
                <th>Solicitante (Técnico)</th>
                <th>Motivo</th>
                <th>Adjuntos</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in bajaSolicitadas" :key="item.id">

                <td>
                  <div
                      class="badge"
                      :class="
                      item.estadoProceso === 'PENDIENTE'
                        ? 'badge-secondary'
                        :  item.estadoProceso === 'APROBADO' ? 'badge-success': 'badge-danger'
                    "
                  >
                    {{ item.estadoProceso }}
                  </div>
                </td>
                <td>{{item?.placa}}</td>
                <td>{{item?.idEquipo}}</td>
                <td>{{item?.direccionVentas}}</td>
                <td>{{item?.gerenciaVentas}}</td>
                <td>{{item?.proveedor}}</td>
                <td>{{item.solicitante}}</td>
                <td>{{item.motivo}}</td>
                <td><FileDownloadMultiple title="Adjuntos" icon="ti-clip" :files="item.adjuntos"/></td>

              </tr>
              </tbody>
            </table>
            <Paginacion :updatePage="updatePage"/>
          </div>
        </div>


      </div>
    </div>
  </div>

</template>

<script>
import Paginacion from "@/components/Paginacion.vue";
import FileDownloadMultiple from "@/components/FileDownloadMultiple.vue";

export default {
  components: {FileDownloadMultiple, Paginacion},
  data() {
    return {
      pagina: 1,
      mostrarAgregar : true
    }
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  mounted() {
    this.consultar(1);
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },

    async agregar() {
      this.$router.push({name: "BajaSolicitadaTecnicoForm"});
    },
    updatePage(page){
      this.pagina = page;
    },
    async consultar(pagina) {

      const data = {
        pagina: pagina || 1,
      };
      this.loaderSpinner();
      try {
        await this.$store.dispatch("bajaSolicitadaTecnico/consultarBajasSolicitadaPaginado", data);
        await this.$store.dispatch(
            "bajaSolicitadaTecnico/consultarBajasSolicitadaTotalPaginas"
        );
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    rol() {
      if (!this?.$store?.state?.auth?.rol) {
        this.logout();
        return;
      }
      return this.$store.state.auth.rol;
    },
    bajaSolicitadas() {
      return this.$store.getters["bajaSolicitadaTecnico/bajas"]
          ? this.$store.getters["bajaSolicitadaTecnico/bajas"]
          : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },
  },
}
</script>
