<template>
  <GestionBloqueos />
</template>
<script>
import GestionBloqueos from "./components/GestionBloqueos";
export default {
  components: { GestionBloqueos },
  mounted() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>