<template>
  <GestionProveedores />
</template>
<script>
import GestionProveedores from "./components/GestionProveedores";
export default {
  components: { GestionProveedores },
  mounted() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>
