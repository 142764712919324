import axios from "axios";

const state = {
    mensaje: "",
};

const getters = {};

const mutations = {
    setMensaje: (state, mensaje) => {
        state.mensaje = mensaje;
    },
};

const actions = {

    async download({commit}, data) {
        try {
            let resp = await axios.post(`/v1/download/file`, data, {responseType: "arraybuffer"});
            if (resp?.data) {
                commit("setMensaje", "Descarga exitosa");
                return resp?.data
            } else {
                commit("setMensaje", "Descarga fallida");
                return null;
            }
        } catch (error) {
            return error;
        }
    },

    async consultarParametro(_, data) {
        try {
            let resp = await axios.get(`/v1/parametros/${data.clave}`);
            return {
                success: true,
                data: resp.data,
            }
        } catch (error) {
            return {
                success: false,
                data: error.response.data.errors
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
