<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-4">
            <h3 class="font-weight-bold">Consulta de solicitudes</h3>
          </div>
        </div>
        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-6">
              <p class="lh-m mb-0">
                <span class="card-title">Total registros: </span
                ><span class="txt-celeste font-weight-bold fs-l">{{
                  totalRegistros
                }}</span>
              </p>
            </div>
            <div class="col-md-6">
              <a
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#modalFiltros"
                  data-backdrop="static"
                  data-keyboard="false"
                  class="btn btn-primary btn-general float-right mb-2 mb-md-0 "
              >
                <i class="ti-filter menu-icon"></i> Filtrar</a
              >

              <button
                  v-if="$config.countryFlag==='EC'"
                  @click="descargar"
                  class="btn btn-primary btn-general float-right mr-3"
              >
                <span class="icon-download"></span> Descargar
              </button>

              <button v-else
                  @click="descargarZona"
                  class="btn btn-primary btn-general float-right mr-3"
              >
                <span class="icon-download"></span> Descargar
              </button>

            </div>
          </div>
        </div>

        <div class="white-content-table">
          <form name="f1" id="formElement" class="mb-2">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th></th>
                  <th>Solicitud</th>
                  <th>Ticket</th>
                  <th>Tipo Solicitud</th>
                  <th>Status Ticket</th>
                  <th>Origen Ticket</th>
                  <th>Bodega</th>
                  <th>Tecnico</th>
                  <th>Fecha de solicitud</th>
                  <th>Dias Transcurridos</th>
                  <th>POCID</th>
                  <th>Nombre</th>
                  <th>{{ $config.labelGerenciaGeneral }}</th>
                  <th>{{ $config.labelGerenciaVentas }}</th>
                  <th>Dirección</th>
                  <th>Observación</th>

                  <th v-if="$config.countryFlag==='EC'">Capacidad</th>
                  <th v-if="$config.countryFlag==='EC'">Nivel de riesgo</th>
                  <th v-if="$config.countryFlag==='EC'">Mediana</th>
                  <th v-if="$config.countryFlag==='EC'">ECB</th>
                  <th v-if="$config.countryFlag==='EC'">Establecimiento</th>


                </tr>
                </thead>
                <tbody>
                <tr v-for="item in solicitudes" :key="item.id">
                  <td>
                    <a
                        title="Ver adjuntos"
                        href="javascript:void(0)"
                        @click="consultarAdjuntos(item?.solicitud)"
                        data-toggle="modal"
                        data-target="#modalEditUbicacion"
                        data-backdrop="static"
                        data-keyboard="false"
                    ><i class="ti-clip"></i></a
                    >&nbsp;
                    <a
                        title="Consultar actividades"
                        href="javascript:void(0)"
                        @click="consultarActividades(item.solicitud)"
                        data-toggle="modal"
                        data-target="#modalActividades"
                    ><i class="ti-exchange-vertical"></i
                    ></a>
                  </td>
                  <td>{{ item.solicitud }}</td>
                  <td>{{ item.numeroTicket }}</td>
                  <td>{{ item.tipoSolicitud }}</td>
                  <td>
                    <div
                        class="badge"
                        :class="
                          item?.estadoSolicitudProveedor === 'EJECUTADO'
                            ? 'badge-success'
                            : item?.estadoSolicitudProveedor === 'EN_RUTA'
                            ? 'badge-warning'
                            : 'badge-danger'
                        "
                    >
                      {{ item?.estadoSolicitudProveedor }}
                    </div>
                  </td>
                  <td>{{ item.tipoCaso }}</td>
                  <td>{{ item.bodega }}</td>
                  <td>{{ item.tecnico }}</td>
                  <td>{{ item.fechaSolicitud }}</td>
                  <td>
                    <div
                        class="badge"
                        :class="
                          item.diasTranscurridosLabolables < 3
                            ? 'badge-success'
                            : item.diasTranscurridosLabolables > 5
                            ? 'badge-danger'
                            : 'badge-warning'
                        "
                    >
                      {{ item.diasTranscurridosLabolables }} día(s)
                    </div>
                  </td>
                  <td>{{ item.codigoCliente }}</td>
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.gerenciaGeneral }}</td>
                  <td>{{ item.gerenciaVentas }}</td>
                  <td>{{ item.direccion }}</td>
                  <td>{{ item.observacionSolicitudProveedor }}</td>

                  <td v-if="$config.countryFlag==='EC'">{{ item.capacidadEeffMediana }}</td>
                  <td v-if="$config.countryFlag==='EC'">{{ item.prioridad }}</td>
                  <td v-if="$config.countryFlag==='EC'">{{ item.medianaUltimoTrimestres }}</td>
                  <td v-if="$config.countryFlag==='EC'">{{ item.ecb }}</td>
                  <td v-if="$config.countryFlag==='EC'">{{ item.nombreEstablecimiento }}</td>


                </tr>
                </tbody>
              </table>
              <br/>
              <div class="d-flex justify-content-center">
                <nav aria-label="Page navigation example" v-if="paginas">
                  <ul class="pagination">
                    <li class="page-item" :disabled="pagina === 1">
                      <a class="page-link" @click="setPreviewPage">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li
                        v-for="page in paginas"
                        :key="page"
                        class="page-item"
                        :class="{ active: pagina === page }"
                    >
                      <a class="page-link" @click="setPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" :disabled="pagina === paginas">
                      <a class="page-link" @click="setNextPage">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </form>
          <div class="row mt-2" v-if="rol !== 'ROLE_CXC'">
            <div class="col-md-12">

            </div>
          </div>
        </div>
        <Adjuntos titulo="Lista de archivos" :listaAdjuntos="adjuntos"/>
        <button
            id="adjuntosId"
            type="button"
            data-toggle="modal"
            data-target="#modalAdjuntos"
            v-show="false"
        ></button>
        <Actividades :listaActividades="actividades" titulo="Actividades"/>
        <Filtros
            titulo="Seleccionar filtros"
            :consultar="consultarXFiltros"
            entidad="solicitudes_proveedor_view"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Filtros from "../../../components/CustomFilters.vue";
import Adjuntos from "../../../components/Adjuntos.vue";
import Actividades from "../../../components/Actividades.vue";

export default {
  name: "ConsultaSolicitudes",
  components: { Filtros, Adjuntos, Actividades},
  data() {
    return {
      condiciones: [],
      mensajeProceso: [],
      titulo: "",
      pagina: 1,
      totalRegistros: 0,
      inicializarPagina: false,
      paginaFiltros: 1,
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.$store.commit("solicitudes/inicializar");
  },
  mounted() {
    this.consultarXFiltros(this.data)
  },

  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarXFiltros(data) {
      this.condiciones = data?.condiciones;

      this.loaderSpinner();
      try {
        await this.$store.dispatch("solicitudes/consultarSolicitudesXFiltros", {
          pagina: data?.inicializarPagina ? 1 : this.pagina,
          condiciones: data?.condiciones || [],
        });
        this.totalRegistros = this.$store.getters["solicitudes/totalRegistros"];
        if (data?.inicializarPagina) {
          this.setPage(1);
          this.inicializarPagina = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    setPage(page) {
      this.pagina = page;
    },
    setNextPage() {
      if (this.pagina < this.totalPaginas) {
        this.pagina = this.pagina + 1;
      }
    },
    setPreviewPage() {
      if (this.pagina > 1) {
        this.pagina = this.pagina - 1;
      }
    },
    async consultarAdjuntos(solicitud) {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("adjuntos/consultarAdjuntos", {
          idSolicitud: solicitud,
        });
        document.getElementById("adjuntosId").click();
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
    async descargar() {
      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch("solicitudes/exportar");
        if (resp) {
          const url = window.URL.createObjectURL(new Blob([resp]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `requerimientos.csv`);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },

    async descargarZona() {

      if (this.condiciones === undefined) {
        this.condiciones = [];
      }
      this.loaderSpinner();
      try {
        let

            entidad = "solicitudes_proveedor_export_view_zona"
        const resp = await this.$store.dispatch("exportar/exportar", {
          entidad: entidad,
          filtro: {
            condiciones: this.condiciones
          }
        });
        if (resp) {
          const url = window.URL.createObjectURL(new Blob([resp]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `solicitudes.csv`);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },

    async consultarActividades(solicitud) {
      this.loaderSpinner();
      try {
        await this.$store.dispatch(
            "actividadesMantenimiento/consultarXSolicitud",
            {
              idSolicitud: solicitud,
            }
        );
      } catch (error) {
        alert("No se pudo realizar la consulta de las actividades");
      } finally {
        this.loader.hide();
      }
    },
  },

  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    solicitudes() {
      return this.$store.getters["solicitudes/solicitudes"]
          ? this.$store.getters["solicitudes/solicitudes"]
          : [];
    },
    rol() {
      return this.$store.state.auth.rol;
    },
    totalPaginas() {
      return this.$store.getters["solicitudes/totalPaginas"]
          ? this.$store.getters["solicitudes/totalPaginas"]
          : 0;
    },
    paginas() {
      let numeroPaginas = 10;
      numeroPaginas = Math.min(numeroPaginas, this.totalPaginas);
      let first = this.pagina - Math.floor(numeroPaginas / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.totalPaginas - numeroPaginas + 1);
      return [...Array(numeroPaginas)].map((k, i) => i + first);
    },
    adjuntos() {
      return this.$store.getters["adjuntos/adjuntos"]
          ? this.$store.getters["adjuntos/adjuntos"]
          : [];
    },
    actividades() {
      return this.$store.getters["actividadesMantenimiento/actividades"]
          ? this.$store.getters["actividadesMantenimiento/actividades"]
          : [];
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultarXFiltros({
          condiciones: this.condiciones,
          inicializarPagina: false,
        });
      }
    },
  },
};
</script>
<style></style>
