<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">Gestión de Solicitudes Rechazadas</h3>
          </div>
        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <button
                  class="btn btn-primary btn-general float-right mb-2 ml-2"
                  @click="agregarExcepcion(selectedItems)"
                  data-toggle="modal"
                  data-target="#modalExcepcion"
                  data-backdrop="static"
                  data-keyboard="false"
              >
                <span class="icon-play"></span> Procesar
              </button>
              <button
                  @click="exportExcel"
                  class="btn btn-primary btn-general float-right"
                  :disabled="solicitudesRechazadas.length === 0"
              >
                <span class="icon-download"></span> Descargar
              </button>
            </div>
          </div>
        </div>

        <div class="white-content-table">
          <div class="row">
            <div class="col-md-6">
              <div class="form-check-inline mt-2">
                <input
                    class="form-check-input"
                    type="radio"
                    value="TICKET"
                    v-model="tipoFiltro"
                />
                <label class="form-check-label radio-margin" for="ticket">
                  {{ this.$config.labelTicket }}
                </label>

                <input
                    class="form-check-input"
                    type="radio"
                    value="CLIENTE"
                    v-model="tipoFiltro"
                />
                <label class="form-check-label mr-2" for="cliente">
                  {{ this.$config.labelCodigoCliente }}
                </label>

                <input
                    class="form-check-input"
                    type="radio"
                    value="ACTIVOID"
                    v-model="tipoFiltro"
                />
                <label class="form-check-label radio-margin" for="ticket">
                  {{ this.$config.labelActivoId }}
                </label>

                <div class="form-check-inline ml-2">
                  <span style="cursor: pointer" @click="consultar(1)" class="form-check-label mr-2" for="todos">
                    <i class="ti-eraser"></i>Limpiar Filtro
                  </span>
                </div>
              </div>
              <div class="input-group col-md-12 no-padding">
                <input
                    type="text"
                    class="form-control"
                    v-model="valorBusqueda"
                    aria-label="Text input with segmented dropdown button"
                />
                <div class="input-group-append">
                  <button
                      type="button"
                      class="btn btn-outline-secondary btn-general btn-buscar mb-2 mb-md-0"
                      @click="buscarSolicitud"
                      :disabled="
                      valorBusqueda.length === 0 || tipoFiltro === 'TODOS'
                    "
                  >
                    <span class="icon-search"></span> Buscar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th></th>
                <th>Id Solicitud</th>
                <th>{{ this.$config.labelActivoId }}</th>
                <th>{{ this.$config.labelTicket }}</th>
                <th>{{ this.$config.labelCodigoCliente }}</th>
                <th>{{ this.$config.labelTipo }}</th>
                <th>{{ this.$config.labelFechaSolicitud }}</th>
                <th>Dias Trascurridos</th>
                <th>{{ this.$config.labelGerenciaGeneral }}</th>
                <th>{{ this.$config.labelGerenciaVentas }}</th>
                <th>{{ this.$config.labelNombreCliente }}</th>
                <th>{{ this.$config.labelEstablecimiento }}</th>
                <th>{{ this.$config.labelDireccion }}</th>
                <th>{{ this.$config.labelTipoCaso }}</th>
                <th>Motivo</th>
                <th v-if="$config.countryFlag==='EC'">Nivel de riesgo</th>
                <th v-if="$config.countryFlag==='EC'">Capacidad</th>
                <th v-if="$config.countryFlag==='EC'">Mediana</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in solicitudesRechazadas" :key="item">
                <td>
                  <input type="checkbox" @click="selectItem(item)"/>
                </td>
                <td>{{ item.solicitud.id }}</td>
                <td>{{ item.solicitud?.activoId }}</td>
                <td>{{ item.solicitud?.numeroTicket }}</td>
                <td>{{ item.solicitud?.codigoCliente }}</td>
                <td>{{ item.solicitud?.tipoSolicitud }}</td>
                <td>{{ item.solicitud.fechaSolicitud }}</td>
                <td>
                  <div
                      class="badge"
                      :class="
                        item.solicitud.diasTranscurridosLabolables < 3
                          ? 'badge-success'
                          : item.solicitud.diasTranscurridosLabolables > 5
                          ? 'badge-danger'
                          : 'badge-warning'
                      "
                  >
                    {{ item.solicitud.diasTranscurridosLabolables }} día(s)
                  </div>
                </td>
                <td>{{ item.solicitud.gerenciaGeneral }}</td>
                <td>{{ item.solicitud.gerenciaVentas }}</td>
                <td>{{ item.solicitud.nombre }}</td>
                <td>{{ item.solicitud.nombreEstablecimiento }}</td>
                <td>{{ item.solicitud.direccion }}</td>
                <td>{{ item.solicitud.tipoCaso }}</td>
                <td>{{ item.motivo }}</td>
                <td v-if="$config.countryFlag==='EC'">{{ item.solicitud.prioridad }}</td>
                <td v-if="$config.countryFlag==='EC'">{{ item.solicitud.capacidadEeffMediana }}</td>
                <td v-if="$config.countryFlag==='EC'">{{ item.solicitud.medianaUltimoTrimestre }}</td>
              </tr>
              </tbody>
            </table>
            <br/>
            <div class="d-flex justify-content-center">
              <nav aria-label="Page navigation example" v-if="paginas">
                <ul class="pagination">
                  <li class="page-item" :disabled="pagina === 1">
                    <a class="page-link" @click="setPreviewPage">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li
                      v-for="page in paginas"
                      :key="page"
                      class="page-item"
                      :class="{ active: pagina === page }"
                  >
                    <a class="page-link" @click="setPage(page)">{{ page }}</a>
                  </li>
                  <li class="page-item" :disabled="pagina === paginas">
                    <a class="page-link" @click="setNextPage">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <AgregarExcepcion
                titulo="Agregar excepción"
                :agregar="procesarAgregarExcepcion"
            />
            <ModalMessages
                :mensaje="mensajeProceso"
                titulo="Agregar excepción"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AgregarExcepcion from "./AgregarExcepcion.vue";
import ModalMessages from "../../../components/ModalMessages.vue";
import {saveExcel} from "@progress/kendo-vue-excel-export";

export default {
  name: "GestionSolicitudesRechazadas",
  components: {AgregarExcepcion, ModalMessages},
  data() {
    return {
      pagina: 1,
      mensaje: "",
      id: "",
      valorBusqueda: "",
      tipoFiltro: "",
      mensajeProceso: [],
      selectedItems: [],
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
    this.consultar(1);

  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultar(pagina) {
      this.loaderSpinner();
      this.valorBusqueda = "";
      try {
        const data = {
          pagina: pagina || 1,
        };
        await this.$store.dispatch(
            "solicitudesRechazadas/consultarSolicitudesXPagina",
            data
        );
        await this.$store.dispatch(
            "solicitudesRechazadas/consultarTotalPaginas"
        );
        this.pagina = pagina;
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    setPage(page) {
      this.pagina = page;
    },
    setNextPage() {
      if (this.pagina < this.totalPaginas) {
        this.pagina = this.pagina + 1;
      }
    },
    setPreviewPage() {
      if (this.pagina > 1) {
        this.pagina = this.pagina - 1;
      }
    },
    agregarExcepcion(solicitud) {
      this.$store.commit(
          "solicitudesRechazadas/setSolicitudRechazadaSeleccionada",
          solicitud
      );
    },
    selectItem(check) {
      const findedOption = this.selectedItems?.findIndex(
          (item) => item === check.id
      );

      let arraySelectedItems = [];
      if (findedOption === -1) {
        arraySelectedItems = [...this.selectedItems, check.id];
      } else {
        arraySelectedItems = this.selectedItems.filter(
            (item) => item !== check.id
        );
      }
      this.selectedItems = arraySelectedItems;
    },
    async procesarAgregarExcepcion(data) {
      this.loaderSpinner();
      try {

        await this.$store.dispatch("solicitudesRechazadas/agregarExcepciones", {
          observacion: data.observacion,
          solicitudRechazadas: this.selectedItems,
        });
        this.mensajeProceso = ["Se agregó la excepxión con éxito"];
        document.getElementById("modalButton").click();
      } catch (error) {
        this.mensajeProceso = ["No se pudo agregar la excepción"];
        document.getElementById("modalButton").click();
      } finally {
        this.loader.hide();
      }
    },
    async buscarSolicitud() {
      this.loaderSpinner();
      try {

        if (this.tipoFiltro === "TICKET") {
          await this.$store.dispatch(
              "solicitudesRechazadas/consultarSolicitudXTicket",
              {
                ticket: this.valorBusqueda,
              }
          );
        }
        if (this.tipoFiltro === "CLIENTE") {
          await this.$store.dispatch(
              "solicitudesRechazadas/consultarSolicitudXCliente",
              {
                codigoCliente: this.valorBusqueda,
              }
          );
        }
        if (this.tipoFiltro === "ACTIVOID") {
          await this.$store.dispatch(
              "solicitudesRechazadas/consultarSolicitudXActivoId",
              {
                activoId: this.valorBusqueda,
              }
          );
        }

        document.querySelectorAll('input[type="radio"]').forEach(radio => {
          radio.checked = false;
        });
        this.tipoFiltro = null;
        this.valorBusqueda = "";
      } catch (error) {
        console.log(error);
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
    exportExcel() {
      saveExcel({
        data: this.solicitudesRechazadas,
        fileName: "solicitudes-rechazadas",
        columns: [
          {field: "solicitud.id", title: "Id Solicitud"},
          {field: "solicitud.numeroTicket", title: this.$config.labelTicket},
          {field: "solicitud.codigoCliente", title: this.$config.labelCodigoCliente},
          {field: "solicitud.tipoSolicitud", title: this.$config.labelTipo},
          {field: "solicitud.fechaSolicitud", title: this.$config.labelFechaSolicitud},
          {
            field: "solicitud.diasTranscurridosLabolables",
            title: "Días Transcurridos",
          },

          {field: "solicitud.gerenciaGeneral", title: this.$config.labelGerenciaGeneral},
          {field: "solicitud.gerenciaVentas", title: this.$config.labelGerenciaVentas},
          {field: "solicitud.nombre", title: this.$config.labelNombreCliente},
          {
            field: "solicitud.nombreEstablecimiento",
            title: this.$config.labelEstablecimiento,
          },
          {field: "solicitud.direccion", title: this.$config.labelDireccion},
          {field: "solicitud.tipoCaso", title: this.$config.labelTipoCaso},
          {field: "solicitud.prioridad", title: "Prioridad"},
          {field: "solicitud.capacidadEeffMediana", title: "Capacidad"},
          {field: "solicitud.medianaUltimoTrimestre", title: "Mediana"},
        ],
      });
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    solicitudesRechazadas() {
      return this.$store.getters["solicitudesRechazadas/solicitudesRechazadas"]
          ? this.$store.getters["solicitudesRechazadas/solicitudesRechazadas"]
          : [];
    },
    totalPaginas() {
      return this.$store.getters["solicitudesRechazadas/totalPaginas"]
          ? this.$store.getters["solicitudesRechazadas/totalPaginas"]
          : [];
    },
    paginas() {
      let numeroPaginas = 10;
      numeroPaginas = Math.min(numeroPaginas, this.totalPaginas);
      let first = this.pagina - Math.floor(numeroPaginas / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.totalPaginas - numeroPaginas + 1);
      return [...Array(numeroPaginas)].map((k, i) => i + first);
    },
  },
  watch: {
    pagina(newPage, oldPage) {
      if (!newPage) return;

      if (newPage !== oldPage) {
        this.consultar(newPage);
      }
    },

  },
};
</script>
