<template>

  <div class="dropdown">
    <a title="Adjuntos"
       class="dropdown-toggle float-right mr-2"
       type="button"
       id="tipo"
       data-toggle="dropdown"
       aria-haspopup="true"
       aria-expanded="false"
    >
      <i class="ti-clip"></i>
    </a>
    <div class="dropdown-menu " aria-labelledby="adjunto">
      <div v-for="file in files" :key="file.nombreArchivo">
        <a  class="dropdown-item" href="#"
           @click="download(file)">{{ file.nombreArchivo }}</a>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    files: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(file => file.nombreArchivo && file.rutaArchivo);
      }
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      dropdownVisibleFile: false,
      loader: null
    };
  },
  methods: {

    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async download(file) {
      this.loaderSpinner();
      try {
        const resp = await this.$store.dispatch("commons/download", {
          fileName: file.nombreArchivo,
          folderName: file.rutaArchivo
        });
        if (resp) {
          const url = window.URL.createObjectURL(new Blob([resp]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file.nombreArchivo);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    }
  }
}
</script>
<style>

</style>

