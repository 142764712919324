<template>
  <div
      class="col-md-12 grid-margin stretch-card"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Cambiar contraseña
            </h3>
          </div>
          <div class="col-md-6">

          </div>

        </div>

        <div class="white-content-general mb-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-sample">
                <Form
                    @submit="submit"
                    :validation-schema="formValidation"
                    v-slot="{ errors }"
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="clave">Nueva Clave *</label>
                            <PasswordValidation v-model="formData.clave" @validation-changed="handlePasswordValidation"
                                                @password-changed="handlePasswordChange"
                            />
                            <MensajeValidacion :mensaje="errors.clave"/>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="confirmarClave">Confirmar clave *</label>
                            <fieldset>
                              <Field
                                  type="password"
                                  name="confirmarClave"
                                  id="confirmarClave"
                                  class="form-control"
                                  placeholder="Confirmar clave"
                                  v-model="formData.confirmarClave"
                              />
                              <MensajeValidacion
                                  :mensaje="errors.confirmarClave"
                              />
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage :mensaje="mensaje" v-if="error"/>

                  </div>

                  <div class="row">
                    <div class="col-lg-12">
                      <button
                          type="submit"
                          id="form-submit"
                          class="btn btn-primary btn-general mr-2"
                          :disabled="!error"
                      >
                        Guardar
                      </button>
                      <button
                          type="button"
                          class="btn btn-secondary btn-general-off"
                          @click="cerrar"
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {Form, Field} from "vee-validate";
import MensajeValidacion from "../../../../components/MensajeValidacion.vue";
import ErrorMessage from "../../../../components/ErrorMessage";
import zxcvbn from "zxcvbn";
import PasswordValidation from "../../../../components/PasswordValidation.vue";

export default {
  name: "CambiarClaveForm",
  components: {
    ErrorMessage,
    Form,
    MensajeValidacion,
    PasswordValidation,
    Field
  },
  data() {
    const formValidation = {
      confirmarClave: "required",
    };
    return {
      formData: {
        clave: "",
        confirmarClave: "",
      },
      error: false,
      mensaje: "",
      formValidation,
    };
  },
  async beforeUnmount() {
    if (this.cambiarClave) {
      await this.$store.dispatch("auth/logout");
      this.$router.replace("/");
    }
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async handlePasswordValidation(isValid) {
      this.error = isValid;

    },
    async handlePasswordChange(clave) {
      this.formData.clave = clave;
    },
    async submit() {
      if (this.rangoSeguridad.rango < 2) {
        this.mensaje = "Ingrese una contraseña más segura";
        return;
      }
      if (this.formData.clave !== this.formData.confirmarClave) {
        this.mensaje = "No coinciden las claves";
        return;
      }
      this.error = false;
      this.loaderSpinner();

      try {
        const resp = await this.$store.dispatch("usuarios/cambiarClave", {
          claveNueva: this.formData.clave,
        });
        if (resp === "ERROR") {
          this.mensaje = "No se pudo realizar el cambio de clave";
          return;
        }
        this.$router.push({name: "Login"});
      } catch (error) {
        this.error = true;
        this.mensaje = "No se pudo guardar la información";
      } finally {
        this.loader.hide();
      }
    },
    cerrar() {
      this.$router.go(-1);
    },
  },
  computed: {
    cambiarClave() {
      return this.$store.getters["auth/cambiarClave"]
          ? this.$store.getters["auth/cambiarClave"]
          : null;
    },
    rangoSeguridad() {
      let ancho = {
        widthNow: "width: 0%",
        valueNow: 0,
        clase: "progress-bar progress-bar-striped",
        rango: 0,
      };
      if (!this.formData.clave) {
        return ancho;
      }
      const rango = zxcvbn(this.formData.clave)?.score;

      if (rango <= 1) {
        ancho = {
          widthNow: "width: 25%",
          valueNow: 25,
          clase: "progress-bar progress-bar-striped bg-danger",
          rango,
        };
      }
      if (rango === 2) {
        ancho = {
          widthNow: "width: 50%",
          valueNow: 75,
          clase: "progress-bar progress-bar-striped bg-warning",
          rango,
        };
      }
      if (rango === 3) {
        ancho = {
          widthNow: "width: 75%",
          valueNow: 75,
          clase: "progress-bar progress-bar-striped",
          rango,
        };
      }
      if (rango === 4) {
        ancho = {
          widthNow: "width: 100%",
          valueNow: 100,
          clase: "progress-bar progress-bar-striped bg-success",
          rango,
        };
      }
      return ancho;

    },
  },
};
</script>
