<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Gestión Base Objetivo
            </h3>
          </div>
        </div>
        <br />
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Cliente</th>
                <th>Wellington</th>
                <th>Días mora</th>
                <th>Vencido</th>
                <th>Mes 1</th>
                <th>Mes 2</th>
                <th>Mes 3</th>
                <th>Estado bloqueo</th>
                <th>Pagado</th>
                <th>Fecha proceso pagado</th>
              </tr>
            </thead>
            <tbody>
              <td></td>
              <tr v-for="item in baseObjetivos" :key="item.id">
                <td></td>
                <td>{{ item.cliente }}</td>
                <td>{{ item.wellington }}</td>
                <td>{{ item.diasMora }}</td>
                <td>{{ item.vencido }}</td>
                <td>{{ item.mes1 }}</td>
                <td>{{ item.mes2 }}</td>
                <td>{{ item.mes3 }}</td>
                <td>{{ item.estadoBloqueo }}</td>
                <td>{{ item.pagado }}</td>
                <td>{{ item.fechaProcesoPagado }}</td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "GestionBaseObjetivo",
  data() {
    return {
      mensaje: "",
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },
  mounted() {
    this.consultarBaseObjetivos();
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    async consultarBaseObjetivos() {
      this.loaderSpinner();
      try {
        await this.$store.dispatch("cartera/consultarBaseObjetivos");
      } catch (error) {
        alert("No se pudo realizar la consulta");
      } finally {
        this.loader.hide();
      }
    },
  },
  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
        ? this.$store.getters["auth/isLogged"]
        : false;
    },
    baseObjetivos() {
      return this.$store.getters["cartera/baseObjetivos"]
        ? this.$store.getters["cartera/baseObjetivos"]
        : [];
    }
  }
};
</script>
<style>
.no-padding {
  padding: 0;
  margin: 0;
}

.radio-margin {
  margin-right: 15px;
}
</style>
