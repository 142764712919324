<template>
  <div class="mb-3">
    <h5 class="mb-3">Datos del Equipo</h5>
    <h6 class="text-black">
      {{ $config.labelPlaca }}: {{ equipoSeleccionado?.placa }}
    </h6>
    <h6 class="text-black">
      Categoria: {{ equipoSeleccionado?.tipoEquipo }}
    </h6>
    <h6 class="text-black">
      Subcategoria: {{ equipoSeleccionado?.subCategoria }}
    </h6>
    <h6 class="text-black">
      Descripción: {{ equipoSeleccionado?.descripcion }}
    </h6>
    <h6 class="text-black">
      Serie: {{ equipoSeleccionado?.serie }}
    </h6>
    <h6 class="text-black">
      Fabricante: {{ equipoSeleccionado?.fabricante }}
    </h6>
    <h6 class="text-black">
      Capacidad: {{ equipoSeleccionado?.capacidadPies }}
    </h6>
    <h6 class="text-black">
      Ubicación actual: {{ equipoSeleccionado?.ubicacion }}
    </h6>
  </div>
</template>
<script>
export default {
  props : {
    equipoSeleccionado: Object
  }
}
</script>
