<template>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body" v-if="isLogged">
        <div class="row mb-4">
          <div class="col-md-6">
            <h3 class="font-weight-bold">
              Gestión de Solicitudes
            </h3>
          </div>
        </div>

        <div class="white-content-general mb-4">

          <ButtonDescargarTemplate template="SolicitudesTemplate.xlsx"/>

          <div class="row">
            <div class="col-md-4">
              <p class="lh-m mb-0">
                <span class="card-title">Total registros: </span><span
                  class="txt-celeste text-primary font-weight-bold fs-l">{{ totalRegistros }}</span>
              </p>
            </div>
            <div class="col-md-8">

              <a
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#modalFiltros"
                  data-backdrop="static"
                  data-keyboard="false"
                  class="btn btn-primary btn-general float-right mb-2 mb-md-0 ml-2"
              >
                <i class="ti-filter menu-icon"></i> Filtrar</a
              >
              <Filtros
                  titulo="Seleccionar filtros"
                  :consultar="consultarXFiltros"
                  entidad="solicitudes_pendientes_view"
              />

              <button
                  class="btn btn-primary btn-general float-right"
                  @click="abrirModalArchivo"
              >
                <span class="icon-upload"></span> Cargar archivo
              </button>
              <button
                  class="btn btn-primary btn-general float-right mr-2"
                  @click="agregarRequerimiento"
              >
                <span class="icon-plus"></span> Agregar
              </button>

              <div class="row">
                <div
                    class="modal"
                    tabindex="-1"
                    :style="displayModal ? 'display: block;' : 'display: none;'"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">Cargar archivo</h5>
                      </div>
                      <div class="modal-body">
                        <p>Seleccione el archivo</p>
                        <div class="input-group mb-3">
                          <input
                              type="file"
                              @change="uploadFile"
                              ref="file"
                              class="form-control"
                          />
                        </div>
                        <div class="alert alert-danger" role="alert" v-if="error">
                          No se pudo cargar el archivo
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-general-off btn-secondary"
                            data-bs-dismiss="modal"
                            @click="cerrarModalArchivo"
                        >
                          <i class="ti-close"></i> Cerrar
                        </button>
                        <button
                            type="button"
                            class="btn btn-general btn-primary"
                            @click="cargarArchivo"
                            :disabled="!archivo"
                        >
                          <i class="ti-ti-upload"></i> Cargar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="white-content-table">

          <form name="f1" id="formElement">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th></th>
                  <th>Id</th>
                  <th>{{ this.$config.labelTicket }}</th>
                  <th>{{ this.$config.labelCodigoCliente }}</th>
                  <th>{{ this.$config.labelTipo }}</th>
                  <th>{{ this.$config.labelFechaSolicitud }}</th>
                  <th>Dias Trascurridos</th>
                  <th>{{ $config.labelGerenciaGeneral }}</th>
                  <th>{{ $config.labelGerenciaVentas }}</th>

                  <th>Nombre</th>
                  <th>Establecimiento</th>
                  <th>Dirección</th>
                  <th>{{ this.$config.labelTipoCaso }}</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in solicitudes"
                    :key="item.id"
                    :class="
                      item?.tipoCaso === 'COOL INVENTORY' ? 'bgFila' : ''
                    "
                >
                  <td>
                    <a title="Eliminar"
                       href="javascript:void(0)"
                       @click="cerrarIndividual(item?.id)"
                       data-toggle="modal"
                       data-target="#exampleModal"
                    ><i class="ti-trash"></i
                    ></a>
                  </td>
                  <td>{{ item.id }}</td>
                  <td>{{ item.numeroTicket }}</td>
                  <td>{{ item.codigoCliente }}</td>
                  <td>{{ item.tipoSolicitud }}</td>
                  <td>{{ item.fechaSolicitud }}</td>
                  <td>
                    <div
                        class="badge"
                        :class="
                          item.diasTranscurridosLabolables < 3
                            ? 'badge-success'
                            : item.diasTranscurridosLabolables > 5
                            ? 'badge-danger'
                            : 'badge-warning'
                        "
                    >
                      {{ item.diasTranscurridosLabolables }} día(s)
                    </div>
                  </td>
                  <td>{{ item.gerenciaGeneral }}</td>
                  <td>{{ item.gerenciaVentas }}</td>

                  <td>{{ item.nombre }}</td>
                  <td>{{ item.nombreEstablecimiento }}</td>
                  <td>{{ item.direccion }}</td>
                  <td>{{ item.tipoCaso }}</td>
                </tr>
                </tbody>
              </table>


            </div>
          </form>
          <Modal :mensaje="mensaje" :method="procesarSeleccionados"/>
          <ModalMessages :mensaje="mensajeProceso" :titulo="titulo"/>
          <Alerta v-if="mostrar" titulo="" :mensaje="mensaje"/>

          <div class="row justify-content-center mt-3" v-if="rol !== 'USER'">
            <div class="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-6">
              <button
                  class="btn btn-primary btn-block"
                  @click="asignar('ASIGNAR')"
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModal"
              >
                <span class="icon-circle-check"></span> Asignar Todos
              </button>
            </div>
            <div class="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-6 mt-2 mt-sm-0">
              <button
                  class="btn btn-primary btn-block"
                  @click="asignar('CERRAR')"
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModal"
              >
                <i class="ti-minus"></i> Cerrar Todos
              </button>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Alerta from "../../../components/Alerta.vue";
import Modal from "../../../components/Modal.vue";
import ModalMessages from "../../../components/ModalMessages.vue";
import ButtonDescargarTemplate from "@/components/ButtonDescargarTemplate.vue";
import Filtros from "@/components/CustomFilters.vue";


export default {
  name: "GestionSolicitudes",
  components: {Filtros, ButtonDescargarTemplate, Alerta, Modal, ModalMessages},
  data() {
    return {
      archivo: null,
      displayModal: false,
      error: false,
      idSeleccionados: [],
      mostrar: false,
      displayConfirmacion: false,
      mensaje: "",
      filtroTipo: null,
      condiciones: [],
      badge: true,
      mensajeProceso: [],
      titulo: "",
      tipoProceso: "",
      idSeleccionado: null,
      totalRegistros: 0,
      country: "EC"
    };
  },
  created() {
    if (!this.$store.getters["auth/isLogged"]) {
      this.$store.dispatch("auth/logout");
      return false;
    }
  },

  mounted() {
    this.consultarXFiltros(this.data);
    this.country = this.$config.countryFlag
  },
  methods: {
    loaderSpinner() {
      this.loader = this.$loading.show();
    },
    agregarRequerimiento() {
      if ("EC" === this.country) {
        this.$router.push({name: "SolicitudForm"});
      } else {
        this.$router.push({name: "SolicitudFormZone"});
      }
    },

    abrirModalArchivo() {
      this.displayModal = true;
      this.error = false;
    },
    cerrarModalArchivo() {
      this.displayModal = false;
      this.archivo = null;
      if (this.$refs.file) {
        this.$refs.file.value = null;
      }
    },
    uploadFile() {
      this.archivo = this?.$refs?.file?.files[0];
    },
    async cargarArchivo() {
      try {
        this.loaderSpinner();
        const formData = new FormData();
        formData.append("file", this.archivo);
        const resp = await this.$store.dispatch(
            "solicitudes/cargarArchivo",
            formData
        );
        if (resp === "error") {
          this.error = true;
          this.loader.hide();
          return;
        }
        this.displayModal = false;
        this.archivo = null;
        if (this.$refs.file) {
          this.$refs.file.value = null;
        }
        this.loader.hide();
        this.titulo = "Cargar archivo";
        this.mensajeProceso = [resp];
        document.getElementById("modalButton").click();
        await this.consultarXFiltros(this.data);
      } catch (error) {
        this.error = true;
        this.loader.hide();
      }
    },

    asignar(tipoProceso) {
      this.tipoProceso = tipoProceso;
      if (tipoProceso === "ASIGNAR") {
        this.mensaje =
            "Se realizará la asignación de bodega para las solicitudes. Está seguro?";
        return;
      }
      if (tipoProceso === "CERRAR") {
        this.mensaje =
            "Se realizará el cierre de las solicitudes. Está seguro?";
      }
    },
    cerrarIndividual(id) {
      this.tipoProceso = "CERRAR INDIVIDUAL";
      this.idSeleccionado = id;
      this.mensaje =
          "Se realizará el cierre de la solicitud seleccionada. Está seguro?";
    },
    async procesarSeleccionados() {
      if (this.tipoProceso === "ASIGNAR") {
        await this.procesar();
        return;
      }
      if (this.tipoProceso === "CERRAR") {
        await this.cerrar();
        return;
      }
      if (this.tipoProceso === "CERRAR INDIVIDUAL") {
        await this.cerrarRequerimiento();
      }
    },

    async procesar() {
      let seleccionados = [];
      this.solicitudes.map((item) => seleccionados.push(item.id));

      try {
        this.loaderSpinner();
        const resp = await this.$store.dispatch(
            "solicitudes/procesar",
            seleccionados
        );
        this.loader.hide();
        await this.consultarXFiltros(this.data);
        this.titulo = "Procesar requerimiento";
        this.mensajeProceso = resp.result.contenido;
        document.getElementById("modalButton").click();
      } catch (error) {
        this.loader.hide();
        this.titulo = "Procesar requerimiento";
        this.mensajeProceso = ["No se pudo concluir con la asignación"];
        document.getElementById("modalButton").click();
      }
    },

    async cerrar() {
      let seleccionados = [];
      this.solicitudes.map((item) => seleccionados.push(item.id));

      try {
        this.loaderSpinner();
        await this.$store.dispatch("solicitudes/cerrar", seleccionados);
        this.loader.hide();
        await this.consultarXFiltros(this.data);
        this.titulo = "Cerrar requerimiento";
        this.mensajeProceso = ["El proceso se realizó con éxito"];
        document.getElementById("modalButton").click();
      } catch (error) {
        this.loader.hide();
        this.titulo = "Cerrar requerimiento";
        this.mensajeProceso = ["No se pudo concluir el proceso"];
        document.getElementById("modalButton").click();
      }
    },
    async cerrarRequerimiento() {
      let seleccionados = [this.idSeleccionado];
      try {
        this.loaderSpinner();
        await this.$store.dispatch("solicitudes/cerrar", seleccionados);
        this.loader.hide();
        await this.consultarXFiltros(this.data);
        this.titulo = "Cerrar requerimiento";
        this.mensajeProceso = ["El proceso se realizó con éxito"];
        document.getElementById("modalButton").click();
      } catch (error) {
        this.loader.hide();
        this.titulo = "Cerrar requerimiento";
        this.mensajeProceso = ["No se pudo concluir el proceso"];
        document.getElementById("modalButton").click();
      }
    },

    async consultarXFiltros(data) {
      this.condiciones = data?.condiciones;
      this.modoFiltros = true;

      this.loaderSpinner();
      try {
        await this.$store.dispatch("solicitudes/filtrar", {
          condiciones: data?.condiciones || [],
        });
        this.totalRegistros = this.solicitudes.length;
      } catch (error) {
        console.log(error);
      } finally {
        this.loader.hide();
      }
    },
  },

  computed: {
    isLogged() {
      return this.$store.getters["auth/isLogged"]
          ? this.$store.getters["auth/isLogged"]
          : false;
    },
    solicitudes() {
      return this.$store.getters["solicitudes/solicitudes"]
          ? this.$store.getters["solicitudes/solicitudes"]
          : [];
    },
    rol() {
      return this.$store.state.auth.rol;
    },
  },
};
</script>
