<template>

    <ProcesaMantenimientoBodegaEC v-if="$config.countryFlag==='EC'"/>
    <ProcesaMantenimientoBodegaZone v-else/>

</template>
<script>
import ProcesaMantenimientoBodegaEC from "@/views/solicitudesProveedor/components/ProcesaMantenimientoBodegaEC.vue";
import ProcesaMantenimientoBodegaZone from "@/views/solicitudesProveedor/components/ProcesaMantenimientoBodegaZone.vue";
import {defineComponent} from "vue";

export default defineComponent({
  components: {ProcesaMantenimientoBodegaEC, ProcesaMantenimientoBodegaZone}
})

</script>






