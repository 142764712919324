<template>
  <div class="nav-container">
    <div v-if="isPreventNavigation" class="nav-overlay" @click="preventNavigation"></div>
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <div class="menu-sl">
        <div>
          <a class="navbar-brand brand-logo ml-0 mr-0 pl-0 pr-0" style="width: 20%;" href="/">
            <img class="w-100 p-2" :src="locationImage" alt="Location"/>
          </a>
          <a class="navbar-brand brand-logo ml-0 mr-0 pl-0 pr-0" style="width: 80%;" href="/"
          ><img
              src="../assets/images/imgs/LogoHorizontalfondoblanco.png"
              class="w-100 p-3"
              alt="logo"
              style="background-color: #fff;"
          /></a>
        </div>



        <ul class="nav">
          <li v-for="(item, index) in menu" :key="index" class="nav-item">
            <div v-if="!item.submenus.length">
              <router-link class="nav-link" :to="{ name: item?.componente }">
                <i :class="item?.icon"></i>
                <span class="menu-title">{{ item?.name }}</span>
              </router-link>
            </div>
            <div v-else>
              <a
                  class="nav-link collapsed"
                  data-toggle="collapse"
                  :href="'#ui-'+ index"
                  aria-expanded="false"
                  :aria-controls="'ui-'+index"
              >
                <i :class="item?.icon"></i>
                <span class="menu-title">{{ item.name }}</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" :id="'ui-'+index" style="">
                <ul class="nav flex-column sub-menu">
                  <li v-for="(submenu, subIndex) in item.submenus" :key="subIndex" class="nav-item">
                    <div v-if="!submenu.submenus?.length">
                      <router-link class="nav-link" :to="{ name: submenu?.componente}">
                        <span class="menu-title">{{ submenu?.name }}</span>
                      </router-link>
                    </div>
                    <div v-else>
                      <a
                          class="nav-link collapsed"
                          data-toggle="collapse"
                          :href="'#ui-sub-'+ index + '-' + subIndex"
                          aria-expanded="false"
                          :aria-controls="'ui-sub-'+index + '-' + subIndex"
                      >
                        <span class="menu-title">{{ submenu?.name }}</span>
                        <i class="menu-arrow"></i>
                      </a>
                      <div class="collapse" :id="'ui-sub-'+index + '-' + subIndex" style="">
                        <ul class="nav flex-column sub-menu">
                          <li v-for="(subsubmenu, subsubIndex) in submenu.submenus" :key="subsubIndex" class="nav-item">
                            <router-link class="nav-link" :to="{ name: subsubmenu?.componente}">
                              <span class="menu-title">{{ subsubmenu?.name }}</span>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <div class="text-center">
              <small>
                <hr/>
                Build 5.1.0
              </small>
            </div>
          </li>
        </ul>



      </div>
    </nav>
  </div>
</template>
<script>
import {is} from "@vee-validate/rules";

export default {

  data() {
    return {
      locationImage: null,
    }
  },

  mounted() {
    this.consultarMenu();
  },

  methods: {
    is,
    async logout() {
      await this.$store.dispatch("auth/logout");
    },

    async consultarMenu() {
      await this.$store.dispatch("menu/consultar");
    },
    preventNavigation() {
    }
  },
  created() {
    const location = process.env.VUE_APP_COUNTRY || 'EC';
    this.locationImage = require(`../assets/images/location/${location}.png`);
  },
  computed: {
    rol() {
      if (!this?.$store?.state?.auth?.rol) {
        this.logout();
        return;
      }
      return this.$store.state.auth.rol;
    },

    menu() {
      return this.$store.getters["menu/menu"]
          ? this.$store.getters["menu/menu"]
          : false;
    },

    isPreventNavigation () {
      return this.$store.state.global.isPreventNavigation;
    }
  },
};
</script>
<style>
.nav-container {
  position: relative;
}

.nav-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 63, 161, 0.1);
}
</style>

